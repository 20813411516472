import * as L from 'leaflet';
import "leaflet-easybutton/src/easy-button.css";
import "leaflet-easybutton/src/easy-button.js";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as DeleteIcon } from "../../images/delete.svg";
import locateMe from "../../images/locateMe.svg";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import {
    ADD_LOCATION, DEFAULT_ATTENDANCE_SAVED_LOCATIONS, DEFAULT_GEOFENCE_LAT, DEFAULT_GEOFENCE_LNG, DEFAULT_GEOFENCE_RAD, IFRAME_PERMISSION_ERROR_MESSAGE,
    INSTRUCTOR_LOCATION_CONFIG,
    LOCATION_ACCURACY_THRESHOLD,
    LOCATION_MESSAGE, LOCATION_NAME_LABEL, LOCATION_NAME_LENGTH, LOCATION_NAME_PLACEHOLDER, MAX_RADIUS, MAX_SAVED_LOCATIONS, MAX_SAVED_LOCATIONS_ERROR, MIN_RADIUS, MODAL_CANCEL_TEXT, MODAL_SAVE_TEXT, RADIUS_LABEL, RADIUS_UNIT, SAVED_LOCATIONS_MODAL_DESCRIPTION, SAVED_LOCATIONS_MODAL_TITLE, USER_SETTINGS
} from "../../utils/constants";
import { fillSlider, hasDefaultLocation, hasDuplicateLocationLatLng, hasDuplicateLocationNames, isValidSavedLocations, notifyError, notifyWarning } from "../../utils/helpers";
import YuJaButton from "../standardization/YuJaButton";
import { YuJaTextBox } from "../standardization/YuJaTextBox";
import "./modal.css";
// import { YuJaRadioBox } from "../standardization/YuJaRadiobox";
import { DUPLICATE_LOCATION_COORDINATES_ERROR, DUPLICATE_LOCATION_NAME_ERROR } from "../../utils/toast-message-constants";
import "../styles.css";

export default function SavedLocationsModal({ show, setShow, savedLocations, setSavedLocations, userSettings, setUserSettings }) {
    const [selectedLocationIdx, setSelectedLocationIdx] = useState(-1);
    const savedLocationsRef = useRef([]);
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const circleRef = useRef(null);

    const addLocation = () => {
        const savedLocationsCopy = JSON.parse(JSON.stringify(savedLocations));
        savedLocationsCopy.push({
            name: `Location ${savedLocationsCopy.length + 1}`,
            lat: 0,
            lng: 0,
            rad: DEFAULT_GEOFENCE_RAD
        });
        setSavedLocations(savedLocationsCopy);
        setSelectedLocationIdx(savedLocationsCopy.length - 1);
    };

    const deleteLocation = (e, index) => {
        e.stopPropagation(); // prevent parent div from being clicked
        if (savedLocations.length === 1) { // reset to default if only 1 location
            const latlng = L.latLng(0, 0, 0);
            markerRef.current.setLatLng(latlng);
            circleRef.current.setLatLng(latlng);
            mapRef.current.setView(latlng, 2);
            setSavedLocations([{
                name: "Location 1",
                lat: 0,
                lng: 0,
                rad: DEFAULT_GEOFENCE_RAD
            }]);
            setSelectedLocationIdx(0);
            return;
        }
        const savedLocationsCopy = JSON.parse(JSON.stringify(savedLocations));
        savedLocationsCopy.splice(index, 1);
        setSavedLocations(savedLocationsCopy);
        if (selectedLocationIdx == index) {
            setSelectedLocationIdx(-1);
        } else if (selectedLocationIdx > index) {
            setSelectedLocationIdx(selectedLocationIdx - 1);
        }
    };

    const handleCancel = () => {
        setSavedLocations(JSON.parse(userSettings && userSettings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS) ? userSettings[USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS] : DEFAULT_ATTENDANCE_SAVED_LOCATIONS));
        setShow(false);
    };

    const handleSubmit = () => {
        if (hasDuplicateLocationNames(savedLocations)) {
            notifyError(DUPLICATE_LOCATION_NAME_ERROR);
            return;
        }
        if (hasDuplicateLocationLatLng(savedLocations)) {
            notifyError(DUPLICATE_LOCATION_COORDINATES_ERROR);
            return;
        }
        if (hasDefaultLocation(savedLocations)) {
            return;
        }
        const userSettingsCopy = JSON.parse(JSON.stringify(userSettings));
        userSettingsCopy[USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS] = JSON.stringify(savedLocations);
        setUserSettings(userSettingsCopy);
        setShow(false);
    };

    useEffect(() => {
        if (!show) {
            setSelectedLocationIdx(-1);
            return;
        }
        if (savedLocations.length > 0) {
            if (selectedLocationIdx === -1) setSelectedLocationIdx(0);
            return;
        }
        addLocation();
    }, [show, savedLocations]);

    useEffect(() => {
        if (selectedLocationIdx === -1) return;
        console.log("initializing map");
        const { lat, lng, rad } = savedLocations[selectedLocationIdx];

        var map = L.map('map').setView([lat, lng], 2);
        mapRef.current = map;
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            minZoom: 2,
            maxNativeZoom: 19,
            maxZoom: 25,
        }).addTo(map);
        map.attributionControl.setPrefix(false);

        const defaultMarker = new L.icon({
            iconUrl: require('../../images/geofence_center.png'),
            iconSize: [64, 64],
            iconAnchor: [32, 64],
        });

        var marker = L.marker([lat, lng], { icon: defaultMarker }).addTo(map);
        markerRef.current = marker;

        var circle = L.circle([lat, lng], {
            color: '#42296E',
            fillColor: '#42296E',
            fillOpacity: 0.3,
            radius: rad
        }).addTo(map);
        if (lat !== DEFAULT_GEOFENCE_LAT || lng !== DEFAULT_GEOFENCE_LNG || rad !== DEFAULT_GEOFENCE_RAD) { // auto zoom if geofence is not {0, 0, 0}
            map.fitBounds(circle.getBounds());
        }
        circleRef.current = circle;

        L.easyButton({
            id: 'leaflet-map-locate-me',
            leafletClasses: false,
            states: [{
                stateName: 'locate-me',
                icon: `<img src=${locateMe} width="30px" height="30px" alt="locate me">`, onClick: () => {
                    navigator.geolocation.getCurrentPosition((position) => {
                        const coordinates = position?.coords;
                        console.log(coordinates);
                        if (coordinates.accuracy > LOCATION_ACCURACY_THRESHOLD) {
                            notifyWarning(LOCATION_MESSAGE.LOWER_ACCURACY);
                        }

                        const savedLocationsCopy = JSON.parse(JSON.stringify(savedLocationsRef.current));
                        savedLocationsCopy[selectedLocationIdx].lat = coordinates.latitude;
                        savedLocationsCopy[selectedLocationIdx].lng = coordinates.longitude;
                        setSavedLocations(savedLocationsCopy);
                        const latlng = L.latLng(coordinates.latitude, coordinates.longitude, 0);
                        marker.setLatLng(latlng);
                        circle.setLatLng(latlng);
                        map.fitBounds(circle.getBounds());
                    }, (e) => {
                        console.log(e);
                        if (e.message === IFRAME_PERMISSION_ERROR_MESSAGE) {
                            notifyError(LOCATION_MESSAGE.IFRAME_PERMISSION_ERROR);
                            return;
                        }
                        switch (e.code) {
                            case 1: // PERMISSION_DENIED
                                notifyError(e.message === IFRAME_PERMISSION_ERROR_MESSAGE ? LOCATION_MESSAGE.IFRAME_PERMISSION_ERROR : LOCATION_MESSAGE.PERMISSION_DENIED);
                                break;
                            // case 2: // POSITION_UNAVAILABLE
                            // case 3: // TIMEOUT
                            default:
                                notifyError(LOCATION_MESSAGE.UNKNOWN_ERROR);
                                break;
                        }
                    }, INSTRUCTOR_LOCATION_CONFIG);
                }
            }]
        }).addTo(map);

        map.on('click', e => {
            const savedLocationsCopy = JSON.parse(JSON.stringify(savedLocationsRef.current));
            const savedLocation = JSON.parse(JSON.stringify(savedLocationsCopy[selectedLocationIdx]));
            savedLocation.lat = e.latlng.lat;
            savedLocation.lng = e.latlng.lng;
            savedLocationsCopy[selectedLocationIdx] = savedLocation;
            setSavedLocations(savedLocationsCopy);
            marker.setLatLng(e.latlng);
            circle.setLatLng(e.latlng);
            map.fitBounds(circle.getBounds());
        });

        return () => {
            console.log("cleaning up");
            map.remove();
        };
    }, [selectedLocationIdx]);

    useEffect(() => {
        // console.log(selectedLocationIdx, savedLocations);
        savedLocationsRef.current = savedLocations;

        const slider = document.querySelector('.radius-slider');
        if (!slider) return;
        
        fillSlider(slider);
    }, [savedLocations, selectedLocationIdx]);

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="saved-locations-modal"
        >
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ModalClose onClick={() => setShow(false)} className="modal-close" />
                <Modal.Title className="modal-title" id="contained-modal-title-vcenter">
                    {SAVED_LOCATIONS_MODAL_TITLE}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* leaflet imports */}
                <link
                    rel="stylesheet"
                    href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
                    integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
                    crossOrigin=""
                />
                <script
                    src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
                    integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
                    crossOrigin=""
                />

                {/* modal body display */}
                <div className="saved-locations-description">
                    {SAVED_LOCATIONS_MODAL_DESCRIPTION}
                </div>

                <div className="saved-locations-modal-body">
                <div className="saved-locations-list-container">
                    <YuJaButton onClick={addLocation} disabled={savedLocations.length === MAX_SAVED_LOCATIONS}>
                        {ADD_LOCATION}
                    </YuJaButton>
                    {/* <div className="saved-locations-list-header">
                        {savedLocations.length}/{MAX_SAVED_LOCATIONS} {SAVED_LOCATIONS_SUFFIX}
                    </div> */}
                    {savedLocations.length === MAX_SAVED_LOCATIONS && 
                        <div className="saved-locations-max-text">
                            {MAX_SAVED_LOCATIONS_ERROR}
                        </div>
                    }
                    <div className="saved-locations-list">
                        {savedLocations.map((location, index) => (
                            <div key={index} className={`saved-location-card ${selectedLocationIdx === index && "saved-location-card-selected"}`} onClick={() => setSelectedLocationIdx(index)}>
                                {/* <YuJaRadioBox
                                    checked={selectedLocationIdx === index}
                                    onClick={() => setSelectedLocationIdx(index)}
                                    value={index}
                                    name="location"
                                    size={20}
                                    style={{ marginRight: 10 }}
                                /> */}
                                <div className="saved-location-name">{location?.name ? location.name : LOCATION_NAME_PLACEHOLDER}</div>
                                <div className="saved-location-buttons">
                                    <DeleteIcon style={{width: 18, cursor: "pointer"}} onClick={e => deleteLocation(e, index)}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {selectedLocationIdx !== -1 &&
                    <div className="saved-location-display-container">
                        <div id="map" style={{ width: "100%", height: 400 }} />
                        <div className="radius-row" style={{ width: "100%", overflowY: "hidden" }}>
                            <label htmlFor="radius" className="radius-label">{RADIUS_LABEL}</label>
                            <input
                                type="range"
                                min={10}
                                max={10000}
                                value={savedLocations[selectedLocationIdx]?.rad}
                                onChange={(e) => {
                                    const savedLocationsCopy = JSON.parse(JSON.stringify(savedLocations));
                                    savedLocationsCopy[selectedLocationIdx].rad = parseInt(e.target.value, 10);
                                    setSavedLocations(savedLocationsCopy);
                                    circleRef.current.setRadius(e.target.value);
                                    mapRef.current.fitBounds(circleRef.current.getBounds());
                                }}
                                className="radius-slider"
                            />
                            <YuJaTextBox
                                placeholder={DEFAULT_GEOFENCE_RAD}
                                value={savedLocations[selectedLocationIdx]?.rad}
                                onChange={(e) => {
                                    const r = e.target.value.replace(/[^0-9]/g, '');
                                    const savedLocationsCopy = JSON.parse(JSON.stringify(savedLocations));
                                    savedLocationsCopy[selectedLocationIdx].rad = parseInt(e.target.value, 10);
                                    setSavedLocations(savedLocationsCopy);
                                    const rad = parseInt(r, 10);
                                    if (isNaN(rad) || rad < MIN_RADIUS || rad > MAX_RADIUS) return;
                                    circleRef.current.setRadius(r);
                                    mapRef.current.fitBounds(circleRef.current.getBounds());
                                }}
                                inputAlign={"center"}
                                containerStyle={{ width: 50, marginRight: 5, fontSize: 13, padding: 6 }}
                            />
                            <span className="radius-unit"> {RADIUS_UNIT}</span>
                        </div>
                        <div className="location-name-container">
                            <div className="location-name-label">{LOCATION_NAME_LABEL}</div>
                            <YuJaTextBox
                                placeholder={LOCATION_NAME_PLACEHOLDER}
                                value={savedLocations[selectedLocationIdx]?.name}
                                onChange={(e) => {
                                    const savedLocationsCopy = JSON.parse(JSON.stringify(savedLocations));
                                    const savedLocation = JSON.parse(JSON.stringify(savedLocationsCopy[selectedLocationIdx]));
                                    savedLocation.name = e.target.value;
                                    savedLocationsCopy[selectedLocationIdx] = savedLocation;
                                    setSavedLocations(savedLocationsCopy);
                                }}
                                containerStyle={{ fontSize: 13, padding: 6 }}
                                maxLength={LOCATION_NAME_LENGTH}
                                showRemaining={true}
                            />
                        </div>
                    </div>
                }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton
                    type="secondary"
                    onClick={handleCancel}
                    style={{ minWidth: 80 }}
                >
                    {MODAL_CANCEL_TEXT}
                </YuJaButton>
                <YuJaButton
                    onClick={handleSubmit}
                    disabled={!isValidSavedLocations(savedLocations) || JSON.stringify(savedLocations) === userSettings[USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS]}
                    style={{ minWidth: 80 }}
                >
                    {MODAL_SAVE_TEXT}
                </YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}