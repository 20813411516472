import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useWindowDimensions } from "react-native-web";
import { ReactComponent as Prohibit } from "../../images/Prohibit.svg";
import styles from "./YuJaTooltip.module.css";


export const YuJaTooltip = ({text="", placement="top", children, tooltipStyle={}, visibleInTouchableDevice=false, prohibit=false, ...props}) => {
    const toolTrigger = useRef();
    const tooltipRef = useRef();
    const tooltipTriangleRef = useRef();
    const windowDimensions = useWindowDimensions();
    const [placementVar, setPlacementVar] = useState(placement);
    const [isTouchAble, setIsTouchAble] = useState(window.navigator.maxTouchPoints > 0);
    const [maskVisible, setMaskVisible] = useState(visibleInTouchableDevice);


    useEffect(() => {
        resetToolTipPosition();
        resetTrianglePosition();
    }, [windowDimensions, toolTrigger?.current?.getBoundingClientRect()]);

    const resetToolTipPosition = () => {
        //only left and top can be used as anchor, all those variables are just for the current window, it doesn't count the scroll offset
        const {width: windowWidth, height: windowHeight} = windowDimensions;
        const {width, height, top, left} = toolTrigger.current.getBoundingClientRect();
        const {width: toolTipWidth, height: tooltipHeight} = tooltipRef.current.getBoundingClientRect();

        tooltipRef.current.style.top = null;
        tooltipRef.current.style.bottom = null;
        tooltipRef.current.style.left = null;
        tooltipRef.current.style.right = null;
        let adjustedTop = 0;
        let adjustedLeft = 0;
        setPlacementVar(placement);
        if (placement === "right") {
            adjustedTop = top - (tooltipHeight / 2 - height / 2);
            adjustedLeft = left + width + 10;
            if (adjustedLeft + toolTipWidth > windowWidth) {//not enough place at right
                adjustedLeft = left - toolTipWidth - 10;
                setPlacementVar("left");
            }
        } else if (placement === "left") {
            adjustedTop = top - (tooltipHeight / 2 - height / 2);
            adjustedLeft = left - toolTipWidth - 10;
            if (adjustedLeft < 0) {//not enough place at left
                setPlacementVar("right");
                adjustedLeft = left + width + 10;
            }
        } else if (placement === "top") {//not enough place at top
            adjustedTop = top - tooltipHeight - 10;
            adjustedLeft = left - (toolTipWidth / 2 - width / 2);
            if (adjustedTop < 0) {
                setPlacementVar("bottom");
                adjustedTop = top + height + 10;
            }
        } else if (placement === "bottom") {//not enough place at bottom
            adjustedTop = top + height + 10;
            adjustedLeft = left - (toolTipWidth / 2 - width / 2);
            if (adjustedTop + tooltipHeight > windowHeight) {
                setPlacementVar("top");
                adjustedTop = top - tooltipHeight - 10;
            }
        }
        if (adjustedLeft < 0) {
            adjustedLeft = 10;
        } else if (adjustedLeft + toolTipWidth > windowWidth) {
            adjustedLeft = windowWidth - toolTipWidth - 10;
        } else if (adjustedTop < 0) {
            adjustedTop = 10;
        } else if (adjustedTop + tooltipHeight > windowHeight) {
            adjustedTop = windowHeight - tooltipHeight - 10;
        }

        tooltipRef.current.style.top = `${adjustedTop + window.scrollY}px`;
        tooltipRef.current.style.left = `${adjustedLeft + window.scrollX}px`;
    }

    const onMouseEnter = (e, callbackF) => {
        if (callbackF) {
            callbackF(e);
        }
        resetToolTipPosition();
        resetTrianglePosition();
        tooltipRef.current.style.visibility = "visible";
        tooltipRef.current.style.zIndex = "10000";
        tooltipTriangleRef.current.style.visibility = "visible";
        tooltipTriangleRef.current.style.zIndex = "10000";
        setMaskVisible(true);
    }

    const onMouseLeave = (e, callbackF) => {
       if (callbackF) {
           callbackF(e);
       }
        tooltipRef.current.style.visibility = "hidden";
        tooltipRef.current.style.zIndex = "-1000";
        tooltipTriangleRef.current.style.visibility = "hidden";
        tooltipTriangleRef.current.style.zIndex = "-1000";
        setMaskVisible(false);
    }

    const resetTrianglePosition = () => {
        //only left and top can be used as anchor, all those variables are just for the current window, it doesn't count the scroll offset
        const {width: windowWidth, height: windowHeight} = windowDimensions;
        const {width, height, top, left } = toolTrigger.current.getBoundingClientRect();
        let adjustedTop = 0;
        let adjustedLeft = 0;
        if (placementVar === "right") {
            adjustedLeft = left + width;
            adjustedTop = top + height / 2;
        } else if (placementVar === "left") {
            adjustedLeft = left - 10;
            adjustedTop = top + height / 2;
        } else if (placementVar === "top") {//not enough place at top
            adjustedLeft = left + width / 2;
            adjustedTop = top - 10;
        } else if (placementVar === "bottom") {//not enough place at bottom
            adjustedLeft = left + width / 2;
            adjustedTop = top + height;
        }


        if (adjustedLeft < 0) {
            adjustedLeft = 10;
        } else if (adjustedLeft > windowWidth) {
            adjustedLeft = windowWidth- 10;
        } else if (adjustedTop < 0) {
            adjustedTop = 10;
        } else if (adjustedTop > windowHeight) {
            adjustedTop = windowHeight - 10;
        }
        tooltipTriangleRef.current.style.top = `${adjustedTop + window.scrollY}px`;
        tooltipTriangleRef.current.style.left = `${adjustedLeft + window.scrollX}px`;
    }

    useEffect(() => {
        resetTrianglePosition();
    }, [placementVar]);

    return (
        <>
            {/*case 1: child is just plain text*/}
            {!children.type &&
                <div
                    className={styles.tooltipTrigger}
                    ref={toolTrigger}
                    onMouseLeave={isTouchAble ? () => {} : (e) => onMouseEnter(e)}
                    onMouseEnter={isTouchAble ? () => {} : (e) => onMouseLeave(e)}
                    onClick={isTouchAble ? (e) => onMouseEnter(e): () => {}}
                    tabIndex={0}
                    onFocus={(e) => onMouseEnter(e)}
                    onBlur={(e) => onMouseLeave(e)}
                >
                    {children}
                </div>
            }

            {/*case 2: complicated children*/}
            {!!children.type &&
                React.cloneElement(
                    children,
                    {
                        ...children.props,
                        ref: toolTrigger,
                        onMouseEnter:  isTouchAble ? () => {} : (e) => onMouseEnter(e, children.props.onMouseEnter),
                        onMouseLeave: isTouchAble ? () => {} : (e) => onMouseLeave(e, children.props.onMouseLeave),
                        onClick: isTouchAble ? (e) => onMouseEnter(e, children.props.onClick): children.props.onClick,
                        tabIndex: 0,
                        onFocus: (e) => onMouseEnter(e, children.props.onMouseEnter),
                        onBlur:  (e) => onMouseLeave(e, children.props.onMouseLeave)
                    }
                )
            }


            {!isTouchAble &&
                <TooltipWrapper>
                    <div
                        ref={tooltipRef}
                        className={`${styles.tooltip}`}
                        style={tooltipStyle}
                        onMouseLeave={(e) => onMouseLeave(e)}
                    >
                        {prohibit ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Prohibit style={{ width: "25px", height: "25px", marginRight: "5px" }} />
                            {text}
                        </div> :
                            text
                        }
                    </div>
                    <div ref={tooltipTriangleRef} className={`${styles[placementVar]}`}/>
                </TooltipWrapper>
            }

            {isTouchAble &&
                <>
                    <TooltipWrapper>
                        <div
                            ref={tooltipRef}
                            className={`${styles.tooltip} ${visibleInTouchableDevice ? styles.visibleInTouchableDevice : ""}`}
                            style={tooltipStyle}
                        >
                            {prohibit ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Prohibit style={{ width: "25px", height: "25px", marginRight: "5px" }} />
                                {text}
                            </div> :
                                text
                            }
                        </div>
                        <div ref={tooltipTriangleRef} className={`${styles[placementVar]}  ${visibleInTouchableDevice ? styles.visibleInTouchableDevice : ""}`}/>
                    </TooltipWrapper>
                    {maskVisible &&
                        <div style={{position: "fixed", height: '100vh', width: '100vw', top: 0, left: 0, zIndex: 1000}} onTouchStart={onMouseLeave}/>
                    }

                </>
            }
        </>

    )
}



const TooltipWrapper = ({children}) => {
    const [wrapper, ] = useState(() => {
        const div = document.createElement("div");
        div.id = `YuJa_Tooltip_${Date.now().toString()}`;
        return div;

    });
    useEffect(() => {
        const rootEle = document.getElementById('root');
        rootEle.appendChild(wrapper);

        return () => {
            if (wrapper) {
                wrapper.remove();
            }
        }
    }, []);



    return ReactDOM.createPortal(children, wrapper);

}


YuJaTooltip.propTypes = {
    text: PropTypes.string,
    placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
}