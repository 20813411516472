import React, { useCallback, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { UserApiCalls } from "../../apis/UserApiCalls";
import useMobileAccess from "../../hooks/useMobileAccess";
import { MENU_LIST, DEFAULT_ROWS_PER_PAGE, FEEDBACK_DATE_HEADER, QUESTION_BANK_ACTION_HEADER, QUESTION_BANK_PAGE_TEXT, QUESTION_BANK_TITLE_HEADER, TEXTBOX } from "../../utils/constants";
import { getPriority } from "../../utils/helpers";
import styles from "./QuestionBank.module.css";
import QuestionBankSearchForm from "./QuestionBankSearchForm";
import QuestionBankTable from "./QuestionBankTable";

export default function QuestionBankPage({ setPageIdentifier }) {
    const [pageIndex, setPageIndex] = useState(0);
    const [dataReady, setDataReady] = useState(false);
    const [questionProfileData, setQuestionProfileData] = useState([]);
    const [keys, setKeys] = useState([]);
    const isMobileScreen = useMobileAccess();
    const [filter, setFilter] = useState({});
    const { getQuestionProfile } = UserApiCalls(); 
    const [nameSort, setNameSort] = useState(1); 
    const [dateSort, setDateSort] = useState(0);
    
    const columns = [
        { header: FEEDBACK_DATE_HEADER, accessorKey: "creationTime", size: isMobileScreen ? 90 : 110 , id: FEEDBACK_DATE_HEADER },
        { header: QUESTION_BANK_TITLE_HEADER, accessorKey: "ProfileTitle", size: isMobileScreen ? 90 : 110 , id: QUESTION_BANK_TITLE_HEADER },
        { header: QUESTION_BANK_ACTION_HEADER, accessorKey: "Actions", size: isMobileScreen ? 90 : 110, id: QUESTION_BANK_ACTION_HEADER},,
    ];

    const getPollInformation = async (pageIndex=0) => {
        setDataReady(false);
        const res = await getQuestionProfile();
        let sortedArray = Object.entries(res).sort((a, b) => new Date(Date.parse(b[1].creationTime)) - new Date(Date.parse(a[1].creationTime)));
        const sortedMap = new Map(sortedArray);
        sortedArray = sortedArray.map(([key, value]) => value);
        setQuestionProfileData(sortedArray); 
        setKeys(Array.from(sortedMap.keys()));
        setPageIndex(pageIndex);
        setDataReady(true);
      };

    useEffect(() => {
        getPollInformation();
    }, [])

    const handleSubmit = useCallback(({ ...filterObj }) => {
        setFilter(filterObj);
        setPageIndex(0);
      }, []);

      const sortDateHelper = () => {
        if(!!questionProfileData) {
          if(dateSort === 0){
            setQuestionProfileData([...questionProfileData].sort(function (a, b) {
                return new Date(Date.parse(a.creationTime)) - new Date(Date.parse(b.creationTime));
              }));
              setDateSort(1);
            }
            else {
                setQuestionProfileData([...questionProfileData].sort(function (a, b) {
                return new Date(Date.parse(b.creationTime)) - new Date(Date.parse(a.creationTime));
              }));
              setDateSort(0);
            }
          }
      }
    
      const sortNameHelper = () => {
        if(!!questionProfileData) {
            const newPollData = [...questionProfileData].sort(function (a, b) {
                const nameA = a.ProfileTitle?.toLowerCase();
                const nameB = b.ProfileTitle?.toLowerCase();
    
                const priorityA = getPriority(nameA);
                const priorityB = getPriority(nameB);
    
                if (priorityA < priorityB) return -1 * nameSort;
                if (priorityA > priorityB) return 1 * nameSort;
    
                if (nameA < nameB) return -1 * nameSort;
                if (nameA > nameB) return 1 * nameSort;
                return 0;
              })
            setQuestionProfileData(newPollData);
            console.log(newPollData)
            setNameSort(-nameSort);
          }
      }

    useEffect(() => {
      setPageIdentifier(MENU_LIST.QUESTION_BANKS.name);
    }, []);
    
    return (
        <>
        <div style={{padding: "30px 5% 30px 5%", display: "flex", flexDirection: "column", height: "100%"}}> 
        <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>{QUESTION_BANK_PAGE_TEXT}</div>
        </div>
        <div style={{display: "flex" , justifyContent: "center", alignItems: "center", marginTop: "15px", gap: "5px"}}>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}> <div style={{width: "80%"}}><QuestionBankSearchForm handleSubmit={handleSubmit} /> </div></div>
        {dataReady && <div style={{flex: "1"}}> <QuestionBankTable columns={columns} data={questionProfileData} pageIdx={pageIndex} nameSort={sortNameHelper} dateSortData={dateSort} nameSortData={nameSort} dateSort={sortDateHelper} globalFilterObj={filter} keys={keys} getQuestionProfiles={getPollInformation}/> </div>}
        {
            !dataReady &&   <div className={styles.skeletonTableContainer}> <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <div className={styles.skeletonHeaderContainer}>
            <div className={styles.skeletonHeaderCell}>{FEEDBACK_DATE_HEADER}</div> 
              <div className={styles.skeletonHeaderCell}>{QUESTION_BANK_TITLE_HEADER}</div> 
               <div className={styles.skeletonHeaderCell}>{QUESTION_BANK_ACTION_HEADER}</div> 
            </div>
            <div className={styles.skeletonBodyContainer}>
              {Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                return (<div className={styles.skeletonBodyRow}>
                  <Skeleton borderRadius={10} height={20} inline={true} width={`33%`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  <Skeleton borderRadius={10} height={20} inline={true} width={`33%`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  <Skeleton borderRadius={10} height={20} inline={true} width={`33%`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                </div>)
              })}
            </div>
          </SkeletonTheme>
          </div>
        }
        </div>
        </>

    )



}