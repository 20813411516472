import { flexRender, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { UserApiCalls } from "../../apis/UserApiCalls";
import FormModal from "../../components/modals/FormModal";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import NormalPagination from "../../components/pagination/NormalPagination";
import { YuJaKebabMenu } from "../../components/standardization/YuJaKebabMenu";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { ReactComponent as EditIcon } from "../../images/pencil.svg";
import { ReactComponent as ShareIcon } from "../../images/share-poll.svg";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import { ACTIONS_LABEL, ACTION_HEADER, CREATION_ROLES, DEFAULT_ROWS_PER_PAGE, DELETE_QUES_BANK, EDIT_QUES_BANK, FEEDBACK_DATE_HEADER, MODAL_CANCEL_TEXT, MODAL_DELETE_TEXT, NO_RESULTS_FOUND, QUESTION_BANK_ACTION_HEADER, QUESTION_BANK_ACTION_SHARE, QUESTION_BANK_TITLE_HEADER, QUESTION_PROFILE_ACTION_ONE, QUESTION_PROFILE_ACTION_TWO, QUESTION_PROFILE_MESSAGE, QUESTION_PROFILE_TITLE, SHARE_QUESTION_BANK_BODY, SHARE_QUES_BANK, SHARE_TEXT, TEXTBOX } from "../../utils/constants";
import styles from "./QuestionBank.module.css";

export default React.memo(QuestionBankTable);
function QuestionBankTable({ data = [], columns = [], pageIdx=0, keys, globalFilterObj = {}, getQuestionProfiles, dateSort,  dateSortData, nameSort, nameSortData,}) {
    const history = useHistory();
    const [selectedQuestionBank, setSelectedQuestionBank] = useState(""); 
    const [selectedQuestionBankName, setSelectedQuestionBankName] = useState(""); 
    const [alertShow, setAlertShow] = useState(false); 
    const [actionshow, setActionShow] = useState(false); 
    const { deleteQuestionProfile, loadUsers, shareQuestionBankToUsers} = UserApiCalls();
    const [, getSession] = useLocalStorageNew("session", {});
    const [globalFilter, setGlobalFilter] = useState("");
    const [sortSelected, setSortSelected] = useState(0);
    const [sharePollModal, setSharePollModal] = useState(false);
    const [selectedMemberOpts, setSelectedMemberOpts] = useState([]);
    const [userData, setUserData] = useState([]);
    const [memberSelectedError, setMemberSelectedError] = useState("");

    useEffect(async () => {
        let res2 = await loadUsers(true);
        res2 = res2.filter(user => user.userId !== getSession().userId && (user.role === CREATION_ROLES.CREATOR.value || user.role === CREATION_ROLES.IT_MANAGER.value));
        const options = res2.map(user => ({value: user.userId, label: `${user.userName} (${user.email})`}));
        setUserData(options);
    }, [])


    const filterCustomize = (row, columnId, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return true;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase() : "";
        if (filteredKeyword && ( (!row.getValue(QUESTION_BANK_TITLE_HEADER) || (row.getValue(QUESTION_BANK_TITLE_HEADER).toLowerCase().indexOf(filteredKeyword) <= -1)))) {
                return false;
        }
        return true; 
            
    }

    const shareQuestionBank = async () => {
        if(selectedMemberOpts.length === 0) {
            setMemberSelectedError("Please select at least 1 member!");
        } else if (!selectedQuestionBank) {
            setMemberSelectedError("Please select at least 1 question bank!");
        } else {
            console.log(selectedMemberOpts)
            shareQuestionBankToUsers(selectedMemberOpts, selectedQuestionBank);
            setSharePollModal(false);
            setSelectedMemberOpts([]);
        }
    };

    const sharePollModalConfig = {
        title: SHARE_QUES_BANK,
        subText: SHARE_QUESTION_BANK_BODY, 
        submitButtonText: SHARE_TEXT,
        clearForm: () => {setSharePollModal(false); setSelectedMemberOpts([])},
        submit: shareQuestionBank,

        form: [
            {
                label: "Members",
                value: selectedMemberOpts,
                options: userData,
                isMulti: true,
                setFunction: setSelectedMemberOpts,
                type: "select4",
                error: memberSelectedError
            },
        ],
    };

    const pageOptions = useMemo(() => {
        let options = [];
        // console.log(responses.length, DEFAULT_ROWS_PER_PAGE);
        let nPages = Math.ceil(data.length / DEFAULT_ROWS_PER_PAGE);
        // console.log(nPages);
        for (let i = 0; i < nPages; i++) options.push(i);
        return options;
    }, [data]);

    const alertConfig = {
        title: QUESTION_PROFILE_TITLE,
        okText: MODAL_DELETE_TEXT,
        cancelText: MODAL_CANCEL_TEXT, 
        submit: async () => {
          setAlertShow(false);
          const res = await deleteQuestionProfile(selectedQuestionBank);
          if (res.success) {
            getQuestionProfiles();
            }
        },
        close:  async () => {
          setAlertShow(false);
        }, 
    };

    const handleSort = (header) => {
        if (header === FEEDBACK_DATE_HEADER) {
            dateSort();
            setSortSelected(0);
        }
        else if(header === QUESTION_BANK_TITLE_HEADER) {
            nameSort();
            setSortSelected(1);
        }
    };

    const renderDate = (date) => {
        if (!date) {
            return "";
        }
        const datetimeObject = new Date(date);
        const day = datetimeObject.getDate().toString().padStart(2, '0');
        const month = (datetimeObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = datetimeObject.getFullYear();
    
        // Format the Date object into the desired format (dd-mm-yyyy)
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate
    }

    const onDelete = async (questionProfileId, questionBankName) => {
        setSelectedQuestionBank(questionProfileId); 
        setSelectedQuestionBankName(questionBankName);
        setAlertShow(true); 
        setActionShow(false);
    }

    const onEdit = (questionProfileId) => {

        history.push({ pathname: "/questions/edit/" + questionProfileId });
    }

    // const handleSort = (header) => {
    //     if(selectedRow) {
    //         selectedRow.getToggleExpandedHandler()();
    //         setSelectedRow("");
    //     }
    //     if (header === FEEDBACK_DATE_HEADER) {
    //         dateSort();
    //         setSortSelected(0);
    //     }
    //     else if(header === FEEDBACK_STUDENT_HEADER) {
    //         nameSort();
    //         setSortSelected(1);
    //     }
    // };


    const {
		getHeaderGroups,
		getRowModel,
		setPageIndex,
		getCanPreviousPage,
		previousPage,
		getCanNextPage,
		nextPage,
		getPageCount,
		getState,
	} = useReactTable({
		columns: columns,
		data: data,
        state: {
            globalFilter : globalFilter,
          },
		paginateExpandedRows: false,
		autoResetPage: true,
        maxLeafRowFilterDepth: 0,
        getFilteredRowModel: getFilteredRowModel(),
		getCoreRowModel: getCoreRowModel(),
        onGlobalFilterChanged: setGlobalFilter,
        globalFilterFn: filterCustomize,
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
	});

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [data, globalFilterObj]);

    return (
        <>
            <YuJaGeneralAlert
                show={alertShow}
                setModalShow={setAlertShow}
                content={QUESTION_PROFILE_MESSAGE + `\"${selectedQuestionBankName}\"?`}
                config={alertConfig}
                AlertType={MODAL_DELETE_TEXT}
            />
            {sharePollModal && <FormModal
              show={sharePollModal}
              setModalShow={setSharePollModal}
              modalConfig={sharePollModalConfig}
            />}
            <div className={styles.tableContainer} style={{borderRadius: "0px", height: "100%", flexDirection: "column"}}>
                <Table hover>
                    <thead>
                        {getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(column => (
                                    <th tabIndex={0} {...{
                                        key: column.id,
                                        style: {padding: column.column.columnDef.header === FEEDBACK_DATE_HEADER ? "0px 0px 0px 10px" : 0 , width: column.getSize(), backgroundClip: "padding-box", textAlign: column.column.columnDef.header ===  ACTION_HEADER ? 'center' : "left"}
                                    }}>
                                        {flexRender(column.column.columnDef.header, column.getContext())}
                                        { ((column.column.columnDef.header === FEEDBACK_DATE_HEADER && dateSortData === 0 && sortSelected === 0)|| (column.column.columnDef.header  === QUESTION_BANK_TITLE_HEADER && nameSortData === 1 && sortSelected === 1)) && <SortDown tabIndex={0} role={TEXTBOX} aria-label={column.column.columnDef.header + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => {handleSort(column.column.columnDef.header)}}/>}
                                        {((column.column.columnDef.header === FEEDBACK_DATE_HEADER && dateSortData === 1 && sortSelected === 0) || (column.column.columnDef.header === QUESTION_BANK_TITLE_HEADER && nameSortData === -1 && sortSelected === 1)) && <SortUp tabIndex={0} role={TEXTBOX} aria-label={ column.column.columnDef.header + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => {handleSort(column.column.columnDef.header)}}/>}
                                        { ((column.column.columnDef.header === FEEDBACK_DATE_HEADER && sortSelected === 1)|| (column.column.columnDef.header  === QUESTION_BANK_TITLE_HEADER && sortSelected === 0)) && <SortGrey tabIndex={0} role={TEXTBOX} aria-label={column.column.columnDef.header + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => {handleSort(column.column.columnDef.header)}}/>}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {getRowModel().rows.length!== 0 ? getRowModel().rows.map((row) => {
                            return (
                                <tr >
                                    { row.depth === 0 && row.getVisibleCells().map((cell) => {
                                        if (cell.column.columnDef.header === FEEDBACK_DATE_HEADER) {
                                            return (
                                                <td tabIndex={0} role={TEXTBOX} >
                                                    <div style={{textAlign: "left", paddingLeft: "10px"}}> {renderDate(cell.getValue())} </div>
                                                </td>
                                                ) 
                                        }
                                        else if (cell.column.columnDef.header === QUESTION_BANK_ACTION_HEADER) {
                                            return (
                                                <td>
                                                    <div aria-label={ACTIONS_LABEL} role={TEXTBOX} style={{display: "flex", justifyContent: "center"}}>
                                                        <YuJaKebabMenu>
                                                            <YuJaKebabMenu.Menu>
                                                                <YuJaKebabMenu.Item label={EDIT_QUES_BANK} onClick={() => onEdit(keys[cell.row.index])}><EditIcon style={{width: 18, marginRight: 5}}/>{QUESTION_PROFILE_ACTION_ONE}</YuJaKebabMenu.Item>
                                                                <YuJaKebabMenu.Item label={DELETE_QUES_BANK} onClick={() => onDelete(keys[cell.row.index], cell.row.original.ProfileTitle)}><DeleteIcon style={{width: 18, marginRight: 5}}/>{QUESTION_PROFILE_ACTION_TWO}</YuJaKebabMenu.Item>
                                                                <YuJaKebabMenu.Item label={SHARE_QUES_BANK} onClick={() => {setSelectedQuestionBank(keys[cell.row.index]); setSharePollModal(true)}}><ShareIcon style={{width: 18, marginRight: 5}}/>{QUESTION_BANK_ACTION_SHARE}</YuJaKebabMenu.Item>
                                                            </YuJaKebabMenu.Menu>
                                                        </YuJaKebabMenu>
                                                    </div>
                                                </td>
                                                );
                                        }
                                        else {
                                            return (
                                                <td tabIndex={0} role={TEXTBOX} >
                                                    <div style={{textAlign: "left"}}> {cell.getValue()} </div>
                                                </td>
                                                ) 
                                        }
                                    })} 
                                </tr>
                            )
                        }
                        ) : <tr><td colSpan="3" className={styles.noData} style={{boxShadow: "none"}}><div style={{height: 500, justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", gap: "5px"}}> <NoResultIllustration /> <div style={{fontSize: "20px",  marginTop: "10px", fontWeight: 600}}>{NO_RESULTS_FOUND} </div> </div></td></tr>}
                    </tbody>
                    </Table>
                    <div style={{flex: 1}}></div>
                    {getRowModel().rows.length!== 0 ?
                    <div style={{marginTop: "40px"}}>
                    <NormalPagination
					pageCount={getPageCount()}
					pageIndex={getState().pagination.pageIndex}
					pageOptions={pageOptions}
					canPreviousPage={getCanPreviousPage()}
					canNextPage={getCanNextPage()}
					previousPage={previousPage}
					nextPage={nextPage}
					gotoPage={setPageIndex}
				    />
                    </div>
                    : null}
            </div>
        </>
    );
}