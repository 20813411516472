import "bootstrap/dist/css/bootstrap.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { YuJaRadioBox } from "../../components/standardization/YuJaRadiobox";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import ArrowIcon from "../../images/arrow-right-big.svg";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-question.svg";
import { ReactComponent as COORDINATE } from "../../images/coordiante.svg";
import DragDropIcon from "../../images/drag_drop.svg";
import { ReactComponent as Megaphone } from "../../images/megaphone.svg";
import NormalBox from "../../public/boxes/NormalBox";
import { YuJaGlobalState } from "../../utils/LoadingContext";
import {
  ASCII_CODE_A,
  ASCII_CODE_SMALL_A,
  ATTENTION_TEXT,
  BLANK_REGEX,
  BLANK_REGEX_NEW,
  BLANK_REGEX_WRAPPED,
  BLANK_REGEX_WRAPPED_NEW,
  BUTTON,
  CI_COORDINATE_SIZE,
  DEFAULT_ENTRIES_PER_PARTICIPANT,
  DEFAULT_IMAGE_ALT,
  DEFAULT_SHOWN_ENTRIES,
  FIRST_WC_PLACEHOLDER,
  FITB_ANSWER_LENGTH,
  FIXED_ANSWER,
  MCSSoptLower,
  MERGED_MODE,
  OE_PLACE_HOLDER,
  OTHER_WC_PLACEHOLDER,
  POINTS_TEXT,
  POLL_BREAK_DURATION_ATTENTION_FORMAT,
  POLL_TEXT,
  POLL_TITLE_LABEL,
  PREMISE,
  QUESTION_TITLE,
  QUESTION_TYPES,
  RESPONSE,
  SA_PLACE_HOLDER,
  SUBMIT_TEXT,
  SURVEY_ALL_PAGE_STATE,
  TEXTBOX,
  THUMBS_DOWN,
  THUMBS_UP,
  UNGRADED_TEXT,
  WC_ANSWER_LENGTH
} from "../../utils/constants";
import {
  checkBlankAnswered,
  checkUnansweredNew,
  fillFormatText,
  handleUserAns,
  newPageLog,
  parseWCId,
} from "../../utils/helpers";
import { decodeCIParam, encodeCIParam, getOption4CI } from "../../utils/questionUtils";
import CountdownTimerAll from "./CountdownTimerAll";
import JoinQuizPagination from "./JoinQuizPagination";
import NetworkStatus from "./NetworkStatus";
import UserCompleteSurvey from "./UserCompleteSurvey";
import deleteIcon from "./images/delete.svg";
import "./styles.css";
import styles from "./styles.module.css";
import {OptionImageViewer} from "./OptionImageViewer";
import OpenEndedResult from "./openEndedResult";

export default React.memo(PollReadyAll, (prevProps, nextProps) => {
  return (
    prevProps.pollInfo === nextProps.pollInfo &&
    prevProps.currentQuestion === nextProps.currentQuestion
  );
});

function PollReadyAll({
                        pollInfo,
                        currentPoll = {},
                        pollLiveQuestionIdx,
                        setPollLiveQuestionIdx,
                        handleUserCompletePoll,
                        handlePollComplete,
                        stoppedAll,
                        state,
                        institutionId,
                        changeCurPoll,
                        websocketState,
                        setCountdownContent,
                        handleReattempt, 
                        allowReattempt,
                        maxAttempts,
}) {
  const isMobile = useMobileAccess(480);
  const isNarrow = useMobileAccess(1200);
  const { getAudienceResponseByUser, updateReaction, handleSubmitMultiResponse } = ResponseApiCalls();
  const [pageState, setPageState] = useState(SURVEY_ALL_PAGE_STATE.NOT_READY);
  const [isDropdownOpen, setIsDropdownOpen] = useState(null); 

  // const [currQuestionIndex, setCurrQuestionIndex] = useState(pollLiveQuestionNo > 0 ? Number(pollLiveQuestionNo) - 1 : 0);
  const { pollKey, uniqueCode, userName, pollTitle, attemptsCount, pollBreakDuration } = pollInfo;
  const { questions, isTimed = true, pollEndTime, rawQueResponses=[] } = currentPoll;
  const curIdx = pollLiveQuestionIdx >= questions.length ? questions.length - 1 : pollLiveQuestionIdx;

  const [currQueTitle, setCurrQueTitle] = useState(questions[curIdx].queTitle);
  const [currCorrectAnswers, setCurrCorrectAnswers] = useState(questions[curIdx].correctAnswers);
  // const [currOptionsMap, setCurrOptionsMap] = useState(questions[0].optionsMap);
  const [currQuestionType, setCurrQuestionType] = useState(questions[curIdx].questionType);
  const [currHasBlankRestriction, setCurrHasBlankRestriction] = useState(questions[curIdx].hasBlankRestriction);
  const [currWeightage, setCurrWeightage] = useState(questions[curIdx].weightage);
  const [currSerialNo, setCurrSerialNo] = useState(questions[curIdx].serialNo);
  const [currEntries, setCurrEntries] = useState(questions[curIdx].entries);
  // const [currSuffix, setCurrSuffix] = useState(questions[curIdx].suffix);
  const [currGradeEnabled, setCurrGradeEnabled] = useState(!!questions[curIdx].weightage);
  const [imageURL, setImageURL] = useState("");
  const [, getSession] = useLocalStorageNew("session", {});
  const {isOnline} = useContext(YuJaGlobalState);
  const session = getSession();
  const [isLoaded, setIsLoaded] = useState(false);
  const [queResponses, setQueResponses] = useState(rawQueResponses);
  const [alertShow, setAlertShow] = useState(false);
  const [pollBreakAlert, setPollBreakAlert] = useState(POLL_BREAK_DURATION_ATTENTION_FORMAT);
  const imageRef = useRef(null);
  const wcInputValRef = useRef(null);
  const [alertContent, setAlertContent] = useState("");
  const [firstSkipped, setFirstSkipped] = useState("");
  const [WCElements, setWCElements] = useState([]);
  const [shownEntries, setShownEntries] = useState(currEntries); // (isNarrow || currEntries == 1 ? 1 : 2);
  const [thumbup, setThumbUp] = useState(false);
  const [thumbdown, setThumbDown] = useState(false);
  const [, getGuestSession] = useLocalStorageNew("guestSession", {});
  const [hoveringThumbUp, setHoveringThumbUp] = useState(false);
  const [hoveringThumbDown, setHoveringThumbDown] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [newRank, setNewRank] = useState("");
  const timeRef = useRef(null);
  const hasCalledTimedUp = useRef(false);
  const [openEndedExpanded, setOpenEndedExpanded] = useState(false);
  const [isAnsweredState, setIsAnsweredState] = useState(false);

  const [currentOptionsImageMap, setCurrentOptionsImageMap] = useState({});

  const [currentSelectedOption, setCurrentSelectedOption] = useState(() => {
    if (pollLiveQuestionIdx >= questions.length) {
      return null;
    }
    const curQuestionType = questions[pollLiveQuestionIdx].questionType;
    const selectedOption = queResponses.length > pollLiveQuestionIdx ? queResponses[pollLiveQuestionIdx]?.selectedOption : null;

    if (!checkUnansweredNew(!!questions[pollLiveQuestionIdx].weightage, curQuestionType, selectedOption)) {
      return selectedOption;
    }


    if (curQuestionType === QUESTION_TYPES.FITB.name) {
      const ans = {};
      for (const key of Object.keys(questions[pollLiveQuestionIdx].correctAnswers)) {
        ans[key] = "";
      }
      return ans;
    } else if (curQuestionType === QUESTION_TYPES.WC.name ) {
      const ans = {};
      for (let i = 0; i < questions[pollLiveQuestionIdx].entries; i++) {
        ans[i] = "";
      }
      return ans;
    } else if(curQuestionType === QUESTION_TYPES.MH.name) {
      const ans = {};
      for (let i = 0; i < questions[pollLiveQuestionIdx].correctAnswers.length; i++) {
        ans[i] = "";
      }
      return ans;
    } else if(!currGradeEnabled && curQuestionType === QUESTION_TYPES.MCSS.name) {
      return [];
    } else if(curQuestionType === QUESTION_TYPES.RK.name) {
      return questions[pollLiveQuestionIdx].optionsMap.filter(option => option !== "");
    } else { //MCSS or SA or TF or CI
      return FIXED_ANSWER.UNANSWERED;
    }
  });

  // BEGIN VARIABLE INIT FOR QUESTION TYPES
  let MCSSelements = [];
  let newOptionsMap = pollLiveQuestionIdx < questions.length && questions[pollLiveQuestionIdx].questionType === QUESTION_TYPES.MCSS.name ?
    questions[pollLiveQuestionIdx].optionsMap : {};
  if ( pollLiveQuestionIdx < questions.length && questions[pollLiveQuestionIdx].questionType === QUESTION_TYPES.RK.name) {
    newOptionsMap = questions[pollLiveQuestionIdx].optionsMap;
  }
  const MCSSnum = Object.keys(newOptionsMap).length;

  let TFelements = ["TF0", "TF1"];
  const TFopt = ["True", "False"];

  let FITBElements = [];
  let idx = -1;

  if (pollLiveQuestionIdx < questions.length) {
    if (questions[pollLiveQuestionIdx].questionType === QUESTION_TYPES.MCSS.name) {
      for (let i = 0; i < MCSSnum; i++) {
        MCSSelements.push(QUESTION_TYPES.MCSS.name + i);
      }
    } else if (questions[pollLiveQuestionIdx].questionType === QUESTION_TYPES.FITB.name && currCorrectAnswers) {
      for (const key of Object.keys(currCorrectAnswers)) {
        FITBElements.push(key);
      }
    }
  }

  // END VARIBALE INIT

  // useEffect(() => {
  //   async function getResponses() {
  //     let questionsCompleted = await getAudienceResponseByUser(userName);
  //
  //     if (questionsCompleted.data.length - 1 < questions.length) {
  //       setPollLiveQuestionNo(questionsCompleted.data.length);
  //     }
  //   }
  //   getResponses();
  // }, []);

  useEffect(() => {
    newPageLog("PollReadyAll.js");
    if(isNarrow && document.getElementById("viewer-navbar-container") !== null) {
      document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
    }
  }, []);

  if (document.getElementById("root") !== null) {
    document.getElementById("root").style.backgroundImage = "none";
  }
  // END DOCUMENT FORMATTING

  useEffect(() => {
    if (!setCountdownContent) {
      return;
    }
    if (window.innerWidth < 1200 && isTimed) {
      setCountdownContent(<CountdownTimerAll
        pollEndTime={pollEndTime}
        timeUp={timeUp}
        state={state}
        // timedSurvey={(pollType.toUpperCase() === POLL_TYPE.SURVEY && endTime)}
      />);
    // } else if (window.innerWidth < 1200 && !currGradeEnabled && endTime != null) {
    //   console.log("setting countdown content 2");
    //   setCountdownContent(<CountdownTimerAll
    //     pollEndTime={pollEndTime}
    //     timeUp={timeUp}
    //     state={state}
    //     // timedSurvey={(pollType.toUpperCase() === POLL_TYPE.SURVEY && endTime)}
    //   />);
    } else {
      setCountdownContent(null);
    }
  }, [setCountdownContent, window.innerWidth, isTimed, pollEndTime, state]);

  const autoScroll = () => {
    if (window.innerHeight >= 480 || window.innerWidth <= window.innerHeight) {
      return;
    }

    const mainContainer = document.getElementById("main-container");
    const questionContainer = document.getElementById("question-container");
    if (!mainContainer || !questionContainer) {
      return;
    }
    const questionContainerTop = questionContainer.getBoundingClientRect().top;
    const mainContainerTop = mainContainer.getBoundingClientRect().top;
    const scrollDistance = questionContainerTop - mainContainerTop;
    mainContainer.scrollTo({
      top: mainContainer.scrollTop + scrollDistance,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setTimeout(autoScroll, 100);
  }, [window.innerWidth, window.innerHeight, currSerialNo]);

  useEffect(() => {
    if (pollLiveQuestionIdx >= questions.length) {
      // if (pollType === POLL_TYPE.SURVEY) {
      //   setPageState(SURVEY_ALL_PAGE_STATE.RESULT);
      //   return;
      // } else {
      if (stoppedAll) {
        handlePollComplete();
      } else {
        handleUserCompletePoll();
      }
      return;
      // }
    }

    const curQueResponse = queResponses[pollLiveQuestionIdx];
    const curQue = questions[pollLiveQuestionIdx];
    //(survey and skipped the question / survey and answered the question)  go to class result page. otherwise, question page.
    // if (pollType === POLL_TYPE.SURVEY && (curQueResponse?.skippedQuestion || !checkIncomplete(pollType, curQue.questionType, curQueResponse?.selectedOption))) {
    //   setSurveyClassResultQuestion(questions[pollLiveQuestionIdx]);
    //   setPageState(SURVEY_ALL_PAGE_STATE.RESULT);
    //   return;
    // }
    // const guestSession = JSON.parse(JSON.stringify(getGuestSession()));
    // const questionNo = guestSession[`$${uniqueCode}`]?.[SURVEY_ALL_CURRENT_QUESTION_NO_KEY];
    // if (!isNaN(questionNo)) {
    //   setSurveyClassResultQuestion(questions[questionNo]);
    //   setPageState(SURVEY_ALL_PAGE_STATE.RESULT);
    //   return;
    // }


    setCurrQueTitle(curQue.queTitle);
    setCurrCorrectAnswers(curQue.correctAnswers);
    // setCurrOptionsMap(questions[currQuestionIndex].optionsMap);
    setCurrQuestionType(curQue.questionType);
    setCurrHasBlankRestriction(curQue?.hasBlankRestriction ? curQue.hasBlankRestriction : false);
    setCurrWeightage(curQue.weightage);
    setCurrSerialNo(curQue.serialNo);
    const newEntriesVal = curQue.entries ? curQue.entries : DEFAULT_ENTRIES_PER_PARTICIPANT;
    setCurrEntries(newEntriesVal);
    setShownEntries(newEntriesVal);
    // setCurrSuffix(curQue.suffix);
    setCurrGradeEnabled(!!curQue.weightage);
    setCurrentOptionsImageMap(!!curQue.optionsImageMap ? curQue.optionsImageMap : {});
    setIsLoaded(false);
    
    if(!!curQue.directLinkEvp) {
      setImageURL(curQue.directLinkEvp);
      setIsVideo(true);
    } else {
      setImageURL(curQue.imageURL);
      setIsVideo(false);
    }

    const selectedOption = !!curQueResponse ? curQueResponse.selectedOption : null;

    if (!checkUnansweredNew(!!curQue.weightage, curQue.questionType, selectedOption)) {
      setCurrentSelectedOption(selectedOption);
    } else {
      if (curQue.questionType === QUESTION_TYPES.FITB.name) {
        const ans = {};
        for (const key of Object.keys(curQue.correctAnswers)) {
          ans[key] = "";
        }
        setCurrentSelectedOption(ans);
      } else if (curQue.questionType === QUESTION_TYPES.WC.name ) {
        const ans = {};
        for (let i = 0; i < curQue.entries; i++) {
          ans[i] = "";
        }
        setCurrentSelectedOption(ans);
      } else if(curQue.questionType === QUESTION_TYPES.MH.name) {
        const ans = {};
        for (let i = 0; i < curQue.correctAnswers.length; i++) {
          ans[i] = "";
        }
        setCurrentSelectedOption(ans);
      } else if (!curQue.weightage && curQue.questionType === QUESTION_TYPES.MCSS.name) {
         setCurrentSelectedOption([]);
      } else if (curQue.questionType === QUESTION_TYPES.RK.name) {
        setCurrentSelectedOption(curQue.optionsMap.filter(option => option !== ""));
      } else { //MCSS or SA or TF or CI
        setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
      }
    }

    setPageState(SURVEY_ALL_PAGE_STATE.QUESTION);
  }, [pollLiveQuestionIdx, queResponses]);

  const onChangeCustomRank = (e, index) => {
    let w = e.target.value.replace(/[^0-9]/g, '');
    w = parseInt(w);
    if(!isNaN(w) && w <=  currentSelectedOption.length && w > 0) {
      setNewRank({[index] : w});
    }
    else {
      setNewRank({[index] : ""});
    }
};


  const handleType = (e) => {
    e.stopPropagation();

    if (currQuestionType === QUESTION_TYPES.SA.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById("SA-IN").value = "";
        setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
      } else {
        const ans = {};
        ans["0"] = e.target.value.toString();
        setCurrentSelectedOption(ans);
      }
    } else if (currQuestionType === QUESTION_TYPES.OE.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById("OE-IN").value = "";
        setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
      } else {
        const ans = {};
        ans["0"] = e.target.value.toString();
        setCurrentSelectedOption(ans);
      }
    } else if (currQuestionType === QUESTION_TYPES.FITB.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById(e.target.id).value = "";
      }
      const copy = currentSelectedOption === FIXED_ANSWER.UNANSWERED ? {} : { ...currentSelectedOption };
      copy[e.target.getAttribute("id")] = e.target.value.toString();
      setCurrentSelectedOption(copy);
    } else if (currQuestionType === QUESTION_TYPES.WC.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById(e.target.id).value = "";
      } else {
        if (parseWCId(e.target.getAttribute("id")) == shownEntries-1 && shownEntries < currEntries && wcInputValRef.current.length < e.target.value.toString().length) {
          // const diff = currEntries - shownEntries;
          // if (diff === 1) {
            setShownEntries(shownEntries+1);
          // } else if (diff > 1) {
            // if (isNarrow) setShownEntries(shownEntries+1);
            // else setShownEntries(shownEntries+2-(shownEntries % 2));
          // }
        }
      }
      const copy = currentSelectedOption === FIXED_ANSWER.UNANSWERED ? {} : { ...currentSelectedOption };
      copy[parseWCId(e.target.getAttribute("id"))] = e.target.value.toString();
      // console.log("copy", copy);
      setCurrentSelectedOption(copy);
    }
  };

  const handleKeyDown = (e) => {
    wcInputValRef.current = e.target.value;
  };

  const handleDelete = (i) => {
    const copy = { ...currentSelectedOption };
    copy[i] = "";
    for (let j = i; j < currEntries-1; ++j) {
      copy[j] = copy[j+1];
    }
    copy[currEntries-1] = "";
    setCurrentSelectedOption(copy);
    if (shownEntries > 1) {
      setShownEntries(shownEntries-1);
    }
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };


  const handleChooseOption = (optIdx) => {
    if (!currGradeEnabled && currQuestionType === QUESTION_TYPES.MCSS.name) {
      const copy = JSON.parse(JSON.stringify(currentSelectedOption));
      if (copy.includes(optIdx)) {
       copy.splice(copy.indexOf(optIdx), 1);
      } else {
        copy.push(optIdx);
      }
      setCurrentSelectedOption(copy);
    } else {
      setCurrentSelectedOption(optIdx);
    }
  };

  const handleClickImage = (e) => {
    if (!isLoaded) {
      return;
    }
    const {optionsMap} = questions[pollLiveQuestionIdx];

    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;

    const encodedX = encodeCIParam(x, imageRef.current.width);
    const encodedY = encodeCIParam(y, imageRef.current.height);
    const option = getOption4CI(encodedX, encodedY, optionsMap);
    setCurrentSelectedOption(option === FIXED_ANSWER.UNANSWERED
        ? FIXED_ANSWER.UNANSWERED
        : {x: encodedX, y: encodedY, selected: option});
  }

  // const checkBlanks = (opts) => {
  //   for (const key of Object.keys(currCorrectAnswers)) {
  //     if (!!opts && !!opts[key] && opts[key] !== "") {
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  // const updateFITB = async () => {
  //   if (!currentSelectedOption) {
  //     await setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
  //     return;
  //   }
  //   const newVal = checkBlanks(currentSelectedOption) ? FIXED_ANSWER.UNANSWERED : currentSelectedOption;
  //   await setCurrentSelectedOption(newVal);
  //   return newVal;
  // };

  // const updateWC = async () => {
  //   if (!currentSelectedOption) {
  //     await setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
  //     return;
  //   }
  //   const newVal = checkBlankAnswered(currentSelectedOption) ? FIXED_ANSWER.UNANSWERED : currentSelectedOption;
  //   await setCurrentSelectedOption(newVal);
  //   return newVal;
  // };

  // const updateMH = async() => {
  //   if (!currentSelectedOption) {
  //     await setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
  //     return;
  //   }
  //   const newVal = checkBlankAnswered(currentSelectedOption) ? FIXED_ANSWER.UNANSWERED : currentSelectedOption;
  //   await setCurrentSelectedOption(newVal);
  //   return newVal;
  // }

  useEffect(() => {
    if (currQuestionType === QUESTION_TYPES.WC.name) {
      let tempWCElements = [];
      for (let i = 0; i < shownEntries; i++) {
        tempWCElements.push(`WC-IN-${i}`);
      }
      setWCElements(tempWCElements);
    }
  }, [shownEntries]);

  // const timeUp = async () => {
  //   console.log("Time Is Up");
  //   handlePollComplete();
  //   // delRc();
  // };

  const switchSerialNo = async (serialNo, isSubmit=false) => {
    let copy = JSON.parse(JSON.stringify(currentSelectedOption));

    if (checkUnansweredNew(!!currWeightage, currQuestionType, copy)) {
      copy = FIXED_ANSWER.UNANSWERED;
    }

    let answersMap  = JSON.parse(JSON.stringify(queResponses));

    //build cur question response
    const isCorr = handleUserAns(questions[pollLiveQuestionIdx], copy);
    const curAnswer = {
      serialNo: questions[pollLiveQuestionIdx].serialNo,
      questionType: questions[pollLiveQuestionIdx].questionType,
      queTitle: questions[pollLiveQuestionIdx].queTitle,
      optionsMap: questions[pollLiveQuestionIdx].optionsMap,
      isCorrect: isCorr,
      attemptsCount: attemptsCount,
      selectedOption: copy,
      correctAnswers: questions[pollLiveQuestionIdx].correctAnswers,
    };
    answersMap[pollLiveQuestionIdx] = curAnswer;
    await handleSubmitResponse(serialNo, [curAnswer]);
    setQueResponses(answersMap);
    const currentPollCopy = JSON.parse(JSON.stringify(currentPoll));
    currentPollCopy.rawQueResponses = answersMap;
    changeCurPoll(currentPollCopy);

    //submit
    if (serialNo > questions.length || isSubmit) {
      if (stoppedAll) { //in additional time after manually stopped, should redirect to class result page
        handlePollComplete();
      // } else if (pollType === POLL_TYPE.SURVEY) {
      //   setPageState(SURVEY_ALL_PAGE_STATE.RESULT);
      } else {
        handleUserCompletePoll();
      }
    }

    setPollLiveQuestionIdx(serialNo - 1);
  }

  useEffect(() => {
    let answerState = true;
    for (let i = 0; i < questions.length; i++) {
      if (i != pollLiveQuestionIdx) {
        const r = queResponses[i];
        if (!r || !Object.entries(r).length || checkUnansweredNew(!!questions[i].weightage, questions[i].questionType, r.selectedOption)) {
          answerState = false;
        }
      } 
      else if (i == pollLiveQuestionIdx) {
        if (!currentSelectedOption || checkUnansweredNew(!!currWeightage, currQuestionType, currentSelectedOption)) {
          answerState = false;
        }
      }
    }
    console.log(answerState)
    setIsAnsweredState(answerState);
}, [queResponses, currentSelectedOption])

  const submit = async () => {
    const skippedQueArr = [];
    //check all the questions if it's unanswered.
    for (let i = 0; i < questions.length - 1; i++) {
      if (i === currSerialNo - 1) {
        continue;
      }
      const r = queResponses[i];
      if (!r || !Object.entries(r).length || checkUnansweredNew(!!questions[i].weightage, questions[i].questionType, r.selectedOption)) {
        skippedQueArr.push(questions[i].serialNo);
      }
    }
    if (checkUnansweredNew(!!currWeightage, currQuestionType, currentSelectedOption)) {
      skippedQueArr.push(questions[questions.length - 1].serialNo);
    }
    if (skippedQueArr.length) { // && pollType !== POLL_TYPE.SURVEY
      setFirstSkipped(skippedQueArr[0]);
      setAlertContent(`You have ${skippedQueArr.length} unanswered questions. Are you sure you want to submit?`);
      setAlertShow(true);
      return;
    }


    // await handleSub(SUB_QUESTION_TYPE.NEXT);
    await switchSerialNo(currSerialNo + 1, isAnsweredState);
  }


  const handleReaction = async (reaction) => {
    if (reaction == 1) {
      if(thumbdown == true) {
        setThumbDown(false);
      }
      setThumbUp(true);

    }
    else {
      if(thumbup == true) {
        setThumbUp(false);
      }
      setThumbDown(true);
    }
    // let sessionUserName = getSession().userId ? getSession().userId : getGuestSession()["$"+ uniqueCode]?.GuestId;
    // const res = await setStudentReaction(pollKey, uniqueCode, sessionUserName , reaction, currSerialNo);
    let reactions = {};
    reactions[currSerialNo] = [reaction];
    let attemptReactions = {};
    attemptReactions[attemptsCount] = reactions;
    await updateReaction(pollKey, uniqueCode, attemptReactions, attemptsCount, null);
  }


  // Fill no-responding question with un-answer response
  useEffect(() => {
    const answersCopy = JSON.parse(JSON.stringify(queResponses));
    const placeholderAnswerArr = [];
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      const r = queResponses[i];
      if (!r || !Object.entries(r).length) {
        const answer = {
          serialNo: question.serialNo,
          questionType: question.questionType,
          queTitle: question.queTitle,
          optionsMap: question.optionsMap,
          isCorrect: false,
          attemptsCount: attemptsCount,
          selectedOption: FIXED_ANSWER.UNANSWERED,
          skippedQuestion: false,
          correctAnswers: question.correctAnswers,
        }
        answersCopy[i] = answer;
        placeholderAnswerArr.push(answer);
      }
    }
    setQueResponses(answersCopy);
    if (placeholderAnswerArr.length) {
      handleSubmitMultiResponse(pollInfo, 1, questions, placeholderAnswerArr, true);
    }

  }, []);

  useEffect(() => {
    if(stoppedAll) {
      // if (pollType.toUpperCase() === POLL_TYPE.SURVEY && !endTime) { // if untimed survey, submit automatically
      //   timeUp();
      //   return;
      // }

      const text = fillFormatText(POLL_BREAK_DURATION_ATTENTION_FORMAT, {
        pollType: MERGED_MODE, // pollType === POLL_TYPE.GRADED_POLL ? GRADED_POLL : SURVEY,
        time: Math.floor(pollBreakDuration / 60) > 0
            ? `${Math.floor(pollBreakDuration / 60)} minutes and ${pollBreakDuration % 60} seconds`
            : `${pollBreakDuration % 60} seconds`,
      });
      setPollBreakAlert(text);
      timeUp(false);
    }
  }, [stoppedAll]);

  useEffect(() => {
    return () => {
      if(hasCalledTimedUp.current) {
        setPollLiveQuestionIdx(questions.length);
      }
    }
  }, []);

  useEffect(async () => {
    if (!isOnline) {
      setThumbUp(false);
      setThumbDown(false);
      return;
    }

    let res = await getAudienceResponseByUser(userName, uniqueCode);
    if(!res.hasOwnProperty("data")) {
      res.data = [];
    }
    // let sessionUserName = getSession().userId ? getSession().userId : getGuestSession()["$"+ uniqueCode]?.GuestId;
    let currentPollRes = res.data;
    let isSet = false;
    for (const tempResult of currentPollRes) {
    if (tempResult.reactionMap && tempResult.reactionMap[attemptsCount] && tempResult.reactionMap[attemptsCount][currSerialNo]) {
      if (tempResult.reactionMap[attemptsCount][currSerialNo][0] == "1") {
        setThumbUp(true);
        setThumbDown(false);
        isSet= true;
        // await setStudentReaction(pollKey, uniqueCode, sessionUserName , "1", currSerialNo);
      }
      else if (tempResult.reactionMap[attemptsCount][currSerialNo][0] == "2") {
        setThumbDown(true);
        setThumbUp(false);
        isSet= true;
        // await setStudentReaction(pollKey, uniqueCode, sessionUserName , "2", currSerialNo);
      }
    }
    else if (tempResult.reaction && tempResult.reaction[currSerialNo]) {
      if (tempResult.reaction[currSerialNo][0] == "1") {
        setThumbUp(true);
        setThumbDown(false);
        isSet= true;
      } else if (tempResult.reaction[currSerialNo][0] == "2") { 
        setThumbDown(true);
        setThumbUp(false);
        isSet= true;
      }
    } 
    }

    if (!isSet) {
        setThumbUp(false);
        setThumbDown(false);
    }
  }, [currSerialNo, isOnline]);


  const timeUp = async (needEnd=true) => {
    let copy = JSON.parse(JSON.stringify(currentSelectedOption));
    if (checkUnansweredNew(!!currWeightage, currQuestionType, copy)) {
      copy = FIXED_ANSWER.UNANSWERED;
    }

    let answersMap  = JSON.parse(JSON.stringify(queResponses));
    if (pollLiveQuestionIdx < questions.length) {
      // const answersMap = getFilledQuestionResponses();
      //current question
      const curQue = questions[pollLiveQuestionIdx];
      const isCorr = handleUserAns(curQue, currentSelectedOption);
      const curAnswer = {
        serialNo: curQue.serialNo,
        questionType: curQue.questionType,
        queTitle: curQue.queTitle,
        optionsMap: curQue.optionsMap,
        isCorrect: isCorr,
        // attemptsCount: attemptsCount,
        selectedOption: copy,
        skippedQuestion: false,
        correctAnswers: curQue.correctAnswers,
      };

      answersMap[pollLiveQuestionIdx] = curAnswer;
      await handleSubmitResponse(questions.length + 1, [curAnswer]);
      setQueResponses(answersMap);
      const currentPollCopy = JSON.parse(JSON.stringify(currentPoll));
      currentPollCopy.rawQueResponses = answersMap;
      changeCurPoll(currentPollCopy);
    }

    hasCalledTimedUp.current = true;

    if (needEnd) {
      setPollLiveQuestionIdx(questions.length);
      handlePollComplete();
    }

  }

  const handleSubmitResponse = (curQuestionNo, answersMap) => {
    handleSubmitMultiResponse(pollInfo, curQuestionNo, questions, answersMap);

  };


  // const onLoad = async () => {
  //   const { offsetWidth: width, offsetHeight: height } = document.getElementById('mobile-image');

  //   try {
  //     const { width: resizedWidth, height: resizedHeight } = await getResizedImageSize(questionImageURL, width, height);

  //     setImageSize({ width: resizedWidth, height: resizedHeight });
  //     setIsLoaded(true);
  //   } catch (error) {
  //     console.error('Error loading image:', error);
  //   }
  // };

  const onLoad = () => {
    setIsLoaded(true);
  }

  const alertConfig = {
    title: `${SUBMIT_TEXT} ${POLL_TEXT}`,
    okText: SUBMIT_TEXT,
    submit: async () => {
      setAlertShow(false);
      await switchSerialNo(currSerialNo + 1);
    },
    close:  async () => {
      setAlertShow(false);
      if ( questions.length !== firstSkipped) {
        await switchSerialNo(firstSkipped);
       }
    },
  };

  const handleMouseEnterThumbUp = (reaction) => {
    if (reaction === 1) {
      setHoveringThumbUp(true);
    }
    else if (reaction === 2) {
      setHoveringThumbDown(true)
    }
  };

  const handleMouseLeaveThumbUp = (reaction) => {
    if (reaction === 1) {
      setHoveringThumbUp(false);
    }
    else if (reaction === 2) {
      setHoveringThumbDown(false)
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceKey = result.source.index;
    const destinationKey = result.destination.index;

    let newSelectedOption = [ ...currentSelectedOption ];
    const [removed] = newSelectedOption.splice(sourceKey, 1);
    newSelectedOption.splice(destinationKey, 0, removed);
    setCurrentSelectedOption(newSelectedOption);
  };

  return (
      <>
        <YuJaGeneralAlert
            show={alertShow}
            setModalShow={setAlertShow}
            content={alertContent}
            config={alertConfig}
            handleclose={()=> {setAlertShow(false)}}
        />

        {/* {pageState === SURVEY_ALL_PAGE_STATE.RESULT &&
            <ClassResultSurvey
                pollInfo={pollInfo}
                pollType={pollType}
                currentPoll={currentPoll}
                queResponses={queResponses}
                setQueResponses={setQueResponses}
                pollLiveQuestionIdx={pollLiveQuestionIdx}
                setPollLiveQuestionIdx={setPollLiveQuestionIdx}
                question={surveyClassResultQuestion}
                handleSubmitResponse={handleSubmitResponse}
                handlePollComplete={handlePollComplete}
                changeCurPoll={changeCurPoll}
                websocketState={websocketState}
                institutionId={institutionId}
                userName={userName}
            />
        } */}

          {pageState === SURVEY_ALL_PAGE_STATE.RESULT && pollLiveQuestionIdx >= questions.length - 1 && 
            <UserCompleteSurvey websocketState={websocketState} pollTitle={pollTitle} allowReattempt={allowReattempt}
            handleReattempt={handleReattempt}
            attemptsCount={attemptsCount}
            maxAttempts={maxAttempts} currentPoll={currentPoll} institutionId={institutionId}
            userName={userName}/>
        }

            {pageState === SURVEY_ALL_PAGE_STATE.QUESTION &&
              <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                <div
                    className={`${styles.centerLayout}  ${styles.pollShareAllLayOut}`}
                    style={{flex: 1, overflowY: "auto", flexDirection: "column", padding: 20, gap: 10}}
                    id="main-container"
                >
                  {/* {isNarrow &&
                  <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: 'calc(100%-42px)'}}>
                    {pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                      <CountdownTimerAll
                          pollEndTime={pollEndTime}
                          timeUp={timeUp}
                          state={state}
                      />
                    }
                    <NetworkStatus tooltipPlacement={'bottom'} websocketState={websocketState}/>
                  </div>
              } */}

                  {stoppedAll &&
                      <div className={styles.pollBreakBar}>
                        <Megaphone  />
                        <strong >{ATTENTION_TEXT}</strong>
                        <span style={{flex: 1}}>{pollBreakAlert}</span>
                      </div>
                  }




                  {!isMobile && window.innerWidth >= 1200 &&
                      <NormalBox boxType="light" className={`${styles.containerTop}`} style={{padding: "0px 3vw 0px 3vw"}}>
                        <div id="header" className="d-flex justify-content-between" style={{alignItems: "center", height: "90px"}}>
                          <div tabIndex={0} aria-label={POLL_TITLE_LABEL + pollTitle} role={TEXTBOX} className="poll-title">{pollTitle}</div>
                          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 20}}>
                            <NetworkStatus tooltipPlacement={'bottom'} refreshAlignFirst={true} websocketState={websocketState}/>
                            {isTimed &&
                                <CountdownTimerAll
                                    pollEndTime={pollEndTime}
                                    timeUp={timeUp}
                                    state={state}
                                    // timedSurvey={(pollType.toUpperCase() === POLL_TYPE.SURVEY && endTime)}
                                />
                            }

                            {/* { (pollType.toUpperCase() === POLL_TYPE.SURVEY && endTime != null) &&
                                <div id="timer-counter">
                                  <CountdownTimerAll
                                      pollEndTime={pollEndTime}
                                      timeUp={timeUp}
                                      state={state}
                                      // timedSurvey={(pollType.toUpperCase() === POLL_TYPE.SURVEY && endTime)}
                                  />
                                </div>
                            } */}
                          </div>
                        </div>
                      </NormalBox>
                  }

                  <div
                      className={`${styles.containerBottom}`}
                      style={{display: "flex" , flexDirection: "column", maxHeight: isMobile ? "calc(100vh - 75px)" : "", padding: "0px", height: "100%"}}
                  >
                    {(isMobile || window.innerWidth < 1200) &&
                        <>
                          {/* { (isTimed && (pollType.toUpperCase() !== POLL_TYPE.SURVEY)) &&
                              <div style={{position: "fixed", top: "20px", left: isMobile ? "calc((100% - 120px)/2)" : "calc((100% - 170px)/2)"}}>
                                <CountdownTimerAll
                                    pollEndTime={pollEndTime}
                                    timeUp={timeUp}
                                    state={state}
                                />
                              </div>
                          }

                          { (pollType.toUpperCase() === POLL_TYPE.SURVEY && endTime != null)  &&
                              <div style={{position: "fixed", top: "20px", left: isMobile ? "calc((100% - 120px)/2)" : "calc((100% - 170px)/2)"}}>
                                <div id="timer-counter">
                                  <CountdownTimerAll
                                      pollEndTime={pollEndTime}
                                      timeUp={timeUp}
                                      state={state}
                                      timedSurvey={(pollType.toUpperCase() === POLL_TYPE.SURVEY && endTime)}
                                  />
                                </div>
                              </div>

                          } */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: 20,
                              padding: window.innerWidth >= window.innerHeight && window.innerHeight < 480 ? 0 : "0px 10px"
                            }}
                          >
                            <div className="poll-title" style={{maxWidth: "100%", flex: 1}}>{pollTitle}</div>
                            <NetworkStatus tooltipPlacement={'bottom'} refreshAlignFirst={true} websocketState={websocketState}/>
                          </div>
                          <div style={{ padding: (window.innerWidth >= window.innerHeight && window.innerHeight < 480) ? 0 : "0px 10px" }} > <hr /> </div>
                        </>
                    }
                    <div
                      id="question-container"
                      style={window.innerWidth >= window.innerHeight && window.innerHeight < 480 ?
                        {display: "flex", flexDirection: "column",  height: "100%", flex: 1, paddingBottom: 5} :
                        {display: "flex", flexDirection: "column",  height: "100%", flex: 1, padding: isNarrow ? "0px 10px 5px 10px" : "0px 21px 5px 21px"}
                      }
                    >
                      {!isMobile && window.innerWidth >= 1200 ?
                          <div className="header-board" style={{display: "flex", alignItems: "center", gap: "10px"}}>
                            <div tabIndex={0} role={TEXTBOX} className="cur-question-title" style={{display: "flex", alignItems: "center"}}>Question {currSerialNo} of {questions.length} </div>
                            {currQuestionType !== QUESTION_TYPES.FITB.name && (
                                <>
                                  <ArrowRight style={{flexShrink: "0"}}/>
                                  <p tabIndex={0} aria-label={QUESTION_TITLE + currQueTitle} className="ques-desc" style={{fontSize: "24px", letterSpacing: "0.84px", lineHeight: "normal", color: "#000", order: "unset", marginTop: "0px", maxWidth: "70%", flexShrink: "1"}}>
                                    {currQueTitle}
                                  </p>
                                </>
                            )}
                            <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end", flex: "1"}}>
                              <div style={{display: "flex", gap: "10px", justifyContent: "center"}}>
                                <div
                                    className={thumbup || hoveringThumbUp ? "reaction-activated-background " : "reaction-background"}
                                    style={{
                                      width: '48px',
                                      height: '48px',
                                      borderRadius: '50%',
                                      border: '2px solid #42296E',
                                      alignItems: "center",
                                      display: 'flex',
                                      justifyContent: "center",
                                      flexDirection: 'column'
                                    }}
                                    onMouseEnter={() => {
                                      handleMouseEnterThumbUp(1);
                                    }}
                                    onMouseLeave={() => {
                                      handleMouseLeaveThumbUp(1);
                                    }}
                                    onClick={() => {
                                      handleReaction(1)
                                    }}
                                    tabIndex={0}
                                    aria-label={THUMBS_UP}
                                    role={BUTTON}
                                >
                                  <div className={thumbup || hoveringThumbUp ? "thumbupicon-activated" : "thumbupicon"}></div>
                                </div>
                                <div
                                    className={thumbdown || hoveringThumbDown ? "reaction-activated-background " : "reaction-background"}
                                    style={{
                                      width: '48px',
                                      height: '48px',
                                      borderRadius: '50%',
                                      border: '2px solid #42296E',
                                      alignItems: "center",
                                      display: 'flex',
                                      justifyContent: "center",
                                      flexDirection: 'column'
                                    }}
                                    onMouseEnter={() => {
                                      handleMouseEnterThumbUp(2);
                                    }}
                                    onMouseLeave={() => {
                                      handleMouseLeaveThumbUp(2);
                                    }}
                                    onClick={() => {
                                      handleReaction(2)
                                    }}
                                    tabIndex={0}
                                    aria-label={THUMBS_DOWN}
                                    role={BUTTON}
                                >
                                  <div
                                      className={thumbdown || hoveringThumbDown ? "thumbdownicon-activated" : "thumbdownicon"}></div>
                                </div>
                              </div>
                              <span className="gradientWrapQuesType" style={{height: "45px", width: isNarrow? "105px" : "195px"}}>
                                <span className="questionPanelQuestionTypeContainer" style={{width: isNarrow? "100px" : ""}}>
                                    <div tabIndex={0} role={TEXTBOX} className="questionPanelQuestionType" style={{fontSize: "18px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                      {currGradeEnabled ? `${currWeightage} ${POINTS_TEXT}` : UNGRADED_TEXT}
                                    </div>
                                </span>
                              </span>
                            </div>
                          </div>
                          :
                          <>
                            <div className="header-board" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                              <div tabIndex={0} role={TEXTBOX} className="cur-question-title" style={{display: "flex", alignItems: "center"}}>Question {currSerialNo} of {questions.length}</div>
                              <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end", flex: "1", alignItems: "center"}}>
                                <div style={{display: "flex", gap: "10px", justifyContent: "center"}}>
                                  <div
                                      className={thumbup || hoveringThumbUp ? "reaction-activated-background " : "reaction-background"}
                                      style={{
                                        width: 36,
                                        height: 36,
                                        borderRadius: '50%',
                                        border: '2px solid #42296E',
                                        alignItems: "center",
                                        display: 'flex',
                                        justifyContent: "center",
                                        flexDirection: 'column'
                                      }}
                                      onMouseEnter={() => {
                                        handleMouseEnterThumbUp(1);
                                      }}
                                      onMouseLeave={() => {
                                        handleMouseLeaveThumbUp(1);
                                      }}
                                      onClick={() => {
                                        handleReaction(1)
                                      }}
                                      tabIndex={0}
                                      aria-label={THUMBS_UP}
                                      role={BUTTON}
                                  >
                                    <div className={thumbup || hoveringThumbUp ? "thumbupicon-activated" : "thumbupicon"} style={{width: 20, height: 20}}/>
                                  </div>
                                  <div
                                      className={thumbdown || hoveringThumbDown ? "reaction-activated-background " : "reaction-background"}
                                      style={{
                                        width: 36,
                                        height: 36,
                                        borderRadius: '50%',
                                        border: '2px solid #42296E',
                                        alignItems: "center",
                                        display: 'flex',
                                        justifyContent: "center",
                                        flexDirection: 'column'
                                      }}
                                      onMouseEnter={() => {
                                        handleMouseEnterThumbUp(2);
                                      }}
                                      onMouseLeave={() => {
                                        handleMouseLeaveThumbUp(2);
                                      }}
                                      onClick={() => {
                                        handleReaction(2)
                                      }}
                                      tabIndex={0}
                                      aria-label={THUMBS_DOWN}
                                      role={BUTTON}
                                  >
                                    <div
                                        className={thumbdown || hoveringThumbDown ? "thumbdownicon-activated" : "thumbdownicon"} style={{width: 20, height: 20}}/>
                                  </div>
                                </div>
                                {currGradeEnabled &&
                                  <span className="gradientWrapQuesType" style={{ height: "23px", width: "75px", marginLeft: "0px"}}>
                                    <span className="questionPanelQuestionTypeContainer" style={{ width: "71px", padding: "0px", height:  "20px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <div className="questionPanelQuestionType" role={TEXTBOX} style={{ fontSize: "12px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center", height:  "12px"}}> {currWeightage} Points </div>
                                    </span>
                                  </span>
                                }
                              </div>

                            </div>
                            {currQuestionType !== QUESTION_TYPES.FITB.name && (currQuestionType !== QUESTION_TYPES.OE.name || !openEndedExpanded) && (
                                <p tabIndex={0} aria-label={QUESTION_TITLE + currQueTitle} className="ques-desc" style={{order: "initial", letterSpacing: "0px"}}>
                                  {currQueTitle}
                                </p>
                            )}

                          </>
                      }

                      {!!imageURL && isMobile && (
                          <div className="img-container-question" style={{alignSelf: "center", margin: "0px", marginTop: "30px", width: currQuestionType === QUESTION_TYPES.CI.name ? "100%" : ""}}>
                            {(currQuestionType !== QUESTION_TYPES.OE.name || !openEndedExpanded) && currQuestionType !== QUESTION_TYPES.CI.name && <>
                              {!isVideo && <img className="img" src={imageURL} onLoad={onLoad} alt="Question" tabIndex={0} aria-label={questions[curIdx].imageAlt ? questions[curIdx].imageAlt : DEFAULT_IMAGE_ALT}/>}
                              {isVideo && <iframe allowFullScreen className="img" src={imageURL} alt="Question"/>}
                            </>}
                            {currQuestionType === QUESTION_TYPES.CI.name &&
                                <div style={{ position: "relative"}}>
                                  <img
                                      className="img"
                                      ref={imageRef}
                                      src={imageURL}
                                      onLoad={onLoad}
                                      onClick={handleClickImage}
                                      alt="Question Image"
                                      tabIndex={0} aria-label={questions[curIdx].imageAlt ? questions[curIdx].imageAlt : DEFAULT_IMAGE_ALT}/>
                                  {isLoaded
                                      && !!currentSelectedOption
                                      && currentSelectedOption.hasOwnProperty("x")
                                      && currentSelectedOption.hasOwnProperty("y")
                                      &&
                                      <COORDINATE
                                          style={{
                                            position: "absolute",
                                            top: `${decodeCIParam(currentSelectedOption.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                            left: `${decodeCIParam(currentSelectedOption.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                          }}
                                      />
                                  }
                                </div>
                            }
                          </div>
                      )}

                      <div className="main-body">
                        <div style={{display: "flex", gap: "70px", height: "100%"}}>

                          {/* Create the options for MCSS */}
                          {currQuestionType === QUESTION_TYPES.MCSS.name && (
                              <div id="body-MCSS" style={{display: "flex", flexDirection: "column", gap: isMobile ? "17px" : "30px", flex: "1", marginTop: isMobile ? "20px" : "", width: "100%"}}>
                                {MCSSelements.map((value, index) => {
                                  const optIdx = String.fromCharCode(ASCII_CODE_SMALL_A + index);
                                  const optIdxDisplay = String.fromCharCode(ASCII_CODE_A + index);
                                  const isSelectedOpt = (!!currentSelectedOption && optIdx === currentSelectedOption)
                                      || (Array.isArray(currentSelectedOption) && currentSelectedOption.includes(MCSSoptLower[index]));

                                  return (
                                      <div key={index}>
                                        {!isMobile && index === 0 && <div style={{ display: "block", height: "30px" }} />}

                                        <div
                                            tabIndex={0}
                                            className={`MCSS-label ${isSelectedOpt ? "selected" : ""}`}
                                            style={{
                                              width: "100%",
                                              minHeight: isMobile ? 50 : "",
                                              borderRadius: isMobile ? "4px" : "",
                                              border: isMobile ? "1.5px solid #909090" : "",
                                              gap: isMobile ? 10 : 20,
                                              paddingLeft: isMobile ? 20 : 30,
                                              paddingRight: isMobile ? 20 : 30,
                                            }}
                                            onClick={() => handleChooseOption(optIdx)}
                                        >
                                          <div
                                              className="MCSS-letter-container"
                                              style={{
                                                border: isSelectedOpt ? "1.5px solid #FFF" : isMobile ? "2px solid #000" : "2.5px solid #000"
                                              }}
                                          >
                                            <span
                                                className="MCSS-letter"
                                                id={"MCSSLetter" + index}
                                                style={{
                                                  color: isSelectedOpt ? "#42296E" : "#000"
                                                }}
                                            >
                                              {optIdxDisplay}
                                            </span>
                                          </div>
                                          <OptionImageViewer optionsImageMap={currentOptionsImageMap} optIdx={optIdx} isSelected={isSelectedOpt}/>
                                          <p
                                              className="MCSS-option"
                                              id={index + value}
                                              style={{
                                                color: isSelectedOpt ? "#FFF" : "#000",
                                                fontSize: isMobile ? 18 : ""
                                              }}
                                          >
                                            {newOptionsMap[optIdx]}
                                          </p>
                                        </div>
                                      </div>
                                  );
                                })}
                              </div>
                          )}
                          <DragDropContext onDragEnd={handleDragEnd}>
                            {currQuestionType === QUESTION_TYPES.RK.name && (
                                <div className="droppable-ranking-choice-question-options" style={{ display: "flex", flexDirection: "column", width: "auto", gap: "20px", paddingTop: "20px"}}>
                                  <Droppable droppableId="droppable-selected-options">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            {...provided.dropHandleProps} style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                                          {currentSelectedOption.map((value, index) => (
                                              <Draggable draggableId={"selected option" + index} key={"selected option" + index} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>
                                                      {value !== "" && (
                                                          <div
                                                              style={{display: "flex", flex: 1, alignItems: "center", paddingTop: "15px", gap: "10px"}}
                                                          >
                                                            <img src={DragDropIcon} alt="drag drop icon" width={15} height={25}/>
                                                            <div style={{fontSize: "20px", fontWeight: 700}}>{index + 1 }</div>
                                                            <div
                                                                style={{ borderRadius: "3px", padding: "12.5px 12.5px 12.5px 5px", flexGrow: "1", border: "1.13px solid #858687", minWidth: "50vw", boxShadow: "0 0 0 1px #B9B9B9", backgroundColor: "#FFFFFF"}}>
                                                              <div>
                                                                <p className="MCSS-option" id={index + value} style={{display: "flex", paddingLeft: isMobile ? "20px" : "10px", fontSize: isMobile ? "18px" : "", paddingRight: "5px", height: "fit-content"}}>
                                                                  <div style={{display: "flex", width: "100%"}}> <div style={{wordBreak: "break-word"}}> {value}  </div> <div style={{flex: 1, display: "flex", justifyContent: "end"}}> <input
                                                                      className="custom-rank-setting"
                                                                      aria-label={"type custom rank"}
                                                                      tabIndex={0}
                                                                      type="number"
                                                                      min="1"
                                                                      max={currentSelectedOption.length}
                                                                      placeholder="00"
                                                                      value={newRank === "" || !newRank.hasOwnProperty(index) ? index + 1 : newRank[index]}
                                                                      onChange={(e) => onChangeCustomRank(e, index)}
                                                                      onBlur={()=> { if(newRank && newRank !== "" && newRank[index] !== "") {let newSelectedOption = [ ...currentSelectedOption ];
                                                                        newSelectedOption.splice(newRank[index] - 1, 0, newSelectedOption.splice(index, 1)[0]);
                                                                        setCurrentSelectedOption(newSelectedOption); setNewRank("")} else {setNewRank("")}}}
                                                                      style={{display: "flex", justifyContent: "center", paddingLeft: "10px",  width: "60px", height: "26px", borderRadius: "2px"}}
                                                                  /> </div></div>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                      )}
                                                    </div>
                                                )}
                                              </Draggable>
                                          ))}
                                        </div>
                                    )}
                                  </Droppable>
                                </div>
                            )}
                          </DragDropContext>
                          {/* Create the TF options */}
                          {currQuestionType === QUESTION_TYPES.TF.name && (
                              <div className= "TF-option" id="body-TF" name={"1234"} style={{flex: "1", display: "flex", flexDirection: "column", gap: 30}}>
                                {TFelements.map((value, index) => {
                                  return (
                                      // <YuJaRadioBox
                                      //     size={isMobile ? 18 : 32}
                                      //     id={value}
                                      //     value={TFopt[index]}
                                      //     name="TF"
                                      //     checked={
                                      //         !!currentSelectedOption &&
                                      //         TFopt[index] === currentSelectedOption
                                      //     }
                                      //     style={{fontSize: isMobile ? 18 : 24, marginTop: 30}}
                                      //     labelStyle={{margin: "0px 0px 0px 16px"}}
                                      //     label={TFopt[index]}
                                      //     onClick={() => {handleChooseOption(TFopt[index])}}
                                      // />

                                      <div key={index}>
                                        {!isMobile && index === 0 && <div style={{ display: "block", height: "30px" }} />}

                                        <div
                                            tabIndex={0}
                                            className={`MCSS-label ${!!currentSelectedOption && TFopt[index] === currentSelectedOption ? "selected" : ""}`}
                                            id={value}
                                            style={{
                                              width: "100%",
                                              minHeight: isMobile ? 50 : "",
                                              borderRadius: isMobile ? "4px" : "",
                                              border: isMobile ? "1.5px solid #909090" : "",
                                              gap: isMobile ? 10 : 20,
                                              paddingLeft: isMobile ? 20 : 30,
                                              paddingRight: isMobile ? 20 : 30,
                                            }}
                                            onClick={() => {handleChooseOption(TFopt[index])}}
                                        >
                                          <p
                                              className="MCSS-option"
                                              id={index + value}
                                              style={{
                                                color: !!currentSelectedOption && TFopt[index] === currentSelectedOption ? "#FFF" : "#000",
                                                fontSize: isMobile ? 18 : ""
                                              }}
                                          >
                                            {TFopt[index]}
                                          </p>
                                        </div>
                                      </div>
                                  );
                                })}
                              </div>
                          )}

                          {/* Create the input box for SA */}
                          {currQuestionType === QUESTION_TYPES.SA.name && (
                              <div id="body-SA" className="sa-center" style={{flex: "1", width: '100%'}}>
                              <textarea
                                  className="sa-ans"
                                  id="SA-IN"
                                  onKeyDown={onEnter}
                                  name="sa-in"
                                  placeholder={SA_PLACE_HOLDER}
                                  value={
                                    !currentSelectedOption ||
                                    currentSelectedOption === "unanswered"
                                        ? ""
                                        : currentSelectedOption["0"]
                                  }
                                  onChange={handleType}
                              />
                              </div>
                          )}

                            {currQuestionType === QUESTION_TYPES.OE.name && (
                              <div id="body-SA" className="sa-center" style={{flex: "1", width: '100%', display: "flex", flexDirection: "column", gap: isMobile ? "15px" :"25px", height: "100%"}}>
                                {!openEndedExpanded &&
                                <>
                              <textarea
                                  className="oe-ans"
                                  id="OE-IN"
                                  onKeyDown={onEnter}
                                  name="oe-in"
                                  placeholder={OE_PLACE_HOLDER}
                                  value={
                                    !currentSelectedOption ||
                                    currentSelectedOption === "unanswered"
                                        ? ""
                                        : currentSelectedOption["0"]
                                  }
                                  onChange={handleType}
                              />
                            { (isAnsweredState || pollLiveQuestionIdx === questions.length - 1) && currQuestionType === QUESTION_TYPES.OE.name &&
                            <>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                <YuJaButton
                                  id="sub-btn-curr"
                                  onClick={submit}
                                  style={{
                                    fontSize: isMobile ? "15px" : "15px",
                                    padding: isMobile ? "10px" : "10px 15px",
                                    width: isMobile ? "75px" : "75px"
                                  }}
                                >
                                  {'Submit'}
                                </YuJaButton>
                              </div>
                              </>
                            }
                          </>
                        }
                        <div style={{ width: "100%", flex: "1" }}> <OpenEndedResult pollKey={pollKey} uniqueCode={uniqueCode} question={questions[curIdx]} queTitle={currQueTitle} openEndedExpanded={openEndedExpanded} setOpenEndedExpanded={setOpenEndedExpanded} /> </div>
                      </div>
                    )}

                          {/* Creates the question for FITB */}
                          {currQuestionType === QUESTION_TYPES.FITB.name && (
                              <div
                                  id="body-FITB"
                                  className="ques-desc"
                                  style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "5px", marginTop: "30px", flex: "1", order: "initial"}}
                              >
                                {/* {reactStringReplace(currQueTitle,  /(\(.*?\))/g , (m, i, o) => {
                                if (m !== "()") {

                                idx += 1;
                                return (
                                    <YuJaTextBox
                                        key={`FITB${i}`}
                                        id={Object.values(FITBElements)[idx]}
                                        containerStyle={{width: 150}}
                                        onChange={handleType}
                                        maxLength={FITB_ANSWER_LENGTH}
                                        value={
                                          !currentSelectedOption ||
                                          currentSelectedOption === "unanswered" ||
                                          !currentSelectedOption.hasOwnProperty(FITBElements[idx])
                                              ? ""
                                              : currentSelectedOption[FITBElements[idx]]
                                        }
                                    />
                                );
                              }
                              else {
                                return ("()");
                              }
                              })} */}

                                {
                                  currQueTitle.split(currHasBlankRestriction ? BLANK_REGEX_WRAPPED_NEW : BLANK_REGEX_WRAPPED).map((str, i) => {
                                    let re = currHasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX;
                                    if (!re.test(str)) {
                                      const oneLine = str.replace(/[\r\n]/g, " @newLine ");
                                      const wordArr = oneLine.split(" ");
                                      return (
                                          <>
                                            {wordArr.map((s, idx) => (<>
                                              {s === '@newLine' &&
                                                  <div key={idx} style={{width: "100%"}}></div>
                                              }
                                              {!!s && s !== '@newLine' &&
                                                  <div key={idx}>{s}</div>
                                              }
                                            </>))}
                                          </>
                                      );
                                    } else {
                                      idx += 1;
                                      return (
                                          <YuJaTextBox
                                              key={`FITB${i}`}
                                              id={Object.values(FITBElements)[idx]}
                                              containerStyle={{width: 150}}
                                              onKeyDown={onEnter}
                                              onChange={handleType}
                                              maxLength={FITB_ANSWER_LENGTH}
                                              value={
                                                !currentSelectedOption ||
                                                currentSelectedOption === "unanswered" ||
                                                !currentSelectedOption.hasOwnProperty(FITBElements[idx])
                                                    ? ""
                                                    : currentSelectedOption[FITBElements[idx]]
                                              }
                                          />
                                      );
                                    } } )}
                              </div>
                          )}

                          {/* Create the input boxes for WC */}
                          {currQuestionType === QUESTION_TYPES.WC.name &&
                              <>
                                {shownEntries > 1 &&
                                    <div id="body-WC" className={isNarrow ? "wc-center-narrow" : "wc-center"} style={{flex: "1", height: "fit-content"}}>
                                      {Array(shownEntries).fill(null).map((item, i) => {
                                        return (
                                            <div key={i} className="wc-ans-container" style={{width: isNarrow ? "100%" : ""}}>
                                              <YuJaTextBox
                                                  id={`WC-IN-${i}`}
                                                  name="wc-in"
                                                  placeholder={i === 0 ? FIRST_WC_PLACEHOLDER : OTHER_WC_PLACEHOLDER}
                                                  maxLength={WC_ANSWER_LENGTH}
                                                  style={{paddingRight: "20px", boxSizing: "border-box"}}
                                                  value={checkBlankAnswered(currentSelectedOption) ? "" : currentSelectedOption[i]}
                                                  onChange={(e) => {
                                                    const copy = currentSelectedOption === FIXED_ANSWER.UNANSWERED ? {} : {...currentSelectedOption}
                                                    copy[i] = e.target.value;
                                                    setCurrentSelectedOption(copy);
                                                    if (i === shownEntries - 1 && shownEntries < currEntries && e.target.value.length) {
                                                      setShownEntries(shownEntries + 1);
                                                    }
                                                  }}
                                                  containerStyle={{width: isMobile ? "75%": "100%", border: "2.5px solid #D0D0D0", borderRadius: "10px"}}
                                              />
                                              <div className="wc-char-count">
                                                {WC_ANSWER_LENGTH - (currentSelectedOption[i] ? currentSelectedOption[i].length : 0)}
                                              </div>
                                              <img
                                                  src={deleteIcon}
                                                  tabIndex={0}
                                                  alt="Delete Answer"
                                                  className="delete-entry-icon"
                                                  role={BUTTON}
                                                  onClick={() => handleDelete(i)}
                                                  style={{width: "25px", height: "25px", marginLeft: "15px"}}
                                              />
                                            </div>
                                        );
                                      })}
                                    </div>
                                }


                                {shownEntries === 1 &&
                                    <div id="body-WC-odd" className="wc-center-odd" style={{width: isMobile ? "100%" : ""}}>
                                      <div className="wc-ans-container" style={{width: isMobile ? "100%" : ""}}>
                                        <YuJaTextBox
                                            id={`WC-IN-0`}
                                            name="wc-in"
                                            containerStyle={{width: isMobile ? "75%": "40vw", border: "2.5px solid #D0D0D0", borderRadius: "10px"}}
                                            placeholder={WCElements.length === 1 ? FIRST_WC_PLACEHOLDER : OTHER_WC_PLACEHOLDER}
                                            maxLength={WC_ANSWER_LENGTH}
                                            style={{paddingRight: "20px", boxSizing: "border-box"}}
                                            value={checkBlankAnswered(currentSelectedOption) ? "" : currentSelectedOption[0]}
                                            onChange={(e) => {
                                              const copy = currentSelectedOption === FIXED_ANSWER.UNANSWERED ? {} : {...currentSelectedOption}
                                              copy[0] = e.target.value;
                                              setCurrentSelectedOption(copy);
                                              wcInputValRef.current = e.target.value;
                                            }}
                                        />
                                        <div className="wc-char-count">
                                          {WC_ANSWER_LENGTH - (currentSelectedOption[0] ? currentSelectedOption[0].length : 0)}
                                        </div>
                                        <img
                                            src={deleteIcon}
                                            className="delete-entry-icon"
                                            role={BUTTON}
                                            tabIndex={0}
                                            alt="Delete Answer"
                                            onClick={() => handleDelete(0)}
                                            style={{width: "25px", height: "25px", marginLeft: "15px"}}
                                        />
                                      </div>
                                    </div>
                                }
                              </>
                          }
                          {currQuestionType === QUESTION_TYPES.MH.name && (<>
                            <div id="body-MCSS" style={{ flex: "1", maxWidth: "100%", overflow: "auto" }}>
                              <div>
                                <div style={{ display: "flex", marginTop: "30px", width: "100%"}}>
                                  <div style={{width: "100%"}}>
                                    {Array.isArray(questions[curIdx].correctAnswers) && questions[curIdx].correctAnswers.map((element, index) => (
                                        <div key={element[0]} id={"Option" + index} className="questionPanelOptionsContainer" style={{ paddingLeft: "0px", paddingRight: "0px", marginBottom: "0px", display: "flex", alignItems: "center" }}>
                                          {element.length > 1 ?
                                              <div style={{ display: "flex", flex: 1, width: "100%", borderTop: isNarrow && index ===0 ? "none" : ""}} className={isNarrow ? styles.MatchingNarrow : ""}>
                                                {isNarrow && <div style={{fontWeight: "700", fontSize: "45", marginTop:"10px", marginBottom: "10px"}}> {PREMISE} </div>}
                                                <div
                                                    tabIndex={0}
                                                    role={TEXTBOX}
                                                    className="questionPanelAnswersText matchingPremise"
                                                    id={"OptionPart1" + index}
                                                    style={{
                                                      marginRight: "0px",
                                                      padding: 10,
                                                      borderRadius: "10px",
                                                      minWidth: !isNarrow ? "250px": "",
                                                      height: "fit-content",
                                                      flex: 1,
                                                      wordBreak: "keep-all"
                                                    }}
                                                >{element[0]}</div>
                                                <img
                                                    src={ArrowIcon}
                                                    alt="Arrow icon"
                                                    className="questions-page-icons cursor-pointer"
                                                    style={{ width: "48px", margin: "0px 20px", height: "fit-content",  display: isNarrow ? "none" : "block"}}
                                                />
                                                <div style={{minWidth: "50%", position: "relative", flex: "1"}} onBlur={() => {if(isDropdownOpen != null) {setIsDropdownOpen(null)}}}>
                                                  {isNarrow && <div style={{fontWeight: "700", fontSize: "45", marginTop:"10px", marginBottom: "10px"}}> {RESPONSE} </div>}
                                                  <YuJaDropdown
                                                      data={questions[curIdx].optionsMap}
                                                      getOptionLabel={option => option.length > 1 ? option[1]: option[0]}
                                                      getOptionValue={option => option.length > 1 ? option[1]: option[0]}
                                                      value={currentSelectedOption[index]}
                                                      placeholder={"Select an option"}
                                                      textStyle={{wordBreak: "break-word"}}
                                                      itemStyle={{borderWidth: "2.5px", borderColor: "#909090" }}
                                                      onChange={(option) => {
                                                        let newAnswer = {[index]: option.length > 1 ? option[1]: option[0]};
                                                        if(currentSelectedOption !==  FIXED_ANSWER.UNANSWERED) {
                                                          newAnswer = { ...currentSelectedOption, [index]: option.length > 1 ? option[1]: option[0] };
                                                        }

                                                        setCurrentSelectedOption(newAnswer);
                                                        document.getElementById("sub-btn-curr").style.display = "block";
                                                      }}
                                                      style={{fontSize: 18, padding: 10, borderRadius: "10px",
                                                        border: "2.5px solid #909090",
                                                      }}
                                                      arrowSize={"24px"}
                                                  />
                                                </div>
                                              </div> : <></>}
                                        </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </> ) }
                          {!!imageURL && !isMobile && (
                              <div className="img-container-question" style={{alignSelf: "center", margin: "0px", marginTop: "30px", width: currQuestionType === QUESTION_TYPES.CI.name ? "100%" : ""}}>
                                {currQuestionType !== QUESTION_TYPES.CI.name && <>
                                  {!isVideo && <img className="img-question" src={imageURL} alt="Question" tabIndex={0} aria-label={questions[curIdx].imageAlt ? questions[curIdx].imageAlt : DEFAULT_IMAGE_ALT}/>}
                                  {isVideo && <iframe className="img-question" allowFullScreen src={imageURL} alt="Question"/>}
                                </>}
                                {currQuestionType === QUESTION_TYPES.CI.name &&
                                    <div style={{ position: "relative"}}>
                                      <img
                                          className="img"
                                          ref={imageRef}
                                          src={imageURL}
                                          onLoad={onLoad}
                                          onClick={handleClickImage}
                                          alt="Question Image"
                                          tabIndex={0} aria-label={questions[curIdx].imageAlt ? questions[curIdx].imageAlt : DEFAULT_IMAGE_ALT}/>
                                      {isLoaded
                                          && !!currentSelectedOption
                                          && currentSelectedOption.hasOwnProperty("x")
                                          && currentSelectedOption.hasOwnProperty("y")
                                          &&
                                          <COORDINATE
                                              style={{
                                                position: "absolute",
                                                top: `${decodeCIParam(currentSelectedOption.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                                left: `${decodeCIParam(currentSelectedOption.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                              }}
                                          />
                                      }
                                    </div>
                                }
                              </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {(pollLiveQuestionIdx === questions.length - 1 || isAnsweredState) &&  currQuestionType != QUESTION_TYPES.OE.name && (isNarrow && !(window.innerWidth >= window.innerHeight && window.innerHeight < 480)) &&
                    <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "0 30px",
                          backgroundColor: "#FCFBFF",
                        }}
                    >
                      <YuJaButton
                          id="sub-btn-curr"
                          onClick={submit}
                          style={{
                            fontSize: isMobile ? "16px" : "20px",
                            padding: isMobile ? "10px" : "10px 15px",
                            width: isMobile ? "75px" : "95px"
                          }}
                      >
                        {'Submit'}
                      </YuJaButton>
                    </div>
                }


                <div
                    style={{
                      position: "relative",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: pollLiveQuestionIdx === questions.length - 1 && window.innerWidth >= window.innerHeight && window.innerHeight < 480 ? "space-between" : "center",
                      flexDirection: pollLiveQuestionIdx === questions.length - 1 && window.innerWidth >= window.innerHeight && window.innerHeight < 480 ? "row" : "column",
                      padding: currQuestionType == QUESTION_TYPES.OE.name ? 0 : 20,
                      paddingBottom: currQuestionType == QUESTION_TYPES.OE.name ? 0 : 60,
                      backgroundColor: "#FCFBFF",
                    }}
                >
                  <JoinQuizPagination
                      // pollType={pollType}
                      curSerialNo={currSerialNo}
                      questions={questions}
                      questionAnswers={queResponses}
                      switchSerialNo={switchSerialNo}
                      limitation={isNarrow ? 5 : 10}
                  />


                  {(pollLiveQuestionIdx === questions.length - 1 || isAnsweredState) && currQuestionType != QUESTION_TYPES.OE.name && <>
                    {(window.innerWidth >= window.innerHeight && window.innerHeight < 480) &&
                      <div style={{ display: "flex", paddingRight: 10, zIndex: 0}}>
                        <YuJaButton
                            id="sub-btn-curr"
                            onClick={submit}
                            style={{
                              fontSize: isMobile ? "16px" : "20px",
                              padding: isMobile ? "10px" : "10px 15px",
                              width: isMobile ? "75px" : "95px"
                            }}
                        >
                          {'Submit'}
                        </YuJaButton>
                      </div>
                    }
                    {(!isNarrow && currQuestionType != QUESTION_TYPES.OE.name && !(window.innerWidth >= window.innerHeight && window.innerHeight < 480)) &&
                      <div
                        style={{
                            position: "absolute",
                            right: 0,
                            height: "100%",
                            display: "flex",
                            padding: 20,
                            justifyContent: "flex-end",
                            alignItems: "center",
                            zIndex: 0,
                        }}
                      >
                        <YuJaButton
                            id="sub-btn-curr"
                            onClick={submit}
                            style={{
                              fontSize: isMobile ? "16px" : "20px",
                              padding: isMobile ? "10px" : "10px 15px",
                              width: isMobile ? "75px" : "95px"
                            }}
                        >
                          {'Submit'}
                        </YuJaButton>
                      </div>
                    }
                  </>}
                </div>
                
              </div>

            }
      </>
  );
}
