import "bootstrap/dist/css/bootstrap.css";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import FeedBackModal from "../../components/modals/FeedBackModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-question.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
// import { ReactComponent as CompletionRateIcon } from "../../images/completion-rate-icon.svg";
// import { ReactComponent as ParticipantsIcon } from "../../images/participants_icon.svg";
// import { ReactComponent as ResponsesIcon } from "../../images/responses-icon.svg";
import { ReactComponent as ThumbDown } from "../../images/openEndedThumbDown.svg";
import { ReactComponent as ThumbUp } from "../../images/openEndedThumbUp.svg";
import { YuJaGlobalState, useLoading } from "../../utils/LoadingContext";
import { BLANK_REGEX, BLANK_REGEX_NEW, DEFAULT_IMAGE_ALT, EMPTY_WORD_CLOUD_TEXT, FEEDBACK_BUTTON_TEXT, FEEDBACK_TEXT, FIXED_ANSWER, NO_RESPONSES_TEXT, OPEN_ENDED_DISLIKE_SORT, OPEN_ENDED_LIKE_SORT, OPEN_ENDED_TIME_SORT, PARTICIPANT_RESPONSE_TEXT, POLL_RESULT_TEXT, QUESTION_TITLE, QUESTION_TYPES, RESULT_CONTAINER_KEY_PREFIX, SORT_BY_TEXT, TEXTBOX, UNANSWERED, WORD_CLOUD_DATA } from "../../utils/constants";
import { InitializeQuestionResultCount, hasWC, newPageLog, removePrefix } from "../../utils/helpers";
import { questionCodeToName } from "../../utils/questionUtils";
import ClassResultChart from "./ClassResultChart";
import ClassResultMH from "./ClassResultMH";
import ClassResultRank from "./ClassResultRank";
import NetworkStatus from "./NetworkStatus";
import WordCloudResult from "./WordCloudResult";
import "./styles.css";
import styles from "./styles.module.css";

export default function ClassResultAllMerged({ currentPoll, institutionId, websocketState, pollTitle = "", userName, pollInfo}) {
    // const isMobile = useMobileAccess(1200);
    const { questions, rawQueResponses = [] } = currentPoll;
    const { showAnswerOnViewer } = pollInfo;
    const pollKey = removePrefix(questions[0].pollKey);
    const { getAllClassResult } = ResponseApiCalls();
    const { setLoading } = useLoading();
    const [questionsData, setQuestionsData] = useState([]);
    const [setWordCloudData,] = useLocalStorageNew(WORD_CLOUD_DATA, {});
    const { isOnline } = useContext(YuJaGlobalState);
    const [selectedQuestion, setSelectedQuestion] = useState(questions[0]);
    // const [participantCount, setParticipantCount] = useState("-");
    // const [responsesCount, setResponsesCount] = useState("-");
    // const [completionRate, setCompletionRate] = useState("-");
    const [questionData, setQuestionData] = useState(() => {
        let correctAnswers;
        if (questions[0].questionType === QUESTION_TYPES.MCSS.name) {
            correctAnswers = questions[0].correctAnswers[0];
        } else if (questions[0].questionType === QUESTION_TYPES.TF.name || questions[0].questionType === QUESTION_TYPES.MH.name) {
            correctAnswers = questions[0].correctAnswers;
        } else {
            correctAnswers = null;
        }
        return {
            correctAnswers: correctAnswers,
            questionType: questions[0].questionType,
            queTitle: questions[0].queTitle,
            questionResults: InitializeQuestionResultCount(questions[0]),
            rawResults: {},
            serialNo: questions[0].serialNo,
            imageURL: questions[0].imageURL,
            image: questions[0].image,
            suffix: questions[0].suffix,
            directLinkEvp: questions[0].directLinkEvp,
            blankRegex: questions[0].hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX
        };
    });

    const isNarrow = useMobileAccess(1200);
    const isMobile = useMobileAccess(480);

    const [dataLoading, setDataLoading] = useState(false);
    const [isSwitching, setIsSwitching] = useState(false);
    const [dataReady, setDataReady] = useState(false);
    const [openEndedData, setOpenEndedData] =  useState([]);
    const [originalOpenEnded, setOriginalOpenEneded] = useState(null);
    const [sortOption, setSortOption] = useState(OPEN_ENDED_TIME_SORT);
    // const [, getSession] = useLocalStorageNew("session", {});
    const [feedbackModalShow, setFeedBackModalShow] = useState(false);

    useEffect(() => {
        document.getElementById('root').style.backgroundColor = "#FCFBFF";
        newPageLog("ClassResultAllMerged.js");
        if (isNarrow && document.getElementById("viewer-navbar-container") !== null) {
            document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
        }
    }, []);

    useEffect(() => {
        if (hasWC(questions)) setWordCloudData({});
    }, [questions]);

    function removeUnanswered(result) {
        let newResult = [];
        if (result == null) {
            return newResult;
        }
        result.forEach((r) => {
            if (r.ans !== UNANSWERED) {
                newResult.push({ ...r });
            }
        });
        return newResult;
    };

    async function getQuestionsData() {
        setLoading(true);
        const results = await getAllClassResult(pollKey, questions[0].pollSortKey.split("#")[2], questions);
        let tempQuestionsData = [];
        for (const result of results) {
            let serialNo = result.serialNo;
            const question = questions.find(({ serialNo: no }) => parseInt(no) === parseInt(serialNo));
            let correctAnswers;
            if (question.questionType === QUESTION_TYPES.MCSS.name) {
                correctAnswers = question.correctAnswers[0];
            } else if (question.questionType === QUESTION_TYPES.TF.name || question.questionType === QUESTION_TYPES.MH.name  || question.questionType === QUESTION_TYPES.CI.name) {
                correctAnswers = question.correctAnswers;
            } else {
                correctAnswers = null;
            }
            let questionType = question.questionType;
            let queTitle = question.queTitle;
            let MCSSelements = [];
            if (questionType === QUESTION_TYPES.MCSS.name) {
                for (let key in question.optionsMap) {
                    MCSSelements.push(question.optionsMap[key]);
                }
            }
            let MCSSnum = MCSSelements.length;
            let questionImageURL;
            let isVideo = false;
            if (question.directLinkEvp) {
                questionImageURL = question.directLinkEvp;
                isVideo = true;
            } else {
                questionImageURL = question.imageURL;
                isVideo = false;
            }

            let questionData = {
                correctAnswers: correctAnswers,
                questionType: questionType,
                queTitle: queTitle,
                gradeEnabled: !!question.weightage,
                questionResults: result.questionResults,
                serialNo: serialNo,
                MCSSnum: MCSSnum,
                questionImageURL: questionImageURL,
                isVideo: isVideo,
                rawResponse: result.rawResponse
            };
            tempQuestionsData.push(questionData);
        }

        tempQuestionsData.forEach(item => {
            item.unansweredCount = getUnanswered(item.questionResults);
        });

        tempQuestionsData.forEach(item => {
            item.questionResults = removeUnanswered(item.questionResults);
        });

        setQuestionsData(tempQuestionsData);
        setDataReady(true);
        setLoading(false);
    }

    useEffect(() => {
        if (!isOnline || websocketState !== WebSocket.OPEN) {
            return;
        }

        // async function setResults() {
        //   let questionResultCopy = {};
        //   let promises = questions.map((question, index) => {
        //     console.log(question.questionType);
        //     if (question.questionType === QUESTION_TYPES.SA.name || question.questionType === QUESTION_TYPES.FITB.name || question.questionType === QUESTION_TYPES.WC.name) {
        //       return getClassResult(pollKey, question.pollSortKey.split("#")[2], question, null, true).then((result) => {
        //         if (result.length > 0) {
        //           questionResultCopy[index] = result;
        //         }
        //       });
        //     }
        //   })
        //   await Promise.all(promises);
        //   setQuestionResults(questionResultCopy);
        // }
        setDataLoading(true);
        setTimeout(() => {
            getQuestionsData();
        }, 3000);

        const interval = setInterval(() => {
            getQuestionsData();
        }, 5000);

        return () => clearInterval(interval);
    }, [questions, isOnline, websocketState]);


    const getUnanswered = (result) => {
        if (result == null) {
            return 0;
        }

        let unansweredCount = 0;
        result.forEach((r) => {
            if (r.ans === UNANSWERED) {
                unansweredCount = r.people;
            }
        });
        return unansweredCount;

    }
    useEffect(() => {
        if (questionsData && questionsData.length !== 0) {
            let serialNo = selectedQuestion.serialNo;
            const questiondata = questionsData.find(({ serialNo: no }) => parseInt(no) === parseInt(serialNo));
            setQuestionData(questiondata);
            if (selectedQuestion.questionType === QUESTION_TYPES.OE.name && questionData && questionData.rawResponse && questionData.rawResponse.openEnded) {
                setOriginalOpenEneded(questionData.rawResponse.openEnded);
                setOpenEndedData(Object.entries(questionData.rawResponse.openEnded));
                handleSort();
            }
            // let overall_count = questiondata.questionType === QUESTION_TYPES.RK.name ? questiondata.rawResponse.total : questiondata.questionResults[0].total;
            // setParticipantCount(overall_count);
            // let count = questiondata.questionType === QUESTION_TYPES.RK.name ? questiondata.rawResponse.total : questiondata.questionResults[0].total - questiondata.unansweredCount;
            // setResponsesCount(count);
            // if (overall_count > 0) {
            //     setCompletionRate(Math.round(count / overall_count * 100));
            // }
            // else {
            //     setCompletionRate(0);
            // }
            setDataLoading(false);
        }
    }, [selectedQuestion, questionsData])

    useEffect(() => {
        handleSort();
    }, [sortOption]);

    const handleSort = () => {
        if (sortOption === OPEN_ENDED_TIME_SORT && originalOpenEnded) {
            setOpenEndedData(Object.entries(originalOpenEnded));
          } else if (sortOption === OPEN_ENDED_LIKE_SORT && originalOpenEnded && Object.keys(originalOpenEnded).length > 1) {
            let entries = Object.entries(originalOpenEnded);
            entries.sort(([, valueA], [, valueB]) => {
              return valueB["1"] - valueA["1"];
            });
            setOpenEndedData(entries);
          } else if (sortOption === OPEN_ENDED_DISLIKE_SORT && originalOpenEnded && Object.keys(openEndedData).length > 1) {
            let entries = Object.entries(originalOpenEnded);
            entries.sort(([, valueA], [, valueB]) => {
              return valueB["2"] - valueA["2"];
            });
            setOpenEndedData(entries);
          }
    }


    const removeSelected = (data, target, questionType) => {
        if (questionType === QUESTION_TYPES.SA.name && data) {
            const indexToRemove = data.findIndex(item => item['0'] === target);
            if (indexToRemove !== -1) {
                const filteredList = [
                    ...data.slice(0, indexToRemove),
                    ...data.slice(indexToRemove + 1),
                ];

                return filteredList
            }
        }
        else if (questionType === QUESTION_TYPES.FITB.name && data) {
            const indexToRemove = data.findIndex(item =>
                Object.entries(item).every(([key, value]) => target[key] === value)
            );

            if (indexToRemove !== -1) {
                const filteredList = [
                    ...data.slice(0, indexToRemove),
                    ...data.slice(indexToRemove + 1),
                ];
                return filteredList
            }
        }
    }

    useEffect(() => {
        setIsSwitching(true);
        getQuestionsData();
    }, [window.innerWidth])

    return (
        <div id="everything" className={styles.centerLayout} style={{ height: "100%" }}>
            <FeedBackModal
                show={feedbackModalShow}
                setModalShow={setFeedBackModalShow}
                pollKey={pollKey}
                pollUniqueCode={questions[0].pollSortKey.split("#")[2]}
                userId={userName}
                institutionId={institutionId}
            />
            <div style={{ display: isMobile ? "flex" : "", flexDirection: isMobile ? "column" : "", height: '100%', width: "100%" }}>
                {!isMobile ?
                    <div className="waiting-room-title-container" style={{ height: "fit-content", marginTop: "32px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", justifyContent: "space-between" }}>
                        <span role={TEXTBOX} tabIndex={0} className="waiting-room-title" style={{ wordBreak: "break-word", paddingLeft: !isNarrow ? "170px" : "10px", textAlign: "center", flex: "1", color: "#000", fontSize: "24px" }}>{POLL_RESULT_TEXT + pollTitle}</span>
                        <div style={{ display: "flex", justifyContent: "end", paddingRight: "20px" }}>  <NetworkStatus websocketState={websocketState} /> </div>
                    </div>
                    :
                    <>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 20, padding: "0px 10px 0px 10px" }}>
                            <div className="poll-title" style={{ maxWidth: "100%", flex: 1 }}>{pollTitle}</div>
                            <NetworkStatus tooltipPlacement={'bottom'} refreshAlignFirst={true} websocketState={websocketState} />
                        </div>
                        <div style={{ padding: "0px 10px 0px 10px" }}> <hr /> </div>
                    </>
                }


                <div style={{ display: "flex", width: isMobile ? "calc(100% - 20px)" : "calc(100% - 36px)", marginLeft: isMobile ? "10px" : "18px", marginTop: isMobile ? "0px" : "28px", gap: isMobile ? "8px" : "25px", marginRight: isMobile ? "10px" : "18px", flexDirection: isMobile ? "column" : "" }}>
                    <div role={TEXTBOX} tabIndex={0} style={{ color: "#000", fontSize: isMobile ? "14px" : "18px", alignItems: "center", display: "flex" }}> Choose Question </div>
                    <YuJaDropdown
                        containerStyle={{ width: !isMobile ? "40%" : "100%" }}
                        data={questions}
                        value={selectedQuestion ? "Question " + selectedQuestion.serialNo + ": " + selectedQuestion.queTitle : ""}
                        getOptionLabel={item => "Question " + item.serialNo + ": " + item.queTitle}
                        getOptionValue={option => "Question " + option.serialNo + ": " + option.queTitle}
                        textStyle={{ overflow: "hidden", color: "#000", fontSize: isMobile ? "14px" : "18px", letterSpacing: "0.63px", whiteSpace: "nowrap", textOverflow: 'ellipsis', textWrap: "nowrap", height: "20px", maxWidth: "100%", paddingTop: isMobile ? "2px" : "" }}
                        onChange={(item) => {
                            setIsSwitching(true);
                            setDataLoading(true);
                            getQuestionsData();
                            setOpenEndedData([]);
                            setOriginalOpenEneded(null);
                            setSortOption(OPEN_ENDED_TIME_SORT);
                            setSelectedQuestion(item);
                        }}

                        style={{ height: isMobile ? "50px" : "40px" }}
                    />
                </div>


                {/* {!isMobile &&
                    <div className="class-result-poll-container" style={{ marginLeft: "18px", width: "calc( 100% - 36px)" }}>

                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                                <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <div style={{ width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <ParticipantsIcon />
                                        </div>
                                        <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "18px", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {PARTICIPANT_TEXT} </div>
                                    </div>
                                    <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "24px", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {dataLoading || participantCount === "-" ? <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                        <Skeleton borderRadius={10} height={20} width="100px" style={{ position: "relative" }} />
                                    </SkeletonTheme> : participantCount} </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                                <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <div style={{ width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <ResponsesIcon />
                                        </div>
                                        <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "18px", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {RESPONSE_TEXT} </div>
                                    </div>
                                    <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "24px", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {dataLoading || responsesCount === "-" ? <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                        <Skeleton borderRadius={10} height={20} width="100px" style={{ position: "relative" }} />
                                    </SkeletonTheme> : responsesCount} </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                                <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <CompletionRateIcon />
                                        <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "18px", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {COMPLETION_RATE_TEXT} </div>
                                    </div>
                                    <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "24px", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {dataLoading || completionRate === "-" ? <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                        <Skeleton borderRadius={10} height={20} width="100px" style={{ position: "relative" }} />
                                    </SkeletonTheme> : <>{completionRate} {completionRate !== "-" ? "%" : ""} </>} </div>
                                </div>
                            </div>
                        </div>

                    </div>
                } */}

                {/* {isMobile &&
                    <div style={{
                        width: "calc(100% - 20px)", marginRight: "10px", marginLeft: "10px", borderRadius: "19.565px", border: "1px solid #969696", background: "#fff", height: "fit-content", marginTop: "15px", padding: "10px"
                    }}>
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", rowGap: '10px', columnGap: "10px" }}>

                            <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%" }}>
                                <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ParticipantsIcon />
                                </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "13px", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px" }}> {PARTICIPANT_TEXT} </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "15px", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end" }}> {dataLoading || participantCount === "-" ? <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                    <Skeleton borderRadius={10} height={20} width="30px" style={{ position: "relative" }} />
                                </SkeletonTheme> : participantCount} </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%" }}>
                                <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ResponsesIcon />
                                </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "13px", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px" }}> {RESPONSE_TEXT} </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "15px", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end", minWidth: "fit-content" }}> {dataLoading || responsesCount === "-" ? <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                    <Skeleton borderRadius={10} height={20} width="30px" style={{ position: "relative" }} />
                                </SkeletonTheme> : responsesCount} </div>
                            </div>

                            <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%", maxWidth: "50%" }}>
                                <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CompletionRateIcon />
                                </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "13px", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px" }}> {COMPLETION_RATE_TEXT} </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "15px", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end" }}> {dataLoading || completionRate === "-" ? <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                    <Skeleton borderRadius={10} height={20} width="30px" style={{ position: "relative" }} />
                                </SkeletonTheme> : <>{completionRate} {completionRate !== "-" ? "%" : ""}</>} </div>
                            </div>
                        </div>

                    </div>
                } */}

                {isMobile &&
                    <>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "16px", width: "calc(100% - 20px)", marginLeft: "10px", marginRight: "10px", gap: "15px", flex: "1", overflowX: "clip" }}>
                            <span className="gradientWrapQuesType" style={{ height: "45px", width: "150px", marginLeft: "0px" }}>
                                <span className="questionPanelQuestionTypeContainer" style={{ width: "", padding: "0px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <div className="questionPanelQuestionType" style={{ fontSize: "14px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center", height: isNarrow ? "12px" : "" }}> {questionCodeToName(selectedQuestion.questionType)} </div>
                                </span>
                            </span>

                            <div className="cur-question-title" style={{ fontSize: isMobile ? "14px" : "18px", height: 'fit-content', wordBreak: "break-word" }}>Question {selectedQuestion.serialNo}  {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name ? " - " + selectedQuestion.queTitle : ""} </div>


                            {(questionData.questionType === QUESTION_TYPES.SA.name) &&
                                <Row style={{ marginTop: "25px" }}>
                                    <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                                        <div className="content">
                                            {questionData.questionImageURL && (
                                                <div style={{ display: 'flex', justifyContent: 'center', flex: "1", padding: "25px" }}>
                                                    {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT} />}
                                                    {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question" />}
                                                </div>
                                            )}
                                            <div style={{ display: "flex" }}>
                                                <Col style={{ height: "300px", overflowY: "auto" }}>
                                                    {dataReady && rawQueResponses && rawQueResponses[selectedQuestion.serialNo - 1] && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption !== "unanswered" &&
                                                        <>
                                                            <div
                                                                className={`questionPanelAnswersText resultAnimation`}
                                                                style={{
                                                                    marginTop: "20px",
                                                                    backgroundColor: "#C4BDD2",
                                                                    color: "#000", borderRadius: "10px",
                                                                    width: "fit-content",
                                                                    padding: "15px"
                                                                }}
                                                            >
                                                                {`"${rawQueResponses[selectedQuestion.serialNo - 1].selectedOption["0"]}"`}
                                                            </div>

                                                            {removeSelected(questionData.rawResponse, rawQueResponses[selectedQuestion.serialNo - 1].selectedOption["0"], questionData.questionType)?.map((item) =>
                                                                item !== "unanswered" ? (
                                                                    <div
                                                                        tabIndex={0}
                                                                        className={`questionPanelAnswersText resultAnimation`}
                                                                        style={{
                                                                            marginTop: "20px",
                                                                            backgroundColor: "#CACACA",
                                                                            color: "#000", borderRadius: "10px",
                                                                            width: "fit-content",
                                                                            padding: "15px"
                                                                        }}
                                                                    >
                                                                        {`"${item["0"]}"`}
                                                                    </div>
                                                                ) : <></>
                                                            )}
                                                        </>
                                                    }

                                                    {rawQueResponses && (!rawQueResponses[selectedQuestion.serialNo - 1] || !rawQueResponses[selectedQuestion.serialNo - 1].selectedOption || rawQueResponses[selectedQuestion.serialNo - 1].selectedOption === "unanswered") &&
                                                        <>{questionData.rawResponse?.map((item) =>
                                                            item !== "unanswered" ? (
                                                                <div
                                                                    className={`questionPanelAnswersText resultAnimation`}
                                                                    style={{
                                                                        marginTop: "20px",
                                                                        backgroundColor: "#CACACA",
                                                                        color: "#000", borderRadius: "10px",
                                                                        width: "fit-content",
                                                                        padding: "15px"
                                                                    }}
                                                                >
                                                                    {`"${item["0"]}"`}
                                                                </div>
                                                            ) : <></>
                                                        )}</>
                                                    }
                                                    {(!rawQueResponses && (!questionData.rawResponse || !questionData.rawResponse.filter(item => item !== FIXED_ANSWER.UNANSWERED).length)) &&
                                                        <div style={{
                                                            color: "grey",
                                                            fontSize: 20,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}> {EMPTY_WORD_CLOUD_TEXT} </div>
                                                    }
                                                </Col>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {(questionData.questionType === QUESTION_TYPES.OE.name) &&
                                <>
                                <div>
                                <Col>
                                {dataReady && questionData?.rawResponse?.responses && Object.entries(questionData.rawResponse.responses).length && openEndedData && openEndedData.filter(entry => questionData.rawResponse.responses[entry[0]] && questionData.rawResponse.responses[entry[0]] !== FIXED_ANSWER.UNANSWERED).length != 0 &&
                                    <> 
                                        <div style={{backgroundColor: "#FFFFFF", border: "1px solid #E8EDF1", boxShadow: "0px 0px 4px 0px #00000040", borderRadius: "7.39px", padding: 20, width: "100%", maxHeight:"360px", overflowY: "auto"}}> 

                                                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: isMobile ? "column" : "",  gap: "8px" }}>
                                                        <div style={{ fontSize: 16, fontWeight: 600 }}> {PARTICIPANT_RESPONSE_TEXT} </div>
                                                        <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "end", width: "100%", flex: 1 }}>
                                                            <div style={{ fontSize: 16, color: "#0000000" }}> {SORT_BY_TEXT}</div>
                                                            <YuJaDropdown
                                                                data={[OPEN_ENDED_TIME_SORT, OPEN_ENDED_LIKE_SORT, OPEN_ENDED_DISLIKE_SORT]}
                                                                getOptionLabel={item => item}
                                                                getOptionValue={item => item}
                                                                onChange={option => setSortOption(option)}
                                                                value={sortOption}
                                                                containerStyle={{ width: 130 }}
                                                                style={{ padding: "7px 10px", fontSize: 15, border: "1px solid #6F6F6F", color: "#4C4C4C" }}
                                                            />
                                                        </div>
                                                    </div>
                                            {openEndedData.map(([key, value], index) => (
                                                <>
                                                {questionData.rawResponse.responses[key] && questionData.rawResponse.responses[key] !== FIXED_ANSWER.UNANSWERED &&
                                                <div
                                                    style={{
                                                        marginTop: "20px",
                                                        border: "1px solid #E8EDF1", 
                                                        color: "#000", borderRadius: "7.4px",
                                                        minHeight: '94px', padding: 10, wordBreak: "break-word", boxShadow: "0px 0px 4px 0px #00000040"
                                                    }}
                                                >
                                                    {`${questionData.rawResponse.responses[key]["0"]}`}
                                                    <div style={{ borderRadius: "200px", gap: "20px", backgroundColor: "#ECEAF1", display: "flex", justifyContent: "space-between",  height: 35, width: 165, marginTop: 10, padding: "5px 20px"}}>
                                                        <div style={{display: "flex", gap: '5px'}}>  
            
                                                                <div>  <ThumbUp/></div>
                                                        <div style={{fontSize: 15, fontWeight: 500, color: "#4D4D4D"}}> {value["1"]} </div> 
                                                        </div> 
            
                                                        <div style={{display: "flex", gap: '5px'}}> 
                                                                <div> <ThumbDown/></div>
                                                        <div style={{fontSize: 15, fontWeight: 500, color: "#4D4D4D"}}> {value["2"]} </div> 
                                                        </div> 
                                                    </div> 
                                                </div>
                                                }
                                                </>
                                                )
                                            )}
                                </div> 
                                    </>
                                }
                                    {dataReady && (!questionData?.rawResponse?.responses || !Object.entries(questionData.rawResponse.responses).length || !openEndedData.filter(entry => questionData.rawResponse.responses[entry[0]] && questionData.rawResponse.responses[entry[0]] !== FIXED_ANSWER.UNANSWERED).length) &&
                                        <div style={{ backgroundColor: "#FFFFFF", border: "1px solid #E8EDF1", boxShadow: "0px 0px 4px 0px #00000040", borderRadius: "7.39px", padding: 20, width: "100%", maxHeight: "500px", overflowY: "auto" }}>
                                            <div style={{ width: "100%", height: "100%", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", gap: 5 }}>
                                                <NoResultIllustration width={192} height={100} />
                                                <div style={{ fontSize: 18, marginTop: 10, fontWeight: 600 }}>
                                                    {NO_RESPONSES_TEXT}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Col> 
                                </div>
                                </>
                            }

                            {(questionData.questionType === QUESTION_TYPES.FITB.name) && <>
                                <Row>
                                    <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                                        <div className="content">
                                            <Row>
                                                <div className="ques-desc">
                                                    {questionData.queTitle.replace(questionData.blankRegex, "______")}
                                                </div>
                                            </Row>

                                            {questionData.questionImageURL && (
                                                <div style={{ display: 'flex', justifyContent: 'center', flex: "1", padding: "25px" }}>
                                                    {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT} />}
                                                    {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question" />}
                                                </div>
                                            )}
                                            <div style={{ display: "flex" }}>
                                                <Col style={{ height: "300px", overflowY: "auto", maxWidth: questionData.questionImageURL ? "50%" : "" }}>
                                                    {dataReady && rawQueResponses && rawQueResponses[selectedQuestion.serialNo - 1] && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption !== "unanswered" &&
                                                        <>
                                                            <div
                                                                className={`questionPanelAnswersText resultAnimation`}
                                                                style={{
                                                                    marginTop: "20px",
                                                                    backgroundColor: "#C4BDD2",
                                                                    color: "#000", borderRadius: "10px",
                                                                    width: "fit-content",
                                                                    padding: "15px",
                                                                }}
                                                                tabIndex={0}
                                                            >
                                                                {Object.keys(rawQueResponses[selectedQuestion.serialNo - 1].selectedOption).map((key) => `"${rawQueResponses[selectedQuestion.serialNo - 1].selectedOption[key]}"`).join(", ")}
                                                            </div>
                                                            <div>
                                                                {removeSelected(questionData.rawResponse, rawQueResponses[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType)?.map((item) =>
                                                                    item !== "unanswered" ? (
                                                                        <div
                                                                            className={`questionPanelAnswersText resultAnimation`}
                                                                            style={{
                                                                                marginTop: "20px",
                                                                                backgroundColor: "#CACACA",
                                                                                color: "#000", borderRadius: "10px",
                                                                                width: "fit-content",
                                                                                padding: "15px",
                                                                            }}
                                                                            tabIndex={0}
                                                                        >
                                                                            {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                                                        </div>
                                                                    ) : <></>)}
                                                            </div>

                                                        </>
                                                    }

                                                    {rawQueResponses && (!rawQueResponses[selectedQuestion.serialNo - 1] || !rawQueResponses[selectedQuestion.serialNo - 1].selectedOption || rawQueResponses[selectedQuestion.serialNo - 1].selectedOption === "unanswered") &&
                                                        <>{questionData.rawResponse?.map((item) =>
                                                            item !== "unanswered" ? (
                                                                <div
                                                                    className={`questionPanelAnswersText resultAnimation`}
                                                                    style={{
                                                                        marginTop: "20px",
                                                                        backgroundColor: "#CACACA",
                                                                        color: "#000", borderRadius: "10px",
                                                                        width: "fit-content",
                                                                        padding: "15px",
                                                                    }}
                                                                >
                                                                    {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                                                </div>
                                                            ) : <></>)} </>

                                                    }
                                                    {(!questionData.rawResponse || !questionData.rawResponse.filter(item => item !== FIXED_ANSWER.UNANSWERED).length) &&
                                                        <div style={{
                                                            color: "grey",
                                                            fontSize: 20,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}> {EMPTY_WORD_CLOUD_TEXT} </div>
                                                    }
                                                </Col>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>}

                            {(questionData.questionType === QUESTION_TYPES.RK.name) &&
                                <ClassResultRank chartData={questionData.rawResponse ? questionData.rawResponse.responses : questionData.rawResponse} isLoading={dataLoading} />
                            }

                            {questionData.questionType === QUESTION_TYPES.MH.name && !questionData.gradeEnabled && (
                                <ClassResultMH
                                    chartData={questionData.rawResponse}
                                    correctAnswers={questionData.correctAnswers}
                                    isLoading={dataLoading}
                                    queResponse={rawQueResponses[selectedQuestion.serialNo - 1]}
                                />
                            )}

                            {(questionData.questionType !== QUESTION_TYPES.RK.name && questionData.questionType !== QUESTION_TYPES.SA.name && questionData.questionType !== QUESTION_TYPES.OE.name && questionData.questionType !== QUESTION_TYPES.FITB.name && (questionData.questionType !== QUESTION_TYPES.MH.name || questionData.gradeEnabled)) &&
                                <Row>
                                    <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                                        <div className="outside">
                                            <div className="content">
                                                {questionData.questionImageURL && (
                                                    <div style={{ display: 'flex', justifyContent: 'center', flex: "1", padding: "25px" }}>
                                                        {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT} />}
                                                        {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question" />}
                                                    </div>
                                                )}
                                                <div key={RESULT_CONTAINER_KEY_PREFIX + questionData.serialNo}>
                                                    {questionData.questionType === QUESTION_TYPES.WC.name ?
                                                        <WordCloudResult
                                                            data={questionData.rawResponse}
                                                            serialNo={questionData.serialNo}
                                                            isSwitching={isSwitching}
                                                            setIsSwitching={setIsSwitching}
                                                        /> :
                                                        <ClassResultChart
                                                            question={selectedQuestion}
                                                            correctAnswers={questionData.correctAnswers}
                                                            chartData={questionData.questionResults}
                                                            showAnswerOnViewer={showAnswerOnViewer}
                                                            questionType={questionData.questionType}
                                                            gradeEnabled={questionData.gradeEnabled}
                                                            queResponse={rawQueResponses[selectedQuestion.serialNo - 1]}
                                                            isLoading={dataLoading}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }

                        </div>
                        <div style={{ backgroundColor: "#EEF9FB", height: "170px", paddingTop: "45px", paddingBottom: "20px" }}>
                            <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "16px", display: "flex", justifyContent: "center", textAlign: "center" }}> {FEEDBACK_TEXT} </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <YuJaButton
                                    style={{ fontSize: isMobile ? 12 : 17, marginTop: "24px" }}
                                    onClick={() => { setFeedBackModalShow(true) }}
                                >
                                    {FEEDBACK_BUTTON_TEXT}
                                </YuJaButton>
                            </div>
                        </div>
                        <div className={"footer-container"} style={{ backgroundColor: "#FCFBFF", width: "calc(100% - 36px)", paddingBottom: "calc(100vh - 100svh)", margin: "0px" }} ></div>
                    </>
                }
                {!isMobile &&
                    <>
                        <div style={{ width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", borderRadius: "8px", background: "#FFF", boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)", marginTop: "30px", minHeight: "385px", padding: "22px" }}>
                            <div className="header-board" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <div role={TEXTBOX} tabIndex={0} className="cur-question-title" style={{ fontSize: "18px" }}>Question {selectedQuestion.serialNo}</div>
                                {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name && (
                                    <>
                                        <ArrowRight />
                                        <p role={TEXTBOX} tabIndex={0} aria-label={QUESTION_TITLE + selectedQuestion.queTitle} className="ques-desc" style={{ lineHeight: "normal", color: "#000", order: "unset", marginTop: "0px", maxWidth: "70%" }}>
                                            {selectedQuestion.queTitle}
                                        </p>
                                    </>
                                )}
                                <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end", flex: "1" }}>
                                    <span className="gradientWrapQuesType" style={{ height: "45px", width: "150px" }}>
                                        <span className="questionPanelQuestionTypeContainer" style={{ padding: "0px" }}>
                                            <div role={TEXTBOX} tabIndex={0} className="questionPanelQuestionType" style={{ fontSize: "14px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center" }}> {questionCodeToName(selectedQuestion.questionType)} </div>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            {(questionData.questionType === QUESTION_TYPES.SA.name) &&
                                <Row style={{ marginTop: "25px" }}>
                                    <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                                        <div className="content">
                                            <div style={{ display: "flex" }}>
                                                <Col style={{ height: "300px", overflowY: "auto", maxWidth: "50%" }}>
                                                    {dataReady && rawQueResponses && rawQueResponses[selectedQuestion.serialNo - 1] && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption !== "unanswered" &&
                                                        <>
                                                            <div
                                                                className={`questionPanelAnswersText resultAnimation`}
                                                                style={{
                                                                    marginTop: "20px",
                                                                    backgroundColor: "#C4BDD2",
                                                                    color: "#000", borderRadius: "10px",
                                                                    width: "fit-content",
                                                                    padding: "15px"
                                                                }}
                                                            >
                                                                {`"${rawQueResponses[selectedQuestion.serialNo - 1].selectedOption["0"]}"`}
                                                            </div>

                                                            {removeSelected(questionData.rawResponse, rawQueResponses[selectedQuestion.serialNo - 1].selectedOption["0"], questionData.questionType)?.map((item) =>
                                                                item !== "unanswered" ? (
                                                                    <div
                                                                        tabIndex={0}
                                                                        className={`questionPanelAnswersText resultAnimation`}
                                                                        style={{
                                                                            marginTop: "20px",
                                                                            backgroundColor: "#CACACA",
                                                                            color: "#000", borderRadius: "10px",
                                                                            width: "fit-content",
                                                                            padding: "15px"
                                                                        }}
                                                                    >
                                                                        {`"${item["0"]}"`}
                                                                    </div>
                                                                ) : <></>
                                                            )}
                                                        </>
                                                    }

                                                    {rawQueResponses && (!rawQueResponses[selectedQuestion.serialNo - 1] || !rawQueResponses[selectedQuestion.serialNo - 1].selectedOption || rawQueResponses[selectedQuestion.serialNo - 1].selectedOption === "unanswered") &&
                                                        <>{questionData.rawResponse?.map((item) =>
                                                            item !== "unanswered" ? (
                                                                <div
                                                                    className={`questionPanelAnswersText resultAnimation`}
                                                                    style={{
                                                                        marginTop: "20px",
                                                                        backgroundColor: "#CACACA",
                                                                        color: "#000", borderRadius: "10px",
                                                                        width: "fit-content",
                                                                        padding: "15px"
                                                                    }}
                                                                >
                                                                    {`"${item["0"]}"`}
                                                                </div>
                                                            ) : <></>
                                                        )}</>
                                                    }

                                                    {(!rawQueResponses && (!questionData.rawResponse || !questionData.rawResponse.filter(item => item !== FIXED_ANSWER.UNANSWERED).length)) &&
                                                        <div style={{
                                                            color: "grey",
                                                            fontSize: 20,
                                                            height: "300px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}> {EMPTY_WORD_CLOUD_TEXT} </div>
                                                    }
                                                </Col>

                                                {questionData.questionImageURL && (
                                                    <div style={{ display: 'flex', justifyContent: 'center', flex: "1", padding: "25px" }}>
                                                        {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT} />}
                                                        {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question" />}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }

                            {(questionData.questionType === QUESTION_TYPES.FITB.name) && <>
                                <Row>
                                    <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                                        <div className="content">
                                            <Row>
                                                <div className="ques-desc" tabIndex={0} role={TEXTBOX} aria-label={questionData.queTitle}>
                                                    {questionData.queTitle.replace(questionData.blankRegex, "______")}
                                                </div>
                                            </Row>
                                            <div style={{ display: "flex" }}>
                                                <Col style={{ height: "300px", overflowY: "auto", maxWidth: "50%" }}>
                                                    {dataReady && rawQueResponses && rawQueResponses[selectedQuestion.serialNo - 1] && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption !== "unanswered" &&
                                                        <>
                                                            <div
                                                                className={`questionPanelAnswersText resultAnimation`}
                                                                style={{
                                                                    marginTop: "20px",
                                                                    backgroundColor: "#C4BDD2",
                                                                    color: "#000", borderRadius: "10px",
                                                                    width: "fit-content",
                                                                    padding: "15px",
                                                                }}
                                                                tabIndex={0}
                                                            >
                                                                {Object.keys(rawQueResponses[selectedQuestion.serialNo - 1].selectedOption).map((key) => `"${rawQueResponses[selectedQuestion.serialNo - 1].selectedOption[key]}"`).join(", ")}
                                                            </div>
                                                            <div>
                                                                {removeSelected(questionData.rawResponse, rawQueResponses[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType)?.map((item) =>
                                                                    item !== "unanswered" ? (
                                                                        <div
                                                                            className={`questionPanelAnswersText resultAnimation`}
                                                                            style={{
                                                                                marginTop: "20px",
                                                                                backgroundColor: "#CACACA",
                                                                                color: "#000", borderRadius: "10px",
                                                                                width: "fit-content",
                                                                                padding: "15px",
                                                                            }}
                                                                            tabIndex={0}
                                                                        >
                                                                            {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                                                        </div>
                                                                    ) : <></>)}
                                                            </div>

                                                        </>
                                                    }

                                                    {rawQueResponses && (!rawQueResponses[selectedQuestion.serialNo - 1] || !rawQueResponses[selectedQuestion.serialNo - 1].selectedOption || rawQueResponses[selectedQuestion.serialNo - 1].selectedOption === "unanswered") &&
                                                        <>{questionData.rawResponse?.map((item) =>
                                                            item !== "unanswered" ? (
                                                                <div
                                                                    className={`questionPanelAnswersText resultAnimation`}
                                                                    style={{
                                                                        marginTop: "20px",
                                                                        backgroundColor: "#CACACA",
                                                                        color: "#000", borderRadius: "10px",
                                                                        width: "fit-content",
                                                                        padding: "15px",
                                                                    }}
                                                                >
                                                                    {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                                                </div>
                                                            ) : <></>)} </>

                                                    }
                                                    {(!questionData.rawResponse || !questionData.rawResponse.filter(item => item !== FIXED_ANSWER.UNANSWERED).length) &&
                                                        <div style={{
                                                            color: "grey",
                                                            fontSize: 20,
                                                            height: "300px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}> {EMPTY_WORD_CLOUD_TEXT} </div>
                                                    }
                                                </Col>
                                                {questionData.questionImageURL && (
                                                    <div style={{ display: 'flex', justifyContent: 'center', flex: "1", padding: "25px" }}>
                                                        {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT} />}
                                                        {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question" />}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>}

                            {(questionData.questionType === QUESTION_TYPES.RK.name) &&
                                <div style={{ width: "50%" }}>
                                    <ClassResultRank chartData={questionData.rawResponse ? questionData.rawResponse.responses : questionData.rawResponse} isLoading={dataLoading} />
                                </div>
                            }

                            {questionData.questionType === QUESTION_TYPES.MH.name && !questionData.gradeEnabled && (
                                <ClassResultMH
                                    chartData={questionData.rawResponse}
                                    correctAnswers={questionData.correctAnswers}
                                    isLoading={dataLoading}
                                    queResponse={rawQueResponses[selectedQuestion.serialNo - 1]}
                                />
                            )}
                            
                            {(questionData.questionType === QUESTION_TYPES.OE.name) &&
                                <>
                                <div>
                                <Col style={{marginTop: "10px"}}>
                                {dataReady && questionData?.rawResponse?.responses && Object.entries(questionData.rawResponse.responses).length && openEndedData && openEndedData.filter(entry => questionData.rawResponse.responses[entry[0]] && questionData.rawResponse.responses[entry[0]] !== FIXED_ANSWER.UNANSWERED).length != 0 &&
                                    <> 
                                        <div style={{backgroundColor: "#FFFFFF", border: "1px solid #E8EDF1", boxShadow: "0px 0px 4px 0px #00000040", borderRadius: "7.39px", padding: 20, width: "100%", maxHeight:"300px", overflowY: "auto"}}> 
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div style={{ fontSize: 16, fontWeight: 600 }}> {PARTICIPANT_RESPONSE_TEXT} </div>
                                                    <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
                                                    <div style={{ fontSize: 16, color: "#0000000" }}> {SORT_BY_TEXT}</div>
                                                    <YuJaDropdown
                                                        data={[OPEN_ENDED_TIME_SORT, OPEN_ENDED_LIKE_SORT, OPEN_ENDED_DISLIKE_SORT]}
                                                        getOptionLabel={item => item}
                                                        getOptionValue={item => item}
                                                        onChange={option => setSortOption(option)}
                                                        value={sortOption}
                                                        containerStyle={{width: 130 }}
                                                        style={{ padding: "8px 15px" }}
                                                    />
                                                    </div>
                                                </div> 
                                            {openEndedData.map(([key, value], index) => (
                                                <>
                                                {questionData.rawResponse.responses[key] && questionData.rawResponse.responses[key] !== FIXED_ANSWER.UNANSWERED && 
                                                <div
                                                    style={{
                                                        marginTop: "20px",
                                                        border: "1px solid #E8EDF1", 
                                                        color: "#000", borderRadius: "7.4px",
                                                        minHeight: '94px', padding: 10, wordBreak: "break-word", boxShadow: "0px 0px 4px 0px #00000040"
                                                    }}
                                                >
                                                    {`${questionData.rawResponse.responses[key]["0"]}`}
                                                    <div style={{ borderRadius: "200px", gap: "20px", backgroundColor: "#ECEAF1", display: "flex", justifyContent: "space-between",  height: 35, width: 135, marginTop: 10, padding: "5px 20px"}}>
                                                        <div style={{display: "flex", gap: '5px'}}>  
            
                                                                <div>  <ThumbUp/></div>
                                                        <div style={{fontSize: 15, fontWeight: 500, color: "#4D4D4D"}}> {value["1"]} </div> 
                                                        </div> 
            
                                                        <div style={{display: "flex", gap: '5px'}}> 
                                                                <div> <ThumbDown/></div>
                                                        <div style={{fontSize: 15, fontWeight: 500, color: "#4D4D4D"}}> {value["2"]} </div> 
                                                        </div> 
                                                    </div> 
                                                </div>
                                                }
                                                </>
                                                )
                                            )}
                                </div> 
                                    </>
                                }
                                {dataReady && (!questionData?.rawResponse?.responses || !Object.entries(questionData.rawResponse.responses).length || !openEndedData.filter(entry => questionData.rawResponse.responses[entry[0]] && questionData.rawResponse.responses[entry[0]] !== FIXED_ANSWER.UNANSWERED).length) &&
                                    <div style={{ backgroundColor: "#FFFFFF", border: "1px solid #E8EDF1", boxShadow: "0px 0px 4px 0px #00000040", borderRadius: "7.39px", padding: 20, width: "100%", maxHeight: "500px", overflowY: "auto" }}>
                                        <div style={{ width: "100%", height: "100%", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", gap: 5 }}>
                                            <NoResultIllustration width={192} height={100} />
                                            <div style={{ fontSize: 18, marginTop: 10, fontWeight: 600 }}>
                                                {NO_RESPONSES_TEXT}
                                            </div>
                                        </div>
                                    </div>
                                }
                                </Col> 
                                </div>
                                </> }

                            {(questionData.questionType !== QUESTION_TYPES.RK.name && questionData.questionType !== QUESTION_TYPES.SA.name && questionData.questionType !== QUESTION_TYPES.OE.name && questionData.questionType !== QUESTION_TYPES.FITB.name && (questionData.questionType !== QUESTION_TYPES.MH.name || questionData.gradeEnabled)) &&
                                <Row>
                                    <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                                        <div className="outside">
                                            <div className="content">
                                                <div style={{ display: "flex" }}>
                                                    <div key={RESULT_CONTAINER_KEY_PREFIX + questionData.serialNo} style={{ width: questionData.questionImageURL ? "50%" : "100%" }}>
                                                        {questionData.questionType === QUESTION_TYPES.WC.name ?
                                                            <WordCloudResult
                                                                data={questionData.rawResponse}
                                                                serialNo={questionData.serialNo}
                                                                isSwitching={isSwitching}
                                                                setIsSwitching={setIsSwitching}
                                                            /> :
                                                            <ClassResultChart
                                                                question={selectedQuestion}
                                                                correctAnswers={questionData.correctAnswers}
                                                                chartData={questionData.questionResults}
                                                                questionType={questionData.questionType}
                                                                showAnswerOnViewer={showAnswerOnViewer}
                                                                gradeEnabled={questionData.gradeEnabled}
                                                                queResponse={rawQueResponses[selectedQuestion.serialNo - 1]}
                                                                isLoading={dataLoading}
                                                            />
                                                        }
                                                    </div>
                                                    {questionData.questionImageURL && (
                                                        <div style={{ display: 'flex', justifyContent: 'center', flex: "1", padding: "25px" }}>
                                                            {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT} />}
                                                            {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question" />}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </div>
                        <div style={{ height: "170px", backgroundColor: "#EEF9FB", marginTop: "65px", paddingTop: "45px" }}>
                            <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "24px", display: "flex", justifyContent: "center" }}> {FEEDBACK_TEXT} </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <YuJaButton
                                    style={{ fontSize: 17, marginTop: "24px" }}
                                    onClick={() => { setFeedBackModalShow(true) }}
                                >
                                    {FEEDBACK_BUTTON_TEXT}
                                </YuJaButton>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}