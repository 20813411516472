import "bootstrap/dist/css/bootstrap.css";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
// import useMobileAccess from "../../hooks/useMobileAccess";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import FeedBackModal from "../../components/modals/FeedBackModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-question.svg";
import { ReactComponent as CompletionRateIcon } from "../../images/completion-rate-icon.svg";
import { ReactComponent as ParticipantsIcon } from "../../images/participants_icon.svg";
import { ReactComponent as ResponsesIcon } from "../../images/responses-icon.svg";
import { YuJaGlobalState, useLoading } from "../../utils/LoadingContext";
import { BLANK_REGEX, BLANK_REGEX_NEW, COMPLETION_RATE_TEXT, DEFAULT_IMAGE_ALT, EMPTY_WORD_CLOUD_TEXT, FEEDBACK_BUTTON_TEXT, FEEDBACK_TEXT, FIXED_ANSWER, PARTICIPANT_TEXT, POLL_TYPE, QUESTION_TITLE, QUESTION_TYPES, RESPONSE_TEXT, RESULT_CONTAINER_KEY_PREFIX, SURVEY_RESULT_TEXT, TEXTBOX, UNANSWERED, WORD_CLOUD_DATA } from "../../utils/constants";
import { InitializeQuestionResultCount, hasWC, newPageLog, removePrefix } from "../../utils/helpers";
import { questionCodeToName } from "../../utils/questionUtils";
import ClassResultChart from "./ClassResultChart";
import ClassResultRank from "./ClassResultRank";
import NetworkStatus from "./NetworkStatus";
import WordCloudResult from "./WordCloudResult";
import "./styles.css";
import styles from "./styles.module.css";

export default function ClassResultAllSurvey({currentPoll, institutionId, websocketState, pollTitle="", userName}) {
  // const isMobile = useMobileAccess(1200);
  const {questions, rawQueResponses=[]} = currentPoll;
  const pollKey = removePrefix(questions[0].pollKey);
  const {getAllClassResult} = ResponseApiCalls();
  const {setLoading} = useLoading();
  const [questionsData, setQuestionsData] = useState([]);
  const [setWordCloudData, ] = useLocalStorageNew(WORD_CLOUD_DATA, {});
  const {isOnline} = useContext(YuJaGlobalState);
  const [selectedQuestion, setSelectedQuestion] = useState(questions[0]);
  const [participantCount, setParticipantCount] = useState("-"); 
  const [responsesCount, setResponsesCount] = useState("-");
  const [completionRate, setCompletionRate] = useState("-"); 
  const [questionData, setQuestionData] = useState(() => {
    let CA;
    if (questions[0].questionType === "MCSS") {
        CA = questions[0].correctAnswers[0];
    } else if (questions[0].questionType === "TF") {
        CA = questions[0].correctAnswers;
    } else {
        CA = null;
    }
    return {
        correctAnswers: CA,
        questionType: questions[0].questionType,
        queTitle: questions[0].queTitle,
        questionResults: InitializeQuestionResultCount(questions[0]),
        rawResults: {},
        serialNo: questions[0].serialNo,
        imageURL: questions[0].imageURL,
        image: questions[0].image,
        suffix: questions[0].suffix,
        directLinkEvp: questions[0].directLinkEvp,
        blankRegex: questions[0].hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX
    };
}); 

  const isNarrow = useMobileAccess(1200);
  const isMobile = useMobileAccess(480); 

  const [dataLoading, setDataLoading] = useState(false);
  const history = useHistory();
  const [isSwitching, setIsSWitching] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [, getSession] = useLocalStorageNew("session", {});
  const [feedbackModalShow, setFeedBackModalShow] = useState(false);
    const session = getSession();
  const anonymous = !session.userName;

  useEffect(() => {
    document.getElementById('root').style.backgroundColor = "#FCFBFF";
    newPageLog("ClassResultAllSurvey.js");
    if(isNarrow && document.getElementById("viewer-navbar-container") !== null) {
      document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
    }
  }, []);

  useEffect(() => {
    if (hasWC(questions)) setWordCloudData({});
  }, [questions]);

  function removeUnanswered(result) {
    let newResult = [];
    if (result == null) {
      return newResult;
    }
    result.forEach((r) => {
        if (r.ans !== UNANSWERED) {
            newResult.push({...r});
        }
    });
    return newResult;
  };

  async function getQuestionsData() {
    setLoading(true);
    const results = await getAllClassResult(pollKey,  questions[0].pollSortKey.split("#")[2], questions);
    let tempQuestionsData = [];
    for (const result of results) { 
      let serialNo = result.serialNo; 
      const question = questions.find(({serialNo: no}) => parseInt(no) === parseInt(serialNo));
      let CA;
      if (question.questionType === "MCSS") {
        CA = question.correctAnswers[0];
      } else if (question.questionType === "TF") {
        CA = question.correctAnswers;
      } else {
        CA = null;
      }
      let questionType = question.questionType;
      let queTitle = question.queTitle;
      let MCSSelements = [];
      if (questionType === "MCSS") {
        for (let key in question.optionsMap) {
          MCSSelements.push(question.optionsMap[key]);
        }
      }
      let MCSSnum = MCSSelements.length;
      let questionImageURL;
      let isVideo = false;
      if(question.directLinkEvp) {
        questionImageURL = question.directLinkEvp;
        isVideo = true;
      } else {
        questionImageURL = question.imageURL;
        isVideo = false;
      }

      let questionData = {
        correctAnswers: CA,
        questionType: questionType,
        queTitle: queTitle,
        questionResults: result.questionResults,
        serialNo: serialNo,
        MCSSnum: MCSSnum,
        questionImageURL: questionImageURL,
        isVideo: isVideo,
        rawResponse: result.rawResponse
      };
      tempQuestionsData.push(questionData); 
    }

    tempQuestionsData.forEach(item => {
      item.unansweredCount = getUnanswered(item.questionResults);
    });

    tempQuestionsData.forEach(item => {
      item.questionResults = removeUnanswered(item.questionResults);
    });

    setQuestionsData(tempQuestionsData);
    setDataReady(true);
    setLoading(false);
  }

  useEffect(() => {
    if (!isOnline || websocketState !== WebSocket.OPEN) {
      return;
    }

    // async function setResults() {
    //   let questionResultCopy = {};
    //   let promises = questions.map((question, index) => {
    //     console.log(question.questionType);
    //     if (question.questionType === QUESTION_TYPES.SA.name || question.questionType === QUESTION_TYPES.FITB.name || question.questionType === QUESTION_TYPES.WC.name) {
    //       return getClassResult(pollKey, question.pollSortKey.split("#")[2], question, null, true).then((result) => {
    //         if (result.length > 0) {
    //           questionResultCopy[index] = result;
    //         }
    //       });
    //     }
    //   })
    //   await Promise.all(promises);
    //   setQuestionResults(questionResultCopy);
    // }
    setDataLoading(true);
    setTimeout(() => {
      getQuestionsData();
    }, 3000);
    
    const interval = setInterval(() => {
      getQuestionsData();
    }, 5000);

    return () => clearInterval(interval);
  }, [questions, isOnline, websocketState]);


  const getUnanswered = (result) => {
    if (result == null) {
      return 0;
    }

    let unansweredCount = 0; 
    result.forEach((r) => {
        if (r.ans === UNANSWERED) {
            unansweredCount = r.people;
        }
    });
    return unansweredCount;

  }
  useEffect(() => {
    if (questionsData && questionsData.length !== 0 ) {
      let serialNo = selectedQuestion.serialNo
      const questiondata = questionsData.find(({ serialNo: no }) => parseInt(no) === parseInt(serialNo));
      setQuestionData(questiondata); 
      let overall_count = questiondata.questionType === QUESTION_TYPES.RK.name ? questiondata.rawResponse.total : questiondata.questionResults[0].total;
      setParticipantCount(overall_count);
      let count = questiondata.questionType === QUESTION_TYPES.RK.name ? questiondata.rawResponse.total : questiondata.questionResults[0].total -  questiondata.unansweredCount;
      setResponsesCount(count);   
      if (overall_count > 0) {
      setCompletionRate(Math.round(count/overall_count * 100));
      }
      else {
        setCompletionRate(0); 
      }
      setDataLoading(false);
  }
  }, [selectedQuestion, questionsData])


  const removeSelected = (data, target, questionType) => {
    if (questionType === QUESTION_TYPES.SA.name && data) {
    const indexToRemove = data.findIndex(item => item['0'] === target);
    if (indexToRemove !== -1) {
        const filteredList = [
          ...data.slice(0, indexToRemove),
          ...data.slice(indexToRemove + 1),
        ];
  
        return filteredList
      }
    }
    else if (questionType === QUESTION_TYPES.FITB.name && data) {
        const indexToRemove = data.findIndex(item =>
            Object.entries(item).every(([key, value]) => target[key] === value)
          );
      
          if (indexToRemove !== -1) {
            const filteredList = [
                ...data.slice(0, indexToRemove),
                ...data.slice(indexToRemove + 1),
              ];
        return filteredList
    }
  }
}

useEffect(() => {
  setIsSWitching(true);
  getQuestionsData();
}, [window.innerWidth])

  return (
    <div id="everything" className={styles.centerLayout} style={{height: "100%"}}>
      {/* <NormalBox
        boxType="lightBlue"
        className={`${styles.shadow} ${styles.centerLayout}`}
      > */}
      <FeedBackModal
                show={feedbackModalShow}
                setModalShow={setFeedBackModalShow}
                pollKey={pollKey}
                pollUniqueCode={questions[0].pollSortKey.split("#")[2]}
                userId={userName}
                institutionId={institutionId}
                pollType={POLL_TYPE.SURVEY}
            />
      <div style={{display: isMobile ? "flex" : "", flexDirection: isMobile ? "column" : "", height: '100%', width: "100%"}}> 
       {!isMobile ?
                <div className="waiting-room-title-container" style={{height: "fit-content" , marginTop: "32px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px" , justifyContent: "space-between"}}>
                    <span role={TEXTBOX} tabIndex={0} className="waiting-room-title" style={{ wordBreak: "break-word", paddingLeft: !isNarrow? "170px" : "10px", textAlign: "center", flex: "1", color: "#000", fontSize: "24px" }}>{SURVEY_RESULT_TEXT + pollTitle}</span>
                    <div style={{display: "flex", justifyContent: "end", paddingRight: "20px"}}>  <NetworkStatus websocketState={websocketState} /> </div>
                </div>
                 :
                 <>
                   <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 20, padding: "0px 10px 0px 10px"}}>
                   <div className="poll-title" style={{maxWidth: "100%", flex: 1}}>{pollTitle}</div>
                   <NetworkStatus tooltipPlacement={'bottom'} refreshAlignFirst={true} websocketState={websocketState}/>
                   </div>
                   <div style={{padding: "0px 10px 0px 10px"}}> <hr/> </div>
                 </>
             }


        <div style={{display: "flex", width: isMobile ? "calc(100% - 20px)" : "calc(100% - 36px)", marginLeft: isMobile ? "10px" : "18px", marginTop: isMobile? "0px" : "28px", gap: isMobile? "8px" : "25px", marginRight: isMobile? "10px" : "18px", flexDirection: isMobile? "column" : ""}}> 
          <div role={TEXTBOX} tabIndex={0} style={{color: "#000",  fontSize:  isMobile? "14px" : "18px", alignItems: "center", display: "flex"}}> Choose Question </div>
          <YuJaDropdown
                      containerStyle={{width: !isMobile? "40%": "100%"}}
                      data={questions}
                      value={selectedQuestion ? "Question " + selectedQuestion.serialNo + ": " + selectedQuestion.queTitle : ""}
                      getOptionLabel={item => "Question " + item.serialNo + ": " + item.queTitle}
                      getOptionValue={option => "Question " + option.serialNo + ": " + option.queTitle}
                      textStyle={{overflow: "hidden", color: "#000",  fontSize: isMobile? "14px" : "18px", letterSpacing: "0.63px", whiteSpace: "nowrap", textOverflow: 'ellipsis', textWrap: "nowrap", height: "20px", maxWidth: "100%", paddingTop: isMobile? "2px" : ""}}
                      onChange={(item) => {
                        setIsSWitching(true);
                        setDataLoading(true);
                        getQuestionsData();
                        setSelectedQuestion(item);
                      }}

                      style={{height: isMobile? "50px" : "40px"}}
                    />
          </div>
        

          {!isMobile &&
        <div className="class-result-survey-container" style={{marginLeft: "18px", width: "calc( 100% - 36px)"}}> 
        
        <div style={{display: "flex", justifyContent:  "center"}}> 
        <div style={{width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}> 
            <div style={{display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px"}}> 
            <div style={{display: "flex", gap: "10px"}}> 
            <div style={{width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <ParticipantsIcon />
            </div> 
            <div role={TEXTBOX} tabIndex={0} style={{fontSize: "18px",  color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px"}}> {PARTICIPANT_TEXT} </div> 
            </div>
            <div role={TEXTBOX} tabIndex={0} style={{fontSize: "24px",  color: "#000", lineHeight: "31.304px", fontWeight: "700"}}> { dataLoading || participantCount === "-" ? <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
										<Skeleton borderRadius={10} height={20} width="100px" style={{ position: "relative" }} />
										</SkeletonTheme> : participantCount} </div>
            </div>
        </div>
        </div> 

        <div style={{display: "flex", justifyContent:  "center"}}> 
        <div style={{width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}> 
            <div style={{display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px"}}> 
            <div style={{display: "flex", gap: "10px"}}> 
            <div style={{width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <ResponsesIcon />
            </div> 
            <div role={TEXTBOX} tabIndex={0} style={{fontSize: "18px",  color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px"}}> {RESPONSE_TEXT} </div> 
            </div>
            <div role={TEXTBOX} tabIndex={0} style={{fontSize: "24px",  color: "#000", lineHeight: "31.304px", fontWeight: "700"}}> {dataLoading || responsesCount === "-" ? <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
										<Skeleton borderRadius={10} height={20} width="100px" style={{ position: "relative" }} />
										</SkeletonTheme> : responsesCount } </div>
            </div>
        </div>
        </div> 
        
        <div style={{display: "flex", justifyContent:  "center"}}> 
        <div style={{width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}> 
            <div style={{display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px"}}> 
            <div style={{display: "flex", gap: "10px"}}> 
        <CompletionRateIcon />
        <div role={TEXTBOX} tabIndex={0} style={{fontSize: "18px",  color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px"}}> {COMPLETION_RATE_TEXT} </div> 
            </div>
            <div role={TEXTBOX} tabIndex={0} style={{fontSize: "24px",  color: "#000", lineHeight: "31.304px", fontWeight: "700"}}> {dataLoading || completionRate === "-" ? <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
										<Skeleton borderRadius={10} height={20} width="100px" style={{ position: "relative" }} />
										</SkeletonTheme> : <>{completionRate} {completionRate !== "-" ? "%" : ""} </>} </div>
            </div>
        </div>
        </div>

        {/* <div style={{display: "flex", justifyContent:  "center"}}>
        <div style={{width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}> 
            <div style={{display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px"}}> 
            <div style={{display: "flex", gap: "10px"}}> 
              <CorrectIcon />
            <div style={{fontSize: "18px",  color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px"}}> {CORRECT_ANSWER_TEXT} </div> 
            </div>
            <div style={{fontSize: "24px",  color: "#000", lineHeight: "31.304px", fontWeight: "700"}}> {UNAVAILABLE_TEXT} </div>
            </div>
        </div>
        </div>

        <div style={{display: "flex", justifyContent:  "center"}}>
        <div style={{width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}> 
            <div style={{display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px"}}> 
            <div style={{display: "flex", gap: "10px"}}> 
            <div style={{width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <WrongIcon />
            </div> 
            <div style={{fontSize: "18px",  color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px"}}> {WRONG_ANSWER_TEXT} </div> 
            </div>
            <div style={{fontSize: "24px",  color: "#000", lineHeight: "31.304px", fontWeight: "700"}}> {UNAVAILABLE_TEXT} </div>
            </div>
        </div>
        </div> */}

        

        </div>

        }

              {
                    isMobile && 
                    <div style={{ width: "calc(100% - 20px)",  marginRight: "10px", marginLeft: "10px", borderRadius: "19.565px", border: "1px solid #969696", background: "#fff", height: "fit-content", marginTop: "15px", padding: "10px"
                    }}> 
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", rowGap: '10px', columnGap: "10px"}}>

                                <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%"}}>
                                    <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ParticipantsIcon />
                                    </div>
                                    <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "13px",  color: "#000", letterSpacing: "-0.28px", marginLeft: "2px" }}> {PARTICIPANT_TEXT} </div>
                                    <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "15px",  color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {dataLoading || participantCount === "-" ? <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
										                <Skeleton borderRadius={10} height={20} width="30px" style={{ position: "relative" }} />
										                </SkeletonTheme> : participantCount} </div>
                                </div>
                            <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%"}}>
                                <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ResponsesIcon />
                                </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "13px",  color: "#000", letterSpacing: "-0.28px", marginLeft: "2px"}}> {RESPONSE_TEXT} </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "15px",  color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end", minWidth: "fit-content"}}> {dataLoading || responsesCount === "-" ? <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
										            <Skeleton borderRadius={10} height={20} width="30px" style={{ position: "relative" }} />
										            </SkeletonTheme> : responsesCount } </div>
                            </div>

                            {/* <div style={{ display: "flex", alignItems: "center", width: window.innerWidth <= 400 ? "100%" : "50%"}}>

                                <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CorrectIcon />
                                </div>
                                <div style={{ fontSize: "14px",  color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px", marginLeft: "2px"}}> {CORRECT_ANSWER_TEXT} </div>
                                <div style={{ fontSize: "15px",  color: "#000", fontWeight: "700", marginLeft: "5px"}}> {UNAVAILABLE_TEXT} </div>
                            </div>

                            <div style={{ display: "flex", alignItems: "center", width: window.innerWidth <= 400 ? "100%" : "50%"}}>

                                <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <WrongIcon />
                                </div>
                                <div style={{ fontSize: "14px",  color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px", marginLeft: "2px"}}> {WRONG_ANSWER_TEXT} </div>
                                <div style={{ fontSize: "15px",  color: "#000", fontWeight: "700", marginLeft: "5px"}}> {UNAVAILABLE_TEXT} </div>
                            </div> */}

                            <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%", maxWidth: "50%"}}>
                                <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CompletionRateIcon />
                                </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "13px",  color: "#000", letterSpacing: "-0.28px", marginLeft: "2px"}}> {COMPLETION_RATE_TEXT} </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "15px",  color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {dataLoading || completionRate === "-" ? <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
										            <Skeleton borderRadius={10} height={20} width="30px" style={{ position: "relative" }} />
										            </SkeletonTheme> : <>{completionRate} {completionRate !== "-" ? "%" : ""}</> } </div>
                            </div>
                    </div>

                    </div> 
                }

          {
            isMobile && 
            <>
            <div style={{display: "flex", flexDirection: "column", marginTop: "16px", width: "calc(100% - 20px)", marginLeft: "10px", marginRight: "10px", gap: "15px", flex: "1", overflowX: "clip"}}> 
                    <span className="gradientWrapQuesType" style={{ height: isNarrow ? "23px" : "45px", width: isNarrow ? "109px" : "150px", marginLeft: "0px"}}>
                                <span className="questionPanelQuestionTypeContainer" style={{ width: isNarrow ? "107px" : "", padding: "0px", height: isNarrow ? "20px" : "", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div className="questionPanelQuestionType" style={{ fontSize: "12px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center", height: isNarrow? "12px" : ""}}> {questionCodeToName(selectedQuestion.questionType)} </div>
                                </span>
                            </span>

                            <div className="cur-question-title" style={{fontSize: isMobile ? "14px" : "18px", height: 'fit-content', wordBreak: "break-word"}}>Question {selectedQuestion.serialNo}  {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name ? " - " + selectedQuestion.queTitle : ""} </div> 


                            {(questionData.questionType === QUESTION_TYPES.SA.name) ? 
                <Row style={{marginTop: "25px"}}>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                      <div className="content">
                      {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center', flex: "1", padding: "25px"}}>
                              {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT}/>}
                              {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                            </div>
                          )}
                        <div style={{display: "flex"}}> 
                          <Col style={{height:"300px", overflowY: "auto"}}>
                          {dataReady && rawQueResponses && rawQueResponses[selectedQuestion.serialNo - 1] && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                    <>
                                    <div
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#C4BDD2",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                        {`"${rawQueResponses[selectedQuestion.serialNo - 1].selectedOption["0"]}"`}
                                        </div> 
                                    
                                    {removeSelected(questionData.rawResponse, rawQueResponses[selectedQuestion.serialNo - 1].selectedOption["0"], questionData.questionType)?.map((item) =>
                                        item !== "unanswered" ? (
                                        <div
                                            tabIndex={0} 
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#CACACA",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                            {`"${item["0"]}"`}
                                        </div>
                                        ) : <></>
                                    )}
                                    </> 
                                 }

                                 { rawQueResponses && (!rawQueResponses[selectedQuestion.serialNo - 1] || !rawQueResponses[selectedQuestion.serialNo - 1].selectedOption || rawQueResponses[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") && 
                                  <>{questionData.rawResponse?.map((item) =>
                                    item !== "unanswered" ? (
                                    <div
                                        className= {`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px"
                                        }}
                                    >
                                        {`"${item["0"]}"`}
                                    </div>
                                    ) : <></>
                                )}</>
                                 }
                                 {(!questionData.rawResponse || !questionData.rawResponse.filter(item => item !== FIXED_ANSWER.UNANSWERED).length) &&
                                      <div style={{
                                        color: "grey",
                                        fontSize: 20,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                        }}> {EMPTY_WORD_CLOUD_TEXT} </div>
                                    }
                          </Col>
                          </div> 
                      </div>
                  </Col>
                </Row>
          : (questionData.questionType === QUESTION_TYPES.FITB.name)  ?
          <>
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                      <div className="content">
                        <Row> 
                        <div className="ques-desc">
                            {questionData.queTitle.replace(questionData.blankRegex, "______")}
                          </div>
                        </Row>

                        {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center', flex: "1", padding: "25px"}}>
                              {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT}/>}
                              {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                            </div>
                          )}
                        <div style={{display: "flex"}}> 
                            <Col style={{height:"300px", overflowY: "auto", maxWidth: questionData.questionImageURL? "50%" : ""}}>
                            {dataReady && rawQueResponses && rawQueResponses[selectedQuestion.serialNo - 1] && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                <>
                                 <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#C4BDD2",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(rawQueResponses[selectedQuestion.serialNo - 1].selectedOption).map((key) => `"${rawQueResponses[selectedQuestion.serialNo - 1].selectedOption[key]}"`).join(", ")}
                                </div>
                                <div>
                                {removeSelected(questionData.rawResponse, rawQueResponses[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType)?.map((item) =>
                                 item !== "unanswered"? (
                                    <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                    </div>
                                ) : <></> )}
                                </div>

                                </>
                                    }

                                    {rawQueResponses && (!rawQueResponses[selectedQuestion.serialNo - 1] || !rawQueResponses[selectedQuestion.serialNo - 1].selectedOption || rawQueResponses[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") && 
                                        <>{questionData.rawResponse?.map((item) =>
                                        item !== "unanswered"? (
                                           <div
                                               className={`questionPanelAnswersText resultAnimation`}
                                               style={{
                                                   marginTop: "20px",
                                                   backgroundColor: "#CACACA",
                                                   color: "#000", borderRadius: "10px",
                                                   width: "fit-content",
                                                   padding: "15px",
                                               }}
                                           >
                                               {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                           </div>
                                       ) : <></> )} </>
                                    
                                    }
                                    {(!questionData.rawResponse || !questionData.rawResponse.filter(item => item !== FIXED_ANSWER.UNANSWERED).length) &&
                                      <div style={{
                                        color: "grey",
                                        fontSize: 20,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                        }}> {EMPTY_WORD_CLOUD_TEXT} </div>
                                    }
                            </Col>
                        </div>
                      </div>
                  </Col>
                </Row>
                </>
          :   (questionData.questionType === QUESTION_TYPES.RK.name)  ? 
           <ClassResultRank chartData={questionData.rawResponse ? questionData.rawResponse.responses : questionData.rawResponse}  isLoading={dataLoading} />
          
          : 
             
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                    <div className="outside">
                      <div className="content">
                        {questionData.questionImageURL && (
                            <div style={{display: 'flex',  justifyContent: 'center', flex: "1", padding: "25px"}}>
                              {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT}/>}
                              {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                            </div>
                          )}
                          <div key={RESULT_CONTAINER_KEY_PREFIX + questionData.serialNo}>
                            {questionData.questionType === QUESTION_TYPES.WC.name ?
                              <WordCloudResult
                                data={questionData.rawResponse}
                                serialNo={questionData.serialNo}
                                isSwitching={isSwitching}
                                setIsSWitching={setIsSWitching}
                              /> :
                              <ClassResultChart
                                correctAnswers={questionData.correctAnswers}
                                chartData={questionData.questionResults}
                                questionType={questionData.questionType}
                                pollType={POLL_TYPE.SURVEY}
                                queResponse={rawQueResponses[selectedQuestion.serialNo - 1]}
                                isLoading={dataLoading}
                              />
                            }
                          </div>
                        </div>
                      </div>
                  </Col>
                </Row>
          }

            </div> 
            <div style={{backgroundColor: "#EEF9FB", height: "170px", paddingTop: "45px", paddingBottom: "20px"}}>
                    <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "16px",  display: "flex", justifyContent: "center", textAlign: "center"}}> {FEEDBACK_TEXT} </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <YuJaButton
                            style={{ fontSize: isMobile ? 12: 17, marginTop: "24px" }}
                            onClick={() => { setFeedBackModalShow(true) }}
                        >
                            {FEEDBACK_BUTTON_TEXT}
                        </YuJaButton>
                    </div>
            </div> 
            <div className={"footer-container"} style={{backgroundColor: "#FCFBFF", width: "calc(100% - 36px)",paddingBottom: "calc(100vh - 100svh)", margin: "0px"}} ></div>
          </>
          }
         {!isMobile &&
         <>
        <div style={{width: "calc(100% - 36px)",  marginLeft: "18px", marginRight: "18px", borderRadius: "8px", background: "#FFF", boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)", marginTop: "30px", minHeight: "385px", padding: "22px"}}> 
        <div className="header-board" style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <div role={TEXTBOX} tabIndex={0} className="cur-question-title" style={{fontSize: "18px"}}>Question {selectedQuestion.serialNo}</div>
                      {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name && (
                        <>
                         <ArrowRight />
                        <p role={TEXTBOX} tabIndex={0} aria-label={QUESTION_TITLE + selectedQuestion.queTitle} className="ques-desc" style={{ lineHeight: "normal", color: "#000", order: "unset", marginTop: "0px", maxWidth: "70%"}}>
                          {selectedQuestion.queTitle}
                        </p>
                        </>
                    )}
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end", flex: "1"}}> 
                      <span className="gradientWrapQuesType" style={{height: "45px", width: "150px"}}>
                                    <span className="questionPanelQuestionTypeContainer" style={{padding: "0px"}}>
                                        <div role={TEXTBOX} tabIndex={0} className="questionPanelQuestionType" style={{fontSize: "14px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center"}}> {questionCodeToName(selectedQuestion.questionType)} </div>
                                    </span>
                      </span>
                      </div>
        </div>

         {(questionData.questionType === QUESTION_TYPES.SA.name) ? 
                <Row style={{marginTop: "25px"}}>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                      <div className="content">
                        <div style={{display: "flex"}}> 
                          <Col style={{height:"300px", overflowY: "auto", maxWidth: "50%"}}>
                          {dataReady && rawQueResponses && rawQueResponses[selectedQuestion.serialNo - 1] && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                    <>
                                    <div
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#C4BDD2",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                        {`"${rawQueResponses[selectedQuestion.serialNo - 1].selectedOption["0"]}"`}
                                        </div> 
                                    
                                    {removeSelected(questionData.rawResponse, rawQueResponses[selectedQuestion.serialNo - 1].selectedOption["0"], questionData.questionType)?.map((item) =>
                                        item !== "unanswered" ? (
                                        <div
                                            tabIndex={0} 
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#CACACA",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                            {`"${item["0"]}"`}
                                        </div>
                                        ) : <></>
                                    )}
                                    </> 
                                 }

                                 { rawQueResponses && (!rawQueResponses[selectedQuestion.serialNo - 1] || !rawQueResponses[selectedQuestion.serialNo - 1].selectedOption || rawQueResponses[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") && 
                                  <>{questionData.rawResponse?.map((item) =>
                                    item !== "unanswered" ? (
                                    <div
                                        className= {`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px"
                                        }}
                                    >
                                        {`"${item["0"]}"`}
                                    </div>
                                    ) : <></>
                                )}</>
                                 }

                                  {(!questionData.rawResponse || !questionData.rawResponse.filter(item => item !== FIXED_ANSWER.UNANSWERED).length) &&
                                      <div style={{
                                        color: "grey",
                                        fontSize: 20,
                                        height: "300px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                        }}> {EMPTY_WORD_CLOUD_TEXT} </div>
                                    }
                          </Col>

                          {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center', flex: "1", padding: "25px"}}>
                              {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT}/>}
                              {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                            </div>
                          )}
                          </div> 
                      </div>
                  </Col>
                </Row>
          : (questionData.questionType === QUESTION_TYPES.FITB.name)  ?
          <>
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                      <div className="content">
                        <Row> 
                        <div className="ques-desc" tabIndex={0} role={TEXTBOX} aria-label={questionData.queTitle}>
                            {questionData.queTitle.replace(questionData.blankRegex, "______")}
                          </div>
                        </Row>
                        <div style={{display: "flex"}}> 
                            <Col style={{height:"300px", overflowY: "auto", maxWidth: "50%"}}>
                            {dataReady && rawQueResponses && rawQueResponses[selectedQuestion.serialNo - 1] && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                <>
                                 <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#C4BDD2",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(rawQueResponses[selectedQuestion.serialNo - 1].selectedOption).map((key) => `"${rawQueResponses[selectedQuestion.serialNo - 1].selectedOption[key]}"`).join(", ")}
                                </div>
                                <div>
                                {removeSelected(questionData.rawResponse, rawQueResponses[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType)?.map((item) =>
                                 item !== "unanswered"? (
                                    <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                    </div>
                                ) : <></> )}
                                </div>

                                </>
                                    }

                                    {rawQueResponses && (!rawQueResponses[selectedQuestion.serialNo - 1] || !rawQueResponses[selectedQuestion.serialNo - 1].selectedOption || rawQueResponses[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") && 
                                        <>{questionData.rawResponse?.map((item) =>
                                        item !== "unanswered"? (
                                           <div
                                               className={`questionPanelAnswersText resultAnimation`}
                                               style={{
                                                   marginTop: "20px",
                                                   backgroundColor: "#CACACA",
                                                   color: "#000", borderRadius: "10px",
                                                   width: "fit-content",
                                                   padding: "15px",
                                               }}
                                           >
                                               {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                           </div>
                                       ) : <></> )} </>
                                    
                                    }
                                    {(!questionData.rawResponse || !questionData.rawResponse.filter(item => item !== FIXED_ANSWER.UNANSWERED).length) &&
                                      <div style={{
                                        color: "grey",
                                        fontSize: 20,
                                        height: "300px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                        }}> {EMPTY_WORD_CLOUD_TEXT} </div>
                                    }
                            </Col>
                        {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center', flex: "1", padding: "25px"}}>
                              {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT}/>}
                              {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                            </div>
                          )}
                        </div>
                      </div>
                  </Col>
                </Row>
                </>
          : (questionData.questionType === QUESTION_TYPES.RK.name)  ? 
            <div style={{width: "50%" }}>
            <ClassResultRank chartData={questionData.rawResponse ? questionData.rawResponse.responses : questionData.rawResponse}  isLoading={dataLoading} />
            </div> 

          :
             
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                    <div className="outside">
                      <div className="content">
                        <div style={{display: "flex"}}>
                          <div key={RESULT_CONTAINER_KEY_PREFIX + questionData.serialNo} style={{width: questionData.questionImageURL ? "50%" : "100%"}}>
                            {questionData.questionType === QUESTION_TYPES.WC.name ?
                              <WordCloudResult
                                data={questionData.rawResponse}
                                serialNo={questionData.serialNo}
                                isSwitching={isSwitching}
                                setIsSWitching={setIsSWitching}
                              /> :
                              <ClassResultChart
                                correctAnswers={questionData.correctAnswers}
                                chartData={questionData.questionResults}
                                questionType={questionData.questionType}
                                pollType={POLL_TYPE.SURVEY}
                                queResponse={rawQueResponses[selectedQuestion.serialNo - 1]}
                                isLoading={dataLoading}
                              />
                            }
                          </div>
                          {questionData.questionImageURL && (
                            <div style={{display: 'flex',  justifyContent: 'center', flex: "1", padding: "25px"}}>
                              {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT}/>}
                              {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
          }
        </div>
        <div style={{height: "170px", backgroundColor: "#EEF9FB", marginTop: "65px", paddingTop: "45px"}}> 
        <div tabIndex={0} role={TEXTBOX} style={{fontSize: "24px",  display: "flex", justifyContent: "center"}}> {FEEDBACK_TEXT} </div>
        <div style={{display: "flex", justifyContent: "center"}}>
                        <YuJaButton
                            style={{fontSize: 17, marginTop: "24px"}}
                            onClick={()=> {setFeedBackModalShow(true)}}
                        >
                           {FEEDBACK_BUTTON_TEXT}
                        </YuJaButton>
                    </div>
        </div> 
        </>
        }
        {/* {questionsData.map((questionData, index) => {
          if (questionData.questionType === QUESTION_TYPES.SA.name) {
            return (
              <NormalBox boxType="light" className={`${styles.resultsContainer}`} key={index}>
                <Row>
                  <div className="header-board">
                    <p className="ques-name">
                      Question {questionData.serialNo}{" "}
                    </p>
                  </div>
                </Row>
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                    <div className="outside box-bord-round">
                      <div className="content">
                        <Row> 
                        <div className="ques-desc">
                            {questionData.queTitle}
                          </div>
                        </Row>
                        <Row>
                          {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} />
                            </div>
                          )}
                        </Row>
                        <Row>
                          <Col style={{height:"300px", overflowY: "auto"}}>
                            {questionResults[index]?.map((item) =>
                              item !== FIXED_ANSWER.UNANSWERED ? (
                                <div className= {`questionPanelAnswersText resultAnimation`} style={{ marginTop: "20px", backgroundColor: "#f4f4f4", width: "fit-content", padding: "15px", marginLeft: "20px"}}>
                                    {`"${item["0"]}"`}
                                </div>
                                ) : <></>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </NormalBox>
            );
          } else if (questionData.questionType === QUESTION_TYPES.FITB.name) {
            return (
              <NormalBox boxType="light" className={`${styles.resultsContainer}`} key={index}>
                <Row>
                  <div className="header-board">
                    <p className="ques-name">
                      Question {questionData.serialNo}{" "}
                    </p>
                  </div>
                </Row>
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                    <div className="outside box-bord-round">
                      <div className="content">
                        <Row> 
                        <div className="ques-desc">
                            {questionData.queTitle.replace(questionData.blankRegex, "______")}
                          </div>
                        </Row>
                        <Row>
                          {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} />
                            </div>
                          )}
                        </Row>
                        <Row>
                            <Col style={{height:"300px", overflowY: "auto"}}>
                              {questionResults[index]?.map((item) =>
                                item !== FIXED_ANSWER.UNANSWERED ? (
                                  <div
                                    className={`questionPanelAnswersText resultAnimation`}
                                    style={{
                                      marginTop: "20px",
                                      backgroundColor: "#f4f4f4",
                                      width: "fit-content",
                                      padding: "15px",
                                      marginLeft: "20px"
                                    }}
                                  >
                                    {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                  </div>
                                ) : <></>
                              )}
                            </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </NormalBox>
            );
          } else {
            return (
              <NormalBox boxType="light" className={`${styles.resultsContainer}`} key={index}>
                <Row>
                  <div className="header-board">
                    <p className="ques-name">
                      Question {questionData.serialNo}{" "}
                    </p>
                  </div>
                </Row>
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                    <div className="outside box-bord-round">
                      <div className="content">
                        <>
                          <div className="ques-desc">
                            {questionData.queTitle}
                          </div>
                          {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} />
                            </div>
                          )}
                          <div key={RESULT_CONTAINER_KEY_PREFIX + questionData.serialNo}>
                            {questionData.questionType === QUESTION_TYPES.WC.name ?
                              <WordCloudResult
                                data={questionResults[index]}
                                serialNo={questionData.serialNo}
                                // pollType={POLL_TYPE.SURVEY}
                              /> :
                              <ClassResultChart
                                correctAnswers={questionData.CA}
                                chartData={questionData.questionResults}
                                questionType={questionData.questionType}
                                pollType={POLL_TYPE.SURVEY}
                                queResponse={rawQueResponses[index]}
                              />
                            }
                          </div>
                        </>
                      </div>
                    </div>
                  </Col>
                </Row>
              </NormalBox>
            );
          }
        })} */}
      {/* </NormalBox> */}
      </div>
    </div>
  );
}