import {
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { LTIApiCalls } from "../../apis/LTIApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import "../../components/modals/modal.css";
import NormalPagination from "../../components/pagination/NormalPagination";
import YuJaButton from "../../components/standardization/YuJaButton";
import { Spin } from "../../components/standardization/YuJaLoading";
import { YuJaTooltip } from "../../components/standardization/YuJaTooltip";
import useHostname from "../../hooks/useHostname";
import RK_Icon from "../../images/RankingIcon.svg";
import CI_ICON from "../../images/clickable_purple.svg";
import FITB_ICON from "../../images/fitb_purple.svg";
import { ReactComponent as Down } from "../../images/links/down.svg";
import { ReactComponent as DownGrey } from "../../images/links/down_grey.svg";
import { ReactComponent as Up } from "../../images/links/up.svg";
import { ReactComponent as UpGrey } from "../../images/links/up_grey.svg";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";
import MH_Icon from "../../images/matching_purple.svg";
import MCSS_ICON from "../../images/mcss_purple.svg";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import  OE_ICON from "../../images/OpenEnded.svg";
import SA_ICON from "../../images/sa_purple.svg";
import TF_ICON from "../../images/tf_purple.svg";
import WC_ICON from "../../images/wcloud_purple.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
	ASCII_CODE_A,
	ATMPT_LABEL_2,
	ATTEMPT_REGEX,
	CHECKBOX_COLUMN_ID,
	CORRECT,
	DEFAULT_ROWS_PER_PAGE,
	FIXED_ANSWER,
	GRADE_BOOK_QUESTION_COLUMN_REGEX,
	GRADE_RESPONSE_TOOLTIP_TEXT,
	HIDE_ATMPS,
	INCORRECT,
	LOSE_CHANGE_MESSAGE,
	MODAL_CLOSE_TEXT,
	NOT_GRADED,
	NO_RESPONSE,
	NO_RESULTS_FOUND,
	POLL_TYPE,
	QUESTION_TYPES,
	QUES_LABEL_1,
	SCORE_COLUMN_ID,
	SCORE_COLUMN_NAME,
	SCORE_LABEL,
	SHOW_ATMPS,
	STD_LABEL,
	STUDENT_COLUMN_ID,
	STUDENT_COLUMN_INDEX,
	STUDENT_COLUMN_NAME,
	STUDENT_RESPONSE_MODAL_TITLE,
	SWITCH_POLL_MODAL_TEXT,
	SWITCH_POLL_MODAL_TITLE,
	SYNC_ATTEMPT,
	TEXTBOX,
	USER_ID_COLUMN_ID,
	USER_ID_COLUMN_NAME,
	VIEW_GRADE_RESPONSE_MODAL_DESCRIPTION,
	VIEW_GRADE_RESPONSE_MODAL_TITLE,
	VIEW_MODAL_DESCRIPTION,
	VIEW_QUES,
	VIEW_RESPONSE_TOOLTIP_TEXT
} from "../../utils/constants";
import { getAllExpanded, getAnswer, getAttemptNo, getUserId, hasWeightage, isDisabled, isValidScore, notifyError, notifySuccess, removePrefixNew } from "../../utils/helpers";
import { ENTER_VALID_SCORES, GRADEBOOK_SYNC_SUCCESS, GRADE_QUESTION_ERROR, SELECT_AT_LEAST_ONE_STUDENT } from "../../utils/toast-message-constants";
import TabQuestion from "../PublishedPollPage/TabQuestion";
import styles from "./GradeBook.module.css";
import GradeQuestion from "./GradeQuestion";
import correct from "./images/Correct.svg";
import incorrect from "./images/Incorrect.svg";
import { ReactComponent as DropDown } from "./images/caret-down.svg";
import { ReactComponent as DropUp } from "./images/caret-right.svg";
import notGraded from "./images/ungraded.svg";

function IndeterminateCheckbox({
	indeterminate,
	className = '',
	...rest
}) {
	const ref = useRef();

	useEffect(() => {
	  if (typeof indeterminate === 'boolean') {
		ref.current.indeterminate = !rest.checked && indeterminate;
	  }
	}, [ref, indeterminate]);

	return (
		<input
			type="checkbox"
			ref={ref}
			className={className + ' indeterminate-checkbox'}
			{...rest}
			aria-label="checkbox"
		/>
	)
}

export default function GradeBookTabs({
	responses = [],
	setResponses=undefined,
	questions = [],
	pollKey=undefined,
	pollCode=undefined,
	// pollTitle=undefined,
	pollType=undefined,
	lmsAttempt="",
	syncMode=0,
	setSyncMode=undefined,
	showCheckbox=true,
	autoSyncData={},
	setAutoSyncData=undefined,
	dataLoading=false,
	pollShareMode, 
	setDataLoading,
	// globalFilterObj=undefined,
	// pageIdx=0,
	// geofence=undefined,
}) {
	const history = useHistory();
	const hostResource = useHostname(window.location.hostname);
	const {institutionId = ""} = hostResource;
	const [question, setQuestion] = useState({});
	const [modalShow, setModalShow] = useState(false);
	const [response, setResponse] = useState({});
	const [attemptNo, setAttemptNo] = useState(0);
	const [isCorrect, setIsCorrect] = useState({});
	const [showAnswerModal, setShowAnswerModal] = useState(false);
	// const [answerModalData, setAnswerModalData] = useState(false);
	const {loading} = useLoading();
    const [updateCustomScore, setUpdateCustomScore] = useState(true);
	const [customScoresMap, setCustomScoresMap] = useState({});
	const [syncCountMap, setSyncCountMap] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(-1);
	const { gradeQuestionStudents, updateScore, syncScore } = ResponseApiCalls();
	const { gradebookSync } = LTIApiCalls();
	const { setLoading } = useLoading();
	const [alertShow, setAlertShow]= useState(false); 
	const [changedCorrect, setChangedCorrect] = useState({});
	const skeletonLoaderWidth = ["50px", "40px", "60px", "30px", "50px"];
	// const [questionImageURL, setQuestionImageURL] = useState("");
	// const [imageLoaded, setImageLoaded] = useState(false);
	// const imageRef = useRef(null);
	// const isNarrow = useMobileAccess(1200);
	// const [globalFilter, setGlobalFilter] = useState({});

	const GRADE_BOOK_COLUMNS = [
		{
			accessorKey: CHECKBOX_COLUMN_ID,
			enableSorting: false,
			id: CHECKBOX_COLUMN_ID,
			header: ({ table }) => {
				return (<>
					{getAllExpanded(table.getRowModel().rows) ? // getIsAllRowsExpanded() ?
						<DropDown tabIndex={0}
							aria-label={HIDE_ATMPS}
							role={TEXTBOX}
							className={styles.expandCollapse}
							onClick={() => toggleAllRowsExpanded()}
						/>
						:
						<DropUp tabIndex={0}
							aria-label={SHOW_ATMPS}
							role={TEXTBOX}
							className={styles.expandCollapse}
							onClick={() => toggleAllRowsExpanded()}
						/>
					}
					{showCheckbox ?
						<IndeterminateCheckbox
							className="form-check-input"
							checked={getIsAllRowsSelected()}
							indeterminate={getIsSomeRowsSelected()}
							onChange={getToggleAllRowsSelectedHandler()}
						/> : <></>
					}
				</>);
			},
			cell: ({ row }) => {
				// console.log(row, row.getAllCells(), row.getAllCells()[STUDENT_COLUMN_INDEX], row.getAllCells()[STUDENT_COLUMN_INDEX].getValue());
				return (<>
					{row.getCanExpand() ?
					(<>
						{row.getIsExpanded() ?
							<DropDown tabIndex={0}
							aria-label={HIDE_ATMPS}
							role={TEXTBOX}
								className={styles.expandCollapse}
								// onClick={() => row.toggleExpanded()}
							/> :
							<DropUp tabIndex={0}
							aria-label={SHOW_ATMPS}
							role={TEXTBOX}
								className={styles.expandCollapse}
								// onClick={() => row.toggleExpanded()}
							/>
						}
						{showCheckbox ?
							<IndeterminateCheckbox
								className="form-check-input"
								checked={row.getIsSelected()}
								// disabled={!row.getCanSelect()}
								indeterminate={row.getIsSomeSelected()}
								onChange={(e) => {
									row.toggleSelected();
									e.stopPropagation();
								}}
							/> : <></>
						}
					</>) :
					(!ATTEMPT_REGEX.test(row.getAllCells()[STUDENT_COLUMN_INDEX].getValue()) && showCheckbox ? 
						<IndeterminateCheckbox
							className="form-check-input"
							checked={row.getIsSelected()}
							// disabled={!row.getCanSelect()}
							indeterminate={row.getIsSomeSelected()}
							onChange={row.getToggleSelectedHandler()}
						/> : <></>
					)}
				</>);
			},
			size: 50
		},
		{
			header: STUDENT_COLUMN_NAME,
			accessorFn: row => `${row.firstName}${row.lastName ? ' ' + row.lastName : ''}`,
			enableSorting: true,
			sortingFn: 'alphanumeric',
			// sortingFn: Namesort,
			id: STUDENT_COLUMN_ID,
			size: 140
		},
		{
			header: SCORE_COLUMN_NAME,
			accessorKey: SCORE_COLUMN_ID,
			enableSorting: true,
			sortingFn: (a, b) => {
				const scoreA = a.original.score;
				const scoreB = b.original.score;
				if (scoreA === scoreB) return 0;
				if (!scoreA) return 1;
				if (!scoreB) return -1;
				return parseFloat(scoreA) - parseFloat(scoreB);	
			},
			sortUndefined: -1,
			id: SCORE_COLUMN_ID,
			size: 140
		},
		{
			header: USER_ID_COLUMN_NAME,
			accessorKey: USER_ID_COLUMN_ID,
			enableSorting: false,
			id: USER_ID_COLUMN_ID
		}, // not shown
	];

	const columns = useMemo(() => {
		return GRADE_BOOK_COLUMNS.concat(questions.map(q => ({
			header: `${q.questionType}`,
			accessorKey: `Q${q.serialNo}`,
			enableSorting: false,
			id: `Q${q.serialNo}`,
			size: 100
		})));
	}, [questions, GRADE_BOOK_COLUMNS]);

    const pageOptions = useMemo(() => {
        let options = [];
        // console.log(responses.length, DEFAULT_ROWS_PER_PAGE);
        let nPages = Math.ceil(responses.length / DEFAULT_ROWS_PER_PAGE);
        // console.log(nPages);
        for (let i = 0; i < nPages; i++) options.push(i);
        return options;
    }, [responses]);

	const {
		getHeaderGroups,
		getRowModel,
		setPageIndex,
		getCanPreviousPage,
		previousPage,
		getCanNextPage,
		nextPage,
		getPageCount,
		getState,
		getSelectedRowModel,
		getIsAllRowsSelected,
		getIsSomeRowsSelected,
		getToggleAllRowsSelectedHandler,
		// getIsAllRowsExpanded,
		toggleAllRowsExpanded,
		setPageSize
	} = useReactTable({
		columns: columns,
		data: responses,
		paginateExpandedRows: false,
		autoResetPage: true,
		enableSortingRemoval: false,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		// getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getSubRows: (row) => {
			// console.log(row, row.subRows);
			return row.subRows;
		},
		// debugAll: true,
		// globalFilterFn: filterCustomize,
		// onGlobalFilterChanged: setGlobalFilter,
		// state: {
        //     globalFilter: globalFilter,
		// },
	});

	useEffect(() => {
		setPageSize(DEFAULT_ROWS_PER_PAGE);
        // console.log(updateCustomScore);
        if (!updateCustomScore) return;
		// console.log(responses);
		let tempScoresMap = {};
		let tempSyncCountMap = {};
		responses.map(r => {
			if (!r.attempts) return;
			tempSyncCountMap[r.userId] = r.syncCount;
			let tempAttemptsMap = {};
			if (!r.attempts) {
				tempScoresMap[r.userId] = tempAttemptsMap;
				return;
			}
			// tempAttemptsMap[0] = r.score.replace('%', '');
			for (const [attemptNo, attempt] of Object.entries(r.attempts)) {
				tempAttemptsMap[attemptNo] = attempt.score ? attempt.score.replace('%', '') : '';
				// console.log(attempt.score, isValidScore(attempt.score));
			}
			tempScoresMap[r.userId] = tempAttemptsMap;
		});
		console.log(tempScoresMap);
		// console.log(tempSyncCountMap);
		setCustomScoresMap(tempScoresMap);
		setSyncCountMap(tempSyncCountMap);
	}, [responses]);

	useEffect(() => {
		if (!!autoSyncData && !!autoSyncData.userId) {
			console.log("Auto Syncing...", pollCode, autoSyncData.userId, responses[responses.findIndex(r => r.userId === autoSyncData.userId)].attempts[0].score);
			autoSync(pollCode, autoSyncData.userId, responses[responses.findIndex(r => r.userId === autoSyncData.userId)].attempts[0].score);
			setAutoSyncData({});
		} else if (!!autoSyncData && !!autoSyncData.userIds) {
			for (const id of autoSyncData.userIds) {
				autoSync(pollCode, id , responses[responses.findIndex(r => r.userId === id)].attempts[0].score);
			}
			setAutoSyncData({});
		}
	}, [autoSyncData]);

	useEffect(() => {
		const syncWrapper = async () => {
			if (syncMode === 0) { // no need to sync
				return;
			}
			setLoading(true);
			if (syncMode === 1) { // student sync
				const selectedUserIds = getSelectedRowModel().flatRows.map((row) => row.original.userId);
				// console.log(selectedUserIds);
				if (selectedUserIds.length === 0) {
					notifyError(SELECT_AT_LEAST_ONE_STUDENT);
				} else {
					const res = await saveSelectedStudents(selectedUserIds);
					if (res) {
						notifySuccess(GRADEBOOK_SYNC_SUCCESS);
					} else {
						notifyError(ENTER_VALID_SCORES);
					}
				}
			// } else if (syncMode === 2) { // quiz sync
			// 	let students = customScoresMap;
			// 	if (students.length === 0) {
			// 		notifyError(NO_STUDENTS_FOUND);
			// 	} else {
			// 		const res = await saveAllStudents(students);
			// 		if (res) {
			// 			notifySuccess(GRADEBOOK_SYNC_SUCCESS);
			// 		} else {
			// 			notifyError(ENTER_VALID_SCORES);
			// 		}
			// 	}
			}
			setSyncMode(0);
			setLoading(false);
		};

		syncWrapper();
    }, [syncMode]);

	const autoSync = (pollCode, userId, score) => {
		console.log(pollCode, userId, score, syncCountMap[userId]);
		if (syncCountMap[userId] > 0) {
			gradebookSync(
				pollCode,
				userId,
				score,
				// () => syncScore(pollKey, pollCode, userId, true)
			);
		}
	};

	const showQuestion = async (serialNo) => {
		const no = parseInt(serialNo);
		const selectedQuestion = questions.find(q => q.serialNo === no);
		setQuestion(selectedQuestion);
		setModalShow(true);
	};

	const showResponseModal = async (row, cell) => {
		// if (row.getCanExpand()) return;
		// if (isDisabledAlt(row, customScoresMap, lmsAttempt, pollType?.toUpperCase())) return;
		// const questionType = cell.column.columnDef.header;
		// if (pollType.toUpperCase() === POLL_TYPE.SURVEY || questionType === QUESTION_TYPES.MCSS.name || questionType === QUESTION_TYPES.TF.name) return;
		const serialNo = parseInt(cell.column.id.substring(1)); // Remove 'Q'
		const selectedQuestion = questions.find(q => q.serialNo === serialNo);
		setQuestion(selectedQuestion);
		const selectedResponse = responses.find(r => r.userId === row.original.userId);
		// if (questionType !== QUESTION_TYPES.SA.name) { // FITB or MH
		// 	// e.stopPropagation();
		// 	let answerData = row.original[cell.column.id.replace('Q', 'A')];
		// 	// console.log(answerData);
		// 	switch (questionType) {
		// 		case QUESTION_TYPES.FITB.name:
		// 			let array = Object.keys(answerData)
		// 				.filter(key => key !== "");
		// 			setAnswerModalData(array.map((key, index) => {
		// 				if (key === "") return <></>;
		// 				return (
		// 					<p
		// 						key={index}
		// 						style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-word' }}
		// 					>
		// 						{key}: {answerData[key]}
		// 					</p>
		// 				)
		// 			}));
		// 		break;

		// 		case QUESTION_TYPES.CI.name:
		// 			const image = selectedQuestion?.image;
		// 			const suffix = selectedQuestion?.suffix;
		// 			let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`;
		// 			url = !!image ? `${url}/${image}` : `${url}/temp-${serialNo}${suffix}`;
		// 			let img = await fetchImg(url);
		// 			setQuestionImageURL(img ? img : "");
		// 			setAnswerModalData(answerData);
		// 		break;
				
		// 		case QUESTION_TYPES.MH.name:
		// 			array = Object.keys(answerData); 
		// 			let values = answerData; 
		// 			let options = selectedQuestion.optionsMap.filter(sublist => sublist.length === 2);
		// 			setAnswerModalData(options.map((key, index) => {
		// 				if (key === "") return <></>;
		// 				return (
		// 					<div style={{ display: "flex", width: "100%", gap: "10px", alignItems: "center", justifyContent: "center" }}>
		// 						<p className="questionPanelAnswersText" id={"OptionPart1" + index} style={{ marginRight: "0px", border: ".5px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1, marginBottom: "0px", marginTop: "10px" }}>
		// 							{key[0]}
		// 						</p>
		// 						<img
		// 							src={ArrowIcon}
		// 							alt="Arrow icon"
		// 							className="questions-page-icons cursor-pointer"
		// 							style={{ width: "30px", margin: "10px 0px 0px 10px" }}
		// 						/>
		// 						<p className="questionPanelAnswersText" id={"OptionPart2" + index} style={{ border: ".5px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1, marginBottom: "0px", marginTop: "10px" }}>
		// 							{values.hasOwnProperty(index) ? values[index] : UNANSWERED}
		// 						</p>
		// 					</div>
		// 				);
		// 			}));
		// 		break;
		// 	}
		// 	setShowAnswerModal(true);
		// 	return;
		// }
		setResponse(selectedResponse);
		const attempt = getAttemptNo(row);
		// if (attempt === 0) {
		// 	console.log(selectedResponse.attempts[0]?.mainAttemptNo);
		// }
		setAttemptNo(attempt > 0 ? attempt : selectedResponse.attempts[0]?.mainAttemptNo);
		setShowAnswerModal(true);
	};

	const handleSave = async () => {
		// setLoading(true);
        try {
			let selectedOptions = {};
			for (const user of Object.keys(changedCorrect)) {
				let response = responses.find(r => r.userId === user);
				if (response) { 
					for (const questionNo of Object.keys(changedCorrect[user])) { 
						for (const attemptNo of Object.keys(changedCorrect[user][questionNo])) {
							selectedOptions = {
								...selectedOptions,
								[user]: {
									...selectedOptions[user],
									[questionNo]: {
										...selectedOptions[user]?.[questionNo],
										[attemptNo]: {
											answer: getAnswer(response, attemptNo, questionNo), // Fetch answer for the attempt and question
											isCorrect: changedCorrect[user][questionNo][attemptNo], // Use correctness information from changedCorrect state
										},
									},
								},
							};
						}
					}
				}
			}
            await gradeQuestionStudents(institutionId, pollKey, pollCode, changedCorrect, questions, selectedOptions);
            // notifySuccess(GRADE_QUESTION_SUCCESS);
        } catch (error) {
            console.log(error);
            notifyError(GRADE_QUESTION_ERROR);
        }
		// setDataLoading(true);
        // history.push({
        //     pathname: "/gradebook", 
        //     state: {
        //         pollKey : removePrefixNew(pollKey),
        //         uniqueCode: removePrefixNew(pollCode),
        //         autoSync: {
        //             userIds: Object.keys(changedCorrect),
        //             // score: newResponses[newResponses.findIndex(r => r.userId === student.value)].attempts[0] // score picked from main attempt
        //         }
        //     }
        // });
		// setIsCorrect({});
		// setChangedCorrect({});
		// setShowAnswerModal(false);
        // setLoading(false);
	};

	const handleClose = () => {
		// if (Object.keys(changedCorrect).length > 0 ) {
		// 	setAlertShow(true);
		// } else {
		// 	setIsCorrect({});
		// 	setChangedCorrect({});
		// 	setShowAnswerModal(false);
		// }

		if (Object.keys(changedCorrect).length === 0 ) {
			setIsCorrect({});
			setChangedCorrect({});
			setShowAnswerModal(false);
			return;
		}

		setDataLoading(true);
        history.push({
            pathname: "/gradebook", 
            state: {
                pollKey : removePrefixNew(pollKey),
                uniqueCode: removePrefixNew(pollCode),
                autoSync: {
                    userIds: Object.keys(changedCorrect),
                    // score: newResponses[newResponses.findIndex(r => r.userId === student.value)].attempts[0] // score picked from main attempt
                }
            }
        });
		setIsCorrect({});
		setChangedCorrect({});
		setShowAnswerModal(false);
	};

	const onBlurCustomScore = (event, userId, attemptNo) => {
		const score = event.target.value;
        // console.log(score, isValidScore(score));
		if (isValidScore(score)) updateScore(pollKey, pollCode, userId, score, attemptNo, lmsAttempt, autoSync);
        let attemptNoToCopy = -1;
        let scoreToCopy = "-1";
        // const attempts = responses.find(r => r.userId === userId).attempts;
        // console.log(attempts);
        if (lmsAttempt === SYNC_ATTEMPT.FIRST.value) {
            attemptNoToCopy = 1;
            scoreToCopy = customScoresMap[userId][attemptNoToCopy];
        } else if (lmsAttempt === SYNC_ATTEMPT.RECENT.value) {
            attemptNoToCopy = Object.keys(customScoresMap[userId]).length - 1;
            scoreToCopy = customScoresMap[userId][attemptNoToCopy];
        } else if (lmsAttempt === SYNC_ATTEMPT.HIGHEST.value) {
            Object.keys(customScoresMap[userId]).forEach((attemptNumber, idx) => {
				if (idx === 0) return; // skip the main attempt
				const attempt = customScoresMap[userId][attemptNumber];
				// console.log(customScoresMap[userId], attempt, idx);
				// console.log(attempt, isValidScore(attempt), parseFloat(attempt), parseFloat(scoreToCopy), parseFloat(attempt) > parseFloat(scoreToCopy));
                if (isValidScore(attempt) && parseFloat(attempt) > parseFloat(scoreToCopy)) {
					scoreToCopy = attempt;
                    attemptNoToCopy = attemptNumber;
                }
			});
        }
        console.log(attemptNoToCopy, scoreToCopy, isValidScore(scoreToCopy));
        if (isValidScore(scoreToCopy)) {
            // console.log("update customScoresMap:");
            let tempScoresMap = {...customScoresMap};
            // console.log(tempScoresMap);
            tempScoresMap[userId][0] = scoreToCopy;
            // console.log(tempScoresMap);
            setCustomScoresMap(tempScoresMap);
            
            // console.log("update main attempt:");
            let tempResponses = [...responses];
            let tempResponse = {...tempResponses.find(r => r.userId === userId)};
            let tempSubRows = [...tempResponse.subRows];
            let attempt = tempSubRows[attemptNoToCopy - 1];
            // console.log(attempt);
            let newResponse = {...tempResponse, ...attempt, firstName: tempResponse.firstName, lastName: tempResponse.lastName};
            setUpdateCustomScore(false);
            // console.log(responses, tempResponses.map(r => r.userId === userId ? newResponse : r));
            setResponses(tempResponses.map(r => r.userId === userId ? newResponse : r));
        }
    };

    const onChangeCustomScore = (event, userId, attemptNo) => {
		// console.log(customScoresMap, userId, attemptNo, event.target.value);
		let tempScoresMap = {...customScoresMap};
		tempScoresMap[userId][attemptNo] = event.target.value.trim();
		setCustomScoresMap(tempScoresMap);
    };

	const syncStudent = async (userId, score) => {
		if (!pollCode) {
			console.log("Invalid pollCode");
			return false;
		}
		if (!userId) {
			console.log("Invalid userId");
			return false;
		}
		if (!isValidScore(score)) {
			console.log("Invalid score");
			return false;
		}
		const res = await gradebookSync(
			pollCode,
			userId,
			score,
			() => {
				syncScore(pollKey, pollCode, userId, true, true);
				setSyncCountMap({...syncCountMap, [userId]: syncCountMap[userId] + 1});
			}
		);
		return res;
	};

	async function saveSelectedStudents (selectedUserIds) {
		if (selectedUserIds.length === 0) { // base case
			return true;
		}
		const selectedUserId = selectedUserIds.pop();
		if (!isValidScore(customScoresMap[selectedUserId][0])) { // invalid score
			console.log("Invalid score", customScoresMap[selectedUserId][0], "for", selectedUserId);
			// return false;
		} else {
			const res = await syncStudent(selectedUserId, customScoresMap[selectedUserId][0]);
			if (res) { 
				console.log("Synced", customScoresMap[selectedUserId][0], "for", selectedUserId);
			} else {
				console.log("Error in Gradebook Sync for", selectedUserId);
			}
		}
		return await saveSelectedStudents(selectedUserIds);
	};
	
	// const saveAllStudents = async (students) => {
	// 	// console.log(students);
	// 	for (let student in students) {
	// 		if (!isValidScore(students[student][0])) {
	// 			console.log("Invalid score", students[student][0], "for", student);
	// 			continue;
	// 			// return false;
	// 		}
	// 		const res = await syncStudent(student, students[student][0]);
	// 		if (res) {
	// 			console.log("Synced", students[student][0], "for", student);
	// 		} else {
	// 			console.log("Error in Gradebook Sync for", student);
	// 		}
	// 	}
	// 	return true;
	// };

	// useEffect(() => {
    //     console.log(changedCorrect);
    // }, [changedCorrect])

	const alertConfig = {
        title: SWITCH_POLL_MODAL_TITLE,
        okText: SWITCH_POLL_MODAL_TEXT,
        submit: async () => {
			setIsCorrect({});
			setChangedCorrect({});
			setAlertShow(false);
			setShowAnswerModal(false);
        },
        close:  async () => {
          setAlertShow(false);
        },
      };

	// const autoUpdateScore = (score) => {
	// 	const userId = response.userId;
	// 	console.log(pollKey, pollCode, userId, score);
	// 	setCustomScoresMap({...customScoresMap, [userId]: score});
	// 	updateScore(pollKey, pollCode, userId, score, autoSync);
	// };

	// useEffect(() => {
	// 	setGlobalFilter(globalFilterObj);
	// 	setPageIndex(0);
	// }, [globalFilterObj, responses]);

	return (
		<>
			<YuJaGeneralAlert
				show={alertShow}
				setModalShow={setAlertShow}
				content={LOSE_CHANGE_MESSAGE}
				config={alertConfig}
			/>
			{/* <Modal
				id="answer-modal"
				show={showAnswerModal}
				onHide={() => setShowAnswerModal(false)}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop={loading ? "static" : true}
				dialogClassName="sa-modal"
			>
				<Spin tip="Loading..." size="large" spinning={loading}>
					<ModalClose tabIndex={0} onClick={() => setShowAnswerModal(false)} className="modal-close" />
					<Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Modal.Title tabIndex={0} >
							{`Student's Answer`}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className={styles.answer}>
							{question.questionType === QUESTION_TYPES.CI.name ?
								<div className="img-container" style={{ display: 'flex', justifyContent: 'center', flex: "1", marginRight: 0, padding: "25px" }}>
									<div style={{ position: "relative" }}>
										<img ref={imageRef} className="img-class-result" src={questionImageURL} tabIndex={0} aria-label={question.imageAlt ? question.imageAlt : DEFAULT_IMAGE_ALT} alt="Question Image" onLoad={() => setImageLoaded(true)} />
										{questionImageURL && imageLoaded && imageRef.current !== null && !!answerModalData?.x && !!answerModalData?.y &&
											<COORDINATE
												style={{
													position: "absolute",
													top: `${decodeCIParam(answerModalData.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
													left: `${decodeCIParam(answerModalData.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
												}}
											/>
										}
									</div>
								</div>
							: answerModalData}
						</div>
					</Modal.Body>
				</Spin>
			</Modal> */}
			<Modal
				id="sa-modal"
				show={showAnswerModal}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop={loading ? "static" : true}
				dialogClassName="sa-grade-modal"
			>
				<Spin tip="Loading..." size="large" spinning={loading}>
					<ModalClose tabIndex={0} onClick={handleClose} className="modal-close" />
					<Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Modal.Title
							tabIndex={0} 
							className="modal-title"
							id="contained-modal-title-vcenter"
						>
							{!!pollType && pollType.toUpperCase() === POLL_TYPE.SURVEY ? STUDENT_RESPONSE_MODAL_TITLE : VIEW_GRADE_RESPONSE_MODAL_TITLE}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div tabIndex={0} role={TEXTBOX} className="activatePollText" style={{fontWeight: 400, fontSize: 14, margin: "0 0 25px"}}>
							{!!pollType && pollType.toUpperCase() === POLL_TYPE.SURVEY ? VIEW_MODAL_DESCRIPTION : VIEW_GRADE_RESPONSE_MODAL_DESCRIPTION}
						</div>
						<GradeQuestion
							question={question}
							questions={questions}
							attemptNo={attemptNo}
							response={response}
							setResponse={setResponse}
							responses={responses}
							setResponses={setResponses}
							institutionId={institutionId}
							pollKey={pollKey}
							isCorrect={isCorrect}
							setIsCorrect={setIsCorrect}
							pollShareMode={pollShareMode}
							// pollCode={pollCode}
							// pollTitle={pollTitle}
							pollType={pollType}
							changedCorrect={changedCorrect}
							setChangedCorrect={setChangedCorrect}
							// lmsAttempt={lmsAttempt}
							// handleClose={handleClose}
							// autoUpdateScore={autoUpdateScore}
							// autoSync={autoSync}
							handleSave={handleSave}
						/>
					</Modal.Body>
					<Modal.Footer>
						<YuJaButton type="secondary" onClick={handleClose}>
							{MODAL_CLOSE_TEXT}
						</YuJaButton>
					</Modal.Footer>
				</Spin>
			</Modal>
			<Modal
				id="question-modal"
				show={modalShow}
				onHide={() => setModalShow(false)}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop={loading ? "static" : true}
				dialogClassName="question-modal"
			>
				<Spin tip="Loading..." size="large" spinning={loading}>
					<ModalClose tabIndex={0} onClick={() => setModalShow(false)} className="modal-close" />
					<Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Modal.Title tabIndex={0} >
							{`Question #${question.serialNo}`}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<TabQuestion
							question={question}
							pollType={pollType}
							institutionId={institutionId}
							pollKey={pollKey}
							forGradebook={true}
						/>
					</Modal.Body>
				</Spin>
			</Modal>
			<div className={`${styles.tableContainer} ${(!hasWeightage(questions) || pollType.toUpperCase() === POLL_TYPE.SURVEY) ?
								styles.surveyTableContainer :
								(pollType.toUpperCase() === POLL_TYPE.ATTENDANCE ?
									(showCheckbox ?
										styles.gradeBookTableContainerAttendanceWCheckbox:
										styles.gradeBookTableContainerAttendanceWoCheckbox) :
										styles.gradeBookTableContainerWCheckbox)
										// : styles.gradeBookTableContainer)
			}`}>
				<Table
					responsive
					hover
					// {...getTableProps()}
				>
				{/* <table> */}
					<thead>
					{getHeaderGroups().map(headerGroup => (
						<tr
							// {...headerGroup.getHeaderGroupProps()}
							key={headerGroup.id}
						>
							{headerGroup.headers.map(column => {
								// console.log(column);
								if (GRADE_BOOK_QUESTION_COLUMN_REGEX.test(column.id)) {
									let icon = '';
									let questionType = QUESTION_TYPES[column.column.columnDef.header].description;
									switch (column.column.columnDef.header) {
										case QUESTION_TYPES.MCSS.name:
											icon = MCSS_ICON;
											break;
										case QUESTION_TYPES.TF.name:
											icon = TF_ICON;
											break;
										case QUESTION_TYPES.SA.name:
											icon = SA_ICON;
											break;
										case QUESTION_TYPES.FITB.name:
											icon = FITB_ICON;
											break;
										case QUESTION_TYPES.CI.name:
											icon = CI_ICON;
											break;
										case QUESTION_TYPES.WC.name:
											icon = WC_ICON;
											break;
										case QUESTION_TYPES.MH.name: 
											icon = MH_Icon; 
											break;
										case QUESTION_TYPES.RK.name: 
											icon = RK_Icon; 
											break;
										case QUESTION_TYPES.OE.name: 
										icon = OE_ICON; 
											break;
										default:
									}
									return (
										<th {...{
											// ...column.getHeaderProps(),
											key: column.id,
											style: {width: column.getSize(), backgroundClip: "padding-box"}
										}}>
											<button tabIndex={0} aria-label={VIEW_QUES + column.id} className={styles.questionButton} onClick={() => showQuestion(column.id.substring(1))}>
												<img src={icon} alt={questionType} style={{width: "24px", height: "24px"}} />
												<div>{column.id}</div>
											</button>
										</th>
									);
								} else if (column.id === SCORE_COLUMN_ID) {
									if (!hasWeightage(questions) || pollType.toUpperCase() === POLL_TYPE.SURVEY) return <></>;
									return (
										<th tabIndex={0} {...{
											// ...column.getHeaderProps(),
											key: column.id,
											style: {width: column.getSize(), backgroundClip: "padding-box", textAlign: "left"}
										}}>
											Score (%)
											{column.column.getIsSorted() ? (column.column.getNextSortingOrder() === "asc" ?
												<SortDown tabIndex={0} role={TEXTBOX} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={column.column.getToggleSortingHandler()} /> :
												<SortUp tabIndex={0} role={TEXTBOX} aria-label={column.id + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="ascending" onClick={column.column.getToggleSortingHandler()} />
											) : 
												<SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={column.column.getToggleSortingHandler()} />
											}
										</th>
									);
								} else if (column.id === USER_ID_COLUMN_ID) { // Remove block to show User ID Header
									// console.log(column.getValue());
								} else if (column.id === CHECKBOX_COLUMN_ID) {
									// if (!showCheckbox) return null;
									// console.log(column.column.columnDef.header, column.getContext());
									return (
										<th {...{
											// ...column.getHeaderProps(),
											key: column.id,
											style: {width: column.getSize(), backgroundClip: "padding-box"}
										}}>
											{flexRender(column.column.columnDef.header, column.getContext())}
										</th>
									);
								} else {
									// console.log(column.column.columnDef.header, column.getContext());
									return (
										<th tabIndex={0} {...{
											// ...column.getHeaderProps(),
											key: column.id,
											style: {width: column.getSize(), backgroundClip: "padding-box", textAlign: "left"}
										}}>
											{flexRender(column.column.columnDef.header, column.getContext())}
											{column.column.getIsSorted() ? (column.column.getNextSortingOrder() === "asc" ?
												<SortDown tabIndex={0} role={TEXTBOX} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={column.column.getToggleSortingHandler()} /> :
												<SortUp tabIndex={0} role={TEXTBOX} aria-label={column.id + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="ascending" onClick={column.column.getToggleSortingHandler()} />
											) : 
												<SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={column.column.getToggleSortingHandler()} /> 
										}
										</th>
									);
								}
							})}
						</tr>
					))}
					</thead>
					<tbody
						// {...getTableBodyProps()}
					>
					{!dataLoading && (getRowModel().rows?.length === 0 ? <tr><td style={{boxShadow: "none"}} colSpan={columns.length - (!hasWeightage(questions) || pollType.toUpperCase() === POLL_TYPE.SURVEY ? 2 : 1)}><div style={{display: "flex", height: 390, flexDirection: "column", alignItems: "center", gap: "5px", justifyContent: "center"}}> <NoResultIllustration /> <div style={{fontSize: "20px",  marginTop: "10px", fontWeight: 600}}> {NO_RESULTS_FOUND} </div> </div></td></tr> : getRowModel().rows.map((row, i) => {
						// console.log(i);
						// prepareRow(row);
						// console.log(i, row);
						return (<>
							<tr
								key={row.id}
								onClick={row.getToggleExpandedHandler()}
								onMouseEnter={() => setHoveredRow(row.id)}
								onMouseLeave={() => setHoveredRow(-1)}
								// {...row.getRowProps()}
								// {...getToggleRowExpandedPropsWrapper(row)}
								// className={!row.getCanExpand() && styles.gradebookTableRow}
							>
								{row.getVisibleCells().map(cell => {
									if (GRADE_BOOK_QUESTION_COLUMN_REGEX.test(cell.column.id)) {
										const serialNo = parseInt(cell.column.id.substring(1)); // Remove 'Q'
										const question = questions.find(q => q.serialNo === serialNo); // Fetch question based on serialNo
										const gradeEnabled = !!question?.weightage && pollType?.toUpperCase() !== POLL_TYPE.SURVEY;
										// console.log(cell.column.columnDef.header, cell.getValue());
										if (cell.getValue() === undefined) {
											return (<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>Not Posted</td>)
										}
										if (!gradeEnabled) {
											if (cell.getValue() === NO_RESPONSE) {
												return (<td tabIndex={0} {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}} onMouseDown={e => e.preventDefault()} onClick={e => {e.stopPropagation(); showResponseModal(row, cell);}}>
													<YuJaTooltip
														text={VIEW_RESPONSE_TOOLTIP_TEXT}
														placement="bottom"
														tooltipStyle={{maxWidth: 400}}
													>
														<span>{cell.getValue()}</span>
													</YuJaTooltip>
												</td>);
											}
											if (cell.column.columnDef.header === QUESTION_TYPES.MCSS.name) {
												if (row.original[cell.column.id.replace('Q', 'A')][0] === FIXED_ANSWER.UNANSWERED) {
													return (<td tabIndex={0} role={TEXTBOX} aria-label={QUES_LABEL_1 + cell.column.id.substring(1) + " " + cell.column.columnDef.header + " " + cell.getValue()} {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}} onMouseDown={e => e.preventDefault()} onClick={e => {e.stopPropagation(); showResponseModal(row, cell);}}>
														<YuJaTooltip
															text={VIEW_RESPONSE_TOOLTIP_TEXT}
															placement="bottom"
															tooltipStyle={{maxWidth: 400}}
														>
															<span>{NO_RESPONSE}</span>
														</YuJaTooltip>
													</td>)
												}
												let array = row.original[cell.column.id.replace('Q', 'A')].sort((a, b) => a.localeCompare(b));
												return (
													<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
														<YuJaTooltip
															text={VIEW_RESPONSE_TOOLTIP_TEXT}
															placement="bottom"
															tooltipStyle={{maxWidth: 400}}
														>
															<div className={styles.gradeBookSurvey} onMouseDown={e => e.preventDefault()} onClick={e => {e.stopPropagation(); showResponseModal(row, cell);}}>
																{array.map((key, index) => {return (
																	<div tabIndex={0}
																		key={index}
																		style={{display: 'inline-flex'}}
																		>
																		{(index === 0 ? "" : ",") + key.toUpperCase()}
																	</div>
																)})}
															</div>
														</YuJaTooltip>
													</td>
												);
											}
											if (cell.column.columnDef.header === QUESTION_TYPES.TF.name) {
												return (
													<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
														<YuJaTooltip
															text={VIEW_RESPONSE_TOOLTIP_TEXT}
															placement="bottom"
															tooltipStyle={{maxWidth: 400}}
														>
															<div tabIndex={0} className={styles.gradeBookSurvey} onMouseDown={e => e.preventDefault()} onClick={e => {e.stopPropagation(); showResponseModal(row, cell);}}>
																{row.original[cell.column.id.replace('Q', 'A')]}
															</div>
														</YuJaTooltip>
													</td>
												);
											}
											if (cell.column.columnDef.header === QUESTION_TYPES.CI.name) {
												if (row.original[cell.column.id.replace('Q', 'A')].selected == 'other') {
													return (
														<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
															<YuJaTooltip
																text={VIEW_RESPONSE_TOOLTIP_TEXT}
																placement="bottom"
																tooltipStyle={{maxWidth: 400}}
															>
																<div
																	className={styles.gradeBookView}
																	tabIndex={0}
																	onMouseDown={e => e.preventDefault()}
																	onClick={(e) => {
																		e.stopPropagation();
																		showResponseModal(row, cell);
																	}}
																>
																	View
																</div>
															</YuJaTooltip>
														</td>
													);
												}
												return (
													<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
														<YuJaTooltip
															text={VIEW_RESPONSE_TOOLTIP_TEXT}
															placement="bottom"
															tooltipStyle={{maxWidth: 400}}
														>
															<div tabIndex={0} className={styles.gradeBookSurvey} onMouseDown={e => e.preventDefault()} onClick={e => {e.stopPropagation(); showResponseModal(row, cell);}}>
																{String.fromCharCode(ASCII_CODE_A + parseInt(row.original[cell.column.id.replace('Q', 'A')].selected, 10))}
															</div>
														</YuJaTooltip>
													</td>
												);
											}
											// SA, FITB or WC
											return (
												<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
													<YuJaTooltip
														text={VIEW_RESPONSE_TOOLTIP_TEXT}
														placement="bottom"
														tooltipStyle={{maxWidth: 400}}
													>
													<div
														className={styles.gradeBookView}
														// title={'show answer'}
														tabIndex={0}
														onMouseDown={e => e.preventDefault()}
														onClick={(e) => {
															e.stopPropagation();
															// if (row.getCanExpand()) return;
															// if (cell.column.columnDef.header === QUESTION_TYPES.SA.name) {
															// 	setAnswerModalData(row.original[cell.column.id.replace('Q', 'A')]['0']);
															// } else if (cell.column.columnDef.header === QUESTION_TYPES.FITB.name) {
															// 	let array = Object.keys(row.original[cell.column.id.replace('Q', 'A')]);
															// 	setAnswerModalData(array.map((key, index) => {return (
															// 		<p
															// 			key={index}
															// 			style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-word' }}
															// 		>
															// 			{key}: {row.original[cell.column.id.replace('Q', 'A')][key]}
															// 		</p>
															// 	)}));
															// } else if (cell.column.columnDef.header === QUESTION_TYPES.WC.name) {
															// 	let array = Object.values(row.original[cell.column.id.replace('Q', 'A')])
															// 		.filter(key => key !== "");
															// 	setAnswerModalData(array.map((key, index) => {
															// 		if (key === "") return <></>;
															// 		console.log(key);
															// 		return (
															// 		<p
															// 			key={index}
															// 			style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-word' }}
															// 		>
															// 			Entry {index+1}: {key}
															// 		</p>
															// 	)}));
															// } else if (cell.column.columnDef.header === QUESTION_TYPES.RK.name) {
															// 	setAnswerModalData(row.original[cell.column.id.replace('Q', 'A')].map((key, index) => {return (
															// 		<p
															// 			key={index}
															// 			tabIndex={0}
															// 			aria-label={`Rank ${index + 1}:  ${key}`}
															// 			style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-word' }}
															// 		>
															// 			{ `Rank ${index + 1}:  ${key}`}
															// 		</p>
															// 	)}));
															// } else {
															// 	console.log(INVALID_QUESTION_TYPE);
															// }
															showResponseModal(row, cell);
															// setShowAnswerModal(true);
														}}
													>
														{/* <img src={showAnswer} alt={'showAnswerImg'}/> */}
														View
													</div>
													</YuJaTooltip>
												</td>
											);
										}
										// Graded Poll:
										if (cell.getValue() === CORRECT) {
											return (
												<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
													<YuJaTooltip
														text={GRADE_RESPONSE_TOOLTIP_TEXT}
														placement="bottom"
														tooltipStyle={{maxWidth: 400}}
													>
														{!row.original[cell.column.id.replace(/Q/, "A")] || row.original[cell.column.id.replace(/Q/, "A")] === FIXED_ANSWER.UNANSWERED ? 
															<div tabIndex={0} role={TEXTBOX} aria-label={QUES_LABEL_1 + cell.column.id.substring(1) + " " + cell.column.columnDef.header + " " + cell.getValue()} className={styles.gradeBookCorrect} onMouseDown={e => e.preventDefault()} onClick={e => {e.stopPropagation(); showResponseModal(row, cell);}}>
																<img src={correct} alt={'correctImg'}/>
															</div>
														:
														<div tabIndex={0} role={TEXTBOX} aria-label={QUES_LABEL_1 + cell.column.id.substring(1) + " " + cell.column.columnDef.header + " " + cell.getValue()} className={styles.gradeBookCorrect} onMouseDown={e => e.preventDefault()} onClick={e => {e.stopPropagation(); showResponseModal(row, cell);}}>
															{cell.column.columnDef.header == QUESTION_TYPES.MCSS.name ?
															<div> {row.original[cell.column.id.replace(/Q/, "A")].toUpperCase()} </div> 
															: cell.column.columnDef.header == QUESTION_TYPES.CI.name && row.original[cell.column.id.replace(/Q/, "A")]?.selected != 'other'?
															<div> {String.fromCharCode(ASCII_CODE_A + parseInt(row.original[cell.column.id.replace(/Q/, "A")]?.selected, 10))} </div>
															:  <img src={correct} alt={'correctImg'}/>
														}
														</div>
														}
													</YuJaTooltip>
												</td>
											)
										}
										if (cell.getValue() === INCORRECT) {
											return (
												<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
													<YuJaTooltip
														text={GRADE_RESPONSE_TOOLTIP_TEXT}
														placement="bottom"
														tooltipStyle={{maxWidth: 400}}
													>
														{!row.original[cell.column.id.replace(/Q/, "A")] || row.original[cell.column.id.replace(/Q/, "A")] === FIXED_ANSWER.UNANSWERED ? 
														<div tabIndex={0} role={TEXTBOX} aria-label={QUES_LABEL_1 + cell.column.id.substring(1) + " " + cell.column.columnDef.header + " " + cell.getValue()} className={styles.gradeBookIncorrect} onMouseDown={e => e.preventDefault()} onClick={e => {e.stopPropagation(); showResponseModal(row, cell);}}>
															<img src={incorrect} alt={'incorrectImg'}/> 
															</div>
														:
														<div tabIndex={0} role={TEXTBOX} aria-label={QUES_LABEL_1 + cell.column.id.substring(1) + " " + cell.column.columnDef.header + " " + cell.getValue()} className={styles.gradeBookIncorrect} onMouseDown={e => e.preventDefault()} onClick={e => {e.stopPropagation(); showResponseModal(row, cell);}}>
															{cell.column.columnDef.header == QUESTION_TYPES.MCSS.name ?
															<div> {row.original[cell.column.id.replace(/Q/, "A")]?.toUpperCase()} </div>
															: cell.column.columnDef.header == QUESTION_TYPES.CI.name &&  row.original[cell.column.id.replace(/Q/, "A")].selected != 'other' ?
															<div> {String.fromCharCode(ASCII_CODE_A + parseInt(row.original[cell.column.id.replace(/Q/, "A")].selected, 10))} </div> 
															: <img src={incorrect} alt={'incorrectImg'}/> 
														}
														</div>
														}
													</YuJaTooltip>
												</td>
											)
										}
										if (cell.getValue() === NOT_GRADED) {
											return (
												<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
													<YuJaTooltip
														text={GRADE_RESPONSE_TOOLTIP_TEXT}
														placement="bottom"
														tooltipStyle={{maxWidth: 400}}
													>
														{!row.original[cell.column.id.replace(/Q/, "A")] || row.original[cell.column.id.replace(/Q/, "A")] === FIXED_ANSWER.UNANSWERED ? 
															<div onMouseDown={e => e.preventDefault()}
															onClick={e => {e.stopPropagation(); showResponseModal(row, cell);}}> {NO_RESPONSE} </div>
														:
														<div tabIndex={0} role={TEXTBOX} aria-label={QUES_LABEL_1 + cell.column.id.substring(1) + " " + cell.column.columnDef.header + " " + cell.getValue()}
															className={ styles.gradeBookNotGraded}
															// title={NOT_GRADED}
															onMouseDown={e => e.preventDefault()}
															onClick={e => {e.stopPropagation(); showResponseModal(row, cell);}}
														>
															<img src={notGraded} alt={'notGradedImg'}/>
														</div>
														}
													</YuJaTooltip>
												</td>
											)
										}
										return (<td tabIndex={0} role={TEXTBOX} aria-label={QUES_LABEL_1 + cell.column.id.substring(1) + " " + cell.column.columnDef.header + " " + cell.getValue()} {...{key: cell.id, style: {width: cell.column.getSize()}}} onMouseDown={e => e.preventDefault()} onClick={() => showResponseModal(row, cell)}>
											<YuJaTooltip
												text={VIEW_RESPONSE_TOOLTIP_TEXT}
												placement="bottom"
												tooltipStyle={{maxWidth: 400}}
											>
												<span>{cell.getValue()}</span>
											</YuJaTooltip>
										</td>)
									} else if (cell.column.id === SCORE_COLUMN_ID) {
										if (!hasWeightage(questions) || pollType.toUpperCase() === POLL_TYPE.SURVEY) return <></>;
										// console.log(`customScoreInput-${getUserId(row)}-${getAttemptNo(row)}`);
										return (
											<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box", height: "fit-content"}}}>
												<div tabIndex={0} role={TEXTBOX} aria-label={SCORE_LABEL + (customScoresMap &&
																customScoresMap[getUserId(row)] &&
																customScoresMap[getUserId(row)][getAttemptNo(row)] ?
																customScoresMap[getUserId(row)][getAttemptNo(row)] : 'score') + " percent"} className={styles.customScoreBox}>
													<input
														className={styles.customScoreInput}
														id={`customScoreInput-${getUserId(row)}-${getAttemptNo(row)}`}
														type="text"
														// pattern="[0-9.]*"
														disabled={isDisabled(row, customScoresMap, lmsAttempt)}
														placeholder="00.00"
														aria-label={SCORE_LABEL + (customScoresMap &&
																customScoresMap[getUserId(row)] &&
																customScoresMap[getUserId(row)][getAttemptNo(row)] ?
																customScoresMap[getUserId(row)][getAttemptNo(row)] : 'score') + " percent"}
														value={customScoresMap &&
																customScoresMap[getUserId(row)] &&
																customScoresMap[getUserId(row)][getAttemptNo(row)] ?
																customScoresMap[getUserId(row)][getAttemptNo(row)] : ''}
														onChange={(e) => { onChangeCustomScore(e, getUserId(row), getAttemptNo(row));}}
														onBlur={(e) => { onBlurCustomScore(e, getUserId(row), getAttemptNo(row));}}
														style={{
															border: (
																isDisabled(row, customScoresMap, lmsAttempt) ||
																(customScoresMap &&
																customScoresMap[getUserId(row)] &&
																customScoresMap[getUserId(row)][getAttemptNo(row)] &&
																isValidScore(customScoresMap[getUserId(row)][getAttemptNo(row)]))
															) ?
																"transparent" : "1px solid #FF0000"
														}}
													/>
												</div>
											</td>
										);
									} else if (cell.column.id === USER_ID_COLUMN_ID) { // Remove block to show User ID
										// console.log(cell.getValue());
									} else if (cell.column.id === CHECKBOX_COLUMN_ID) {
										// if (!showCheckbox) return null;
										return (
											<td
												className={hoveredRow === row.id ? styles.hoveredRow : ''}
												{...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}
											>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										);
									} else if (cell.column.id === STUDENT_COLUMN_ID) {
										// console.log(cell.column.columnDef.cell, cell.getValue());
										return (
											<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
												<div
												 aria-label={cell.getValue().includes("Attempt") ? (cell.getValue() + ATMPT_LABEL_2) : (STD_LABEL + cell.getValue())} className={ATTEMPT_REGEX.test(cell.getValue()) ? styles.subRow : styles.mainRow} style={{display: "flex", justifyContent: "left"}}
												>
													 <div tabIndex={0} role={TEXTBOX} style={{width: "fit-content"}}> {flexRender(cell.column.columnDef.cell, cell.getContext())} </div>
												</div>
											</td>
										);
									} else {
										// console.log(cell);
										return (
											<td tabIndex={0} {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
												{/* {flexRender(cell.column.columnDef.cell, cell.getContext())} */}
												{cell.getValue()}
											</td>
										);
									}
								})}
							</tr>
							{/* Space between last subrow and next main row */}
							{/* {!isLastRow(row, getRowModel().rows) && getAttemptNo(row) === getTotalAttempts(row, customScoresMap) && <tr style={{height: "30px"}} />} */}
						</>)
					}))}
					{
						dataLoading && (Array.from({ length: 5 }).map((_, rowIndex) => (
								<tr key={rowIndex}> 
								{getHeaderGroups().map(headerGroup => (
									<>
									{headerGroup.headers.map(column => {
										// console.log(column);
										if (GRADE_BOOK_QUESTION_COLUMN_REGEX.test(column.id)) {
											return (
												<th {...{
													// ...column.getHeaderProps(),
													key: column.id,
													style: {width: column.getSize(), backgroundClip: "padding-box"}
												}}>
													<SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
														<Skeleton borderRadius={10} height={20} width={skeletonLoaderWidth[rowIndex]} style={{ position: "relative" }} />
													</SkeletonTheme>
												</th>
											);
										} else if (column.id === SCORE_COLUMN_ID) {
											if (!hasWeightage(questions) || pollType.toUpperCase() === POLL_TYPE.SURVEY) return <></>;
											return (
												<th tabIndex={0} {...{
													// ...column.getHeaderProps(),
													key: column.id,
													style: {width: column.getSize(), backgroundClip: "padding-box"}
												}}>
													<SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
														<Skeleton borderRadius={10} height={20} width={skeletonLoaderWidth[rowIndex]} style={{ position: "relative" }} />
													</SkeletonTheme>
												</th>
											);
										} else if (column.id === USER_ID_COLUMN_ID) { 
										} else if (column.id === CHECKBOX_COLUMN_ID) {
											return (
												<th tabIndex={0} {...{
													key: column.id,
													style: {width: column.getSize(), backgroundClip: "padding-box"}
												}}>
													<SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
														<Skeleton borderRadius={10} height={20} width={skeletonLoaderWidth[rowIndex]} style={{ position: "relative" }} />
													</SkeletonTheme>
												</th>
											);
											// return (
											// 	<td {...{
											// 		key: column.id,
											// 		style: {width: column.getSize(), backgroundClip: "padding-box"}
											// 	}}>
												
											// 	</td>
											// );
										} else {
											return (
												<th tabIndex={0} {...{
													key: column.id,
													style: {width: column.getSize(), backgroundClip: "padding-box"}
												}}>
													<SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
														<Skeleton borderRadius={10} height={20} width={skeletonLoaderWidth[rowIndex]} style={{ position: "relative" }} />
													</SkeletonTheme>
												</th>
											);
										}
									})}
									</>
							)) }
							</tr>))
							)
						}
					</tbody>
				</Table>

				{getRowModel().rows?.length !== 0 ? <div style={{display: "flex", alignItems: "end"}}>
					<NormalPagination
						pageCount={getPageCount()}
						pageIndex={getState().pagination.pageIndex}
						pageOptions={pageOptions}
						canPreviousPage={getCanPreviousPage()}
						canNextPage={getCanNextPage()}
						previousPage={previousPage}
						nextPage={nextPage}
						gotoPage={setPageIndex}
					/>
				</div> : null}

			</div>
		</>
	)
}