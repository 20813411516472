import React, { useCallback, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as GoBack } from "../../images/goBack.svg";
import { DEFAULT_ROWS_PER_PAGE, FEEDBACK_CATEGORY_HEADER, FEEDBACK_DATE_HEADER, FEEDBACK_DETAIL_HEADER, FEEDBACK_FILE_HEADER, FEEDBACK_SATISFACTION_HEADER, FEEDBACK_STUDENT_HEADER, FEEDBACK_TABLE_POLL_TEXT, FEEDBACK_TABLE_SURVEY_TEXT, FEEDBACK_TITLE_HEADER, POLL_TYPE, TEXTBOX, TITLE_HEADER } from "../../utils/constants";
import { feedbackAnonymousToGuest, getPriority, prepareFeedbackSubrows } from "../../utils/helpers";
import styles from "./FeedBack.module.css";
import FeedbackSearchForm from "./FeedbackSearchForm";
import FeedbackTable from "./FeedbackTable";


export default function FeedbackPage(props) {
    const { pollKey, pollCode } = useParams();
    const location = useLocation();
    const history = useHistory();
    const [pageIndex, setPageIndex] = useState(0);
    const [dataReady, setDataReady] = useState(false);
    const { getAllFeedBack } = ResponseApiCalls();
    const {getPollDataFromKey} = PollApiCalls()
    const [pollData, setPollData] = useState([]);
    const isMobileScreen = useMobileAccess();
    const [filter, setFilter] = useState({});
    const [pollTitle, setPollTitle] = useState(location.state ? location.state.pollTitle : "");
    const [pollType, setPollType] = useState("");
    const [nameSort, setNameSort] = useState(1); 
    const [dateSort, setDateSort] = useState(0);
    const columns = [
        { header: FEEDBACK_STUDENT_HEADER, accessorKey: "userName", size: isMobileScreen ? 90 : 110 , id: FEEDBACK_STUDENT_HEADER },
        { header: FEEDBACK_TITLE_HEADER, accessorKey: "title", size: isMobileScreen ? 90 : 110, id: FEEDBACK_TITLE_HEADER},
        { header: FEEDBACK_SATISFACTION_HEADER, accessorKey: "satisfaction", size: isMobileScreen ? 90 : 110, id:  FEEDBACK_SATISFACTION_HEADER},
        { header: FEEDBACK_CATEGORY_HEADER, accessorKey: "category", size: isMobileScreen ? 80 : 100, id:FEEDBACK_CATEGORY_HEADER },
        { header: FEEDBACK_DATE_HEADER, accessorKey: "date", size: isMobileScreen ? 80 : 100, id: FEEDBACK_DATE_HEADER},
        { header: FEEDBACK_FILE_HEADER, accessorKey: "fileName", size: isMobileScreen ? 100 : 120, id: FEEDBACK_FILE_HEADER},
        { header: FEEDBACK_DETAIL_HEADER, accessorKey: "text", size: isMobileScreen ? 70 : 90 , id: FEEDBACK_DETAIL_HEADER},
    ];


    const sortDateHelper = () => {
        if(!!pollData) {
          if(dateSort === 0){
            setPollData([...pollData].sort(function (a, b) {
                return new Date(Date.parse(a.date)) - new Date(Date.parse(b.date));
              }));
              setDateSort(1);
            }
            else {
              setPollData([...pollData].sort(function (a, b) {
                return new Date(Date.parse(b.date)) - new Date(Date.parse(a.date));
              }));
              setDateSort(0);
            }
          }
      }
    
      const sortNameHelper = () => {
        if(!!pollData) {
            const newPollData = [...pollData].sort(function (a, b) {
                const nameA = a.userName?.toLowerCase();
                const nameB = b.userName?.toLowerCase();
    
                const priorityA = getPriority(nameA);
                const priorityB = getPriority(nameB);
    
                if (priorityA < priorityB) return -1 * nameSort;
                if (priorityA > priorityB) return 1 * nameSort;
    
                if (nameA < nameB) return -1 * nameSort;
                if (nameA > nameB) return 1 * nameSort;
                return 0;
              })
            setPollData(newPollData);
            console.log(newPollData)
            setNameSort(-nameSort);
          }
      }

    const getPollInformation = async (pageIndex=0) => {
        setDataReady(false);
        const res = await getAllFeedBack(pollKey, pollCode);
        console.log(res);
        let feedback = res.feedBack;
        feedback = feedbackAnonymousToGuest(feedback);
        feedback.sort(function (a, b) {
            return new Date(Date.parse(b.date)) - new Date(Date.parse(a.date));
          });
        setPollData(prepareFeedbackSubrows(feedback));
        setPageIndex(pageIndex);
        setDataReady(true);
        getPollDataFromKey(pollKey).then(res => {
            setPollTitle(res.pollTitle);
            setPollType(res.pollType);
        })
      };

    useEffect(() => {
        getPollInformation();
    }, [])

    const handleSubmit = useCallback(({ ...filterObj }) => {
        setFilter(filterObj);
        setPageIndex(0);
      }, []);
    
    return (
        <>
        <div style={{padding: "30px 5% 30px 5%", display: "flex", flexDirection: "column", height: "100%"}}> 
        <div style={{display: "flex", gap: "5px"}}>
        <GoBack style={{ cursor: "pointer", width: "20px", height: "20px"}} onClick={() => { history.push(`/poll/user`)}}/> 
        <div tabIndex={0} aria-label={"go back option"} style={{fontSize: "13px", color: "#000", cursor: "pointer"}} onClick={() => { history.push(`/poll/user`)}}> Back </div>
        </div>
        <div role={TEXTBOX} tabIndex={0} style={{width: "100%", fontSize: 24, fontWeight: 700, color: "#000", textAlign: "center"}}> Student Feedback </div>
        <div style={{display: "flex" , justifyContent: "center", alignItems: "center", marginTop: "15px", gap: "5px"}}>
          <div tabIndex={0} role={TEXTBOX} className={styles.pollTitleTitle} style={{ fontSize: 18 }}>{pollType.toUpperCase() === POLL_TYPE.SURVEY ? "Survey" : "Poll"} {TITLE_HEADER}:</div>
          <div tabIndex={0} role={TEXTBOX} className={styles.pollTitleText} style={{ fontSize: 18 }}>{pollTitle}</div>
        </div>
        <div role={TEXTBOX} tabIndex={0} style={{ textAlign: "center", color: "#7D7D7D", fontSize: "18px", marginTop: "16px", marginBottom: "13px" }}> {pollType.toUpperCase() === POLL_TYPE.SURVEY ? FEEDBACK_TABLE_SURVEY_TEXT : FEEDBACK_TABLE_POLL_TEXT}</div>
        <div style={{ display: "flex", justifyContent: "center" }}><FeedbackSearchForm handleSubmit={handleSubmit} /> </div>
        {dataReady && <div style={{ flex: 1}}> <FeedbackTable columns={columns} data={pollData} pageIdx={pageIndex} globalFilterObj={filter} nameSort={sortNameHelper} dateSortData={dateSort} nameSortData={nameSort} dateSort={sortDateHelper} pollKey={pollKey}/> </div>}
        {
            !dataReady &&   <div className={styles.skeletonTableContainer}> <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <div className={styles.skeletonHeaderContainer}>
              <div className={styles.skeletonHeaderCell}>{FEEDBACK_STUDENT_HEADER}</div> 
               <div className={styles.skeletonHeaderCell}>{FEEDBACK_TITLE_HEADER}</div> 
              <div className={styles.skeletonHeaderCell}>{FEEDBACK_SATISFACTION_HEADER}</div>
              <div className={styles.skeletonHeaderCell}>{FEEDBACK_CATEGORY_HEADER}</div>
              <div className={styles.skeletonHeaderCell}>{FEEDBACK_DATE_HEADER}</div>
              <div className={styles.skeletonHeaderCell}>{FEEDBACK_FILE_HEADER}</div>
              <div className={styles.skeletonHeaderCell}>{FEEDBACK_DETAIL_HEADER}</div>
            </div>
            <div className={styles.skeletonBodyContainer}>
              {Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                return (<div className={styles.skeletonBodyRow}>
                  <Skeleton borderRadius={10} height={20} inline={true} width={`${6 + Math.random()}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  <Skeleton borderRadius={10} height={20} inline={true} width={`${3 + (Math.random()*7)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  <Skeleton borderRadius={10} height={20} inline={true} width={`${idx % 2 === 0 ? 3.5 : 3}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  <Skeleton borderRadius={10} height={20} inline={true} width={`${1 + Math.random()}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  <Skeleton borderRadius={10} height={20} inline={true} width={`${2 + (Math.random()*3)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  <Skeleton borderRadius={10} height={20} inline={true} width={`${1 + (Math.random())}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  <Skeleton borderRadius={10} height={20} inline={true} width="2vw" className={styles.skeletonActionBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                </div>)
              })}
            </div>
          </SkeletonTheme>
          </div>
        }
        </div>
        </>

    )



}