import React, { useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { UserApiCalls } from "../../apis/UserApiCalls";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { CHOOSE_FILE_TEXT, CREATE_QUESTION_PROFILE_MODAL_INPUT, CREATE_QUESTION_PROFILE_MODAL_TEXT, CREATE_QUESTION_PROFILE_MODAL_TITLE, DOWNLOAD_SAMPLE_CSV_TEXT, IMPORT_QUESTION_MODAL_BODY_TEXT, IMPORT_QUESTION_MODAL_TITLE, INVALID_IMPORT_TEXT, LEARN_MORE_LINK, LEARN_MORE_TEXT, MODAL_CANCEL_TEXT, MODAL_GO_BACK_TEXT, MODAL_IMPORT_TEXT, MODAL_NEXT_TEXT, MODAL_SAVE_TEXT, POLL_TITLE_LENGTH, POLL_TYPE, QUESTION_TYPES, SAMPLE_QUESTIONS, SEARCH_BTN, TEXTBOX, UPLOAD_CSV_FILE_TEXT, UPLOAD_MEDIA, USER_SETTINGS, VALIDATE_IMPORT_TEXT } from "../../utils/constants";
import {  fillFormatText, formatFileSize, notifyError, notifySuccess, validateText } from "../../utils/helpers";
import { initialQuestionData, questionNameToCode, trimQuestion, addQuestion, questionCodeToName} from "../../utils/questionUtils";
import { IMPORT_SUCCESS_MESSAGE, INVALID_CSV_ERROR, QUESTION_BANK_CHARACTER_LIMIT_EXCEEDED, QUESTION_BANK_TITLE_ERROR, UPLOAD_ONE_FILE_ERROR } from "../../utils/toast-message-constants";
import YuJaButton from "../standardization/YuJaButton";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as UploadImage } from "../../images/upload_purple.svg";
import { ReactComponent as DeleteImage } from "../../images/feedbackFileDelete.svg";
import styles from "./QuestionProfiles.module.css";
import { YuJaTextBox } from "../standardization/YuJaTextBox";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import { updateQuestionType } from "../../utils/questionUpdate";

export default function ImportQuestionModal({setModalShow, show, pollType, questions, setQuestions, userSettings}) {
    const ref = useRef();
    const isNarrow = useMobileAccess(1100);
    const [file, setFile] = useState();
    const [next, setNext] = useState(false);
    const [fileName, setFileName] = useState(""); 
    const [fileSize, setFileSize] = useState(""); 
    const [keyword, setKeyword] = useState("");
    const [data, setData] = useState([]);
    const [importedData, setImportedData] = useState([]);
    const [validQuestions, setValidQuestions] = useState(0);
    const [invalidQuestions, setInvalidQuestions] = useState(0);

    const [array, setArray] = useState([]);

    const fileReader = new FileReader();

  const handleImport = (list) => {
    let questionsCopy = JSON.parse(JSON.stringify(questions));
    const size = questionsCopy.length;
    const lastQ = size ? questionsCopy[size - 1] : null;
    const otherQs = JSON.parse(JSON.stringify(questionsCopy.slice(0, size - 1)));
    const otherQsWDefaultQ = addQuestion(otherQs, QUESTION_TYPES.MCSS.name, userSettings);
    const otherQsWDefaultQWUpdatedQType = updateQuestionType(questionCodeToName(lastQ.questionType), size - 1, otherQsWDefaultQ, QUESTION_TYPES.MCSS.name, userSettings);
    const defaultQ = otherQsWDefaultQWUpdatedQType[size - 1];
    if (JSON.stringify(lastQ) === JSON.stringify(defaultQ)) {
      questionsCopy.pop();
    }
    let curr_questions = questionsCopy; 
    list.forEach((item) => {
      const modifiedQuestion = {};
      modifiedQuestion.queTitle = item["Question Title"];
      modifiedQuestion.questionType = questionNameToCode(item["Question Type"]);
      modifiedQuestion.timeLimit = userSettings[USER_SETTINGS.QUESTION_DURATION];
      const { optionsMap, correctAnswers } = initialQuestionData(questionNameToCode(item["Question Type"]), userSettings, false);
      modifiedQuestion.optionsMap = optionsMap; 
      modifiedQuestion.correctAnswers = correctAnswers;
       if (item.Options && item["Question Type"] === QUESTION_TYPES.MCSS.description) {
        let MCoptionsMap = {};
        const optionsArray = item.Options.split(", ").map(option => option.trim());

        optionsArray.forEach((option, index) => {
          const key = String.fromCharCode(97 + index);
          if (item.Answer && option == item.Answer) {
            modifiedQuestion.correctAnswers = [key]; 
          }
          MCoptionsMap[key] = option;
        });
        modifiedQuestion.optionsMap = MCoptionsMap;
      }
      else if (item.Answer && item["Question Type"] === QUESTION_TYPES.TF.description) {
        if (item.Answer.toLowerCase() == "true" || item.Answer.toLowerCase() == "false" ) {
          modifiedQuestion.correctAnswers = item.Answer;
        }
      }
      else if (item.Answer && item["Question Type"] === QUESTION_TYPES.MH.description) {
        modifiedQuestion.optionsMap = transformMatchingString(item.Answer);
      } 
      else if (item["Blank Pairs"] && item["Question Type"] === QUESTION_TYPES.FITB.description) {
        modifiedQuestion.correctAnswers = transformFITBString(item["Blank Pairs"]);
      } else if (item["Question Type"] === QUESTION_TYPES.RK.description && item.Options) {
        const optionsArray = item.Options.split(", ").map(option => option.trim());
        modifiedQuestion.optionsMap = optionsArray;
      }
      modifiedQuestion.weightage = (modifiedQuestion.questionType === QUESTION_TYPES.WC.name
        || modifiedQuestion.questionType === QUESTION_TYPES.RK.name || modifiedQuestion.questionType === QUESTION_TYPES.OE.name) ?
            0 :
            userSettings[USER_SETTINGS.QUESTION_POINTS];
      // modifiedQuestion.weightage = userSettings[USER_SETTINGS.QUESTION_POINTS];
      modifiedQuestion.getReadyTimeLimit = userSettings[USER_SETTINGS.QUESTION_COUNTDOWN];
      modifiedQuestion.entries = userSettings[USER_SETTINGS.QUESTION_ENTRIES];
      modifiedQuestion.serialNo = curr_questions.length + 1;
      modifiedQuestion.classResultDuration = userSettings[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION];
      curr_questions.push(modifiedQuestion);
    });
    setQuestions(curr_questions);
    notifySuccess(IMPORT_SUCCESS_MESSAGE);
    handleClose();
    };

    const transformMatchingString = (input) => {
      try {
      const cleanedInput = input.slice(1, -1);
    
      const pairs = cleanedInput.split(',');

      const result = pairs.map(pair => {
        const [premise, response] = pair.split(':');
        return response ? [premise.trim(), response.trim()] : [premise.trim()];
      });
    
      return result;
    } catch (e) {
      throw new Error("invalid format"); 
    }
    };

    const transformFITBString = (input) => {
      try {
      const cleanedInput = input.slice(1, -1);
  
      const pairs = cleanedInput.split(', ');
    
      const result = pairs.reduce((acc, pair) => {
        const [blank, answers] = pair.split(': ');
        const answersArray = answers.split('|').map(answer => answer.trim())
        acc[blank.trim()] = answersArray;
        return acc;
      }, {});
    
      return result;
    } catch (e) {
      throw new Error("invalid format"); 
    }
    };


    const handleClose = () => {
      setModalShow(false); 
      setFile("");
      setArray([]);
      setNext(false);
      setFileName("");
      setFileSize(""); 
      setImportedData([]);
      setKeyword("");
      setData([]);
      setValidQuestions(0);
      setInvalidQuestions(0);
    }

    const handleBack = () =>{
      if(!next){
        setModalShow(false); 
        setFile("");
        setFileName("");
        setFileSize(""); 
        setImportedData([]);
        setKeyword("");
        setData([]);
        setValidQuestions(0);
        setInvalidQuestions(0);
      }
       else {
        setNext(false);
        setImportedData(data);
        setKeyword("");
       }
    };

    const handleOnChange = (e) => {
      fileReader.onload = function (event) {
        const text = event.target.result;
        let validCount  = csvFileToArray(text);
        if (validCount == 0) {
          notifyError(INVALID_CSV_ERROR);
        }
        else {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        setFileSize(e.target.files[0].size);
        }
        ref.current.value = '';
      };

      fileReader.readAsText(e.target.files[0]);
    };

    const validateRows = (rows) => {
      let invalidRowCount = 0;
      const validRows = [];
      rows.forEach(row => {
        let valid = true; 
        if (!row["Question Type"] || !row["Question Title"]) {
          invalidRowCount++;
          valid = false;
        } 
        if(valid && row["Question Type"]) {
          try {
            questionNameToCode(row["Question Type"]);
          } catch (e) {
            invalidRowCount++;
            valid = false
          }
        } 
        // if (valid && row["Question Type"] && pollType.toUpperCase() === POLL_TYPE.SURVEY && row["Question Type"] === QUESTION_TYPES.MH.description) {
        //   invalidRowCount++;
        //   valid = false
        // }
        // if (valid && pollType.toUpperCase() !== POLL_TYPE.SURVEY && pollType !== POLL_TYPE.ATTENDANCE && (row["Question Type"] === QUESTION_TYPES.RK.description || row["Question Type"] === QUESTION_TYPES.WC.description) ) {
        //   invalidRowCount++;
        //   valid = false
        // }
        if (valid && row["Question Type"] === QUESTION_TYPES.MH.description) {
          try { transformMatchingString(row.Answer);
          } catch (e) {
            invalidRowCount++;
            valid = false
          }
        // } else if (valid && pollType.toUpperCase() !== POLL_TYPE.SURVEY && pollType !== POLL_TYPE.ATTENDANCE && row["Question Type"] === QUESTION_TYPES.FITB.description) {
        //   try { 
        //     transformFITBString(row["Blank Pairs"]);
        //   } catch (e) {
        //     invalidRowCount++;
        //     valid = false
        //   }
        }
        if (valid) {
          validRows.push(row);
        }
      });
      setData(validRows);
      setImportedData(validRows);
    
      const validRowCount = rows.length - invalidRowCount;
      setValidQuestions(validRowCount);
      setInvalidQuestions(invalidRowCount); 
      return validRowCount; 
    };


    const handleExportFromModal = () => {
        const fileType = "application/octet-stream";
        const fileExtension = ".csv";
        const ws = XLSX.utils.json_to_sheet(SAMPLE_QUESTIONS);
        const csvOutput = XLSX.utils.sheet_to_csv(ws);
        function s2ab(s) {
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }
    
        const data = new Blob([s2ab(csvOutput)], { type: fileType });
        FileSaver.saveAs(data, "Sample File" + fileExtension);
      };

      const dragOver = (e) => {
        e.preventDefault();
      };
    
      const dragEnter = (e) => {
        e.preventDefault();
      };
    
      const dragLeave = (e) => {
        e.preventDefault();
      };
    
      const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 1) {
          notifyError(UPLOAD_ONE_FILE_ERROR);
          return;
        }
        fileReader.onload = function (event) {
          const text = event.target.result;
          let validCount = csvFileToArray(text);
          if (validCount == 0) {
            notifyError(INVALID_CSV_ERROR)
          } else {
          setFile(files[0]);
          setFileName(files[0].name);
          setFileSize(files[0].size);
          }
        };
        fileReader.readAsText(files[0]);
      };
    
      const handleOnSubmit = (e) => {
        e.preventDefault();
    
        if (file) {
          // fileReader.onload = function (event) {
          //   const text = event.target.result;
          //   csvFileToArray(text);
          // };
    
          // fileReader.readAsText(file);
          setNext(true);
        }
        else {
          notifyError("Please upload a file.");
        }
    }

    const csvFileToArray = string => {
      const csvRows = string.split('\n');
      const csvHeader = parseCSVRow(csvRows[0]);
    
      const array = csvRows.slice(1, -1).map(row => {
        const values = parseCSVRow(row);
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {});
        return obj;
      });
    
      setArray(array);
      return validateRows(array);
    };
    
    const parseCSVRow = row => {
      const result = [];
      let inQuotes = false;
      let value = '';
    
      for (let i = 0; i < row.length; i++) {
        const char = row[i];
        if (char === '"' && row[i + 1] === '"') {
          // Handle escaped quotes within a quoted field
          value += '"';
          i++;
        } else if (char === '"') {
          // Toggle the inQuotes flag when encountering a quote character
          inQuotes = !inQuotes;
        } else if (char === ',' && !inQuotes) {
          // If not inside a quoted field, treat comma as field separator
          result.push(value);
          value = '';
        } else {
          // Otherwise, add the character to the current field
          value += char;
        }
      }
      result.push(value); // Add the last value
      return result;
    };
  


    return (
        <Modal
            id="import-question-modal"
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
        >
            <ModalClose onClick={handleClose} className="modal-close" />
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title className="modal-title" id="contained-modal-title-vcenter">
                    {IMPORT_QUESTION_MODAL_TITLE}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!next && 
              <>
                <div style={{ fontSize: 14, display: "flex", gap: 2}}> 
                    {UPLOAD_CSV_FILE_TEXT} <div style={{textDecoration: "underline", color: "#006DC7", cursor: "pointer"}}  onClick={() => window.open(LEARN_MORE_LINK)}> {LEARN_MORE_TEXT} </div>. 
                </div>
                <div style={{ fontSize: 14, display: "flex", gap: 2, marginTop: 15}}> 
                    {IMPORT_QUESTION_MODAL_BODY_TEXT} <div onClick={handleExportFromModal} style={{color: "#42296E", textDecoration: "underline", cursor: "pointer"}}>{DOWNLOAD_SAMPLE_CSV_TEXT} </div>
                </div>

                <div
                  className={"image-upload-container"}
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}
                  onClick={(e) => ref.current.click(e)}
                  style={isNarrow ? { height: "auto", marginTop: 25} : {marginTop: 25}}
                >
                  <div className="justify-content-center" style={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "0px", padding: 5 }}>
                    <UploadImage width={ 40} height={40} />
                    <p
                      tabIndex={0}
                      aria-label={UPLOAD_MEDIA}
                      role={TEXTBOX}
                      className={`${"drag-drop-text"} d-flex`}
                      style={{  width: "auto", height: "100%", margin: 0, alignItems: "center", flexWrap: "wrap" }}
                    >
                      Drag & Drop or
                      <div style={{ color: "#006DC7", textDecoration: "underline",  fontWeight: "700", marginLeft: "5px", marginRight: "5px" }}> {CHOOSE_FILE_TEXT} </div>
                      to Upload
                    </p>
                    <input
                      ref={ref}
                      className="browse-box"
                      type="file"
                      accept={".csv"}
                      onChange={handleOnChange}
                      aria-label="upload image"
                    />
                  </div>
                </div>
        {fileName && <div style={{display: "flex", gap: "5px", fontSize: "14px",  marginTop: "10px"}}> <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "600px", overflow: "hidden"}}>{fileName}</div> <div>[{formatFileSize(fileSize)}]</div> <div style={{flex: 1, display: "flex", justifyContent: "end",cursor: "pointer"}}> <DeleteImage onClick={()=> {setFile(""); setFileName(""); setFileSize("")}}/> </div></div>}
        </>
            }
            {
              next && <> 
              <div style={{ fontSize: 14, fontWeight: 400, marginBottom: "15px", color: "#000000"}}> {fillFormatText(VALIDATE_IMPORT_TEXT, {ImportCount: validQuestions != "1" ? validQuestions + " questions" : validQuestions + " question" }) + (invalidQuestions != "0" ? fillFormatText(INVALID_IMPORT_TEXT, {UnimportCount: invalidQuestions != "1" ? invalidQuestions + " questions" : invalidQuestions + " question"}) : "")} </div>
              <div className={styles.searchBox} style={{ margin: "0px 0px 20px 0px", border: "1px solid #E4E4E4", backgroundColor: "#FFFFFF", height: 60,  borderRadius: "0.461rem",  padding: "0px 15px 0px 15px", boxShadow: "0px 0px 4.5px 0px #0000001A"}}>
                <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}>
                  <YuJaTextBox
                    containerStyle={{ marginRight: 15, height: 37, fontSize:13 }}
                    placeholder="Find by question title"
                    onChange={e => setKeyword(e.target.value)}
                    value={keyword}
                    before={<SearchIcon style={{marginRight: 5, width: 16}}/>}
                  >
                  </YuJaTextBox>

                  <YuJaButton aria-label={SEARCH_BTN} role={TEXTBOX} onClick={() => {
                    setImportedData(data.filter(item => {
                      return item["Question Title"] && item["Question Title"].toLowerCase().indexOf(keyword.toLowerCase()) > -1;
                    }));
                  }}>Search</YuJaButton>
                </Form>
              </div>
              <div style={{borderRadius: "10px 10px 0px 0px", border: "0.5px solid #646464", fontSize: "13px",  color: "#000000"}}> 
              <div> 
              <th style={{minWidth: 75, padding: "0.85rem", fontWeight: 400}}> 
                No 
              </th>
              <th style={{minWidth: 145, padding: "0.85rem", fontWeight: 400}}> 
                Question Type
              </th>
              <th style={{padding: "0.85rem", fontWeight: 400}}>  
                Question Title
              </th>
              </div>
              
              {importedData.map((item, index) => (
                <>
                <tr key={item.id} style={{width: "100%", borderTop: "0.5px solid #646464", display: "flex", color: "#575757"}}>
                  <td style={{ minWidth: 75 }}> {index + 1} </td>
                  <td style={{ minWidth: 145 }}> {item["Question Type"]}</td>
                  <td style={{ flex: 1, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    {item["Question Title"]}
                  </td>
                </tr>
                </>
              ))} </div>
              </>
            }
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton type="secondary" onClick={handleBack}>{next === false ? MODAL_CANCEL_TEXT : MODAL_GO_BACK_TEXT}</YuJaButton>
                {next === false && <YuJaButton onClick={handleOnSubmit} disabled={validQuestions == 0}>{MODAL_NEXT_TEXT}</YuJaButton>}
                {next === true && <YuJaButton onClick={() => {handleImport(data)}} >{MODAL_IMPORT_TEXT}</YuJaButton>}
            </Modal.Footer>
        </Modal>
    );
}