import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import BaseQuestion from "../../questions/BaseQuestion";
import { POLL_TITLE_LENGTH, QUESTION_TYPES, QUESTION_BANK_TITLE_PLACEHOLDER, UPDATE_CLOSE_BUTTON_TEXT, SAVE_CLOSE_BUTTON_TEXT, UPDATE_CLOSE_QUESTION_BANK_TOOLTIP } from "../../utils/constants";
import "../CreateQuizPage/styles.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function QuestionBankMain({
                                        title,
                                        setTitle,
                                        isEditPage = false,
                                        questions,
                                        setQuestions,
                                        savePoll,
                                        savePollClose,
                                        institutionId,
                                        currQueDisplay,
                                        changeCurrQueDisplay,
                                        saveButtonDisabled,
                                        questionBankId,
                                        isSaving,
                                        isBank=false,
                                        hasBlankRestriction,
                                        dataloading=false
                                      }) {


  const pollTitleLimit = POLL_TITLE_LENGTH;

  useEffect(() => {
      let questionsWithoutAnswers = questions;
      questionsWithoutAnswers.forEach((question) => {
        if (question.questionType === QUESTION_TYPES.MCSS.name || question.questionType === QUESTION_TYPES.TF.name || question.questionType === QUESTION_TYPES.SA.name) {
          question.correctAnswers = [];
        }
      })
      setQuestions(questionsWithoutAnswers);
   }, []);

   const saveButtonStyle = {
    fontSize: 16,
    width: "208px", 
    height: "35px" , 
    padding: "7.941px", 
    borderRadius: "4.21px", 
    border: "1.336px solid #42296E", 
    justifyContent: "center", 
    alignItems: "center",
    display: "flex",
  };

  return (
    <div style={{display: 'flex', flexDirection: "column", height: "100%"}}>
        <Row style={{marginTop: "36px"}}>
          <Col className="quiz-title-col margin-vertical-auto no-padding ">
          {!dataloading &&
              <YuJaTextBox
                  containerStyle={{fontSize: "25px",  width: "auto", padding: 12, fontWeight: 700, borderRadius: 9}}
                  inputAlign={"center"}
                  type="text"
                  name={"pollTitle"}
                  placeholder={QUESTION_BANK_TITLE_PLACEHOLDER}
                  value={title}
                  onChange={(e) => {
                      if (e.target.value.length <= pollTitleLimit) {
                          setTitle(e.target.value);
                      } else {
                          setTitle(e.target.value.substring(0, POLL_TITLE_LENGTH));
                      }
                  }}
              />
                }
              {
                  dataloading && <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                  <Skeleton borderRadius={10} height={49} style={{ position: "relative" }} />
                </SkeletonTheme>
                }
          </Col>
        </Row>

      <BaseQuestion
        questions={questions}
        setQuestions={setQuestions}
        currQueDisplay={currQueDisplay}
        changeCurrQueDisplay={changeCurrQueDisplay}
        selectedQuestion={questions[currQueDisplay-1]}
        pollType={"Graded Poll"}
        institutionId={institutionId}
        pollKey={""}
        questionBankId={questionBankId}
        isSaving={isSaving}
        isBank={isBank}
        hasBlankRestriction={hasBlankRestriction}
        dataloading={dataloading}
      />

      <div style={{ display: "flex", justifyContent: "center", margin: "10px 0" }}>
        <YuJaButton title={UPDATE_CLOSE_QUESTION_BANK_TOOLTIP} onClick={() => savePollClose(true)} style={saveButtonStyle } disabled={saveButtonDisabled}>
          {isEditPage ? UPDATE_CLOSE_BUTTON_TEXT : SAVE_CLOSE_BUTTON_TEXT}
        </YuJaButton>
      </div>
    </div>
  );
}