import {useFetchWrapper} from "../hooks/useFetchWrapper";
import {REPORT_API_URL} from "../utils/properties";

export const ReportApiCalls = () => {
    const fetchWrapper = useFetchWrapper();

    return {
        getInstitutionWiseReport,
        getStandaloneWiseReport,
        getCourseWiseReport,
        getActivityWiseReport,
    };

    function getInstitutionWiseReport(startMonth, endMonth) {
        return fetchWrapper.get({
            url: `${REPORT_API_URL}/report/instReport?startMonth=${startMonth}&endMonth=${endMonth}`,
        }).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
            return {};
        });
    }

    function getStandaloneWiseReport(startMonth, endMonth) {
        return fetchWrapper.get({
            url: `${REPORT_API_URL}/report/standaloneReport?startMonth=${startMonth}&endMonth=${endMonth}`,
        }).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
            return {};
        });
    }

    function getCourseWiseReport(courseId, startMonth, endMonth) {
        return fetchWrapper.get({
            url: `${REPORT_API_URL}/report/${courseId}/courseReport?startMonth=${startMonth}&endMonth=${endMonth}`,
        }).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
            return {};
        });
    }


    function getActivityWiseReport(pollKey, curMonth) {
        return fetchWrapper.get({
            url: `${REPORT_API_URL}/report/${pollKey}/pollReport?curMonth=${curMonth}`,
        }).then((res) => {
            return res;
        }).catch(err => {
            console.log(err);
            return {};
        });
    }
}