import {
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { LTIApiCalls } from "../../apis/LTIApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import "../../components/modals/modal.css";
import NormalPagination from "../../components/pagination/NormalPagination";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { YuJaTooltip } from "../../components/standardization/YuJaTooltip";
import useHostname from "../../hooks/useHostname";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as ArrowDown } from "../../images/YuJa_arrow_down_icon.svg";
import { ReactComponent as ArrowUp } from "../../images/YuJa_arrow_up_icon.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
	ABSENT_STATUS,
	ABSENT_TEXT,
	ACCURACY,
	ATMPT_LABEL_2,
	ATTEMPT_REGEX,
	ATTENDANCE_DETAILS_COLUMN_ID,
	ATTENDANCE_DETAILS_COLUMN_NAME,
	ATTENDANCE_STATUS_COLUMN_ID,
	ATTENDANCE_STATUS_COLUMN_NAME,
	ATTENDANCE_TITLE_COLUMN_ID,
	ATTENDANCE_TITLE_COLUMN_NAME,
	CHECKBOX_COLUMN_ID,
	DATE_COLUMN_ID,
	DATE_COLUMN_NAME,
	DEFAULT_ATTENDANCE_ROWS_PER_PAGE,
	FIXED_ANSWER,
	GRADE_ATTENDANCE_TOOLTIP_TEXT,
	LOCATION,
	NO_RESPONSE,
	NO_RESULTS_FOUND,
	POLL_TITLE_DROPDOWN_LENGTH,
	POLL_TYPE,
	PRESENT_STATUS,
	PRESENT_TEXT,
	RESPONSE,
	SCORE_COLUMN_ID,
	SERIAL_NO_COLUMN_ID,
	SERIAL_NO_COLUMN_NAME,
	STD_LABEL,
	STUDENT_COLUMN_ID,
	STUDENT_COLUMN_INDEX,
	STUDENT_COLUMN_NAME,
	TEXTBOX,
	USER_ID_COLUMN_ID,
	USER_ID_COLUMN_NAME
} from "../../utils/constants";
import { getLocationGBDisplay, isValidScore, notifyError, notifySuccess } from "../../utils/helpers";
import { ENTER_VALID_SCORES, GRADEBOOK_SYNC_SUCCESS, GRADE_ATTENDANCE_SUCCESS, SELECT_AT_LEAST_ONE_STUDENT } from "../../utils/toast-message-constants";
import styles from "./GradeBook.module.css";

function IndeterminateCheckbox({
	indeterminate,
	className = '',
	...rest
}) {
	const ref = useRef();

	useEffect(() => {
	  if (typeof indeterminate === 'boolean') {
		ref.current.indeterminate = !rest.checked && indeterminate;
	  }
	}, [ref, indeterminate]);

	return (
		<input
			type="checkbox"
			ref={ref}
			className={className + ' indeterminate-checkbox'}
			{...rest}
			aria-label="checkbox"
		/>
	)
}

export default function AttendanceGradeBookTabs({
	responses = [],
	setResponses=undefined,
	// questions = [],
	// pollKey=undefined,
	// pollCode=undefined,
	// pollTitle=undefined,
	pollType=undefined,
	// lmsAttempt="",
	syncMode=0,
	setSyncMode=undefined,
	showCheckbox=true,
	// autoSyncData={},
	// setAutoSyncData=undefined,
	dataLoading=false,
	// setDataLoading,
	globalFilterObj=undefined,
	// pageIdx=0,
	// geofence=undefined,
	attendanceFilter=null
}) {
	// const history = useHistory();
	const hostResource = useHostname(window.location.hostname);
	const {institutionId = ""} = hostResource;
	const [customScoresMap, setCustomScoresMap] = useState({});
	const [syncCountMap, setSyncCountMap] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(-1);
	const { gradeQuestion, syncScore } = ResponseApiCalls();
	const { gradebookSync } = LTIApiCalls();
	const { setLoading } = useLoading();
	const skeletonLoaderWidth = ["50px", "40px", "60px", "30px", "50px"];
	const [globalFilter, setGlobalFilter] = useState({});
	const [showGradeOptions, setShowGradeOptions] = useState({});
	const [selectedRow, setSelectedRow] = useState(null);
    const [isSubrowclosing, setIsSubrowClosing] = useState(false);

    const ATTENDANCE_GRADE_BOOK_COLUMNS = [
		{
			accessorKey: CHECKBOX_COLUMN_ID,
			id: CHECKBOX_COLUMN_ID,
			header: () => {
				return (showCheckbox ?
					<IndeterminateCheckbox
						className="form-check-input"
						checked={getIsAllRowsSelected()}
						indeterminate={getIsSomeRowsSelected()}
						onChange={getToggleAllRowsSelectedHandler()}
					/> : <></>
				);
			},
			cell: ({ row }) => {
				// console.log(row, row.getAllCells(), row.getAllCells()[STUDENT_COLUMN_INDEX], row.getAllCells()[STUDENT_COLUMN_INDEX].getValue());
				return (!ATTEMPT_REGEX.test(row.getAllCells()[STUDENT_COLUMN_INDEX].getValue()) && showCheckbox ?
					<IndeterminateCheckbox
						className="form-check-input"
						checked={row.getIsSelected()}
						// disabled={!row.getCanSelect()}
						indeterminate={row.getIsSomeSelected()}
						onChange={row.getToggleSelectedHandler()}
					/> : <></>
				);
			},
			size: 25
		},
		{
			header: SERIAL_NO_COLUMN_NAME,
			accessorKey: SERIAL_NO_COLUMN_ID,
			enableSorting: false,
			id: SERIAL_NO_COLUMN_ID,
			size: 25
		},
		{
			header: DATE_COLUMN_NAME,
			accessorKey: DATE_COLUMN_ID,
			enableSorting: true,
			sortingFn: (rowA, rowB, _) => {
				const dateAParts = rowA.original.dateTitle.split("-");
				const dateBParts = rowB.original.dateTitle.split("-");
				// console.log(new Date(+dateAParts[2], dateAParts[1] - 1, +dateAParts[0]), new Date(+dateBParts[2], dateBParts[1] - 1, +dateBParts[0]));
				return new Date(+dateAParts[2], dateAParts[1] - 1, +dateAParts[0]) - new Date(+dateBParts[2], dateBParts[1] - 1, +dateBParts[0]);
			},
			id: DATE_COLUMN_ID,
			size: 75
		},
		{
			header: STUDENT_COLUMN_NAME,
			accessorFn: row => `${row.firstName}${row.lastName ? ' ' + row.lastName : ''}`,
			enableSorting: true,
			sortingFn: 'alphanumeric',
			id: STUDENT_COLUMN_ID,
			size: 175
		},
		{
			header: ATTENDANCE_TITLE_COLUMN_NAME,
			accessorKey: ATTENDANCE_TITLE_COLUMN_ID,
			enableSorting: true,
			sortingFn: 'alphanumeric',
			id: ATTENDANCE_TITLE_COLUMN_ID,
			size: 175
		},
		{
			header: ATTENDANCE_STATUS_COLUMN_NAME,
			accessorKey: ATTENDANCE_STATUS_COLUMN_ID,
			enableSorting: false,
			id: ATTENDANCE_STATUS_COLUMN_ID,
			size: 100
		},
		{
			header: ATTENDANCE_DETAILS_COLUMN_NAME,
			accessorKey: ATTENDANCE_DETAILS_COLUMN_ID,
			enableSorting: false,
			size: 50,
			id: ATTENDANCE_DETAILS_COLUMN_ID
		},
		{
			header: USER_ID_COLUMN_NAME,
			accessorKey: USER_ID_COLUMN_ID,
			id: USER_ID_COLUMN_ID
		}, // not shown
	];

	const columns = useMemo(() => {
        // if (!!geofence && JSON.stringify(geofence) !== "") {
        // 	ATTENDANCE_GRADE_BOOK_COLUMNS.splice(2, 0, {
        // 		header: GEOFENCE_COLUMN_NAME,
        // 		accessorKey: GEOFENCE_COLUMN_NAME,
        // 		id: GEOFENCE_COLUMN_NAME,
        // 		size: 150
        // 	});
        // }
        return ATTENDANCE_GRADE_BOOK_COLUMNS;
	}, [ATTENDANCE_GRADE_BOOK_COLUMNS]); // pollType, geofence

    const pageOptions = useMemo(() => {
        let options = [];
        // console.log(responses.length, DEFAULT_ATTENDANCE_ROWS_PER_PAGE);
        let nPages = Math.ceil(responses.length / DEFAULT_ATTENDANCE_ROWS_PER_PAGE);
        // console.log(nPages);
        for (let i = 0; i < nPages; i++) options.push(i);
        return options;
    }, [responses]);

	const filterCustomize = (r, _, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return true;
        }
		
        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase().trim() : "";
		const filteredStatus = globalFilterValue.status ? globalFilterValue.status : "";
		// console.log(filteredKeyword, globalFilterValue);
		if (!filteredKeyword && !filteredStatus) {
			return true;
		}

		const status = r.getValue(ATTENDANCE_STATUS_COLUMN_ID);
		if (filteredStatus && filteredStatus !== 'all' && status !== filteredStatus) {
			return false;
		}

		if (!attendanceFilter) {
			const attendanceTitle = r.getValue(ATTENDANCE_TITLE_COLUMN_ID);
			if (attendanceTitle && attendanceTitle.toLowerCase().indexOf(filteredKeyword) > -1) {
				return true;
			}
		}
		
		const participantName = r.getValue(STUDENT_COLUMN_ID)
		if (participantName && participantName.toLowerCase().indexOf(filteredKeyword) > -1) {
			return true;
		}

		return false;
    };

	const {
		getHeaderGroups,
		getRowModel,
		setPageIndex,
		getCanPreviousPage,
		previousPage,
		getCanNextPage,
		nextPage,
		getPageCount,
		getState,
		getSelectedRowModel,
		getIsAllRowsSelected,
		getIsSomeRowsSelected,
		getToggleAllRowsSelectedHandler,
		setPageSize
	} = useReactTable({
		columns: columns,
		data: responses,
		state: {
            globalFilter: globalFilter,
		},
		maxLeafRowFilterDepth: 0,
		paginateExpandedRows: false,
		autoResetPage: true,
		enableSortingRemoval: false,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getSubRows: (row) => {
			return row.subRows;
		},
		globalFilterFn: filterCustomize,
		onGlobalFilterChanged: setGlobalFilter,
	});

	useEffect(() => {
		setPageSize(DEFAULT_ATTENDANCE_ROWS_PER_PAGE);
		// console.log(responses);
		let tempScoresMap = {};
		let tempSyncCountMap = {};
		responses.map(r => {
			tempSyncCountMap[r.pollKey + "#" + r.uniqueCode] = tempSyncCountMap[r.pollKey + "#" + r.uniqueCode] || {};
			tempSyncCountMap[r.pollKey + "#" + r.uniqueCode][r.userId] = r.syncCount;
			tempScoresMap[r.pollKey + "#" + r.uniqueCode] = tempScoresMap[r.pollKey + "#" + r.uniqueCode] || {};
			tempScoresMap[r.pollKey + "#" + r.uniqueCode][r.userId] = r.attendance === PRESENT_STATUS ? "100.00" : "0.00";
		});

		console.log(tempScoresMap);
		console.log(tempSyncCountMap);
		setCustomScoresMap(tempScoresMap);
		setSyncCountMap(tempSyncCountMap);
	}, [responses]);

	// useEffect(() => {
	// 	if (!!autoSyncData && !!autoSyncData.userId) {
	// 		console.log("Auto Syncing...", pollCode, autoSyncData.userId, responses[responses.findIndex(r => r.userId === autoSyncData.userId)].attempts[0].score);
	// 		autoSync(pollCode, autoSyncData.userId, responses[responses.findIndex(r => r.userId === autoSyncData.userId)].attempts[0].score);
	// 		setAutoSyncData({});
	// 	} else if (!!autoSyncData && !!autoSyncData.userIds) {
	// 		for (const id of autoSyncData.userIds) {
	// 			autoSync(pollCode, id , responses[responses.findIndex(r => r.userId === id)].attempts[0].score);
	// 		}
	// 		setAutoSyncData({});
	// 	}
	// }, [autoSyncData]);

	useEffect(() => {
		const syncWrapper = async () => {
			if (syncMode === 0) { // no need to sync
				return;
			}
			setLoading(true);
			if (syncMode === 1) { // student sync
				const selectedResponses = getSelectedRowModel().flatRows.filter(row => row.depth === 0).map(row => row.original);
				// console.log(selectedResponses);
				if (selectedResponses.length === 0) {
					notifyError(SELECT_AT_LEAST_ONE_STUDENT);
				} else {
					const res = await saveSelectedStudents(selectedResponses);
					if (res) {
						notifySuccess(GRADEBOOK_SYNC_SUCCESS);
					} else {
						notifyError(ENTER_VALID_SCORES);
					}
				}
			// } else if (syncMode === 2) { // quiz sync
			// 	let students = customScoresMap;
			// 	if (students.length === 0) {
			// 		notifyError(NO_STUDENTS_FOUND);
			// 	} else {
			// 		const res = await saveAllStudents(students);
			// 		if (res) {
			// 			notifySuccess(GRADEBOOK_SYNC_SUCCESS);
			// 		} else {
			// 			notifyError(ENTER_VALID_SCORES);
			// 		}
			// 	}
			}
			setSyncMode(0);
			setLoading(false);
		};

		syncWrapper();
    }, [syncMode]);

	const autoSync = (pollKey, pollCode, userId, score) => {
		console.log(pollCode, userId, score, syncCountMap, syncCountMap[pollKey + "#" + pollCode][userId]);
		if (syncCountMap[pollKey + "#" + pollCode][userId] > 0) {
			gradebookSync(
				pollCode,
				userId,
				score,
				// () => syncScore(pollKey, pollCode, userId, true)
			);
		}
	};

	const syncStudent = async (pollKey, pollCode, userId, score) => {
		if (!pollCode) {
			console.log("Invalid pollCode");
			return false;
		}
		if (!userId) {
			console.log("Invalid userId");
			return false;
		}
		if (!isValidScore(score)) {
			console.log("Invalid score");
			return false;
		}
		const res = await gradebookSync(
			pollCode,
			userId,
			score,
			() => {
				syncScore(pollKey, pollCode, userId, true, true);
				setResponses(responses.map(r => {
					if (r.userId === userId && r.pollKey === pollKey) {
						r.syncCount = r.syncCount + 1;
					}
					return r;
				}));
				// console.log("incrementing syncCountMap", pollKey + "#" + pollCode, userId, syncCountMap[pollKey + "#" + pollCode][userId] + 1);
				// setSyncCountMap({ ...syncCountMap, [pollKey + "#" + pollCode]: { ...syncCountMap[pollKey + "#" + pollCode], [userId]: syncCountMap[pollKey + "#" + pollCode][userId] + 1 } });
			}
		);
		return res;
	};

	async function saveSelectedStudents (selectedResponses) {
		if (selectedResponses.length === 0) { // base case
			return true;
		}
		const selectedResponse = selectedResponses.pop();
		// console.log(selectedResponse);
		const { pollKey, uniqueCode, userId } = selectedResponse;
		// console.log(pollKey, uniqueCode, userId);
		// console.log(customScoresMap[selectedResponse.pollKey + "#" + selectedResponse.uniqueCode][selectedResponse.userId]);
		if (!isValidScore(customScoresMap[pollKey + "#" + uniqueCode][userId])) { // invalid score
			console.log("Invalid score", customScoresMap[pollKey + "#" + uniqueCode][userId], "for", pollKey + "#" + uniqueCode, userId);
			// return false;
		} else {
			const res = await syncStudent(pollKey, uniqueCode, userId, customScoresMap[pollKey + "#" + uniqueCode][userId]);
			if (res) { 
				console.log("Synced", customScoresMap[pollKey + "#" + uniqueCode][userId], "for", pollKey + "#" + uniqueCode, userId);
			} else {
				console.log("Error in Gradebook Sync for", pollKey + "#" + uniqueCode, userId);
			}
		}
		return await saveSelectedStudents(selectedResponses);
	};
	
	// const saveAllStudents = async (responses) => {
	// 	// console.log(students);
	// 	for (let pollKeyCode in responses) {
	// 		for (let student in responses[pollKeyCode]) {
	// 			if (!isValidScore(responses[pollKeyCode][student])) {
	// 				console.log("Invalid score", responses[pollKeyCode][student], "for", pollKeyCode, student);
	// 				continue;
	// 				// return false;
	// 			}
	// 			const pollKey = pollKeyCode.substring(0, pollKeyCode.indexOf("#"));
	// 			const pollCode = pollKeyCode.substring(pollKeyCode.indexOf("#") + 1);
	// 			const res = await syncStudent(pollKey, pollCode, student, responses[pollKeyCode][student][0]);
	// 			if (res) {
	// 				console.log("Synced", responses[pollKeyCode][student], "for", pollKeyCode, student);
	// 			} else {
	// 				console.log("Error in Gradebook Sync for", pollKeyCode, student);
	// 			}
	// 		}
	// 	}
	// 	return true;
	// };

	useEffect(() => {
		setGlobalFilter(globalFilterObj);
		setPageIndex(0);
		if (selectedRow) {
			selectedRow.getToggleExpandedHandler()();
			setSelectedRow(null);
		}
	}, [globalFilterObj, responses]);

	const toggleGradeOption = (pollKey, userId) => {
		console.log(pollKey, userId);
		// setShowGradeOptions({ ...showGradeOptions, [pollKey]: { ...showGradeOptions[pollKey], [userId]: !(showGradeOptions[pollKey] && showGradeOptions[pollKey][userId]) } }); // for multiple dropdowns
		setShowGradeOptions({ [pollKey]: { [userId]: !(showGradeOptions[pollKey] && showGradeOptions[pollKey][userId]) } });
	};

	const gradeResponse = async (pollKey, pollCode, userId, isCorrect) => {
		console.log(pollKey, pollCode, userId, isCorrect);
		await gradeQuestion(institutionId, pollKey, pollCode, userId, isCorrect, {serialNo: 1}, 1);
		setResponses(responses.map(r => {
			if (r.userId === userId && r.pollKey === pollKey) {
				r.score = isCorrect ? "100.00" : "0.00";
				r.attendance = isCorrect ? PRESENT_STATUS : ABSENT_STATUS;
			}
			return r;
		}));
		setShowGradeOptions({ ...showGradeOptions, [pollKey]: { ...showGradeOptions[pollKey], [userId]: false } });
		notifySuccess(GRADE_ATTENDANCE_SUCCESS);
		autoSync(pollKey, pollCode, userId, isCorrect ? "100.00" : "0.00");
	};

	const expandDetail = (e, row) => {
		e.stopPropagation();
		console.log("expand detail", row);
		if (!!selectedRow && selectedRow.id !== row.id) {
			console.log("same id");
			selectedRow.getToggleExpandedHandler()();
		}
		row.getToggleExpandedHandler()();
		setSelectedRow(row);
	};
	
	const collapseDetail = (e, row) => {
		e.stopPropagation();
		// console.log("collapse detail", row);
		setIsSubrowClosing(true);
		setTimeout(() => {
			row.getToggleExpandedHandler()();
			setIsSubrowClosing(false)
		}, 400);
		setSelectedRow(null);
	};

	return (
		<>
			<div
				className={`
					${styles.tableContainer}
					${showCheckbox ?
						styles.gradeBookTableContainerAttendanceWCheckbox :
						styles.gradeBookTableContainerAttendanceWoCheckbox
					}
				`}
			>
				<Table
					responsive
					hover
					// {...getTableProps()}
				>
				{/* <table> */}
					<thead>
					{getHeaderGroups().map(headerGroup => (
						<tr
							// {...headerGroup.getHeaderGroupProps()}
							key={headerGroup.id}
						>
							{headerGroup.headers.map(column => {
								// console.log(column);
								if (column.id === USER_ID_COLUMN_ID) { // Remove block to show User ID Header
									// console.log(column.getValue());
								} else if (column.id === CHECKBOX_COLUMN_ID) {
									if (!showCheckbox || dataLoading) return null;
									// console.log(column.column.columnDef.header, column.getContext());
									return (
										<th {...{
											// ...column.getHeaderProps(),
											key: column.id,
											style: {width: column.getSize(), backgroundClip: "padding-box"}
										}}>
											{flexRender(column.column.columnDef.header, column.getContext())}
										</th>
									);
								} else {
									// console.log(column.column.columnDef.header, column.getContext());
									return (
										<th tabIndex={0} {...{
											// ...column.getHeaderProps(),
											key: column.id,
											style: {width: column.getSize(), backgroundClip: "padding-box"}
										}}>
											{flexRender(column.column.columnDef.header, column.getContext())}
											{column.column.getCanSort() ? (
												column.column.getIsSorted() ? (column.column.getNextSortingOrder() === "asc" ?
													<SortDown tabIndex={0} role={TEXTBOX} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={column.column.getToggleSortingHandler()} /> :
													<SortUp tabIndex={0} role={TEXTBOX} aria-label={column.id + "ascending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="ascending" onClick={column.column.getToggleSortingHandler()} />
												) : 
													<SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.id + "descending order"} style={{ width: "15px", cursor: "pointer", marginLeft: "10px" }} title="descending" onClick={column.column.getToggleSortingHandler()} /> 
											) : null}
										</th>
									);
								}
							})}
						</tr>
					))}
					</thead>
					<tbody
						// {...getTableBodyProps()}
					>
					{!dataLoading && (getRowModel().rows?.length === 0 ? <tr><td colSpan={columns.length - (pollType.toUpperCase() === POLL_TYPE.SURVEY || pollType === POLL_TYPE.ATTENDANCE ? 2 : 1)}><div style={{height: 450, justifyContent: "center", display: "flex", marginTop: "20px", flexDirection: "column", alignItems: "center", gap: "5px"}}> <NoResultIllustration /> <div style={{fontSize: "20px",  marginTop: "10px", fontWeight: 600}}> {NO_RESULTS_FOUND} </div> </div></td></tr> : getRowModel().rows.map((row, i) => {
						// console.log(i);
						// prepareRow(row);
						// console.log(i, row, row.getAllCells());
						let subRowData;
						let selectedOption;
						let locationData;
						let locationDisplay;
						let locationAccuracy;
						if (row.depth === 0) {
							subRowData = null;
							selectedOption = null;
							locationData = null;
						} else {
							subRowData = row.original;
							selectedOption = subRowData.selectedOption && subRowData.selectedOption !== `"${FIXED_ANSWER.UNANSWERED}"` ?
								JSON.parse(row.original.selectedOption)[0] :
								NO_RESPONSE;
							locationData = subRowData.locationData ? JSON.parse(subRowData.locationData) : null;
							if (locationData) {
								locationDisplay = getLocationGBDisplay(locationData.message);
								locationAccuracy = locationData.accuracy ? `${parseFloat(locationData.accuracy).toFixed(2)} m` : null;
							}
							// console.log(subRowData);
							// console.log(selectedOption);
							// console.log(locationData);
							// console.log(locationDisplay);
							// console.log(locationAccuracy);
						}
						return (<>
							<tr
								key={row.id}
								// onClick={row.getToggleExpandedHandler()}
								onMouseEnter={() => setHoveredRow(row.id)}
								onMouseLeave={() => setHoveredRow(-1)}
								// {...row.getRowProps()}
								// {...getToggleRowExpandedPropsWrapper(row)}
								// className={!row.getCanExpand() && styles.gradebookTableRow}
							>
								{row.depth === 0 && row.getVisibleCells().map(cell => {
									if (cell.column.id === SERIAL_NO_COLUMN_ID) {
										return <td tabIndex={0} role={TEXTBOX} {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box", height: "fit-content"}}}>{row.index + 1}</td>
									} else if (cell.column.id === ATTENDANCE_STATUS_COLUMN_ID) {
										// console.log(cell.row.original.pollKey, cell.row.original.userId);
										return <td
											{...{ key: cell.id, style: { width: cell.column.getSize(), backgroundClip: "padding-box" } }}
											className={styles.gradeBookAttendanceStatus}
											// onMouseDown={e => e.preventDefault()} onClick={() => showGradeAttendanceModal(row)}
										>
											<div className={styles.attendanceStatusContainer}>
												{JSON.stringify(showGradeOptions) !== "{}" && showGradeOptions[cell.row.original.pollKey] && showGradeOptions[cell.row.original.pollKey][cell.row.original.userId] ? <>
													{/* {cell.getValue() === PRESENT_STATUS ?
														<div tabIndex={0} role={TEXTBOX} aria-label={PRESENT_STATUS} className={styles.gradeBookCorrectDropdown} onClick={() => toggleGradeOption(cell.row.original.pollKey, cell.row.original.userId)}>
															{PRESENT_TEXT}
														</div> :
														<div tabIndex={0} role={TEXTBOX} aria-label={ABSENT_STATUS} className={styles.gradeBookIncorrectDropdown} onClick={() => toggleGradeOption(cell.row.original.pollKey, cell.row.original.userId)}>
															{ABSENT_TEXT}
														</div>
													}
													{cell.getValue() === ABSENT_STATUS ?
														<div tabIndex={0} role={TEXTBOX} aria-label={PRESENT_STATUS} className={styles.gradebookCorrectDropdownOption} onClick={() => gradeResponse(cell.row.original.pollKey, cell.row.original.uniqueCode, cell.row.original.userId, true)}>
															{PRESENT_TEXT}
														</div> :
														<div tabIndex={0} role={TEXTBOX} aria-label={ABSENT_STATUS} className={styles.gradebookIncorrectDropdownOption} onClick={() => gradeResponse(cell.row.original.pollKey, cell.row.original.uniqueCode, cell.row.original.userId, false)}>
															{ABSENT_TEXT}
														</div>
													} */}
													<YuJaDropdown
														data={cell.getValue() === PRESENT_STATUS ? [ABSENT_STATUS, PRESENT_STATUS] : [PRESENT_STATUS, ABSENT_STATUS]}
														getOptionLabel={option => option === PRESENT_STATUS ? PRESENT_TEXT : ABSENT_TEXT}
														getOptionValue={option => option}
														value={cell.getValue()}
														textStyle={{ wordBreak: "break-word" }}
														onChange={(option) => {
															if (option === cell.getValue()) {
																toggleGradeOption(cell.row.original.pollKey, cell.row.original.userId);
																return;
															} else {
																gradeResponse(cell.row.original.pollKey, cell.row.original.uniqueCode, cell.row.original.userId, option === PRESENT_STATUS);
															}
														}}
														style={{
															fontSize: 14, padding: 10, gap: 5, height: "34px", borderRadius: 5, borderColor: "#909090", minWidth: 100
														}}
														defaultOpen={true}
														setOpen={open => setShowGradeOptions({ ...showGradeOptions, [cell.row.original.pollKey]: { [cell.row.original.userId]: open } })}
													/>
												</> :
													<YuJaTooltip
														text={GRADE_ATTENDANCE_TOOLTIP_TEXT}
														placement="top"
														tooltipStyle={{ maxWidth: 400 }}
													>
														{cell.getValue() === PRESENT_STATUS ?
															<div
																tabIndex={0}
																role={TEXTBOX}
																aria-label={PRESENT_STATUS}
																className={styles.gradeBookCorrect}
																onClick={e => {
																	e.stopPropagation();
																	toggleGradeOption(cell.row.original.pollKey, cell.row.original.userId);
																}}
															>
																{PRESENT_TEXT}
															</div> :
															<div
																tabIndex={0}
																role={TEXTBOX}
																aria-label={ABSENT_STATUS}
																className={styles.gradeBookIncorrect}
																onClick={e => {
																	e.stopPropagation();
																	toggleGradeOption(cell.row.original.pollKey, cell.row.original.userId);
																}}
															>
																{ABSENT_TEXT}
															</div>
														}
													</YuJaTooltip>
												}
											</div>
										</td>
									} else if (cell.column.id === USER_ID_COLUMN_ID) { // Remove block to show User ID
										// console.log(cell.getValue());
									} else if (cell.column.id === CHECKBOX_COLUMN_ID) {
										if (!showCheckbox) return null;
										return (
											<td
												className={hoveredRow === row.id ? styles.hoveredRow : ''}
												{...{ key: cell.id, style: { width: cell.column.getSize(), backgroundClip: "padding-box" } }}
											>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										);
									} else if (cell.column.id === STUDENT_COLUMN_ID) {
										// console.log(cell.column.columnDef.cell, cell.getValue());
										return (
											<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
												<div
												 aria-label={cell.getValue().includes("Attempt") ? (cell.getValue() + ATMPT_LABEL_2) : (STD_LABEL + cell.getValue())} className={ATTEMPT_REGEX.test(cell.getValue()) ? styles.subRow : styles.mainRow} style={{display: "flex", justifyContent: "left"}}
												>
													<div tabIndex={0} role={TEXTBOX} style={{width: "fit-content"}}>{flexRender(cell.column.columnDef.cell, cell.getContext())} </div>
												</div>
											</td>
										);
									} else if (cell.column.id === ATTENDANCE_DETAILS_COLUMN_ID) {
										return (
											<td> 
												<div style={{ display: "flex", justifyContent: "left" }} aria-label="detail drop down">
													{row.getIsExpanded() ?
														<div
															className={styles.detailDropdownSelected}
															style={{ width: 80, cursor: "pointer" , backgroundColor: "#42296E" }}
															onClick={e => collapseDetail(e, row)}
															tabIndex={0}
														>
															<div style={{ color: "#FFF", fontSize: 12 }}>
																{ATTENDANCE_DETAILS_COLUMN_NAME}
															</div>
															<ArrowUp style={{ width: 15, height: 15, cursor: "pointer" }} />
														</div> :
														<div
															className={styles.detailDropdown}
															style={{ width: 80, cursor: "pointer" }}
															onClick={e => expandDetail(e, row)}
															tabIndex={0}
														>
															<div style={{ color: "#42296E", fontSize: 12 }}>
																{ATTENDANCE_DETAILS_COLUMN_NAME}
															</div>
															<ArrowDown style={{ width: 15, height: 15, cursor: "pointer" }} />
														</div>
													}
												</div>
											</td>
										)
									} else {
										// console.log(cell);
										return (
											<td
												tabIndex={0}
												title={cell.column.id === ATTENDANCE_TITLE_COLUMN_ID && !!cell.getValue() && cell.getValue().length > POLL_TITLE_DROPDOWN_LENGTH ? cell.getValue() : ""}
												{...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}
											>
												{cell.column.id === ATTENDANCE_TITLE_COLUMN_ID && !!cell.getValue() && cell.getValue().length > POLL_TITLE_DROPDOWN_LENGTH ? (cell.getValue().slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : cell.getValue()}
											</td>
										);
									}
								})}
								{row.depth !== 0 &&
									<td colSpan={showCheckbox ? 7 : 6} className={`${styles.subrow} ${isSubrowclosing ? styles.subrowClosing : ""}`}>
										<div tabIndex={0} role={TEXTBOX} aria-label={"attendance detail"} style={{ height: "100%", width: "100%", textAlign: "center", wordBreak: "break-word", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
											{/* <div style={{ textAlign: "left", wordBreak: "break-word", width: "80%" }}>
												{JSON.stringify(subRowData)}
											</div> */}
											<div style={{ textAlign: "left", wordBreak: "break-word", width: "80%", display: "flex", flexDirection: "row" }}>
												<div>
													{RESPONSE}: {selectedOption}
												</div>
												{row.original.locationData && <>
													<div style={{ borderLeft: "1px solid #000", paddingLeft: "10px", marginLeft: "10px" }}>
														{LOCATION}: {locationDisplay}
													</div>
													{locationAccuracy &&
														<div style={{ borderLeft: "1px solid #000", paddingLeft: "10px", marginLeft: "10px" }}>
															{ACCURACY}: {locationAccuracy}
														</div>
													}
												</>}
											</div>
										</div>
									</td>
								}
							</tr>
						</>)
					}))}
					{
						dataLoading && (Array.from({ length: 5 }).map((_, rowIndex) => (
								<tr key={rowIndex}> 
								{getHeaderGroups().map(headerGroup => (
									<>
									{headerGroup.headers.map(column => {
										// console.log(column);
										if (column.id === SCORE_COLUMN_ID) {
											if (pollType.toUpperCase() === POLL_TYPE.SURVEY) return <></>;
											return (
												<th tabIndex={0} {...{
													// ...column.getHeaderProps(),
													key: column.id,
													style: {width: column.getSize(), backgroundClip: "padding-box"}
												}}>
													<SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
														<Skeleton height={20} borderRadius={10} width={skeletonLoaderWidth[rowIndex]} style={{ position: "relative" }} />
													</SkeletonTheme>
												</th>
											);
										} else if (column.id === USER_ID_COLUMN_ID) { 
										} else if (column.id === CHECKBOX_COLUMN_ID) {
											// if (!showCheckbox) return null;
											// return (
											// 	<td {...{
											// 		key: column.id,
											// 		style: {width: column.getSize(), backgroundClip: "padding-box"}
											// 	}}>
											// 		<SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
											// 			<Skeleton height={20} width={skeletonLoaderWidth[rowIndex]} style={{ position: "relative" }} />
											// 		</SkeletonTheme>
											// 	</td>
											// );
										} else {
											return (
												<th tabIndex={0} {...{
													key: column.id,
													style: {width: column.getSize(), backgroundClip: "padding-box"}
												}}>
													<SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
														<Skeleton height={20} borderRadius={10} width={skeletonLoaderWidth[rowIndex]} style={{ position: "relative" }} />
													</SkeletonTheme>
												</th>
											);
										}
									})}
									</>
							)) }
							</tr>))
							)
						}
					</tbody>
				</Table>

				{getRowModel().rows?.length !== 0 ? <>
					<NormalPagination
						pageCount={getPageCount()}
						pageIndex={getState().pagination.pageIndex}
						pageOptions={pageOptions}
						canPreviousPage={getCanPreviousPage()}
						canNextPage={getCanNextPage()}
						previousPage={previousPage}
						nextPage={nextPage}
						gotoPage={setPageIndex}
					/>
				</> : null}

			</div>
		</>
	)
}