import React, { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory, useLocation } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as DownGrey } from "../../images/SortingIcon.svg";
import {
  ACTION_HEADER,
  CREATE_POLL,
  MENU_LIST,
  DATE_HEADER,
  DEFAULT_ROWS_PER_PAGE,
  FEEDBACK_HEADER,
  FEEDBACK_KEY,
  GRADED_POLL,
  NO_POLLS_TEXT,
  POLLS,
  POLL_KEY,
  POLL_SHARE_MODE_ACCESSOR,
  POLL_STATUS_HEADER,
  POLL_TITLE,
  POLL_TYPE,
  POLL_TYPE_ACCESSOR,
  POLL_TYPE_HEADER,
  QUESTION_HEADER,
  SURVEY,
  TEXTBOX,
  TITLE_HEADER,
  TOTAL_ATTENDANCES,
  TOTAL_GRADED,
  TOTAL_POLLS,
  TOTAL_QUESTIONS,
  TOTAL_SURVEYS,
  UPDATED_DATE_PK, DRAFT_STATUS
} from "../../utils/constants";
import { getPollStatus, getPriority } from "../../utils/helpers";
import PublishModal from "../CreateQuizPage/PublishModal";
import styles from "./ManagePolls.module.css";
import PollSearchForm from "./PollSearchForm";
import PollTable from "./PollTable";
import { ReactComponent as NoResultIllustration } from "../../images/NoActivityFound.svg";
import YuJaButton from "../../components/standardization/YuJaButton";

export default function ManagePollsPage(props) {
  const location = useLocation();
  let pollCode;
  let pollKey;
  if (location.state && location.state.pollCode) {
    pollCode = location.state.pollCode;
    pollKey = location.state.pollKey;
  }
  const [ publishModalShow, setPublishModalShow ] = useState(pollCode != undefined);
  const [filter, setFilter] = useState({});
  const [pollData, setPollData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [dataReady, setDataReady] = useState(false);
  const { getPollsFromUser } = PollApiCalls();
  const history = useHistory();
  const [dateSort, setDateSort] = useState(0);
  const [titleSort, setTitleSort] = useState(1); 
  const [modeSort, setModeSort] = useState(1); 
  const [statusSort, setStatusSort] = useState(1); 

  useEffect(() => {
    props.setPageIdentifier(MENU_LIST.ACTIVITIES.name);
  }, []);


  const sortDateHelper = () => {
    if(!!pollData) {
      if(dateSort === 0){
        setPollData(
            pollData
                .sort(function (a, b) {
                  return new Date(Date.parse(a.updatedTimeSK)) - new Date(Date.parse(b.updatedTimeSK));
                })
        );
          setDateSort(1);
        } else {
          setPollData(
              pollData
                  .sort(function (a, b) {
                    return new Date(Date.parse(b.updatedTimeSK)) - new Date(Date.parse(a.updatedTimeSK));
                  })
          );
          setDateSort(0);
        }
      }
  }

  const sortTitleHelper = () => {
    if(!!pollData) {
        setPollData(pollData.sort(function (a, b) {
            const nameA = a.pollTitle?.toLowerCase();
            const nameB = b.pollTitle?.toLowerCase();

            const priorityA = getPriority(nameA);
            const priorityB = getPriority(nameB);

            if (priorityA < priorityB) return -1 * titleSort;
            if (priorityA > priorityB) return 1 * titleSort;

            if (nameA < nameB) return -1 * titleSort;
            if (nameA > nameB) return 1 * titleSort;
            return 0;
          }));
          setTitleSort(-titleSort);
      }
  }

  const sortModeHelper = () => {
    if(!!pollData) {
        setPollData(pollData.sort(function (a, b) {
            const nameA = a.pollType?.toLowerCase();
            const nameB = b.pollType?.toLowerCase();
      
            if (nameA < nameB) return -1 * modeSort;
            if (nameA > nameB) return 1 * modeSort;
            return 0;
          }));
          setModeSort(-modeSort);
      }
  }

  const sortStatusHelper = () => {
    if(!!pollData) {
        console.log(statusSort)
        setPollData(pollData.sort(function (a, b) {
            const nameA = getPollStatus(a.pollShareMode, a?.isValid, a.pollType).toLowerCase();
            const nameB = getPollStatus(b.pollShareMode, b?.isValid, b.pollType).toLowerCase();

            if (nameA < nameB) return -1 * statusSort;
            if (nameA > nameB) return 1 * statusSort;
            return 0;
          }));
          setStatusSort(-statusSort);
      }
  }


  const getPollInformation = async (pageIndex=0) => {
    setDataReady(false);
    let res = await getPollsFromUser(true);
    res = res.filter(p => !!p.pollType && [GRADED_POLL, SURVEY, POLL_TYPE.ATTENDANCE, POLL_TYPE.MERGED_POLL].includes(p.pollType))
        .filter(p => p.isValid !== false)
        .sort((p1, p2) => Date.parse(p2.updatedTimeSK) - Date.parse(p1.updatedTimeSK));
    setPollData(res);
    setPageIndex(pageIndex);
    setDataReady(true);
  };

  useEffect(() => {
    getPollInformation();
  }, []);

  const handleSubmit = useCallback(({ ...filterObj }) => {
    setFilter(filterObj);
    setPageIndex(0);
  }, []);

  useEffect(() => {
    console.log(`filter:${JSON.stringify(filter)}`);
  }, [filter]);

  const isMobileScreen = useMobileAccess();

  const columns = [
    { Header: DATE_HEADER, accessor: UPDATED_DATE_PK, width: isMobileScreen ? 90 : 110 },
    { Header: TITLE_HEADER, accessor: POLL_TITLE, width: 250 },
    { Header: POLL_TYPE_HEADER, accessor: POLL_TYPE_ACCESSOR, width: isMobileScreen ? 90 : 110 },
    { Header: QUESTION_HEADER, accessor: TOTAL_QUESTIONS, width: isMobileScreen ? 80 : 100 },
    // { Header: ACTIVATION_STATUS_HEADER, accessor: POLL_SORT_KEY, width: isMobileScreen ? 100 : 120 },
    { Header: POLL_STATUS_HEADER, accessor: POLL_SHARE_MODE_ACCESSOR, width: isMobileScreen ? 100 : 120 },
    { Header: FEEDBACK_HEADER, accessor: FEEDBACK_KEY, width: isMobileScreen ? 70 : 90 },
    { Header: ACTION_HEADER, accessor: POLL_KEY, width: isMobileScreen ? 70 : 90, id: 'action' }
  ];

  const clickCreate = () => {
    history.push({ pathname: "/create" });
  };

  const clickOkay = () => {
    history.push(`/poll/share/${pollKey}/${pollCode.uniqueCode}`);
  };

  const clickHide = () => {
    setPublishModalShow(false);
    getPollInformation();
  };


  return (
    <>
      {typeof(pollCode) != "undefined" && (
        <PublishModal
          show={publishModalShow}
          setModalShow={setPublishModalShow}
          pollCode={pollCode}
          handleClickClose={clickHide}
          handleClickOkay={clickOkay}
          isGenerated={true}
        />
      )}
      {dataReady && pollData && pollData.length !== 0 &&  (
        <>
          <div className={styles.container} id="manage-poll-page-container">
            <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>{POLLS}</div>
            </div>
            <div className={styles.cardsContainer}>
              <Card className={styles.card}>
                <div className={styles.cardNumber}>{pollData.length}</div>
                <div className={styles.cardCaption}>{TOTAL_POLLS}</div>
              </Card>
              <Card className={styles.card}>
                <div className={styles.cardNumber}>
                  {pollData.filter(e => e.pollType !== POLL_TYPE.ATTENDANCE).length}
                </div>
                <div className={styles.cardCaption}>{TOTAL_GRADED}</div>
              </Card>
              <Card className={styles.card}>
                <div className={styles.cardNumber}>{pollData.filter(e => e.pollType === POLL_TYPE.ATTENDANCE).length}</div>
                <div className={styles.cardCaption}>{TOTAL_ATTENDANCES}</div>
              </Card>
            </div>
            <PollSearchForm handleSubmit={handleSubmit} collapsed={props?.collapsed} />
            <PollTable columns={columns} data={pollData} pageIdx={pageIndex} globalFilterObj={filter} getPollInformation={getPollInformation} Datesort={sortDateHelper} dateSort={dateSort} Titlesort={sortTitleHelper} Modesort={sortModeHelper} titleSort={titleSort} modeSort={modeSort} Statussort={sortStatusHelper} statusSort={statusSort}/>
          </div>
        </>
      )}
      {dataReady && (!pollData || pollData.length === 0) && (
        <>
          <div className={styles.c2} style={{height: "100%"}}>
            <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>{POLLS}</div>
            </div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "631px"}}>
            <NoResultIllustration style={{width: "100%", maxHeight: "100%", marginRight: "15px"}}/>
            <div role={TEXTBOX} style={{marginTop: "50px", fontSize: 15, marginBottom: 25}}>{NO_POLLS_TEXT}</div>
              <YuJaButton
                onClick={() => clickCreate()}
              >
                {CREATE_POLL}
              </YuJaButton>
            </div>
          </div>
        </>
      )}
      {!dataReady && (
        <>
          <div className={styles.container}>
            <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div tabIndex={0} className={styles.titleBoxTitle}>{POLLS}</div>
            </div>
            <div className={styles.cardsContainer}>
              <Card className={styles.card}>
                <Skeleton borderRadius={10} height={30} inline={true} width={`${2 + (Math.random() * 3)}vw`} className={styles.cardNumberSkeleton} containerClassName={styles.cardNumberSkeletonWrapper} />
                <div className={styles.cardCaption}>{TOTAL_POLLS}</div>
              </Card>
              <Card className={styles.card}>
                <Skeleton borderRadius={10} height={30} inline={true} width={`${2 + (Math.random() * 3)}vw`} className={styles.cardNumberSkeleton} containerClassName={styles.cardNumberSkeletonWrapper} />
                <div className={styles.cardCaption}>{TOTAL_GRADED}</div>
              </Card>
              <Card className={styles.card}>
                <Skeleton borderRadius={10} height={30} inline={true} width={`${2 + (Math.random() * 3)}vw`} className={styles.cardNumberSkeleton} containerClassName={styles.cardNumberSkeletonWrapper} />
                <div className={styles.cardCaption}>{TOTAL_ATTENDANCES}</div>
              </Card>
            </div>
            <PollSearchForm handleSubmit={handleSubmit} disabled={true} collapsed={props?.collapsed} />
            <div className={styles.skeletonTableContainer}>
              <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                <div className={styles.skeletonHeaderContainer}>
                  <div className={styles.skeletonHeaderCell}>{DATE_HEADER}<DownGrey role={TEXTBOX} tabIndex={0} aria-label={DATE_HEADER + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" /></div>
                  <div className={styles.skeletonHeaderCell}>{TITLE_HEADER}<DownGrey role={TEXTBOX} tabIndex={0} aria-label={TITLE_HEADER + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" /></div>
                  <div className={styles.skeletonHeaderCell}>{POLL_TYPE_HEADER}<DownGrey role={TEXTBOX} tabIndex={0} aria-label={POLL_TYPE_HEADER + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" /></div>
                  <div className={styles.skeletonHeaderCell}>{QUESTION_HEADER}</div>
                  {/* <div className={styles.skeletonHeaderCell}>{ACTIVATION_STATUS_HEADER}</div> */}
                  <div className={styles.skeletonHeaderCell}>{POLL_STATUS_HEADER}<DownGrey role={TEXTBOX} tabIndex={0} aria-label={DATE_HEADER + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" /></div>
                  <div className={styles.skeletonHeaderCell}>{FEEDBACK_HEADER}</div>
                  <div className={styles.skeletonActionHeaderCell}>{ACTION_HEADER}</div>
                  {/* <Skeleton height={16} width="3vw" className={styles.skeletonHeaderCell} />
                  <Skeleton height={16} width="3vw" className={styles.skeletonHeaderCell} />
                  <Skeleton height={16} width="3vw" className={styles.skeletonHeaderCell} />
                  <Skeleton height={16} width="5vw" className={styles.skeletonHeaderCell} />
                  <Skeleton height={16} width="7vw" className={styles.skeletonHeaderCell} />
                  <Skeleton height={16} width="4vw" className={styles.skeletonHeaderCell} /> */}
                </div>
                <div className={styles.skeletonBodyContainer}>
                  {Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                    return (<div className={styles.skeletonBodyRow}>
                      <Skeleton borderRadius={10} height={20} inline={true} width={`${6 + Math.random()}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton borderRadius={10} height={20} inline={true} width={`${3 + (Math.random()*7)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton borderRadius={10} height={20} inline={true} width={`${idx % 2 === 0 ? 3.5 : 3}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton borderRadius={10} height={20} inline={true} width={`${1 + Math.random()}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton borderRadius={10} height={20} inline={true} width={`${2 + (Math.random()*3)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton borderRadius={10} height={20} inline={true} width={`${1 + (Math.random())}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                      <Skeleton borderRadius={10} height={20} inline={true} width="2vw" className={styles.skeletonActionBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                    </div>)
                  })}
                </div>
              </SkeletonTheme>
            </div>
          </div>
        </>
      )}
    </>
  );
}