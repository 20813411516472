import React, {useEffect, useRef, useState} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Container } from "react-bootstrap";
import { PollApiCalls } from "../../apis/PollApiCalls";
import "../../components/buttons/addButton.css";
import CreateQuestionProfileModal from "../../components/modals/CreateQuestionProfileModal";
import QuestionProfileModal from "../../components/modals/QuestionProfileModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaCheckbox } from "../../components/standardization/YuJaCheckbox";
import useHostname from "../../hooks/useHostname";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as DeleteIcon } from "../../images/delete.svg";
import { ReactComponent as DuplicateIcon } from "../../images/duplicate.svg";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import {
    ADD_QUESTION_TEXT,
    BUTTON,
    CREATE_QUESTION_PROFILE_MODAL_TITLE,
    DELETE_BTN,
    DUPLICATE_BTN,
    IMPORT_BANK_TEXT,
    QUESTION_DASHBOARD,
    QUESTION_DEFAULT_OPTIONS,
    QUESTION_TYPES,
    QUES_BANK_SAVE,
    QUES_LABEL_1,
    QUES_LABEL_2,
    QUES_LABEL_3,
    TEXTBOX,
} from "../../utils/constants";
import {

  notifyError
} from "../../utils/helpers";
import {
    addQuestion,
    deleteQuestion,
    handleQuestionOrderChange,
    questionCodeToName,
} from "../../utils/questionUtils";
import { SAVE_BANK_WARNING_MESSAGE } from "../../utils/toast-message-constants";
import "./styles.css";
import { useLoading } from "../../utils/LoadingContext";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {useImageLoaderManager} from "../../hooks/useImageLoaderManager";
import {Spin} from "../../components/standardization/YuJaLoading";

export default function CreateQuizSidebar({
  questions,
  setQuestions,
  currQueDisplay,
  setCurrQueDisplay,
  pollKey,
  changePollKey,
  questionBankId,
  userSettings,
  dataloading=false
}) {
    const {
        checkQueImagesIsLoading,
        setQuestionLoading,
        resetImages,
        setImages
    } = useImageLoaderManager(questions, setQuestions);
    const hostResource = useHostname(window.location.hostname);
    const {institutionId = ""} = hostResource;
    const [, getSession] = useLocalStorageNew("session", {});
    const { duplicateImagesOfQuestion, duplicateImagesOfQuestionBank} = PollApiCalls();
    const {loading } = useLoading()
    // const [showGradebookOptions, setShowGradebookOptions] = useState(false);

    // const [showDragIcon, setShowDragIcon] = useState(null);

    // const [dragIconShowSerialNo, setDragIconShow] = useState();

    // const [showButton, setShowButton] = useState(true);
    // const [alertShow, setAlertShow] = useState(false);
    // const [alertContent,] = useState(SWITCH_POLL_MESSAGE);
    // const [setType,] = useLocalStorageNew("pollType", "");

    const [importingModal, setImportingModal] = useState(false);
    const [profileSettingModalShow, setProfileSettingModalShow]= useState(false);
    const questionsRef = useRef([]);

    useEffect(() => {
        questionsRef.current = questions;
    }, [questions]);

    // const [currentView, setCurrentView] = useState(0);

    const handleDragStart = () => {
      // setShowButton(false);
    };

    const handleDragEnd = () => {
      // setShowButton(true);
    };

    const clickNewQuestion = (e) => {
        e.stopPropagation();
        const newQuestions = addQuestion(questions, QUESTION_TYPES.MCSS.name, userSettings);
        setQuestions(newQuestions);
        setCurrQueDisplay(newQuestions.length);
    };

    const clickDuplicate = async (selectedQuestion, e) => {
        e.stopPropagation();
        const {userId} = getSession();

        if (!institutionId || !userId) {
            return;
        }
        const {serialNo} = selectedQuestion;


        const questionsCopy = JSON.parse(JSON.stringify(questionsRef.current));
        let duplicatedQuestion = JSON.parse(JSON.stringify(selectedQuestion));
        let newQuestions = [];
        for (const q of questionsCopy) {
            if (q.serialNo === serialNo) {
                newQuestions.push({...q, checked: false});
                duplicatedQuestion.serialNo = q.serialNo + 1;
                duplicatedQuestion.checked = true;
                newQuestions.push(duplicatedQuestion);
            } else if (q.serialNo > serialNo) {
                newQuestions.push({ ...q, serialNo: q.serialNo + 1, checked: false });
            } else {
                newQuestions.push({ ...q, checked: false });
            }
        }


        const resourceMapping = setQuestionLoading(duplicatedQuestion);
        setQuestions(newQuestions);
        setCurrQueDisplay(serialNo + 1);

        if (!resourceMapping || !Object.entries(resourceMapping).length) {
            return;
        }

        //duplicate images of the question
        if (!questionBankId) {
            duplicateImagesOfQuestion(pollKey, selectedQuestion, resourceMapping).then((returnedMapping) => {
                //error
                if (!returnedMapping) {
                    resetImages(Object.values(resourceMapping));
                    return;
                }

                //set imageURL
                setImages(returnedMapping);
            });
        } else {
            duplicateImagesOfQuestionBank(questionBankId, resourceMapping).then((returnedMapping) => {
                //error
                if (!returnedMapping) {
                    resetImages(Object.values(resourceMapping));
                    return;
                }

                //set imageURL
                setImages(returnedMapping);
            });
        }
    };

    const clickDelete = (selectedQuestion, e) => {
        e.stopPropagation();
        const {serialNo} = selectedQuestion;
        const newQuestions = deleteQuestion(selectedQuestion, questions);
        setCurrQueDisplay(serialNo === 1 ? 1 : serialNo - 1);
        setQuestions(newQuestions);
    };


  const questionBankCheckbox = () => {
    let show = false;
    questions.forEach((question) => {
      if(question.queTitle !== "") {
        show = true;
      }
      else if (question.hasOwnProperty("image") && question.image !== "") {
        show = true;
      }
      else if (question.questionType == QUESTION_TYPES.MCSS.name && JSON.stringify(question.optionsMap) !== QUESTION_DEFAULT_OPTIONS.MCSS) {
        show = true;
      }
    })
    return show;
  }

  const handleCheckboxChange = () => {
    if(questionBankCheckbox()){
      setProfileSettingModalShow(true);
    }
    else {
      notifyError(SAVE_BANK_WARNING_MESSAGE);
    }
  }

  return (
    <>
      {/* <GradebookOptionsModal
        show={showGradebookOptions}
        setShow={setShowGradebookOptions}
        recordAttempt={recordAttempt}
        setRecordAttempt={setRecordAttempt}
        lmsAttempt={lmsAttempt}
        setLMSAttempt={setLMSAttempt}
        maxAttempts={maxAttempts}
        setMaxAttempts={setMaxAttempts}
      /> */}
      <CreateQuestionProfileModal setModalShow={setProfileSettingModalShow} show={profileSettingModalShow} questions={questions} pollKey={pollKey} />
      <QuestionProfileModal
          show={importingModal}
          setModalShow={setImportingModal}
          setQuestions={setQuestions}
          questions={questions}
          setCurrQueDisplay={setCurrQueDisplay}
          pollKey={pollKey}
          changePollKey={changePollKey}
          userSettings={userSettings}
      />
      {/* <YuJaGeneralAlert
            show={alertShow}
            setModalShow={setAlertShow}
            content={alertContent}
            config={alertConfig}
        /> */}
      {/* {currentView === 0 ? */}
        {/* <div style={{  }}> */}
          <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={(result) => {
                const {destination={}} = result;
                const {index} = destination;
                const updatedQuestions = handleQuestionOrderChange(result, questions);
                if (updatedQuestions) {
                    setQuestions(updatedQuestions);
                    setCurrQueDisplay(index + 1);
                }
            }}
          >
            <Droppable droppableId="droppable-sidebar">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  {...provided.dropHandleProps}
                >
                  <Container className="max-height">
                    {!dataloading &&
                    <>
                    <div style={{ display: "flex", width: "100%", height: "45px", marginTop: "36px" }}>
                      <div style={{
                        fontWeight: "700",
                        
                        display: "flex",
                        fontSize: "16px",
                        lineHeight: "15px",
                        width: "100%", alignItems: "center",
                        borderTopLeftRadius: "10.2px",
                        borderTopRightRadius: "10.2px",
                        borderBottom: "0.5px solid #42296E", 
                        color: "#1D1B20"
                      }} tabIndex={0} role={TEXTBOX}>
                        {QUESTION_DASHBOARD}
                      </div>
                      {/* <div style={{ fontWeight: "400",  display: "flex", justifyContent: "center", fontSize: "14.688px", backgroundColor: "#F7EFF6", width: "50%", alignItems: "center", borderTopRightRadius: "10.2px", borderBottom: currentView === 1 ? "2px solid #6750A4" : "", cursor: "pointer" }} tabIndex={0} role={TEXTBOX} onClick={() => { setCurrentView(1) }}> Question Details </div> */}
                    </div>
                    {/* {showButton ? */}
                    <div style={{ display: "flex", textAlign: "center", justifyContent: !questionBankId ? "space-between" : 'center', padding: "20px 0px", flexWrap: "wrap", gap: 5 }}>
                      {!questionBankId &&
                        <YuJaButton title={IMPORT_BANK_TEXT} onClick={() => { setImportingModal(true) }} style={{ fontSize: 13 }}>
                          <PlusIcon style={{ width: 15, height: 15, marginRight: 2 }} /> {IMPORT_BANK_TEXT}
                        </YuJaButton>
                      }
                      <YuJaButton title={ADD_QUESTION_TEXT} onClick={clickNewQuestion} style={{ fontSize: 13 }} disabled={loading}>
                        <PlusIcon style={{ width: 15, height: 15, marginRight: 2 }} /> {ADD_QUESTION_TEXT}
                      </YuJaButton>
                    </div>
                    {/* : null} */}
                    <div className="question-dashboard-list" style={{ height: `${questions.length * 61}px` }}>
                      {questions.map((question, index) => {
                        const { serialNo, questionType, queTitle } = question;
                        return (
                          <div
                            key={index}
                            onClick={(event) => {
                              setCurrQueDisplay(serialNo);
                            }}
                          >
                            <Draggable
                              key={String(serialNo)}
                              draggableId={String(serialNo)}
                              index={index}
                            >
                              {(draggableProvided) => (
                                <div
                                  className={`question-bar-container ${serialNo === currQueDisplay ? "is-cur" : ""}`}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  ref={draggableProvided.innerRef}
                                  aria-label={QUES_LABEL_1 + serialNo + " " + questionCodeToName(questionType) + QUES_LABEL_2 + QUES_LABEL_3}
                                  // onMouseEnter={() => setShowDragIcon(index)}
                                  // onMouseLeave={() => setShowDragIcon(null)}
                                  role={TEXTBOX}
                                  onMouseDown={() => document.getElementById("dummyInputBox").focus()}
                                >
                                  {/* <div style={{ height: "50.84px", width: "4.47px", borderRadius: "9px", backgroundColor: serialNo === currQueDisplay ? "#6750A4" : "", marginRight: "7px" }}>

                                  </div> */}
                                  {/* <div style={{ width: "25px", height: "25px" }}>
                                    {!isNarrowScreen && showDragIcon === index &&
                                      <DragDropIcon
                                        alt="drag drop icon"
                                        title={"Drag to Recorder"}
                                        style={{ marginRight: 16, width: "17.831px", height: "27.886px" }}
                                      />
                                    }
                                  </div> */}
                                  <div style={{ flex: 1, paddingLeft: 10 }}>
                                    <div className="sidebar-question" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", wordBreak: "keep-all", maxWidth: "200px" }}>
                                      {!!queTitle
                                        ? queTitle
                                        : `Question ${serialNo}`}
                                    </div>
                                    <div className="sidebar-question-type">
                                      {questionCodeToName(questionType)}
                                    </div>
                                  </div>
                                  {!checkQueImagesIsLoading(question) &&
                                      <>
                                          <DuplicateIcon
                                            title={"Duplicate Question"}
                                            alt="duplicate icon"
                                            onClick={(e) => clickDuplicate(question, e)}
                                            style={{ height: "28.646px", width: "28.646px" }}
                                            tabIndex={0}
                                            aria-label={DUPLICATE_BTN + (index + 1)}
                                            role={BUTTON}
                                          />
                                          <DeleteIcon
                                            title={"Delete Question"}
                                            alt="delete icon"
                                            onClick={(e) => clickDelete(question, e)}
                                            tabIndex={0}
                                            aria-label={DELETE_BTN + (index + 1)}
                                            role={BUTTON}
                                            style={{ height: "28.646px", width: "28.646px" }}
                                          />
                                      </>
                                  }

                                    {checkQueImagesIsLoading(question) &&
                                        <>
                                            <div style={{width: 28, height: 28}}>
                                                <Spin size={"medium"} spinning={true} style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}} />
                                            </div>
                                            <div style={{width: 28, height: 28}}>
                                                <Spin size={"medium"} spinning={true} style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}} />
                                            </div>
                                        </>
                                    }
                                </div>
                              )}
                            </Draggable>
                          </div>
                        );
                      })}
                    </div>
                    </>
                  }
                  {
                    dataloading && <div> 
                      <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                      <Skeleton  height={20} width={156} borderRadius={10} style={{ position: "relative", marginTop: 36 }} />
                      <div style={{ display: "flex", textAlign: "center", justifyContent: !questionBankId ? "space-between" : 'center', padding: "20px 0px", flexWrap: "wrap", gap: 5 }}>
                      <Skeleton  height={36} width={140} borderRadius={10} />
                      <Skeleton  height={36} width={140} borderRadius={10} />
                    </div>
                    <Skeleton  height={61} width={312} borderRadius={10} />
                    </SkeletonTheme>
                    </div>

                  }
                  </Container>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {!questionBankId && !dataloading &&
            <div style={{ display: "flex", gap: "10px", flexDirection: "column", position: "absolute", bottom: "25px", height: "25px", padding: "0px 20px", fontSize: "14px" }}>
              <YuJaCheckbox ariaLabel={QUES_BANK_SAVE + (profileSettingModalShow ? "checked" : "unchecked")} checked={profileSettingModalShow} onClick={handleCheckboxChange} label={CREATE_QUESTION_PROFILE_MODAL_TITLE} color={"#0C7086"} />
              {/* {pollType !== SURVEY_TEXT &&
                <YuJaButton onClick={() => setShowGradebookOptions(true)} style={{ borderRadius: "4.21px", border: "1.336px solid #42296E", height: "35px", display: "flex", alignItems: "center", fontSize: "14px" }}>
                  {CONFIGURE_GRADEBOOK_OPTIONS_TEXT}
                </YuJaButton>
              } */}
            </div>
          }
        {/* </div> */}
        {/* :
        <Container className="max-height" style={{ maxHeight: "100%", paddingTop: "36px" }}> */}
          {/* <div style={{ display: "flex", width: "100%", height: "45px" }}>
            <div style={{ fontWeight: "400",  display: "flex", justifyContent: "center", fontSize: "14.688px", backgroundColor: "#F7EFF6", width: "50%", alignItems: "center", borderTopLeftRadius: "10.2px", borderBottom: currentView === 0 ? "2px solid #6750A4" : "", cursor: "pointer" }} role={TEXTBOX} tabIndex={0} onClick={() => { setCurrentView(0) }}> Dashboard </div>
            <div style={{ fontWeight: "700",  display: "flex", justifyContent: "center", fontSize: "14.688px", backgroundColor: "#F7EFF6", width: "50%", alignItems: "center", borderTopRightRadius: "10.2px", borderBottom: currentView === 1 ? "2px solid #6750A4" : "", cursor: "pointer" }} role={TEXTBOX} tabIndex={0} onClick={() => { setCurrentView(1) }}> Question Details </div>
          </div>
          <div style={{ paddingBottom: "100px", position: "relative" }}>
            <CreateQuestionDetailSidebar
              title={"Question Details"}
              noContentText={"Question Details"}
              questions={questions}
              setQuestions={setQuestions}
              currQueDisplay={currQueDisplay}
              pollType={pollType}
              showAnswerOnViewer={showAnswerOnViewer}
              setShowAnswerOnViewer={setShowAnswerOnViewer}
              anonymousJoin={anonymousJoin}
              setAnonymousJoin={setAnonymousJoin}
              recordAttempt={recordAttempt}
              setRecordAttempt={setRecordAttempt}
              lmsAttempt={lmsAttempt}
              setLMSAttempt={setLMSAttempt}
              maxAttempts={maxAttempts}
              setMaxAttempts={setMaxAttempts}
              isBank={isBank} />
          </div>
          {!!pollType && !isBank &&
            <div style={{ position: "absolute", bottom: "40px", height: "60px", paddingRight: "20px" }}>
              <Col>
                {pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                  <Row className="justify-content-md-center" style={{ marginBottom: "10px" }}>
                    <Col>
                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", gap: "5px" }}>
                        <div style={{ fontSize: "14px" }}>{SHOW_CORRECT_ANSWER_CHECKBOX_LABEL}</div>
                        <YuJaToggle style={{ width: "auto" }} ariaLabel={SHOW_CORRECT_ANSWER_CHECKBOX_LABEL} toggled={showAnswerOnViewer} onClick={() => setShowAnswerOnViewer(!showAnswerOnViewer)} />
                      </div>
                    </Col>
                  </Row>
                }

                <Row className="justify-content-md-center">
                  <Col>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", gap: "5px" }}>
                      <div style={{ fontSize: "14px" }}> {DISABLE_ANONYMOUS_SIDE_BAR}</div>
                      <YuJaToggle style={{ width: "auto" }} ariaLabel={DISABLE_ANONYMOUS_SIDE_BAR} toggled={anonymousJoin} onClick={() => { setAnonymousJoin(!anonymousJoin); }} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          } */}
        {/* </Container>
      } */}
    </>
  );
}
