import React, { useEffect, useRef, useState } from "react";
import { PollApiCalls } from "../apis/PollApiCalls";
import YuJaGeneralAlert from "../components/modals/YuJaGeneralAlert";
import { YuJaTextBox } from "../components/standardization/YuJaTextBox.js";
import { YuJaTooltip } from "../components/standardization/YuJaTooltip.js";
import useHostname from "../hooks/useHostname";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
import useMobileAccess from "../hooks/useMobileAccess";
import { usePPTWebView } from "../hooks/usePPTWebView";
import { ReactComponent as InfoIcon } from "../images/Info.svg";
import { ReactComponent as DeleteImage } from "../images/feedbackFileDelete.svg";
import { ReactComponent as UploadImage } from "../images/upload_purple.svg";
import { ReactComponent as UploadClickableImage } from "../images/ClickableImageUpload.svg";
import { ReactComponent as UploadClickableImageButton } from "../images/ClickableImageUploadSmall.svg";
import { ALT_TEXT_LABEL, ALT_TEXT_PLACEHOLDER, ALT_TEXT_TOOL_TIP, CHOOSE_MEDIA_TEXT, DRAG_DROP_IMAGE_TEXT, OVERRIDE_IMAGE_BUTTON, OVERRIDE_IMAGE_TEXT, OVERRIDE_IMAGE_TITLE, POLL_TITLE_LENGTH, QUESTION_TYPES, TEXTBOX, UPLOAD_MEDIA, imageSuffixArr, videoSuffixArr } from "../utils/constants";
import { notifyError } from "../utils/helpers";
import {
  CI_NOT_IMAGE_UPLOAD_ERROR,
  INVALID_FILE_FORMAT_ERROR,
  SELECT_FILE_ERROR,
  UPLOAD_ONE_IMAGE_ERROR
} from "../utils/toast-message-constants";
import "./styles.css";
import { useLoading } from "../utils/LoadingContext.js";
import { UserApiCalls } from "../apis/UserApiCalls.js";
import YuJaButton from "../components/standardization/YuJaButton.js";
import { ReactComponent as ClickableImageDelete } from "../images/ClickableImageDelete.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {extractOriginalImageName, useImageLoaderManager} from "../hooks/useImageLoaderManager";

export default function UploadQuestionImage({
                                              selectedQuestion,
                                              questions,
                                              setQuestions,
                                              data=null,
                                              // currQueDisplay,
                                              pollKey,
                                              changePollKey,
                                              questionBankId = null,
                                              isEditPage = false,
                                              questionBuilder = false,
                                              disabled = false,
                                              generateDefaultPollTitle=null,
                                              pollCode=null,
                                              savePoll=null,
                                              clickable=false, 
                                              dataloading=false
}) {
  const hostResource = useHostname(window.location.hostname);
  const {institutionId = ""} = hostResource;
  const ref = useRef();
  const isNarrow = useMobileAccess(1100);
  const {
    getUuid,
    createUniqueImage,
    resetImage,
    setImage,
    setQuestionImageLoading,
    checkImageIsLoading,
  } = useImageLoaderManager(questions, setQuestions);
  const { uploadQueImage, uploadQueBankImage, uploadVideo, getUploadLinkForVideo, deleteVideo} = PollApiCalls();
  const { deleteUserVideo} = UserApiCalls()
  const { setLoading } = useLoading();

  const [alertShow, setAlertShow] = useState(false);
  const [tempFile, setTempFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isVideo, setIsVideo] = useState(null);


  useEffect(() => {
    setIsVideo(!!selectedQuestion?.directLinkEvp);
    if (!selectedQuestion || !selectedQuestion.imageURL || checkImageIsLoading(selectedQuestion)) {
      setFileName(extractOriginalImageName(""));
    } else {
      setFileName(extractOriginalImageName(selectedQuestion?.image));
    }

  }, [institutionId, pollKey, selectedQuestion, questionBuilder]);

  const alertConfig = {
    title: OVERRIDE_IMAGE_TITLE,
    okText: OVERRIDE_IMAGE_BUTTON,
    submit: async () => {
      setAlertShow(false);
      if (tempFile) {
          upload(tempFile); 
      }
    },
    close:  async () => {
      setAlertShow(false);
    },
  };

  const handleVideoUpload = async(file, questionBuilder=false) => {
    setLoading(true);
    // 1. Get upload link
    const res = await getUploadLinkForVideo();

    // 2. PUT to s3 bucket
    await fetch(res.url, {
      headers: {
        'Content-Type': "Video/mp4",
      },
      method: "PUT",
      body: file
    })
    .then((res) => console.log(res))
    .catch((err) => console.log(err));

    // 3. POST /upload/video/
    const res3 = await uploadVideo(res.key, file.name, pollKey, selectedQuestion.serialNo);
    if(res3) {
      selectedQuestion.directLinkEvp = res3;
      
      let newQuestions = [];
      questions.forEach((q) => {
        if (q.serialNo === selectedQuestion.serialNo) {
          const newQuestion = {...q};
          newQuestion.image = file.name;
          newQuestion.imageURL = "";
          newQuestion.directLinkEvp = res3;
          newQuestions.push(newQuestion);
        } else {
          newQuestions.push({ ...q });
        }
      });
      
      if (JSON.stringify(newQuestions) !== JSON.stringify(questions)) {
        setQuestions(newQuestions);
      }
      if(generateDefaultPollTitle) {
        generateDefaultPollTitle();
      }
      if (savePoll !== null && !questionBuilder) {
        savePoll(false);
      }
      else if (savePoll !== null && questionBuilder) {
        savePoll(newQuestions);
      }

    }
    setLoading(false);
  };

  const upload = async (file) => {
    if(!!selectedQuestion.directLinkEvp) {
      if(!questionBankId) {
        await deleteVideo(pollKey, selectedQuestion.serialNo, pollCode, true);
     }
     else {
       await deleteUserVideo(questionBankId, selectedQuestion.serialNo);
     }
    }

    const suffix = file.name.substring(file.name.lastIndexOf('.'))?.toLowerCase();
    if(selectedQuestion.questionType === QUESTION_TYPES.CI.name && (!imageSuffixArr.includes(suffix) || videoSuffixArr.includes(suffix))) {
      notifyError(CI_NOT_IMAGE_UPLOAD_ERROR);
      return;
    }

    if (!imageSuffixArr.includes(suffix) && !videoSuffixArr.includes(suffix)) {
      notifyError(INVALID_FILE_FORMAT_ERROR);
      ref.current.value = '';
      return;
    }

    if (!file || !file.name) { // if no image is selected
      notifyError(SELECT_FILE_ERROR);
      return;
    }

    // setFileName(file.name);
    const newFileName = createUniqueImage(file.name);
    const f = new File([file], newFileName); // Make new File with name temp-<currQueDisplay>.<suffix>
    // updateSuffix(suffix);
    if(videoSuffixArr.includes(suffix)) {
      await handleVideoUpload(new File([file], file.name), questionBuilder);
    } else if (!questionBankId) {
      let uuid = pollKey;
      if (!uuid) {
        uuid = getUuid();
        changePollKey(uuid);
      }
      uploadQueImage(uuid, f, (name, url) => {
        setImage(name, url);
      }).then(res => {
        if (!res) {
          resetImage(newFileName);
        }
      });
      setQuestionImageLoading(selectedQuestion.serialNo, newFileName);
    } else if (!!questionBankId) {
      uploadQueBankImage(questionBankId, f, (name, url) => {
        setImage(name, url);
      }).then(res => {
        if (!res) {
          resetImage(fileName);
        }
      });
      setQuestionImageLoading(selectedQuestion.serialNo, newFileName);
    }

    if (ref.current) {
      ref.current.value = '';
    }
  };

  const handleUpload = (e) => {
    if (!!selectedQuestion.imageURL || !!selectedQuestion.directLinkEvp) {
      setTempFile(e.target.files[0]);
      e.target.value = "";
      setAlertShow(true); 
    }
    else {
      upload(e.target.files[0]);
    }
  };

  const handleClose = async () => {
    if (disabled) {
      return;
    }
    if(!!selectedQuestion.directLinkEvp) {
      if(!pollKey && !questionBankId) {
        selectedQuestion.directLinkEvp = null;
      } else {
        let res;
        if(!questionBankId) {
          res = await deleteVideo(pollKey, selectedQuestion.serialNo, pollCode);
        } else {
          res = await deleteUserVideo(questionBankId, selectedQuestion.serialNo);
        }

        if(res === "success") {
          selectedQuestion.directLinkEvp = null;
        }
        setLoading(false);

        let newQuestions = [];
        for (const q of questions) {
          if (q.serialNo !== selectedQuestion.serialNo) {
            newQuestions.push(q);
            continue;
          }

          const updatedQuestion = { ...q, directLinkEvp: "", imageURL: "", suffix: "", image: "" };
          newQuestions.push(updatedQuestion);
        }

        setQuestions(newQuestions);
        if (savePoll !== null && !questionBuilder) {
          savePoll(false);
        }
        else if (savePoll !== null && questionBuilder) {
          savePoll(newQuestions);
        }
      }
      return;
    }


    //clear options
    let newQuestions = [];
    for (const q of questions) {
      if (q.serialNo !== selectedQuestion.serialNo) {
        newQuestions.push(q);
        continue;
      }

      const updatedQuestion = {...q, imageURL: "", suffix: "", image: "", imageAlt: ""};
      if (selectedQuestion.questionType === QUESTION_TYPES.CI.name) {
        updatedQuestion.optionsMap = [];
        updatedQuestion.correctAnswers = [];
      }
      newQuestions.push(updatedQuestion);
    }

    setQuestions(newQuestions);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 1) {             // if more than one image is selected
      notifyError(UPLOAD_ONE_IMAGE_ERROR);
      return;
    }
    if (!!selectedQuestion.imageURL) {
      setTempFile(files[0]);
      e.target.value = "";
      setAlertShow(true); 
    }
    else {
      upload(files[0]);       
    }            // Upload the file
  };

  const onChangeAltText = (e) => {
    const altText = e.target.value;
    let newQuestions = [];
    questions.forEach((q) => {
        if (q.serialNo === selectedQuestion.serialNo) {
            const updatedQuestion = { ...q, imageAlt: altText };
            newQuestions.push(updatedQuestion);
        } else {
            newQuestions.push({ ...q });
        }
    })
    setQuestions(newQuestions);
};

  return (
    <>
      <YuJaGeneralAlert
        show={alertShow}
        setModalShow={setAlertShow}
        content={OVERRIDE_IMAGE_TEXT}
        config={alertConfig}
      />
      {clickable && 
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "100%" }}>
          {(!!selectedQuestion.imageURL || !!selectedQuestion.directLinkEvp) &&
              <div style={{ display: "flex", justifyContent: "end", gap: "10px", marginTop: "5px"}}>
                <YuJaButton
                    type="deleteImage"
                    style={{ height: "33px", padding: "8px 10px", borderRadius: "4.205px", border: "2px solid #BF0033", display: 'flex', alignItems: "center", whiteSpace: 'nowrap' }}
                    onClick={handleClose}
                >
                  <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                    <ClickableImageDelete style={{ width: "24px", height: "24px" }} />{"Delete"}
                  </div>
                </YuJaButton>
                <YuJaButton
                    onClick={(e) => ref.current.click(e)}
                    style={{ height: "33px", padding: "8px 10px", borderRadius: "4.205px", display: 'flex', alignItems: "center", whiteSpace: 'nowrap', fontSize: "14px" }}
                >
                  <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                    <UploadClickableImageButton style={{ width: "24px", height: "24px" }} />{"Upload"}
                    </div>
                    <input
                      disabled={questionBuilder && disabled}
                      ref={ref}
                      className="browse-box"
                      type="file"
                      accept="image/png, image/jpg, image/jpeg, image/jfif, image/pjpeg, image/pjp, video/*"
                      onChange={handleUpload}
                      aria-label="upload image"
                    />
                </YuJaButton>
              </div>
          }
          {!selectedQuestion.imageURL && !selectedQuestion.directLinkEvp &&
            <div
              className={`${questionBuilder && disabled ? "image-upload-container-disabled" : "image-upload-container"} ${questionBuilder ? "question-builder-image-upload-container" : ""}`}
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
              onClick={(e) => ref.current.click(e)}
              style={isNarrow ? { height: "auto", minHeight: "300px", backgroundColor: "#FCFBFF", marginTop: "40px"} : { minHeight: "300px", backgroundColor: "#FCFBFF", marginTop: "40px"}}
            >
              <div className="justify-content-center" style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", height: "100%", gap: "10px", marginLeft: "0px", padding: questionBuilder ? 0 : 5 }}>
                <UploadClickableImage width={questionBuilder ? 24 : 94} height={questionBuilder ? 24 : 94} />
                <p
                  tabIndex={0}
                  aria-label={UPLOAD_MEDIA}
                  role={TEXTBOX}
                  className={`${questionBuilder ? "drag-drop-text-narrow" : "drag-drop-text"} d-flex`}
                  style={{ width: "auto", height: questionBuilder ? 20 : "auto", margin: 0, alignItems: "center", flexWrap: "wrap" }}
                >
                  {DRAG_DROP_IMAGE_TEXT}
                </p>
                <YuJaButton>
                  {CHOOSE_MEDIA_TEXT}
                </YuJaButton>
                <input
                  disabled={questionBuilder && disabled}
                  ref={ref}
                  className="browse-box"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg, image/jfif, image/pjpeg, image/pjp, video/*"
                  onChange={handleUpload}
                  aria-label="upload image"
                />
              </div>
            </div>
          }
        </div>
      }

      {!clickable && !dataloading &&
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "100%" }}>
        <div
          className={`${questionBuilder && disabled ? "image-upload-container-disabled" : "image-upload-container"} ${questionBuilder ? "question-builder-image-upload-container" : ""}`}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={(e) => ref.current.click(e)}
          style={isNarrow ? { height: "auto" } : {}}
        >
          <div className="justify-content-center" style={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "0px", padding: questionBuilder ? 0 : 5 }}>
            <UploadImage width={questionBuilder ? 24 : 40} height={questionBuilder ? 24 : 40} />
            <span
              tabIndex={0}
              aria-label={UPLOAD_MEDIA}
              role={TEXTBOX}
              className={`${questionBuilder ? "drag-drop-text-narrow" : "drag-drop-text"} d-flex`}
              style={{  width: "auto", height: questionBuilder ? 20 : "100%", margin: 0, alignItems: "center", flexWrap: "wrap" }}
            >
              Drag & Drop or
              <span style={{ color: "#006DC7", textDecoration: "underline",  fontWeight: "700", marginLeft: "5px", marginRight: "5px" }}> Choose Media </span>
              to Upload
            </span>
            {/* <p style={{marginBottom: 5}}>or</p>
            <YuJaButton  onClick={(e) => ref.current.click(e)}>Browse</YuJaButton> */}
            <input
              disabled={questionBuilder && disabled}
              ref={ref}
              className="browse-box"
              type="file"
              accept="image/png, image/jpg, image/jpeg, image/jfif, image/pjpeg, image/pjp, video/*"
              onChange={handleUpload}
              aria-label="upload image"
            />
          </div>
        </div>
        {questionBuilder && fileName && <>
          <div style={{ display: "flex", gap: "5px", fontSize: "14px" }}>
            <div>1.</div>
            <div style={{width: 250, height: 25, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{fileName}</div>
            {(selectedQuestion.questionType !== QUESTION_TYPES.CI.name || !disabled) && <div onClick={handleClose} style={{ flex: 1, display: "flex", justifyContent: "end", cursor: "pointer" }}><DeleteImage /></div>}
          </div>
          {!isVideo &&
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px", width: "100%", marginTop: "2%", marginBottom: "3%" }}>
                <div style={{  fontSize: 15, display: "flex", gap: "2px", fontWeight: 700 }}>
                  <span role={TEXTBOX} tabIndex={0}> Alt text </span>
                  <YuJaTooltip text={ALT_TEXT_TOOL_TIP} tooltipStyle={{ maxWidth: 400 }} placement="top">
                    <span role={TEXTBOX} aria-label={"Tooltip text" + ALT_TEXT_TOOL_TIP}>
                      <InfoIcon />
                    </span>
                  </YuJaTooltip>
                </div>
                <YuJaTextBox
                  value={selectedQuestion?.imageAlt}
                  disabled={disabled}
                  maxLength={POLL_TITLE_LENGTH}
                  onChange={onChangeAltText}
                  placeholder={ALT_TEXT_PLACEHOLDER}
                  aria-label={ALT_TEXT_LABEL}
                  tabIndex={0}
                  containerStyle={{ width: "100%", padding: 5, border: "1.13px solid #858687" }}
                />
              </div>
            </div>
          }
        </>}
      </div>
  }
    {!clickable && dataloading && 
    <div style={{width: "100%"}}>
      <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                <Skeleton  height={62} borderRadius={10} style={{ position: "relative", marginBottom:15 }} />
                </SkeletonTheme>
                </div>
          
    }
    </>
  );
}
