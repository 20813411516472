import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { usePagination, useTable } from "react-table";
import NormalPagination from "../../components/pagination/NormalPagination";
import ArrowIcon from "../../images/arrow_right.svg";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import CIImageAnswerViewer from "../../questions/CI/CIImageAnswerViewer";
import { ASCII_CODE_A, BUTTON, CLOSE_MODAL, DEFAULT_ROWS_PER_PAGE, ENTRY_TEXT, FITB_BLANK, FITB_BLANK_LIMIT, FITB_NARRATOR, FIXED_ANSWER, NUMBER, NUMBER_LABEL, POLL_TYPE, QUESTION_LABEL, QUESTION_TYPES, SA_ANSWER_LENGTH, STATUS_LABEL, TEXTBOX, TYPE, TYPE_LABEL, UNANSWERED, VIEW_TEXT, WC_DISPLAY_LIMIT, YOUR_ANS_LABEL } from "../../utils/constants";
import { fixFITB, hasWeightage } from "../../utils/helpers";
import styles from "./ViewTakenPollsPage.module.css";

export default React.memo(TakenPollTable);
function TakenPollTable({
    institutionId="",
    selectedPoll = {},
    columns = [],
    setShowAnswerModal,
    setModalData,
    selectedPollLoading=false,
    numberSort,
    sortNumber,
    typeSort,
    sortType,
    statusSort,
    sortStatus
}) {
    const [modalShow, setModalShow] = useState(false);
    const [question, setQuestion] = useState({});
    const [scrollableContainer, setScrollableContainer] = useState(false);
    const [sortSelected, setSortSelected] = useState(0); 
    const skeletonLoaderWidth = ["50px", "40px", "60px", "30px", "50px"];
    const { questions=[], pollType} = selectedPoll;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable({
        columns,
        data: questions,
        initialState: { pageIndex: 0, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
    }, usePagination);

    const showQuestion = async (serialNo) => {
        const no = parseInt(serialNo);
        const selectedQuestion = questions.find(q => q.serialNo === no);
        setQuestion(selectedQuestion);
        setModalShow(true);
    };

    useEffect(() => {
        const handleResize = () => {
            const container = document.getElementById("taken-poll-container");
            setScrollableContainer(container.scrollWidth > container.clientWidth || container.scrollHeight > container.clientHeight);
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [selectedPollLoading]);

    const handleSort = (column) => {
        if (column.render('Header') === NUMBER) {
            sortNumber();
            setSortSelected(0);
        } else if(column.render('Header') === TYPE) {
            sortType();
            setSortSelected(1);
        } else if (column.render('Header') === STATUS_LABEL) {
            sortStatus();
            setSortSelected(2);
        }
    };

    return (
    <>
        <Modal
            id="question-modal"
            show={modalShow}
            onHide={() => setModalShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
            dialogClassName="question-modal2"
        >
            <ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_MODAL} onClick={() => setModalShow(false)} className="modal-close" />
            <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Modal.Title tabIndex={0} role={TEXTBOX}>
                    {`Q${question.serialNo} --- Your Answer`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CIImageAnswerViewer
                    question={question}
                />
            </Modal.Body>
        </Modal>
        <div className={styles.tableContainer} id="taken-poll-container">
            <Table responsive hover {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {
                                if (column.Header === 'Status' && (!hasWeightage(questions) || pollType?.toUpperCase() === POLL_TYPE.SURVEY)) return <></>;
                                return (
                                <th tabIndex={0} {...column.getHeaderProps({
                                    style: {
                                        width: column.width,
                                        minWidth: column.minWidth
                                    }
                                })}>
                                    <div className={column.Header === 'Number' ? styles.leftCell : styles.tableCell}>
                                        {column.render('Header')}
                                        {(column.render('Header') === NUMBER && numberSort === 1 && sortSelected === 0) ||
                                            (column.render('Header') === TYPE && typeSort === 1 && sortSelected === 1) ||
                                            (column.render('Header') === STATUS_LABEL && statusSort === 1 && sortSelected === 2) ?
                                        <SortDown tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => handleSort(column)}/>
                                        : (column.render('Header') === NUMBER && numberSort === 0 && sortSelected === 0) ||
                                            (column.render('Header') === TYPE && typeSort === -1 && sortSelected === 1) ||
                                            (column.render('Header') === STATUS_LABEL && statusSort === -1 && sortSelected === 2) ?
                                        <SortUp tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => handleSort(column)}/>
                                        : (column.render('Header') === NUMBER && numberSort === 1) ||
                                            (column.render('Header') === TYPE && typeSort === 1) ||
                                            (column.render('Header') === STATUS_LABEL && statusSort === 1) ?
                                        <SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => handleSort(column)}/>
                                        : (column.render('Header') === NUMBER && numberSort === 0) ||
                                            (column.render('Header') === TYPE && typeSort === -1) ||
                                            (column.render('Header') === STATUS_LABEL && statusSort === -1) ?
                                        <SortGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => handleSort(column)}/> : null}
                                    </div>
                                </th>);
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {!selectedPollLoading && page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    if (cell.column.Header === 'Number') {
                                        return (
                                            <td aria-label={NUMBER_LABEL + cell.row.original.serialNo} tabIndex={0} {...cell.getCellProps()}>
                                                <div className={styles.leftCell}>
                                                    Q{cell.row.original.serialNo}
                                                </div>
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Status') {
                                        if (!hasWeightage(questions) || pollType?.toUpperCase() === POLL_TYPE.SURVEY) return <></>;
                                        let display;
                                        const serialNo = cell.row.original.serialNo;
                                        const currQues = questions.find(q => q.serialNo === serialNo);
                                        const {isCorrect, isGraded, questionType, weightage, isPosted=true} = currQues;
                                        const gradeEnabled = !!weightage;
                                        if (!isPosted) { // not shared yet
                                            display = "Not Posted";
                                        } else if (!gradeEnabled) {
                                            display = "---"; // does not have weightage
                                        } else if (questionType === QUESTION_TYPES.SA.name) {
                                            if (isCorrect) { // isCorrect and isGraded have default value as false
                                                display = "Correct";
                                            } else if (isGraded) {
                                                display = "Incorrect";
                                            } else if ((!cell.row.original.hasOwnProperty("selectedOption") || cell.row.original.selectedOption === FIXED_ANSWER.UNANSWERED)) {
                                                display = "Unanswered";
                                            } else {
                                                display = "Not Graded";
                                            }
                                        } else {
                                            if (isCorrect) {
                                                display = "Correct";
                                            } else if ((!cell.row.original.hasOwnProperty("selectedOption") || cell.row.original.selectedOption === FIXED_ANSWER.UNANSWERED)) {
                                                display = "Unanswered";
                                            } else {
                                                display = "Incorrect";
                                            }
                                        }
                                        return (
                                            <td aria-label={STATUS_LABEL + display} tabIndex={0} {...cell.getCellProps()}>
                                                {display}
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Question') {
                                        if (cell.row.original.questionType === QUESTION_TYPES.FITB.name) {
                                            let display = fixFITB(cell.value);
                                            return (
                                                <td aria-label={QUESTION_LABEL + display.replaceAll(FITB_BLANK, FITB_NARRATOR)} tabIndex={0} {...cell.getCellProps()} style={{wordBreak: 'break-word'}}>
                                                    {display}
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td aria-label={QUESTION_LABEL + cell.value} tabIndex={0} {...cell.getCellProps()} style={{wordBreak: 'break-word'}}>
                                                    {cell.value}
                                                </td>
                                            );
                                        }
                                    }

                                    if (cell.column.Header === 'Type') {
                                        for (let type in QUESTION_TYPES) {
                                            if (QUESTION_TYPES[type].name === cell.value) {
                                                return (
                                                    <td aria-label={TYPE_LABEL + QUESTION_TYPES[type].description} tabIndex={0} {...cell.getCellProps()}>
                                                        {QUESTION_TYPES[type].description}
                                                    </td>
                                                );
                                            }
                                        };
                                    }

                                    if (cell.column.Header === 'Your Answer') {
                                        const gradeEnabled = !!cell.row.original?.weightage && pollType.toUpperCase() !== POLL_TYPE.SURVEY;
                                        if (cell.row.original.hasOwnProperty("isPosted") && !cell.row.original.isPosted) {
                                            return (
                                                <td aria-label={'Not Posted'} tabIndex={0} {...cell.getCellProps()}>
                                                    <div className={styles.leftCell}>
                                                        ---
                                                    </div>
                                                </td>
                                            );
                                        }
                                        if (!cell.row.original.hasOwnProperty("selectedOption") || cell.row.original.selectedOption === FIXED_ANSWER.UNANSWERED) {
                                            return (
                                                <td aria-label={YOUR_ANS_LABEL + !gradeEnabled ? 'Unanswered' : 'NA'} tabIndex={0} {...cell.getCellProps()}>
                                                    <div className={styles.leftCell}>
                                                        {!gradeEnabled ? 'Unanswered' : '---'}
                                                    </div>
                                                </td>
                                            );
                                        }
                                        let display = "---";
                                        var array;
                                        switch (cell.row.original.questionType) {
                                            case QUESTION_TYPES.MCSS.name:
                                                if (!gradeEnabled) {
                                                    if(cell.row.original.selectedOption[0] === FIXED_ANSWER.UNANSWERED) {
                                                        display = UNANSWERED;
                                                    }
                                                    else {
                                                        display = cell.row.original.selectedOption.sort((a, b) => a.localeCompare(b)).map((ch, idx) => {return <>{idx === 0 ? "" : ","}{ch.toUpperCase()}</>});
                                                    }
                                                } else {
                                                    display = cell.row.original.selectedOption.toUpperCase();
                                                }
                                                break;
                                            case QUESTION_TYPES.TF.name:
                                                display = cell.row.original.selectedOption.charAt(0).toUpperCase() + cell.row.original.selectedOption.slice(1);
                                                break;
                                            case QUESTION_TYPES.SA.name:
                                                display = cell.row.original.selectedOption["0"];
                                                if (display.length > SA_ANSWER_LENGTH) {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {/* {display.slice(0, SA_ANSWER_LENGTH)} */}
                                                            <div
                                                                onClick={() => {
                                                                    setModalData(display);
                                                                    setShowAnswerModal(true);
                                                                }}
                                                                className={styles.clickableAnswer}
                                                                style={{margin: "auto", textDecoration: "underline", textAlign: "left"}}
                                                            >
                                                                {VIEW_TEXT}
                                                            </div>
                                                        </td>
                                                    );
                                                }
                                                break;
                                                case QUESTION_TYPES.OE.name:
                                                    display = cell.row.original.selectedOption["0"];
                                                    if (display.length > SA_ANSWER_LENGTH) {
                                                        return (
                                                            <td {...cell.getCellProps()}>
                                                                {/* {display.slice(0, SA_ANSWER_LENGTH)} */}
                                                                <div
                                                                    onClick={() => {
                                                                        setModalData(display);
                                                                        setShowAnswerModal(true);
                                                                    }}
                                                                    className={styles.clickableAnswer}
                                                                    style={{margin: "auto", textDecoration: "underline", textAlign: "center"}}
                                                                >
                                                                    {VIEW_TEXT}
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                    break;
                                            case QUESTION_TYPES.FITB.name:
                                                array = Object.values(cell.row.original.selectedOption);
                                                if (array.length > FITB_BLANK_LIMIT) {
                                                    return (
                                                        <td {...cell.getCellProps()} className={styles.leftCell}>
                                                            {/* {array.slice(0, FITB_BLANK_LIMIT).map((key, index) => { return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-word' }}>Blank {index + 1}: {key}</p>); })} */}
                                                            <div
                                                                onClick={() => {
                                                                    setModalData(array.map((key, index) => {return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-word' }}>Blank {index + 1}: {key}</p>)}));
                                                                    setShowAnswerModal(true);
                                                                }}
                                                                className={styles.clickableAnswer}
                                                                style={{margin: "auto", textDecoration: "underline", textAlign: "center"}}
                                                            >
                                                                {VIEW_TEXT}
                                                            </div>
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td tabIndex={0} {...cell.getCellProps()}>
                                                        <div className={styles.leftCell}>
                                                            {array.map((key, index) => { return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-word' }}>Blank {index + 1}: {key}</p>); })}
                                                        </div>
                                                    </td>
                                                );
                                            case QUESTION_TYPES.CI.name:
                                                display = cell.row.original.selectedOption.selected;
                                                return (
                                                    <td tabIndex={0} aria-label={YOUR_ANS_LABEL + isNaN(display) ? display : String.fromCharCode(ASCII_CODE_A + parseInt(display))} className={styles.clickableAnswer} {...cell.getCellProps()} onClick={() => showQuestion(cell.row.original.serialNo)}>
                                                        <div className={styles.leftCell}>
                                                            {isNaN(display) ? "View": String.fromCharCode(ASCII_CODE_A + parseInt(display))}
                                                        </div>
                                                    </td>
                                                );
                                            case QUESTION_TYPES.RK.name: 
                                            array = cell.row.original.selectedOption;
                                                return (
                                                    <td {...cell.getCellProps()}>
                                                        <div
                                                            onClick={() => {
                                                                setModalData(array.map((key, index) => {return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-word' }}>Rank {index + 1}: {key}</p>)}));
                                                                setShowAnswerModal(true);
                                                            }}
                                                            className={styles.clickableAnswer}
                                                            style={{margin: "auto", textDecoration: "underline", textAlign: "left"}}
                                                        >
                                                            {VIEW_TEXT}
                                                        </div>
                                                    </td>
                                                );
                                            case QUESTION_TYPES.WC.name:
                                                array = Object.values(cell.row.original.selectedOption).filter(key => key !== "");
                                                if (array.length > WC_DISPLAY_LIMIT) { // display only first 3 words; or limit by characters?
                                                    return (
                                                        <td {...cell.getCellProps()} className={styles.leftCell}>
                                                            {/* {array.slice(0, FITB_BLANK_LIMIT).map((key, index) => { return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-word' }}>Blank {index + 1}: {key}</p>); })} */}
                                                            <div
                                                                onClick={() => {
                                                                    setModalData(array.map((key, index) => {
                                                                        if (key === "") return <></>;
                                                                        return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-word' }}>
                                                                            {ENTRY_TEXT} {index + 1}: {key}
                                                                        </p>)
                                                                    }));
                                                                    setShowAnswerModal(true);
                                                                }}
                                                                className={styles.clickableAnswer}
                                                                style={{margin: "auto", textDecoration: "underline", textAlign: "left"}}
                                                            >

                                                                {VIEW_TEXT}
                                                            </div>
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td tabIndex={0} {...cell.getCellProps()}>
                                                        <div className={styles.leftCell}>
                                                            {array.map((key, index) => {
                                                                return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-word' }}>
                                                                    {key}
                                                                </p>)    
                                                            })}
                                                        </div>
                                                    </td>
                                                );
                                            case QUESTION_TYPES.MH.name: 
                                                array = Object.keys(cell.row.original.selectedOption); 
                                                let values = cell.row.original.selectedOption; 
                                                let options = cell.row.original.optionsMap.filter(sublist => sublist.length === 2);
                                                return (
                                                    <td tabIndex={0} {...cell.getCellProps()} className={styles.leftCell}>
                                                        {/* {array.slice(0, FITB_BLANK_LIMIT).map((key, index) => { return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-word' }}>Blank {index + 1}: {key}</p>); })} */}
                                                        <div
                                                            className={styles.clickableAnswer}
                                                            onClick={() => {
                                                                setModalData(options.map((key, index) => {
                                                                    if (key === "") return <></>;
                                                                    return (<div style={{ display: "flex", width: "100%", gap: "10px", alignItems: "center", justifyContent: "center"}}> <p className="questionPanelAnswersText" id={"OptionPart1" + index} style={{ marginRight: "0px", border: ".5px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1, marginBottom: "0px", marginTop: "10px"}}>
                                                                        {key[0]}
                                                                    </p>
                                                                        <img
                                                                            src={ArrowIcon}
                                                                            alt="Arrow icon"
                                                                            className="questions-page-icons cursor-pointer"
                                                                            style={{ width: "30px", margin: "10px 0px 0px 10px" }}
                                                                        /> <p className="questionPanelAnswersText" id={"OptionPart2" + index} style={{ border: ".5px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1,  marginBottom: "0px", marginTop: "10px" }}>
                                                                            {values.hasOwnProperty(index) ? values[index] : UNANSWERED}
                                                                        </p> </div>)
                                                                }));
                                                                setShowAnswerModal(true);
                                                            }}
                                                            style={{margin: "auto", textDecoration: "underline", textAlign: "left"}}
                                                        >
                                                            {VIEW_TEXT}
                                                        </div>
                                                    </td>
                                                );

                                            default:
                                                break;
                                        }
                                        if (cell.row.original.questionType !== QUESTION_TYPES.FITB.name) {
                                            return (
                                                <td tabIndex={0} aria-label={YOUR_ANS_LABEL + display} style={{wordBreak: 'break-word'}} {...cell.getCellProps()}>
                                                    <div className={styles.leftCell}>
                                                        {display}
                                                    </div>
                                                </td>
                                            );
                                        }
                                    }

                                    return (
                                        <td tabIndex={0} {...cell.getCellProps()}>
                                            <div className={styles.leftCell}>
                                                {cell.value}
                                            </div>
                                        </td>
                                    );

                                })}
                            </tr>
                        )
                    })}

                    {
                        selectedPollLoading &&  (Array.from({ length: 5 }).map((_, rowIndex) => (
                            <tr key={rowIndex}> {headerGroups.map(headerGroup => (
                                <>
                                {headerGroup.headers.map(column => {
                                    if (column.Header === 'Status' && (!hasWeightage(questions) || pollType?.toUpperCase() === POLL_TYPE.SURVEY)) return <></>;
                                    return (
                                    <td tabIndex={0} {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth,
                                            height: 40
                                        }
                                    })}>
                                        <div className={column.Header === 'Number' ? styles.leftCell : styles.tableCell}>
                                            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                                <Skeleton borderRadius={10} height={14} width={skeletonLoaderWidth[rowIndex]} style={{ position: "relative" }} />
                                            </SkeletonTheme>
                                        </div>
                                    </td>);
                                })}
                                </> 
                                ))}
                            </tr>
                        )))
                    }
                </tbody>
            </Table>
            
            {page && page.length !== 0 ? <>
                {!scrollableContainer && <div style={{ height: "0px", marginTop: "40px" }} />}
                <div style={scrollableContainer ? { marginTop: "10px" } : { position: "absolute", bottom: "25px" }}>
                    <NormalPagination
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        gotoPage={gotoPage}
                    />
                </div>
            </> : <></>}
        </div>
    </>
    );
}
