import React, {useEffect, useRef, useState} from "react";
import styles from "./YuJaKebabMenu.module.css";
import {ReactComponent as MenuIcon} from "../../images/cebabe_menu_icon.svg"
import {useYuJaEventBus} from "./YuJaEventBusProvider";
import { BUTTON, TEXTBOX } from "../../utils/constants";
import {useWindowDimensions} from "react-native-web";
import {findFirstOverflowParent} from "../../utils/helpers";

export const YuJaKebabMenu = ({children, iconVisible=true, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const iconRef = useRef();

    const open = () => {
        setIsOpen(!isOpen);
    }

    const handleKeyDown = (event) => {
        if (event.key === ' ') {
          event.preventDefault();
          open()
        }
      };


    return (
        <div className={styles.container} >
            <div onClick={open}
            onMouseDown={e => e.preventDefault()}
            onKeyDown={handleKeyDown}
            ref={iconRef} style={{padding: "10px 20px 10px 20px", cursor: "pointer"}}>
            <MenuIcon
                aria-label="Open Menu"
                role={BUTTON}
                tabIndex={0}
                className={styles.focusIcon}
                style={{ opacity: iconVisible ? 1: 0, padding: iconVisible ? 0: 20}}
            />
             </div>
            {
                children &&
                React.cloneElement(
                    children,
                    {
                        ...children.props,
                        isOpen: isOpen,
                        setIsOpen: setIsOpen,
                        iconRef: iconRef,
                    }
                )
            }
        </div>
    );
}


function Menu({isOpen, setIsOpen, iconRef, children}) {
    const menuRef = useRef();
    const { addClickEventHandler, removeClickEventHandler } = useYuJaEventBus();
    const windowDimensions = useWindowDimensions();
    const [isTop, setIsTop] = useState(null);

    useEffect(() => {
        const handleGlobalClick = (event) => {
            // Check if the click occurred outside of the dropdown
            if (isOpen && menuRef.current && !iconRef.current.contains(event.target) && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            //find the first overflow hidden parent ele

            adaptMenuPosition();
            addClickEventHandler(handleGlobalClick);
            menuRef.current.style.visibility = "visible";
        } else {
            menuRef.current.style.visibility = "hidden";
        }

        return () => {
            removeClickEventHandler(handleGlobalClick);
        };
    }, [isOpen]);

    useEffect(() => {
        adaptMenuPosition();
    }, []);

    const adaptMenuPosition = () => {
        const parent = findFirstOverflowParent(iconRef.current.parentElement);
        const {top: parentTop, height: parentHeight} = parent.getBoundingClientRect();
        const { height, top} = iconRef.current.getBoundingClientRect();
        const menuHeight = menuRef.current.offsetHeight;

        /*
        parentTop
                <parent>             iconTop
                    |   <menuIcon/>  iconHeight
                    |                10
        parentHeight|   <menuList>
                    |   </menuList>  menuHeight
                </parent>
        */

        const isBottomOverflow = top + height + menuHeight + 15 > parentTop + parentHeight;
        const isTopOverflow = top - menuHeight - 15 < parentTop;
        if (isBottomOverflow && !isTopOverflow) {
            setIsTop(true);
        } else {
            setIsTop(false);
        }
    }

    return (
        <>

            <>
                <div className={`${styles.menuContainer} ${isTop ? styles.top : styles.bottom}`} ref={menuRef}>
                    <div className={`${styles.menuList}`} >
                        {
                            children && Array.isArray(children) &&
                            children.map((item, index) => {
                                    if (!item) {
                                        return null;
                                    }

                                    return  React.cloneElement(
                                            item,
                                            {
                                                ...item.props,
                                                id: index,
                                                key: index,
                                                isOpen: isOpen,
                                                setIsOpen: setIsOpen
                                            });
                            })
                        }


                        {
                            children && !Array.isArray(children) &&
                            React.cloneElement(
                                children,
                                {
                                    ...children.props,
                                    isOpen: isOpen,
                                    setIsOpen: setIsOpen
                                }
                            )

                        }
                    </div>
                </div>
                {/*<div className={styles.backDrop} onClick={() => setIsOpen(false)}/>*/}
            </>

        </>
    );
}


function Item({children, isOpen, label, setIsOpen, ...props}) {
    const nodeRef = useRef();

    useEffect(() => {
        const textNode = nodeRef.current;
        if (textNode && textNode.offsetWidth < textNode.scrollWidth) {
            textNode.title = textNode.innerText;
        }
        // console.log(`isOpen: ${isOpen}`)
    }, []);

    const onClick =(e) => {
        setIsOpen(false);
        if (props.onClick) {
            props.onClick(e);
        }

    }

    return (
        <div className={`${styles.menuItem}`} role={TEXTBOX} onClick={onClick} tabIndex={0} aria-label={label}>
            <span ref={nodeRef} className={styles.menuItemText}>{children}</span>
        </div>
    );
}


YuJaKebabMenu.Menu= Menu;
YuJaKebabMenu.Item= Item;