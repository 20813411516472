import React, { useEffect, useState } from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { UserApiCalls } from "../../apis/UserApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import { Spin } from "../../components/standardization/YuJaLoading";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { usePPTWebView } from "../../hooks/usePPTWebView";
import { useLoading } from "../../utils/LoadingContext";
import {
    AUDIENCE_RESTRICTION, DEFAULT_CLASS_RESULT_TIME_LIMIT,
    DEFAULT_GET_READY_ALL_TIME_LIMIT,
    DEFAULT_GET_READY_TIME_LIMIT,
    DEFAULT_POINTS,
    DEFAULT_POLL_LMS_SYNC, DEFAULT_POLL_MAX_ATTEMPTS,
    DEFAULT_POLL_RECORD_ATTEMPTS,
    DEFAULT_POLL_REQUIRED_NAME,
    DEFAULT_POLL_SHOW_CORRECT_ANS,
    DEFAULT_POLL_TIME_LIMIT,
    DEFAULT_PPT_AUTO_SHARE,
    DEFAULT_TIME_LIMIT, GO_BACK_POLLS_LINK, GRADED_POLL,
    POLL_SHARE_MODE,
    POLL_TIME_LIMIT_MODAL_CANCEL,
    POLL_TITLE_LENGTH,
    POLL_TYPE,
    QUESTION_TYPES, SURVEY,
    SWITCH_POLL_MESSAGE,
    SWITCH_POLL_MODAL_TEXT,
    SWITCH_POLL_MODAL_TITLE,
    USER_SETTINGS
} from "../../utils/constants";
import {getDefaultUserSettings, notifyError, removePrefix, validateText} from "../../utils/helpers";
import {
    FormatDate,
    addQuestion,
    chooseQuestion,
    questionError,
    trimQuestion
} from "../../utils/questionUtils";
import { POLL_TITLE_ERROR, QUESTION_MAX_POINTS_ERROR, SURVEY_TITLE_ERROR } from "../../utils/toast-message-constants";
import QuestionsMain from "../CreateQuizPage/QuestionsMain";
import styles from "./PPTCreatePollPage.module.css";
import PPTCreatePollSideBar from "./PPTCreatePollSideBar";
import {useImageLoaderManager} from "../../hooks/useImageLoaderManager";


export default function PPTCreatePollPage({}) {
    const { pollType } = useParams();
    const {isFromSidebar, goPollsPage, parseToken} = usePPTWebView();
    const [pollKey, setPollKey] = useState("");
    const [pollCode, setPollCode] = useState("");
    const [userSettings, setUserSettings] = useState(getDefaultUserSettings());
    const [currQueDisplay, setCurrQueDisplay] = useState(1);
    const [questions, setQuestions] = useState([]);
    const [title, setTitle] = useState("");
    // const [description, setDescription] = useState("");
    const { saveAndPublish, fakeCall } = PollApiCalls();
    const { loadUser } = UserApiCalls();
    // const { getSetting } = InstitutionApiCalls();
    const history = useHistory();
    // const [pollTypeModalShow, setPollTypeModalShow] = useState(false);
    const { loading, setLoading } = useLoading();
    const location = useLocation();
    const [setPollTitle, getPollTitle] = useLocalStorageNew("pollTitle", "");
    const [setQues, getQues] = useLocalStorageNew("questions", []);
    const [setType, getType] = useLocalStorageNew("pollType", "");
    const [setCreateTime, getCreateTime] = useLocalStorageNew("createTime", new Date());

    const [focusMCElem, setFocusMCElem] = useState(-1);
    const [focusSAElem, setFocusSAElem] = useState(-1);
    const [focusTFElem, setFocusTFElem] = useState(-1);
    const [focusFITBElem, setFocusFITBElem] = useState(-1);
    const [focusCIElem, setFocusCIElem] = useState(-1);
    const [focusMHElem, setFocusMHElem] = useState(-1);
    const [disableSave, setDisableSave] = useState(false);
    // const [focusWCElem, setFocusWCElem] = useState(-1);
    const [isConfirmationPending, setIsConfirmationPending] = useState(false);
    const [setSaveExit, getsaveExit] = useLocalStorageNew("saveExit", false);
    const [alertShow, setAlertShow] = useState(false);
    const [nextRouterPath, setNextRouterPath] = useState({ pathname: '', state: null });
    const [setRecentQuestions, getRecentQuestions] = useLocalStorageNew("recentQuestions", []);
    // const [setShowAnswer, getShowAnswer] = useLocalStorageNew("showAnswer", false);
    const [setPollKeyStorage, getPollKeyStorage] = useLocalStorageNew("PollKey", "");
    const [alertContent,] = useState(SWITCH_POLL_MESSAGE);

    const [isSaving, setIsSaving] = useState(false);

    const {checkAnyQuestionIsLoading} = useImageLoaderManager(questions, setQuestions);

    const initializeQuestions = () =>  {
        setLoading(true);
        const {institutionId, userId} = parseToken();
        loadUser(institutionId, userId).then(res => {
            const { settings = {} } = res;
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION)) {
                settings[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
            }
            settings[USER_SETTINGS.QUESTION_DURATION] = parseInt(settings[USER_SETTINGS.QUESTION_DURATION], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS)) {
                settings[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
            }
            settings[USER_SETTINGS.QUESTION_POINTS] = parseInt(settings[USER_SETTINGS.QUESTION_POINTS], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_COUNTDOWN)) {
                settings[USER_SETTINGS.QUESTION_COUNTDOWN] = DEFAULT_GET_READY_TIME_LIMIT;
            }
            settings[USER_SETTINGS.QUESTION_COUNTDOWN] = parseInt(settings[USER_SETTINGS.QUESTION_COUNTDOWN], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_DURATION)) {
                settings[USER_SETTINGS.POLL_DURATION] = DEFAULT_POLL_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_DURATION] = parseInt(settings[USER_SETTINGS.POLL_DURATION], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_COUNTDOWN)) {
                settings[USER_SETTINGS.POLL_COUNTDOWN] = DEFAULT_GET_READY_ALL_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_COUNTDOWN] = parseInt(settings[USER_SETTINGS.POLL_COUNTDOWN], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_REQUIRED_NAME)) {
                settings[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
            }
            settings[USER_SETTINGS.POLL_REQUIRED_NAME] = (settings[USER_SETTINGS.POLL_REQUIRED_NAME] === "true");
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_SHOW_CORRECT_ANS)) {
                settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = DEFAULT_POLL_SHOW_CORRECT_ANS;
            }
            settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = (settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === "true");
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_LMS_SYNC)) {
                settings[USER_SETTINGS.POLL_LMS_SYNC] = DEFAULT_POLL_LMS_SYNC;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_RECORD_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_MAX_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
            }
            settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = parseInt(settings[USER_SETTINGS.POLL_MAX_ATTEMPTS], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION)) {
                settings[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION] = DEFAULT_CLASS_RESULT_TIME_LIMIT;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.PPT_AUTO_SHARE)) {
                settings[USER_SETTINGS.PPT_AUTO_SHARE] = DEFAULT_PPT_AUTO_SHARE;
            } else {
                settings[USER_SETTINGS.PPT_AUTO_SHARE] = (settings[USER_SETTINGS.PPT_AUTO_SHARE] === "true" || settings[USER_SETTINGS.PPT_AUTO_SHARE] === true);
            }
            setUserSettings(settings);

            const ques = getQues();
            if (!!ques && !!ques.length) {
                setQuestions(ques);
            } else {
                const qs = addQuestion([], QUESTION_TYPES.MCSS.name, settings);
                setQuestions(qs);
                setQues(qs);
                setCurrQueDisplay(qs[0].serialNo);
            }
        }).catch(e => {
            const ques = getQues();
            if (!!ques && !!ques.length) {
                setQuestions(ques);
            } else {
                const qs = addQuestion([], QUESTION_TYPES.MCSS.name, userSettings);
                setQuestions(qs);
                setQues(qs);
                setCurrQueDisplay(qs[0].serialNo);
            }
        }).finally(() => {
            setLoading(false);
        });
    };


    const checkChanged = () => {
        const recentQuestions = getRecentQuestions();
        const curr_ques = JSON.stringify(recentQuestions);
        const curr_saved = JSON.stringify(getQues());
        if (curr_saved === "[]") {
            console.log("curr_saved is empty");
            return false;
        }
        else {
            return curr_ques !== curr_saved;
        }

    };

    useEffect(() => {
        if (questions.length < 1) {
            const qs = addQuestion(questions, QUESTION_TYPES.MCSS.name, userSettings);
            setQuestions(qs);
            setCurrQueDisplay(qs[0].serialNo);
        }
    }, [questions]);

    const alertConfig = {
        title: SWITCH_POLL_MODAL_TITLE,
        okText: SWITCH_POLL_MODAL_TEXT,
        cancelText: POLL_TIME_LIMIT_MODAL_CANCEL, 
        submit: async () => {
            setAlertShow(false);
            setIsConfirmationPending(true);
        },
        close:  async () => {
            setAlertShow(false);
        },
    };
    useEffect (() => {
        setRecentQuestions(questions);
    }, [questions]);

    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (!checkChanged() || getsaveExit() === true) {
                setSaveExit(false);
                resetPoll();
                return true;
            }
            if (location.pathname && !isConfirmationPending) {
                setNextRouterPath({ pathname: location.pathname, search:  location.search});
                setAlertShow(true);
                return false;
            }
            return true;
        });

        if (isConfirmationPending) {
            resetPoll();
            history.push(`${nextRouterPath.pathname}${nextRouterPath.search}`);
            setIsConfirmationPending(false);
            unblock();
        }

        return () => {
            unblock();
        };
    }, [history, isConfirmationPending, nextRouterPath]);

    useEffect(() => {
        if (questions.length < currQueDisplay) {
            return;
        }

        switch(questions[currQueDisplay-1].questionType) {
            case QUESTION_TYPES.MCSS.name:
                if(focusMCElem !== -1) {
                    document.getElementsByName("question-text")[focusMCElem].focus();
                    setFocusMCElem(-1);
                }
                break;
            case QUESTION_TYPES.SA.name:
                if(focusSAElem !== -1) {
                    document.getElementsByName("question-text")[focusSAElem].focus();
                    setFocusSAElem(-1);
                }
                break;
            case QUESTION_TYPES.TF.name:
                if(focusTFElem !== -1) {
                    document.getElementsByName("question-text")[focusTFElem].focus();
                    setFocusTFElem(-1);
                }
                break;
            case QUESTION_TYPES.FITB.name:
                if(focusFITBElem !== -1) {
                    if(focusFITBElem === 0) {
                        document.getElementsByClassName("notranslate public-DraftEditor-content")[focusFITBElem].focus();
                    } else {
                        document.getElementsByName("question-text")[focusFITBElem-1].focus();
                    }
                    setFocusFITBElem(-1);
                }
                break;
            case QUESTION_TYPES.CI.name:
                if(focusCIElem !== -1) {
                    document.getElementsByName("question-text")[focusCIElem].focus();
                    setFocusCIElem(-1);
                }
                break;

            case QUESTION_TYPES.MH.name:
                if(focusMHElem !== -1) {
                    console.log(focusMHElem);
                    document.getElementsByName("question-text")[focusMHElem].focus();
                    setFocusMHElem(-1);
                }
                break;
            case QUESTION_TYPES.WC.name:
                // if(focusWCElem !== -1) {
                //   document.getElementsByClassName("poll-sub-text focus-outline-none")[focusWCElem].focus();
                //   setFocusWCElem(-1);
                // }
                break;
        }
    }, [currQueDisplay, focusMCElem, focusSAElem, focusTFElem, focusFITBElem, focusCIElem, focusMHElem]);

    const validatePoll = (focusError=true) => {
        if (!validateText(title, POLL_TITLE_LENGTH)) {
            if (focusError) {
                notifyError(pollType.toUpperCase() === POLL_TYPE.SURVEY ? SURVEY_TITLE_ERROR : POLL_TITLE_ERROR);
                document.getElementsByName("pollTitle")[0].focus();
            }
            return false;
        }

        let i = 0;
        for (let q of questions) {
            i++;
            if (q.weightage > 1000) {
                if (focusError) {
                    let errorWithQuestion = "Question " + i + ": " + QUESTION_MAX_POINTS_ERROR;
                    notifyError(errorWithQuestion);
                    setCurrQueDisplay(q.serialNo);
                }
                return false;
            }
        }

        let index = 0;
        for (let q of questions) {
            index++;
            if (focusError) setCurrQueDisplay(q.serialNo);
            let translatePollType = "";
            if (pollType === POLL_TYPE.SURVEY) {
                translatePollType = SURVEY;
            } else if (pollType === POLL_TYPE.GRADED_POLL) {
                translatePollType = GRADED_POLL;
            }
            let {quesError, elemIndex} = questionError(q, translatePollType);
            switch(q.questionType) {
                case QUESTION_TYPES.MCSS.name:
                    if (focusError) setFocusMCElem(elemIndex);
                    break;
                case QUESTION_TYPES.SA.name:
                    if (focusError) setFocusSAElem(elemIndex);
                    break;
                case QUESTION_TYPES.TF.name:
                    if (focusError) setFocusTFElem(elemIndex);
                    break;
                case QUESTION_TYPES.FITB.name:
                    if (focusError) {
                        setTimeout(() => {
                            setFocusFITBElem(elemIndex);
                        }, 100);
                    }
                    break;
                case QUESTION_TYPES.CI.name:
                    if (focusError) setFocusCIElem(elemIndex);
                    break;
                case QUESTION_TYPES.MH.name:
                    if (focusError) setFocusMHElem(elemIndex);
                    break;
                case QUESTION_TYPES.WC.name:
                    // setFocusWCElem(elemIndex);
                    break;
            }

            if (quesError) {
                if (focusError) {
                    let errorWithQuestion = "Question " + index + ": " + quesError;
                    setQuestions(chooseQuestion(q.serialNo, questions));
                    notifyError(errorWithQuestion);
                    return false;
                }
            }
        }
        return true;
    };

    const changePollKey = (pollKeyStr) => {
        setPollKey(pollKeyStr);
        setPollKeyStorage(pollKeyStr);
    };

    const savePoll = async (updatedQuestions=[]) => {
        setDisableSave(true);
        let generatedTitle = generateDefaultPollTitle();

        let questionsCopy = JSON.parse(JSON.stringify(
            !!updatedQuestions && !!Object.entries(updatedQuestions).length
                ? updatedQuestions
                : questions
        ));

        let savedQuestions = trimQuestion(questionsCopy);

        let pollToAdd = {
            pollType: pollType,
            questions: savedQuestions,
            // ownerId: "794e0074-e325-4fb5-973c-d55750ab5324",
            pollTitle: generatedTitle,
            pollShareMode: POLL_SHARE_MODE.UNKNOWN,
            pollTimeLimit: userSettings[USER_SETTINGS.POLL_DURATION],
            getReadyAllTimeLimit: userSettings[USER_SETTINGS.POLL_COUNTDOWN],
            showAnswerOnViewer: userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS],
            // anonymousJoin: userSettings[USER_SETTINGS.POLL_REQUIRED_NAME],
            audienceRestriction: userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED,
            recordAttempt: userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS],
            lmsAttempt: userSettings[USER_SETTINGS.POLL_LMS_SYNC],
            maxAttempts: userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS],
            // pollDescription: description
            pptAutoShare: userSettings[USER_SETTINGS.PPT_AUTO_SHARE]
        };

        if (!!pollKey) {
            pollToAdd.pollKey = pollKey;
        }

        let result = await saveAndPublish(pollToAdd, false);
        if (!result) {
            setDisableSave(false);
            return false;
        }

        const {draft={}, uniqueCode} = result;
        if (uniqueCode) {
            setPollCode(uniqueCode);
        }

        setQuestions(questionsCopy);
        setPollTitle(generatedTitle);
        setQues(questionsCopy);
        setType(pollType);
        changePollKey(removePrefix(draft.pollKey));
        setDisableSave(false);
        window.onbeforeunload = undefined;
        return true;
    };

    const savePollClose = async () => {
        setDisableSave(true);
        setSaveExit(true);
        if (!validatePoll()) {
            setDisableSave(false);
            return;
        }

        let questionsCopy = JSON.parse(JSON.stringify(questions));
        questionsCopy = trimQuestion(questionsCopy);

        let pollToAdd = {
            pollType: pollType,
            questions: questionsCopy,
            pollTitle: title,
            pollShareMode: POLL_SHARE_MODE.UNKNOWN,
            pollTimeLimit: userSettings[USER_SETTINGS.POLL_DURATION],
            getReadyAllTimeLimit: userSettings[USER_SETTINGS.POLL_COUNTDOWN],
            showAnswerOnViewer: userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS],
            // anonymousJoin: userSettings[USER_SETTINGS.POLL_REQUIRED_NAME],
            audienceRestriction: userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED,
            recordAttempt: userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS],
            lmsAttempt: userSettings[USER_SETTINGS.POLL_LMS_SYNC],
            maxAttempts: userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS],
            pptAutoShare: userSettings[USER_SETTINGS.PPT_AUTO_SHARE]
        };

        if (!!pollKey) {
            pollToAdd.pollKey = pollKey;
        }

        let result = await saveAndPublish(pollToAdd);
        if (!result) {
            setDisableSave(false);
            return;
        }

        setDisableSave(false);
        window.onbeforeunload = undefined;
        goPollsPage();
    };


    useEffect(() => {
        return () => {
            window.onbeforeunload = undefined;
        };
    }, []);



    const resetPoll = () => {
        // window.onbeforeunload = undefined;
        // window.location = window.location.href;
        changePollKey("");
        setCreateTime(new Date());
        // setPollCode();
        setQuestions([]);
        setTitle("");
        // setDescription("");
        // setCurrQueDisplay("1");
        setPollTitle("");
        setQues([]);
        // setShowAnswer(false);
        setType("");
        // setType(pollType);
        // history.push({pathname: "/create", state : { pollType : pollType }})
    }

    useEffect(async () => {
        const ques = getQues();
        const pollTitle = getPollTitle();
        if (pollTitle && pollTitle !== "") {
            setTitle(pollTitle);
        }

        initializeQuestions();


        if (!pollKey) {
            fakeCall().then();
        }
    }, [location]);



    // code that saves when refresh
    // useEffect(() => {
    //   const handleBeforeUnload = async () => {
    //       if (validateText(title, POLL_TITLE_LENGTH)) {
    //         await savePoll(false);
    //         setPollTitle(title);
    //         setQues(questions);
    //         setType(pollType);
    //     }
    //   };

    //   window.addEventListener('beforeunload', handleBeforeUnload);

    //   return () => {
    //     window.removeEventListener('beforeunload', handleBeforeUnload);
    //   };
    // }, [title, questions, pollType]);

    // Set the image url to an empty string for each question so that image load correctly
    useEffect(() => {
        const handleBeforeUnload = async () => {
            const Local_questions = getQues();
            const updatedQuestions = Local_questions.map((question) => ({
                ...question,
                imgUrl: "",
            }));
            setQues(updatedQuestions)
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [title, questions, pollType]);

    // const handlePollTypeChange = async (newPollType, isSave) => {
    //   // Switch without saving
    //   // if (!isSave) {
    //   //   resetPoll();
    //   //   return true;
    //   // }
    //   await setShowAnswerOnViewer(USER_SETTINGS.POLL_SHOW_CORRECT_ANS);
    //   await setAnonymousJoin(USER_SETTINGS.POLL_REQUIRED_NAME);
    //   // if (await savePoll(false)) {
    //   resetPoll();
    //   return true;
    //   // }
    //   // return false;
    // };

    useEffect(() => {
        const interval = setInterval(() => {
            if (checkAnyQuestionIsLoading()) {
                return;
            }

            if (title !== getPollTitle() || JSON.stringify(questions) !== JSON.stringify(getQues())) {
                // console.log("auto saving");
                setIsSaving(true);
                savePoll(false);
            }
        }, 10000);
        return () => clearInterval(interval);
    }, [title, questions, pollType, pollKey, pollCode]);


    useEffect(() => {
        if (isSaving) {
            const gifTimer = setTimeout(() => {
                setIsSaving(false);
            }, 5000);

            return () => clearTimeout(gifTimer);
        }
    }, [isSaving]);

    const generateDefaultPollTitle = () => {
        let res = "";
        if (!title) {
            res = "Draft: " + FormatDate(new Date(getCreateTime()));
            setTitle(res);
            setPollTitle(res);
            return res;
        } else {
            return title;
        }
    };

    return (
        <div className={styles.container}>
            <PPTCreatePollSideBar
                pollKey={pollKey}
                setQuestions={setQuestions}
                questions={questions}
                currQueDisplay={currQueDisplay}
                setCurrQueDisplay={setCurrQueDisplay}
                userSettings={userSettings}
                changePollKey={changePollKey}
            />
            <YuJaGeneralAlert
                show={alertShow}
                setModalShow={setAlertShow}
                content={alertContent}
                config={alertConfig}
            />

            <div style={{flex: 1, height: "100%"}}>
                <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                    <div style={{ backgroundColor: "#FCFBFF", padding: 20,  height: "100%", overflowY: "hidden", display: "flex", flexDirection: "column"}}>
                        {isFromSidebar() &&
                            <div style={{position: "absolute", top: 10}}>
                                <div className={styles.goBackLink} onClick={goPollsPage}>
                                    <MdArrowBackIosNew/> {GO_BACK_POLLS_LINK}
                                </div>
                            </div>
                        }

                        {questions.length > 0 &&
                            <QuestionsMain
                                title={title}
                                setTitle={setTitle}
                                questions={questions}
                                setQuestions={setQuestions}
                                savePoll={savePoll}
                                savePollClose={savePollClose}
                                pollKey={pollKey}
                                changePollKey={changePollKey}
                                currQueDisplay={currQueDisplay}
                                changeCurrQueDisplay={setCurrQueDisplay}
                                pollType={pollType}
                                pollCode={pollCode}
                                isSaving={isSaving}
                                disableSave={disableSave}
                                hasBlankRestriction={true}
                                generateDefaultPollTitle={generateDefaultPollTitle}
                                userSettings={userSettings}
                            />
                        }
                    </div>
                </Spin>
            </div>
        </div>
    );
}