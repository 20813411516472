import React, { useEffect, useState } from "react";
import { Form, Card } from "react-bootstrap";
import useHostname from "../../hooks/useHostname";
import LoginIcon from "../../images/login_blue.svg";

import {
    DEFAULT_LOGIN_METHODS,
    LOGIN_SETTINGS_ERROR_MSG,
    LMS_GRADEBOOK_SYNC,
    PLATFORM_SETTINGS,
    YUJA_DISABLE_LABEL,
    TEXTBOX,
    QUES_BANK_CHECKBOX,
    SETT_LABEL,
    RADIAL_BTN,
    PWD_EXPIRY_LABEL
} from "../../utils/constants";
import { checkAllEmpty, notifyError } from "../../utils/helpers";
import styles from "./platform.module.css";
import {YuJaCheckbox} from "../../components/standardization/YuJaCheckbox";
import {YuJaRadioBox} from "../../components/standardization/YuJaRadiobox";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import {YuJaDropdown} from "../../components/standardization/YuJaDropdown";
import { LMS_DISABLE_YUJA_ERROR } from "../../utils/toast-message-constants";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";



export default function PlatformSettingTab({platformSettings, setPlatformSettings, loading=false}) {
    const hostResource = useHostname(window.location.hostname);
    const [, setLMSAttemptSetting] = useState("");
    const [showADFS, setShowADFS] = useState(false);
    const [showSHIBBOLETH, setShowSHIBBOLETH] = useState(false);
    const [showDCAM, setShowDCAM] = useState(false);
    const [showGOOGLE, setShowGOOGLE] = useState(false);
    const [showAZURE, setShowAZURE] = useState(false);

    const attemptSettingsArr = [
        LMS_GRADEBOOK_SYNC.FIRST,
        LMS_GRADEBOOK_SYNC.RECENT,
        LMS_GRADEBOOK_SYNC.HIGHEST
    ];

    useEffect(() => {
        const {platformSetting, ssoConfig={}} = hostResource;
        if (platformSetting && platformSetting.hasOwnProperty(PLATFORM_SETTINGS.LMS_QUIZ_ATTEMPTS)) {
            const LMSQuizAttempts = platformSetting[PLATFORM_SETTINGS.LMS_QUIZ_ATTEMPTS];
            setLMSAttemptSetting(LMSQuizAttempts);
        }
        if(ssoConfig.ADFS && !checkAllEmpty(ssoConfig.ADFS)) {
          setShowADFS(true);
        }
        if(ssoConfig.SHIBBOLETH && !checkAllEmpty(ssoConfig.SHIBBOLETH)) {
          setShowSHIBBOLETH(true);
        }
        if(ssoConfig.DCAM && !checkAllEmpty(ssoConfig.DCAM)) {
          setShowDCAM(true);
        }
        if(ssoConfig.GOOGLE && !checkAllEmpty(ssoConfig.GOOGLE)) {
          setShowGOOGLE(true);
        }
        if(ssoConfig.AZURE && !checkAllEmpty(ssoConfig.AZURE)) {
          setShowAZURE(true);
        }
    } , [hostResource]) 


    useEffect(() => {
        if (!platformSettings) {
            return; 
        }
        var replaced = false; 
        if (platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_ADFS && showADFS === false) {
            replaced = true; 
        }
        else if (platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_SHI && showSHIBBOLETH === false) {
            replaced = true; 
        }
        else if (platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_DCAM && showDCAM === false) {
            replaced = true; 
        }
        else if (platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_GOOGLE && showGOOGLE === false) {
            replaced = true;
        }
        else if (platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_AZURE && showAZURE == false) {
            replaced = true; 
        }

        if (replaced) {
            const copy = JSON.parse(JSON.stringify(platformSettings));
            copy[PLATFORM_SETTINGS.DEFAULT] = "1";
            setPlatformSettings(copy);
        }
    }, [showADFS, showSHIBBOLETH, showDCAM, showGOOGLE, showAZURE, platformSettings]);
    
    const handleLMSAttemptSettingChange = (item) => {
        const copy = JSON.parse(JSON.stringify(platformSettings));
        setLMSAttemptSetting(item.value);
        copy[PLATFORM_SETTINGS.LMS_QUIZ_ATTEMPTS] = item.value;
        setPlatformSettings(copy);
    };

    const handleAnonymousJoinSettingChange = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.ANONYMOUS_JOIN] = input.checked;
        setPlatformSettings(copy);
    };

    const handleLoginSettingChange = (e) => {
        if (!showADFS && !showSHIBBOLETH && !showDCAM && !showGOOGLE && !showAZURE) {
            notifyError(LOGIN_SETTINGS_ERROR_MSG)
            return; 
        }
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        if (platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.YUJA_LOGIN && input.checked) {
            if (showADFS) {
                copy[PLATFORM_SETTINGS.DEFAULT] = DEFAULT_LOGIN_METHODS.SSONAME_ADFS;
            } else if (showSHIBBOLETH) {
                copy[PLATFORM_SETTINGS.DEFAULT] = DEFAULT_LOGIN_METHODS.SSONAME_SHI;
            } else if (showDCAM) {
                copy[PLATFORM_SETTINGS.DEFAULT] = DEFAULT_LOGIN_METHODS.SSONAME_DCAM;
            } else if (showGOOGLE) {
                copy[PLATFORM_SETTINGS.DEFAULT] = DEFAULT_LOGIN_METHODS.SSONAME_GOOGLE;
            } else if (showAZURE) {
                copy[PLATFORM_SETTINGS.DEFAULT] = DEFAULT_LOGIN_METHODS.SSONAME_AZURE;
            }

        }
        copy[PLATFORM_SETTINGS.YUJA_LOGIN] = input.checked.toString();
        setPlatformSettings(copy);
    };


    const handlePwdResetChange = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.PWD_RESET] = input.checked.toString();
        setPlatformSettings(copy);
    };

    const handleYujaLoginNameChange = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.YUJA_NAME] = input.value;
        setPlatformSettings(copy);
    }; 

    const handleSSONameChangeADFS = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.SSONAME_ADFS] = input.value;
        setPlatformSettings(copy);
    }

    const handleSSONameChangeSHI = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.SSONAME_SHI] = input.value;
        setPlatformSettings(copy);
    }

    const handleSSONameChangeDCAM = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.SSONAME_DCAM] = input.value;
        setPlatformSettings(copy);
    }

    const handleSSONAMEChangeGOOGLE = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.SSONAME_GOOGLE] = input.value;
        setPlatformSettings(copy);
    }

    const handleSSONAMEChangeAZURE = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.SSONAME_AZURE] = input.value;
        setPlatformSettings(copy);
    }

    const handleDefaultChange = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(platformSettings));
        copy[PLATFORM_SETTINGS.DEFAULT] = input.value;
        setPlatformSettings(copy);
    }

    return (<div>
        <div style={{display: "flex", flexDirection: "row"}}>
            {/* <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", flex: 1, marginRight: "50px"}}>
                <div>
                <Card className={styles.smallBox}>
                    <Card.Header>
                        <img src={PollIcon} alt="PollIcon" width={"24px"} height={"24px"} />
                        <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>Poll Settings</span>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group>
                                <YuJaCheckbox      
                                    ariaLabel={QUES_BANK_CHECKBOX + DISABLE_ANONYMOUS_PLATFORM + ((platformSettings[PLATFORM_SETTINGS.ANONYMOUS_JOIN] === "true" || platformSettings[PLATFORM_SETTINGS.ANONYMOUS_JOIN] === true) ? "checked" : "unchecked")}             
                                    name="anonymous"
                                    label={DISABLE_ANONYMOUS_PLATFORM}
                                    checked={platformSettings[PLATFORM_SETTINGS.ANONYMOUS_JOIN] === "true" || platformSettings[PLATFORM_SETTINGS.ANONYMOUS_JOIN] === true}
                                    onClick={handleAnonymousJoinSettingChange}
                                />
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
                </div>
                <div>
                <Card className={styles.smallBox}>
                    <Card.Header>
                        <img src={GradebookIcon} alt="GradebookIcon" width={"24px"} height={"24px"} />
                        <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{LMS_GRADEBOOK_SETTINGS_TITLE}</span>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group>
                                <span tabIndex={0} role={TEXTBOX} className="form-labelReg">
                                    {LMS_GRADEBOOK_SETTINGS_SUBTITLE}
                                </span>
                                <YuJaDropdown
                                    ariaLabel={ATMPT_DROPDOWN + LMSAttemptSetting}
                                    containerStyle={{maxWidth: "250px", marginTop: "15px"}}
                                    data={attemptSettingsArr}
                                    placeholder={NO_SELECTION_TEXT}
                                    value={LMSAttemptSetting}
                                    onChange={handleLMSAttemptSettingChange}
                                />
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
                </div>
            </div> */}
            <div style={{display: "flex", flex: 1, height: "fit-content"}}>
                <Card className={styles.smallBox}>
                    <Card.Header>
                        <img src={LoginIcon} alt="LoginIcon" width={"24px"} height={"24px"} />
                        <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>Login Settings</span>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group>
                            {!loading &&
                            <>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div tabIndex={0} role={TEXTBOX} className="form-labelRegTitle" style={{flex: 3}}>Enterprise Login</div>
                                    <div style={{flex: 5}}></div>
                                    <div tabIndex={0} role={TEXTBOX} className="form-labelRegTitle" style={{flex: 3,  textAlign: "center"}}>Default</div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
                                    <div className="form-label" style={{flex: 3}}>YuJa Login</div>
                                    <div style={{flex: 5}}>
                                        <YuJaTextBox
                                            label={"YuJa Login" + SETT_LABEL}
                                            containerStyle={{fontSize: "14px"}}
                                            disabled={platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === "true"}
                                            type="text"
                                            value={platformSettings[PLATFORM_SETTINGS.YUJA_NAME]}
                                            onChange={handleYujaLoginNameChange}
                                        />
                                    </div>
                                    <div style={{flex: 3}}>
                                        <YuJaRadioBox
                                            aria-label={"YuJa Login" +  RADIAL_BTN + ((platformSettings[PLATFORM_SETTINGS.DEFAULT] === null || platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.YUJA_LOGIN) ? "selected" : "unselected")}
                                            size={20}
                                            value={DEFAULT_LOGIN_METHODS.YUJA_LOGIN}
                                            disabled={platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === "true"}
                                            checked={platformSettings[PLATFORM_SETTINGS.DEFAULT] === null || platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.YUJA_LOGIN}
                                            onClick={handleDefaultChange}
                                        />
                                    </div>
                                </div>
                                {showADFS && 
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
                                    <div className="form-label" style={{flex: 3}}>SSO - ADFS</div>
                                    <div style={{flex: 5}}>
                                        <YuJaTextBox
                                            label={"SSO - ADFS" + SETT_LABEL}
                                            containerStyle={{ fontSize: "14px" }}
                                            type="text"
                                            value={platformSettings[PLATFORM_SETTINGS.SSONAME_ADFS]}
                                            onChange={handleSSONameChangeADFS}
                                        />
                                    </div>
                                    <div style={{flex: 3}}>
                                        <YuJaRadioBox
                                            aria-label={"SSO - ADFS" +  RADIAL_BTN + ((platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_ADFSN) ? "selected" : "unselected")}
                                            size={20}
                                            value={DEFAULT_LOGIN_METHODS.SSONAME_ADFS}
                                            checked={platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_ADFS}
                                            onClick={handleDefaultChange}
                                        />
                                    </div>
                                </div>}
                                {showSHIBBOLETH && 
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
                                    <div className="form-label" style={{flex: 3}}>SSO - SHIBBOLETH</div>
                                    <div style={{flex: 5}}>
                                        <YuJaTextBox
                                            label={"SSO - SHIBBOLETH" + SETT_LABEL}
                                            containerStyle={{ fontSize: "14px" }}
                                            type="text"
                                            value={platformSettings[PLATFORM_SETTINGS.SSONAME_SHI]}
                                            onChange={handleSSONameChangeSHI}
                                        />
                                    </div>
                                    <div style={{flex: 3}}>
                                        <YuJaRadioBox
                                            aria-label={"SSO - SHIBBOLETH" +  RADIAL_BTN + ((platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_SHI) ? "selected" : "unselected")}
                                            size={20}
                                            value={DEFAULT_LOGIN_METHODS.SSONAME_SHI}
                                            checked={platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_SHI}
                                            onClick={handleDefaultChange}
                                        />
                                    </div>
                                </div>}
                                {showDCAM && 
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
                                    <div className="form-label" style={{flex: 3}}>SSO - DCAM</div>
                                    <div style={{flex: 5}}>
                                        <YuJaTextBox
                                            label={"SSO - DCAM" + SETT_LABEL}
                                            containerStyle={{ fontSize: "14px" }}
                                            type="text"
                                            value={platformSettings[PLATFORM_SETTINGS.SSONAME_DCAM]}
                                            onChange={handleSSONameChangeDCAM}
                                        />
                                    </div>
                                    <div style={{flex: 3}}>
                                        <YuJaRadioBox
                                            aria-label={"SSO - DCAM" +  RADIAL_BTN + ((platformSettings[PLATFORM_SETTINGS.SSONAME_DCAM]) ? "selected" : "unselected")}
                                            size={20}
                                            value={DEFAULT_LOGIN_METHODS.SSONAME_DCAM}
                                            checked={platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_DCAM}
                                            onClick={handleDefaultChange}
                                        />
                                    </div>
                                </div>}
                                {showGOOGLE && 
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "10px"}}>
                                    <div className="form-label" style={{flex: 3}}>SSO - GOOGLE</div>
                                    <div style={{flex: 5}}>
                                        <YuJaTextBox
                                            label={"SSO - GOOGLE" + SETT_LABEL}
                                            containerStyle={{ fontSize: "14px" }}
                                            type="text"
                                            value={platformSettings[PLATFORM_SETTINGS.SSONAME_GOOGLE]}
                                            onChange={handleSSONAMEChangeGOOGLE}
                                        />
                                    </div>
                                    <div style={{flex: 3}}>
                                        <YuJaRadioBox
                                            aria-label={"SSO - GOOGLE" +  RADIAL_BTN + ((platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_GOOGLE) ? "selected" : "unselected")}
                                            size={20}
                                            value={DEFAULT_LOGIN_METHODS.SSONAME_GOOGLE}
                                            checked={platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_GOOGLE}
                                            onClick={handleDefaultChange}
                                        />
                                    </div>
                                </div>}
                                {showAZURE &&
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "20px"}}>
                                    <div className="form-label" style={{flex: 3}}>SSO - AZURE</div>
                                    <div style={{flex: 5}}>
                                        <YuJaTextBox
                                            label={"SSO - AZURE" + SETT_LABEL}
                                            containerStyle={{ fontSize: "14px" }}
                                            type="text"
                                            value={platformSettings[PLATFORM_SETTINGS.SSONAME_AZURE]}
                                            onChange={handleSSONAMEChangeAZURE}
                                        />
                                    </div>
                                    <div style={{flex: 3}}>
                                        <YuJaRadioBox
                                            aria-label={"SSO - AZURE" +  RADIAL_BTN + ((platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_AZURE) ? "selected" : "unselected")}
                                            size={20}
                                            value={DEFAULT_LOGIN_METHODS.SSONAME_AZURE}
                                            checked={platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.SSONAME_AZURE}
                                            onClick={handleDefaultChange}
                                        />
                                    </div>
                                </div>}
                                <YuJaCheckbox
                                    ariaLabel={QUES_BANK_CHECKBOX + YUJA_DISABLE_LABEL + ((platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === "true" || platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === true) ? "checked" : "unchecked")}
                                    label={YUJA_DISABLE_LABEL}
                                    checked={platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === "true" || platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === true}
                                    onClick={handleLoginSettingChange}
                                    id="platformLastElem"
                                />
                                <div style={{height: 10}}/>
                                <YuJaCheckbox
                                    ariaLabel={PLATFORM_SETTINGS.PWD_RESET + (platformSettings[PLATFORM_SETTINGS.PWD_RESET] === "true"  ? "checked" : "unchecked")}
                                    label={PWD_EXPIRY_LABEL}
                                    checked={platformSettings[PLATFORM_SETTINGS.PWD_RESET] === "true"}
                                    onClick={handlePwdResetChange}
                                />

                                </>
                            }

                                { loading && <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                                <div style={{ flex: 3, display: "flex", alignItems: "center"}}>
                                                    <Skeleton height={21} width={160}  inline={true} borderRadius={10} />
                                                </div>
                                                <div style={{ flex: 5 }}>
                                                </div>
                                                <div style={{ flex: 3, display: "flex", justifyContent: "center" }}>
                                                    <Skeleton height={21} width={50} inline={true} borderRadius={10} />
                                                </div>
                                            </SkeletonTheme>
                                                </div>
                                                <div style={{display: "flex"}}>
                                                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                                <div style={{ flex: 3, display: "flex", alignItems: "center"}}>
                                                    <Skeleton height={19} width={90}  inline={true} borderRadius={10} />
                                                </div>
                                                <div style={{ flex: 5 }}>
                                                    <Skeleton height={37} inline={true} borderRadius={10} />
                                                </div>
                                                <div style={{ flex: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Skeleton height={20} width={20} borderRadius={10} inline={true} circle={true} />
                                                </div>
                                            </SkeletonTheme>
                                            </div>
                                            <div style={{display: "flex"}}>
                                                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                                <div style={{ flex: 3, display: "flex", alignItems: "center"}}>
                                                <Skeleton height={19} width={90}  borderRadius={10} inline={true} />
                                                </div>
                                                    <div style={{ flex: 5}}>
                                                        <Skeleton height={37} borderRadius={10} inline={true} />
                                                    </div>
                                                    <div style={{ flex: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <Skeleton height={20} width={20} borderRadius={10} inline={true} circle={true}/>
                                                    </div>
                                                </SkeletonTheme>
                                                </div>
                                                <div style={{display: "flex"}}>
                                                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                                    <div style={{ flex: 3, display: "flex", alignItems: "center"}}>
                                                    <Skeleton height={19} width={110} borderRadius={10}  inline={true} />
                                                    </div>
                                                    <div style={{ flex: 5 }}>
                                                        <Skeleton height={37} borderRadius={10} inline={true} />
                                                    </div>
                                                    <div style={{ flex: 3, display: "flex", justifyContent: "center" , alignItems: "center"}}>
                                                        <Skeleton height={20} width={20} borderRadius={10} inline={true} circle={true}/>
                                                    </div>
                                                </SkeletonTheme>
                                                </div>
                                                <div style={{display: "flex"}}>
                                                    <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                                <div style={{ flex: 3, display: "flex", alignItems: "center" }}>
                                                    <Skeleton height={19} width={90} borderRadius={10} inline={true} />
                                                </div>
                                                        <div style={{ flex: 5 }}>
                                                            <Skeleton height={37} borderRadius={10} inline={true} />
                                                        </div>
                                                        <div style={{ flex: 3, display: "flex", justifyContent: "center" , alignItems: "center"}}>
                                                            <Skeleton height={20} width={20} borderRadius={10} inline={true} circle={true}/>
                                                        </div>
                                                    </SkeletonTheme>
                                                    </div>
                                                    <div style={{display: "flex"}}>
                                        <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                            <div style={{ flex: 3, display: "flex", alignItems: "center"}}>
                                                <Skeleton height={19} width={90}  borderRadius={10} inline={true} />
                                                </div>
                                            <div style={{ flex: 5 }}>
                                                <Skeleton height={37} borderRadius={10} inline={true} />
                                            </div>
                                            <div style={{ flex: 3, display: "flex", justifyContent: "center" , alignItems: "center"}}>
                                                <Skeleton height={20} width={20} borderRadius={10} inline={true} circle={true}/>
                                            </div>
                                        </SkeletonTheme>
                                        </div>
                                </div>
                                    }
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>    
            </div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", flex: 1, marginRight: "50px"}}></div>
        </div>
        </div>
    );
}