import classes from "./Report.module.css"
import React, {useEffect, useState} from "react";
import { ReactComponent as ARROW_RIGHT } from "../../images/arrow_right.svg";
import {MonthPicker} from "./MonthPicker";
import {ReactComponent as POLL_ICON}  from "./images/MetricIconPoll.svg";
import {ReactComponent as ATTENDANCE_ICON}  from "./images/MetricIconAttendance.svg";
import {ReactComponent as TOTAL_QUE_ICON}  from "./images/MetricIconTotalQue.svg";
import {ReactComponent as RESPONSE_ICON}  from "./images/MetricIconResponse.svg";
import {ReactComponent as PARTICIPANT_ICON}  from "./images/MetricIconParticipant.svg";
import {ReactComponent as LINE_CHART_ICON}  from "./images/LineChartInstIcon.svg";
import {ReactComponent as TABLE_ICON}  from "./images/LineChartCourseIcon.svg";

import {
    REPORT_ATTENDANCE_METRIC_TITLE,
    REPORT_DATE_FILTER_LABEL,
    REPORT_INSTITUTION_LINE_CHART_DESC, REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_ATTENDANCES,
    REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_OVERALL,
    REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_POLLS,
    REPORT_INSTITUTION_LINE_CHART_TITLE,
    REPORT_INSTITUTION_PAGE_TAB_KEY,
    REPORT_INSTITUTION_TABLE_DESC,
    REPORT_INSTITUTION_TABLE_TITLE,
    REPORT_INSTITUTION_TITLE,
    REPORT_INSTITUTION_TITLE_DESC,
    REPORT_PARTICIPANT_METRIC_TITLE,
    REPORT_POLL_METRIC_TITLE,
    REPORT_RESPONSE_METRIC_TITLE,
    REPORT_TOTAL_QUE_METRIC_TITLE
} from "../../utils/constants";
import {YuJaCheckbox} from "../../components/standardization/YuJaCheckbox";
import {CartesianGrid, LineChart, Tooltip, XAxis, YAxis, Line, Label, ResponsiveContainer} from "recharts";
import CourseTab from "./CourseTab";
import {ReportApiCalls} from "../../apis/ReportApiCalls";
import StandaloneTab from "./StandaloneTab";
import {
    getFullMonthYear,
    getMetricBGColorByCount,
    getMetricColorByCount,
    getShortMonthYear,
    getTrendByCount, getYearMonthStr, isCountIncreased
} from "./ReportHelper";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {useWindowDimensions} from "react-native-web";

export default function ReportInstPage({ }) {
    const {getInstitutionWiseReport} = ReportApiCalls();
    let {width: windowWidth} = useWindowDimensions();

    const [reportStartDate, setReportStartDate] = useState(() => {
        let currentDate = new Date();
        currentDate.setDate(1);
        currentDate.setMonth(currentDate.getMonth() - 6);
        return getYearMonthStr(currentDate);
    });
    const [reportEndDate, setReportEndDate] = useState(() =>{
        let currentDate = new Date();
        currentDate.setDate(1);
        return getYearMonthStr(currentDate);
    });
    const [showOverallLine, setShowOverallLine] = useState(true);
    const [showPollLine, setShowPollLine] = useState(true);
    const [showAttendanceLine, setShowAttendanceLine] = useState(true);
    const [activeTab, setActiveTab] = useState(REPORT_INSTITUTION_PAGE_TAB_KEY.COURSES);
    const [coursesData, setCoursesData] = useState([]);
    const [nanCourseData, setNanCourseData] = useState({});
    const [lastMonthCount, setLastMonthCount] = useState({});
    const [overviewCount, setOverviewCount] = useState({});
    const [lineChartData, setLineChartData] = useState([]);
    const [chartDiagramWidth, setChartDiagramWidth] = useState(45);
    const [dataLoading, setDataLoading] = useState(false);
    const [lastMonthDisplay, setLastMonthDisplay] = useState("");

    useEffect(() => {
        if (!reportStartDate || !reportEndDate) {
            return;
        }

        setDataLoading(true);
        getInstitutionWiseReport(reportStartDate, reportEndDate).then(result => {
            const {
                pollCount=0, attendanceCount=0, questionCount=0, responseCount=0, participantCount=0,
                monthsChartCount={},
                lastMonthCount={},
                courseData=[],
                nanCourseCount={},
            } = result;
            setOverviewCount({pollCount, attendanceCount, questionCount, responseCount, participantCount, activityCount: pollCount + attendanceCount});
            setLastMonthCount(lastMonthCount);
            setCoursesData(courseData);
            setNanCourseData(nanCourseCount)
            setLineChartData(convertLineChartData(monthsChartCount));
        }).finally(() => {
            setDataLoading(false);
        });
    }, [reportStartDate, reportEndDate]);

    useEffect(() => {
        if (!!overviewCount.activityCount) {
            const str = overviewCount.activityCount.toString();
            if (str.length > 2) {
                setChartDiagramWidth(45 + (str.length - 2) * 8);
            } else {
                setChartDiagramWidth(45);
            }
        } else {
            setChartDiagramWidth(45);
        }
    }, [overviewCount]);

    useEffect(() => {
        if (windowWidth < 1400 ) {
            setLastMonthDisplay(` in ${getShortMonthYear(reportEndDate)}`);
        } else {
            setLastMonthDisplay(` in ${getFullMonthYear(reportEndDate)}`);
        }
    }, [reportEndDate, windowWidth]);



    const convertLineChartData = (monthsChartCount={}) => {
        const lineChartData = []
        for (const yearMonth in monthsChartCount) {
            const {totalCount=0, pollCount=0, attendanceCount=0} = monthsChartCount[yearMonth];
            lineChartData.push({name: getShortMonthYear(yearMonth), Overall: totalCount, Polls: pollCount, Attendances: attendanceCount});
        }
        return lineChartData;
    }


    const ChartTooltip = ({ active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className={classes.chartTooltips}>
                    <span className={classes.chartTooltipsLabel} >{label}</span>
                    {payload.map((item, index) =>
                        <div className={classes.chartTooltipsRow} key={index}>
                            <div className={classes.chartTooltipsDiagram} style={{backgroundColor: item.color}}/>
                            {`${item.name} - ${item.value}`}
                        </div>
                    )}
                </div>
            );
        }

        return <></>;
    };


    const CustomizedLabel = ({ x, y, payload, dy }) => {
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={10} fill="#737373" style={{fontSize: 15}}>
                    <tspan x="0">{payload.value.split(" ")[0]}</tspan>
                    <tspan x="0" dy="20">{payload.value.split(" ")[1]}</tspan>

                </text>
            </g>
        );
    }


    return (
        <div className={classes.parentContainer}>
            <div className={classes.titleCard} style={windowWidth < 800 ? {flexDirection: "column", height: "fit-content"} : {}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <span className={classes.title} tabIndex={0}>{REPORT_INSTITUTION_TITLE}</span>
                    <span style={{height: 15}}/>
                    <span className={classes.description} tabIndex={0}>{REPORT_INSTITUTION_TITLE_DESC}</span>
                </div>
                <div style={windowWidth < 800 ? {height: 20} : {flex: 1}}/>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div className={classes.filterController}>
                        <span className={classes.regularLabel} style={{marginRight: 5}} tabIndex={0}>{REPORT_DATE_FILTER_LABEL}</span>
                        <MonthPicker dateStr={reportStartDate} setDateStr={setReportStartDate}
                                     maxDateStr={reportEndDate}/>
                        <ARROW_RIGHT style={{marginLeft: 5, marginRight: 5}}/>
                        <MonthPicker dateStr={reportEndDate} setDateStr={setReportEndDate}
                                     minDateStr={reportStartDate}/>
                    </div>
                </div>
            </div>

            {/*part1: metric card*/}
            {!dataLoading &&
                <div
                    className={classes.metricCardsContainer}
                    style={{
                        gridTemplateColumns:
                            new Array(Math.min(Math.floor((windowWidth - 60 - 60 -10) / 240), 5)).fill("1fr").join(" ")
                    }}
                >
                    {/*POLL*/}
                    <div className={`${classes.metricCard} ${classes.firstMetricCard}`}>
                        <POLL_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel} tabIndex={0}>{REPORT_POLL_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.pollCount ? overviewCount.pollCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.pollCount)}
                        </div>
                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.pollCount)}} tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.pollCount)}}>
                            {isCountIncreased(lastMonthCount.pollCount) === false ? "" : "+"}
                            {!!lastMonthCount.pollCount ? lastMonthCount.pollCount : 0}
                        </span>
                            {lastMonthDisplay}
                        </div>
                    </div>
                    {/*ATTENDANCE*/}
                    <div className={`${classes.metricCard} ${classes.secondMetricCard}`}>
                        <ATTENDANCE_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel}
                                     tabIndex={0}>{REPORT_ATTENDANCE_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.attendanceCount ? overviewCount.attendanceCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.attendanceCount)}
                        </div>

                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.attendanceCount)}} tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.attendanceCount)}}>
                            {isCountIncreased(lastMonthCount.attendanceCount) === false ? "" : "+"}
                            {!!lastMonthCount.attendanceCount ? lastMonthCount.attendanceCount : 0}
                        </span>
                            {lastMonthDisplay}
                        </div>
                    </div>

                    {/*TOTAL QUESTION*/}
                    <div className={`${classes.metricCard} ${classes.thirdMetricCard}`}>
                        <TOTAL_QUE_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel} tabIndex={0}>{REPORT_TOTAL_QUE_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.questionCount ? overviewCount.questionCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.questionCount)}
                        </div>
                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.questionCount)}} tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.questionCount)}}>
                            {isCountIncreased(lastMonthCount.questionCount) === false ? "" : "+"}
                            {!!lastMonthCount.questionCount ? lastMonthCount.questionCount : 0}
                        </span>
                            {lastMonthDisplay}
                        </div>
                    </div>

                    {/*RESPONSE*/}
                    <div className={`${classes.metricCard} ${classes.fourthMetricCard}`}>
                        <RESPONSE_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel} tabIndex={0}>{REPORT_RESPONSE_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.responseCount ? overviewCount.responseCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.responseCount)}
                        </div>

                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.responseCount)}} tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.responseCount)}}>
                            {isCountIncreased(lastMonthCount.responseCount) === false ? "" : "+"}
                            {!!lastMonthCount.responseCount ? lastMonthCount.responseCount : 0}
                        </span>
                            {lastMonthDisplay}
                        </div>
                    </div>

                    {/*PARTICIPANT*/}
                    <div className={`${classes.metricCard} ${classes.fifthMetricCard}`}>
                        <PARTICIPANT_ICON/>
                        <div className={classes.metricCardBody}>
                            <div>
                                <div className={classes.regularLabel}
                                     tabIndex={0}>{REPORT_PARTICIPANT_METRIC_TITLE}</div>
                                <div style={{height: 5}}/>
                                <div className={classes.metric}
                                     tabIndex={0}>{!!overviewCount.participantCount ? overviewCount.participantCount : 0}</div>
                            </div>
                            <div style={{flex: 1}}/>
                            {getTrendByCount(lastMonthCount.participantCount)}
                        </div>

                        <div className={classes.metricCardDivideLine}/>
                        <div className={classes.metricCardLastMonth}
                             style={{background: getMetricBGColorByCount(lastMonthCount.participantCount)}}
                             tabIndex={0}>
                        <span className={classes.metricCardLastMonthMetric}
                              style={{color: getMetricColorByCount(lastMonthCount.participantCount)}}>
                            {isCountIncreased(lastMonthCount.participantCount) === false ? "" : "+"}
                            {!!lastMonthCount.participantCount ? lastMonthCount.participantCount : 0}
                        </span>
                            {lastMonthDisplay}
                        </div>
                    </div>
                </div>
            }


            {!!dataLoading &&
                <div
                    className={classes.metricCardsContainer}
                    style={{
                        gridTemplateColumns:
                            new Array(Math.min(Math.floor((windowWidth - 60 - 60 -10) / 240), 5)).fill("1fr").join(" ")
                    }}
                >
                    {/*POLL*/}
                    <div className={`${classes.metricCard} ${classes.firstMetricCard}`}>
                        <POLL_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>
                    {/*ATTENDANCE*/}
                    <div className={`${classes.metricCard} ${classes.secondMetricCard}`}>
                        <ATTENDANCE_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>

                    {/*TOTAL QUESTION*/}
                    <div className={`${classes.metricCard} ${classes.thirdMetricCard}`}>
                        <TOTAL_QUE_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>

                    {/*RESPONSE*/}
                    <div className={`${classes.metricCard} ${classes.fourthMetricCard}`}>
                        <RESPONSE_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>

                    {/*PARTICIPANT*/}
                    <div className={`${classes.metricCard} ${classes.fifthMetricCard}`}>
                        <PARTICIPANT_ICON/>
                        <Skeleton borderRadius={10} height={40} inline={true} width={"50%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <div className={classes.metricCardDivideLine}/>
                        <Skeleton borderRadius={10} height={29} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                    </div>
                </div>
            }

            {/*part2: line chart*/}
            {!dataLoading &&
                <div className={classes.lineChartCardContainer} style={windowWidth > 800 ? {height: "fit-content"} : {}}>
                    <div className={classes.cardContainerHead} tabIndex={0}>
                        <LINE_CHART_ICON/>
                        {REPORT_INSTITUTION_LINE_CHART_TITLE}
                    </div>
                    <div className={classes.cardContainerBody}>
                        <div className={classes.description} tabIndex={0}>{REPORT_INSTITUTION_LINE_CHART_DESC}</div>
                        <div
                            style={
                                windowWidth > 800
                                ? {display: "flex", alignItems: "center", flex: 1, gap: 40}
                                : {display: "flex", flexDirection: "column", flex: 1, padding: '0 20px' }
                            }
                        >
                            <div className={classes.chartControllerContainer}>
                                <div className={classes.lineChartControllerRow}>
                                    <YuJaCheckbox
                                        ariaLabel={"Overall-checkbox"}
                                        checked={showOverallLine}
                                        onClick={() => setShowOverallLine(!showOverallLine)}
                                        size={17}
                                    />
                                    <div className={classes.overallDiagramDot}/>
                                    <div className={classes.description}
                                         tabIndex={0}>{REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_OVERALL}</div>
                                    <div style={{flex: 1}}/>
                                    <div className={classes.overallDiagramCountLabel} tabIndex={0}
                                         style={{width: chartDiagramWidth}}>
                                        {overviewCount.activityCount ? overviewCount.activityCount : 0}
                                    </div>
                                </div>

                                <div style={{height: 1, backgroundColor: "#858687"}}/>

                                <div className={classes.lineChartControllerRow}>
                                    <YuJaCheckbox
                                        ariaLabel={"Overall-checkbox"}
                                        checked={showPollLine}
                                        onClick={() => setShowPollLine(!showPollLine)}
                                        size={17}
                                    />
                                    <div className={classes.pollDiagramDot}/>
                                    <div className={classes.description}
                                         tabIndex={0}>{REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_POLLS}</div>
                                    <div style={{flex: 1}}/>
                                    <div className={classes.pollDiagramCountLabel} tabIndex={0}
                                         style={{width: chartDiagramWidth}}>
                                        {!!overviewCount.pollCount ? overviewCount.pollCount : 0}
                                    </div>
                                </div>

                                <div className={classes.lineChartControllerRow}>
                                    <YuJaCheckbox
                                        ariaLabel={"Overall-checkbox"}
                                        checked={showAttendanceLine}
                                        onClick={() => setShowAttendanceLine(!showAttendanceLine)}
                                        size={17}
                                    />
                                    <div className={classes.attendanceDiagramDot}/>
                                    <div className={classes.description}
                                         tabIndex={0}>{REPORT_INSTITUTION_LINE_CHART_DIAGRAM_LABEL_ATTENDANCES}</div>
                                    <div style={{flex: 1}}/>
                                    <div className={classes.attendanceDiagramCountLabel} tabIndex={0}
                                         style={{width: chartDiagramWidth}}>
                                        {!!overviewCount.attendanceCount ? overviewCount.attendanceCount : 0}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.chartContainer}>
                                <ResponsiveContainer  height={windowWidth > 800 ? 400 : 200}>
                                    <LineChart data={lineChartData} {...{overflow: 'visible'}} >
                                        <CartesianGrid strokeDasharray="6 6" stroke={"#C4C4C4"}/>
                                        <XAxis dataKey="name" axisLine={false} interval={0}
                                               strokeWidth={2} strokeDasharray={6} stroke={"#737373"}
                                               tick={<CustomizedLabel/>}
                                               tickMargin={10}
                                        >
                                            <Label style={{fontSize: 15}} value="Month" position="bottom"
                                                   offset={35}/>
                                        </XAxis>
                                        <YAxis name="month" style={{fontSize: 15}} axisLine={false} interval={0}
                                               strokeWidth={2} strokeDasharray={6} stroke={"#737373"}
                                               tickMargin={10}
                                        >
                                            <Label angle={270} value="Total Activities" position="left" offset={20}
                                                   style={{fontSize: 15}}/>
                                        </YAxis>
                                        <Tooltip
                                            content={<ChartTooltip/>}
                                            cursor={{stroke: "#2C2C2C", strokeWidth: 2, strokeDasharray: 6}}
                                        />

                                        {showOverallLine &&
                                            <Line type="monotone" dataKey="Overall" stroke="#4F75CB" strokeWidth={2}
                                                  activeDot={{
                                                      stroke: '#4F75CB',
                                                      fill: '#FFFFFF',
                                                      strokeWidth: 3,
                                                      r: 10
                                                  }}
                                                  dot={false}/>
                                        }
                                        {showPollLine &&
                                            <Line type="monotone" dataKey="Polls" stroke="#E2726E" strokeWidth={2}
                                                  activeDot={{
                                                      stroke: '#E2726E',
                                                      fill: '#FFFFFF',
                                                      strokeWidth: 3,
                                                      r: 10
                                                  }}
                                                  dot={false}/>

                                        }
                                        {showAttendanceLine &&
                                            <Line type="monotone" dataKey="Attendances" stroke="#6DA186"
                                                  strokeWidth={2}
                                                  activeDot={{
                                                      stroke: '#6DA186',
                                                      fill: '#FFFFFF',
                                                      strokeWidth: 3,
                                                      r: 10
                                                  }}
                                                  dot={false}/>
                                        }
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>

                        </div>
                    </div>
                </div>
            }


            {!!dataLoading &&
                <div className={classes.lineChartCardContainer}>
                    <div className={classes.cardContainerHead} tabIndex={0}>
                        <LINE_CHART_ICON/>
                        {REPORT_INSTITUTION_LINE_CHART_TITLE}
                    </div>
                    <div className={classes.cardContainerBody}>
                        <div style={{display: "flex", alignItems: "center", flex: 1, gap: 40}}>
                            <div className={classes.chartControllerContainer}>
                                <Skeleton borderRadius={10} height={25} inline={true} width={200}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />

                                <div style={{height: 1, backgroundColor: "transparent"}}/>
                                <Skeleton borderRadius={10} height={25} inline={true} width={200}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <Skeleton borderRadius={10} height={25} inline={true} width={200}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />

                            </div>
                            <div className={classes.chartContainer} style={{gap: 30, flexDirection: "column", justifyContent: "center"}}>
                                <Skeleton borderRadius={10} height={25} inline={true} width={400}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <Skeleton borderRadius={10} height={25} inline={true} width={300}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <Skeleton borderRadius={10} height={25} inline={true} width={200}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <Skeleton borderRadius={10} height={25} inline={true} width={300}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                                <Skeleton borderRadius={10} height={25} inline={true} width={500}
                                          className={classes.cardNumberSkeleton}
                                          containerClassName={classes.cardNumberSkeletonWrapper}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            }

            {/* part3: table*/}
            {!dataLoading &&
                <div className={classes.tableCardContainer}>
                    <div className={classes.cardContainerHead}>
                        <TABLE_ICON/>
                        {REPORT_INSTITUTION_TABLE_TITLE}
                    </div>
                    <div className={classes.cardContainerBody}>
                        <div className={classes.description}>{REPORT_INSTITUTION_TABLE_DESC}</div>
                        <div className={classes.tabsContainer}>
                            <div
                                onClick={() => setActiveTab(REPORT_INSTITUTION_PAGE_TAB_KEY.COURSES)}
                                className={activeTab === REPORT_INSTITUTION_PAGE_TAB_KEY.COURSES ? classes.activeTab : classes.tab}
                            >
                                {REPORT_INSTITUTION_PAGE_TAB_KEY.COURSES}
                            </div>
                            <div
                                onClick={() => setActiveTab(REPORT_INSTITUTION_PAGE_TAB_KEY.STANDALONE)}
                                className={activeTab === REPORT_INSTITUTION_PAGE_TAB_KEY.STANDALONE ? classes.activeTab : classes.tab}
                            >
                                {REPORT_INSTITUTION_PAGE_TAB_KEY.STANDALONE}
                            </div>
                        </div>

                        {activeTab === REPORT_INSTITUTION_PAGE_TAB_KEY.COURSES &&
                            <CourseTab data={coursesData} setData={setCoursesData} reportStartDate={reportStartDate}
                                       reportEndDate={reportEndDate}/>
                        }

                        {activeTab === REPORT_INSTITUTION_PAGE_TAB_KEY.STANDALONE &&
                            <StandaloneTab data={nanCourseData} reportStartDate={reportStartDate}
                                           reportEndDate={reportEndDate}/>
                        }
                    </div>
                </div>
            }

            {!!dataLoading &&
                <div className={classes.tableCardContainer}>
                    <div className={classes.cardContainerHead}>
                        <TABLE_ICON/>
                        {REPORT_INSTITUTION_TABLE_TITLE}
                    </div>
                    <div className={classes.cardContainerBody} style={{gap: 30}}>
                        <Skeleton borderRadius={10} height={25} inline={true} width={350}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <Skeleton borderRadius={10} height={30} inline={true} width={300}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />
                        <Skeleton borderRadius={10} height={30} inline={true} width={"100%"}
                                  className={classes.cardNumberSkeleton}
                                  containerClassName={classes.cardNumberSkeletonWrapper}
                        />

                        <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                            <div style = {{display: "grid", gridTemplateColumns: "15% 15% 25%  15% 15%"}}>
                                <Skeleton borderRadius={10} height={30} width={"20%"} style = {{position: "relative"}} />
                                <Skeleton borderRadius={10} height={30} width={"30%"} style = {{position: "relative"}} />
                                <Skeleton borderRadius={10} height={30} width={"80%"} style = {{position: "relative"}} />
                                <Skeleton borderRadius={10} height={30} width={"30%"} style = {{position: "relative"}} />
                                <Skeleton borderRadius={10} height={30} width={"30%"} style = {{position: "relative"}} />
                            </div>
                            <div style = {{ display: "grid", gridTemplateColumns: "15% 15% 25% 15% 15%"}}>
                                <Skeleton borderRadius={10} height={25} width={`${15 + Math.random() * 10}%`} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} />
                                <Skeleton borderRadius={10} height={25} width={`${70 + Math.random() * 10}%`} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} />

                                <Skeleton borderRadius={10} height={25} width={`${15 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${70 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />

                                <Skeleton borderRadius={10} height={25} width={`${15 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${70 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                                <Skeleton borderRadius={10} height={25} width={`${20 + Math.random() * 10}%`} style = {{marginTop: 15}} />
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
            }
        </div>
    );
}