import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { UserApiCalls } from "../../apis/UserApiCalls";
import { Spin } from "../standardization/YuJaLoading";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { useLoading } from "../../utils/LoadingContext";
import { ACTION_HEADER, BUTTON, CLEAR_ALL_TEXT, DEFAULT_ROWS_PER_PAGE, GO_BACK_TEXT, QUESTIONS_HEADER, QUESTION_BANK_MODAL, QUESTION_BANK_NAME_HEADER, QUESTION_PROFILE_IMPORT_MESSAGE, QUESTION_PROFILE_MODAL_MESSAGE, QUESTION_PROFILE_MODAL_SUB_TITLE, QUESTION_PROFILE_MODAL_TEXT, QUESTION_PROFILE_MODAL_TITLE, QUESTION_TYPES, QUES_BANK_CHECKBOX, QUES_BANK_CLOSE, SELECT_ALL_TEXT, TEXTBOX, USER_SETTINGS } from "../../utils/constants";
import { notifyError, notifySuccess } from "../../utils/helpers";
import { updateQuestionType } from "../../utils/questionUpdate";
import { addQuestion, questionCodeToName } from "../../utils/questionUtils";
import { IMPORT_QUESTION_BANK_ERROR, IMPORT_QUESTION_BANK_SUCCESS, IMPORT_QUESTION_ERROR } from "../../utils/toast-message-constants";
import YuJaButton from "../standardization/YuJaButton";
import { YuJaCheckbox } from "../standardization/YuJaCheckbox";
import QuestionBankSearch from "./QuestionBankSearch";
import QuestionProfileTable from "./QuestionProfileTable";
import styles from "./QuestionProfiles.module.css";
import {useImageLoaderManager} from "../../hooks/useImageLoaderManager";

export default function QuestionProfileModal({setModalShow, show, setQuestions, questions, setCurrQueDisplay, pollKey, changePollKey, userSettings}) {

    const {getUuid, setQuestionsLoading, resetImages, setImages} = useImageLoaderManager(questions, setQuestions);
    const [questionProfileData, setQuestionProfileData] = useState([]);
    const [dataReady, setDataReady] = useState(false);
    const { importBankImage } = PollApiCalls();
    const { getQuestionProfile } = UserApiCalls(); 
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [selectedProfileName, setSelectedProfileName] = useState(null);
    const [checkedValues, setCheckedValues] = useState(new Map());
    // const [setRecentQuestions, getRecentQuestions] = useLocalStorageNew("recentQuestions", []);
    const [selectedQuestionProfile, setSelectedQuestionProfile] = useState(""); 
    const [isImporting, setIsImporting] = useState(true);
    const history = useHistory();
    const { loading, setLoading } = useLoading();
    // const [collapsed, setCollapsed] = useState(new Map()); 
    // const [data, setData] = useState([]);
    const [questionTitleLimit, setQuestionTitleLimit] = useState(60);

    const [filter, setFilter] = useState({});

    const handleClose = () =>{
        handleSearch({ keyword: ""});
        setIsImporting(false);
        setCheckedValues(new Map());
        setModalShow(false); 
    };

    // const isValidQue = (pollType, questionType) => {
    //     if (!pollType || !questionType) {
    //         return false;
    //     }

    //     const isInvalid = (pollType === GRADED_POLL && (questionType === QUESTION_TYPES.WC.name || questionType === QUESTION_TYPES.RK.name))
    //         || (pollType === SURVEY && questionType === QUESTION_TYPES.MH.name);
    //     return !isInvalid
    // }

    const selectAllProfile = () => {
        const updatedMap = new Map(checkedValues);
        if (selectedProfile) {
            if(!updatedMap.get(selectedProfileId)) {
                updatedMap.set(selectedProfileId, []); 
            }
          Array.from(selectedProfile.values()).map((value, index) => {
            if (!updatedMap.get(selectedProfileId).includes(index)) {
                //(pollType === SURVEY && value.questionType !== QUESTION_TYPES.MH.name) || (pollType === GRADED_POLL && value.questionType !== QUESTION_TYPES.WC.name)
                // if (isValidQue(pollType, value.questionType)) {
                updatedMap.get(selectedProfileId).push(index);
                // }
            }
          });
        }
        setCheckedValues(updatedMap);
    };

    const clearAllProfile = () => {
        const updatedMap = new Map(checkedValues);
        if (selectedProfile) {
            if(!updatedMap.get(selectedProfileId)) {
                updatedMap.set(selectedProfileId, []); 
            }
          Array.from(selectedProfile.values()).map((value, index) => {
            if (updatedMap.get(selectedProfileId).includes(index)) {
                const pos = updatedMap.get(selectedProfileId).indexOf(index);
                updatedMap.get(selectedProfileId).splice(pos, 1);
            }
          });
        }
        setCheckedValues(updatedMap);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            let questionsCopy = JSON.parse(JSON.stringify(questions));
            const size = questionsCopy.length;
            const lastQ = size ? questionsCopy[size - 1] : null;
            const otherQs = JSON.parse(JSON.stringify(questionsCopy.slice(0, size - 1)));
            const otherQsWDefaultQ = addQuestion(otherQs, QUESTION_TYPES.MCSS.name, userSettings);
            const otherQsWDefaultQWUpdatedQType = updateQuestionType(questionCodeToName(lastQ.questionType), size - 1, otherQsWDefaultQ, QUESTION_TYPES.MCSS.name, userSettings);
            const defaultQ = otherQsWDefaultQWUpdatedQType[size - 1];
            // console.log(JSON.stringify(lastQ));
            // console.log(JSON.stringify(defaultQ));
            // console.log(JSON.stringify(lastQ) === JSON.stringify(defaultQ));
            if (JSON.stringify(lastQ) === JSON.stringify(defaultQ)) {
                questionsCopy.pop();
                // console.log("poppedQ", poppedQ);
            }

            let idx = 0;
            let bankQuestions = [];
            let selectedQuestions = [];

            let questionBankMapping = {};
            let totalResourceMapping = {};

            for (let [key, list] of checkedValues) {
                const questionList = questionProfileData.get(key).questions;

                const questionsGroup = [];
                for (const [, i] of list.entries()) {
                    const modifiedQuestion = JSON.parse(JSON.stringify(questionList[i]));
                    if (!modifiedQuestion.hasOwnProperty("queTitle")) {
                        modifiedQuestion.queTitle = "";
                    }
                    if (modifiedQuestion.questionType === QUESTION_TYPES.MH.name && !modifiedQuestion.correctAnswers) {
                        modifiedQuestion.correctAnswers = modifiedQuestion.optionsMap;
                    }

                    modifiedQuestion.timeLimit = userSettings[USER_SETTINGS.QUESTION_DURATION];
                    if(modifiedQuestion.questionType === QUESTION_TYPES.WC.name
                        || modifiedQuestion.questionType === QUESTION_TYPES.RK.name || modifiedQuestion.questionType === QUESTION_TYPES.OE.name) {
                            modifiedQuestion.weightage = 0;
                        }
                    // modifiedQuestion.weightage = (modifiedQuestion.questionType === QUESTION_TYPES.WC.name
                    //     || modifiedQuestion.questionType === QUESTION_TYPES.RK.name || modifiedQuestion.questionType === QUESTION_TYPES.OE.name) ?
                    //         0 :
                    //         userSettings[USER_SETTINGS.QUESTION_POINTS];
                    modifiedQuestion.getReadyTimeLimit = userSettings[USER_SETTINGS.QUESTION_COUNTDOWN];
                    modifiedQuestion.entries = userSettings[USER_SETTINGS.QUESTION_ENTRIES];
                    modifiedQuestion.serialNo = questionsCopy.length + idx + 1;
                    modifiedQuestion.classResultDuration = userSettings[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION];
                    bankQuestions.push(modifiedQuestion);
                    questionsGroup.push(modifiedQuestion);
                    selectedQuestions.push({...questionList[i]});
                    idx++;
                }


                const resourceMapping = setQuestionsLoading(questionsGroup, key);

                totalResourceMapping = {...totalResourceMapping, ...resourceMapping};
                for (const image of Object.keys(resourceMapping)) {
                    questionBankMapping[image] = key;
                }
            }



            if (!bankQuestions.length) {
                notifyError(IMPORT_QUESTION_ERROR);
                return;
            }

            let pollKeyTemp = pollKey;
            if (!pollKeyTemp) {
                pollKeyTemp = getUuid();
                changePollKey(pollKeyTemp);
            }

            if (!!Object.entries(totalResourceMapping).length) {
                importBankImage(pollKeyTemp, totalResourceMapping)
                    .then(returnedMapping => {
                        //error
                        if (!returnedMapping) {
                            resetImages(Object.values(totalResourceMapping));
                            return;
                        }

                        //set imageURL
                        setImages(returnedMapping);
                    });
            }


            questionsCopy = questionsCopy.concat(bankQuestions);
            setQuestions(questionsCopy);
            setCurrQueDisplay(questionsCopy.length);
            setCheckedValues(new Map());
            handleSearch({ keyword: "" });
            notifySuccess(IMPORT_QUESTION_BANK_SUCCESS);
            setModalShow(false);
        } catch (error) {
            notifyError(IMPORT_QUESTION_BANK_ERROR);
        } finally {
            setIsImporting(false);
            setLoading(false);
        }
    }

    const handleCheckboxChange = (index) => {
        const updatedMap = new Map(checkedValues);

        if(!updatedMap.get(selectedProfileId)) {
            updatedMap.set(selectedProfileId, []); 
        }
      
        if(updatedMap.get(selectedProfileId).includes(index)) {
            const pos = updatedMap.get(selectedProfileId).indexOf(index);
            updatedMap.get(selectedProfileId).splice(pos, 1);
        }
        else {
            updatedMap.get(selectedProfileId).push(index);
          }
        setCheckedValues(updatedMap);
    }

    const handleKeyClick = (value, index) => {
        const keyArray = Array.from(questionProfileData.keys());
        setSelectedProfile(value.questions);
        setSelectedProfileId(keyArray[index]); 
        setSelectedProfileName(value.ProfileTitle);
        setIsImporting(true);
        console.log(value.questions)
    };

    const getQuestionProfiles = async () => {
        setDataReady(false); 
        const res = await getQuestionProfile();
        const sortedArray = Object.entries(res).sort((a, b) => new Date(Date.parse(b[1].creationTime)) - new Date(Date.parse(a[1].creationTime)));
        const sortedMap = new Map(sortedArray);  
        setQuestionProfileData(sortedMap); 
        console.log(sortedMap)
        setDataReady(true); 
    };

    useEffect(() => {
        if(show === true) {
            getQuestionProfiles(); 
            setIsImporting(false);
            setCheckedValues(new Map());
            setSelectedProfile(null); 
            setSelectedProfileId(null);
            setSelectedProfileName(null);
            setSelectedQuestionProfile("");
            // setCollapsed(false);
        }
    }, [show]);

    // useEffect(() => {
    //     const collapsedMap = new Map(); 
    //     Array.from(questionProfileData.keys()).map((value, index) => {
    //         collapsedMap.set(value, false)
    //       });
    //     setCollapsed(collapsedMap); 
    // }, [questionProfileData])

    useEffect(() => {
        if(selectedProfileId && !checkedValues.has(selectedProfileId)) {
            setCheckedValues((prevCheckedQuestions) => {
                const updatedMap = new Map(prevCheckedQuestions);
                updatedMap.set(selectedProfileId, []); 
                return updatedMap; 
            }); 
        }
    }, [selectedProfileId])

    const columns = [
        { Header: QUESTION_BANK_NAME_HEADER, accessor: 'ProfileTitle', width: 200 },
        { Header: QUESTIONS_HEADER, accessor: 'questions', width: 100 },
        // { Header: ACTION_HEADER, accessor: '', width: 100, id: 'action' },
      ];

    const onEdit = (questionProfileId) => {
        setModalShow(false);
        history.push({ pathname: "/questions/edit/" + questionProfileId });
    }

    useEffect(() => {
        const updateConstantValue = () => {
          const windowWidth = window.innerWidth;
          if (windowWidth <= 395) {
            setQuestionTitleLimit(20);
          }
          else if (windowWidth <= 495 ) {
            setQuestionTitleLimit(30);
          }
          else if (windowWidth <= 1500) {
            setQuestionTitleLimit(40);
          } else if (windowWidth <= 1800) {
            setQuestionTitleLimit(50);
          } else {
            setQuestionTitleLimit(60);
          }
        };
    
        // Call the function once to set the initial value
        updateConstantValue();
    
        // Add an event listener to handle window resize
        window.addEventListener('resize', updateConstantValue);
    
        // Clean up the event listener when the component is unmounted
        return () => {
          window.removeEventListener('resize', updateConstantValue);
        };
      }, []);

    const handleSearch = useCallback(({ ...filterObj }) => {
        setFilter(filterObj);
      }, []);
    
    return (
        <Modal
            id="form-modal"
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
            dialogClassName= {"qp-modal"}
        >

            <ModalClose tabIndex={0} role={BUTTON} aria-label={QUES_BANK_CLOSE} onClick={handleClose} className="modal-close" style={{width: "24px", height: "24px"}}/>
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title tabIndex={0} role={TEXTBOX} aria-label={QUESTION_BANK_MODAL} className="modal-title" id="contained-modal-title-vcenter">
                    {QUESTION_PROFILE_MODAL_TITLE}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isImporting ?
                    <>
                        <div>
                            <div tabIndex={0} role={TEXTBOX} style={{
                                fontSize: "16px",
                                lineHeight: "16px",
                                fontWeight: "bold",
                                
                                color: "#000000"
                            }}> {QUESTION_PROFILE_MODAL_SUB_TITLE} </div>
                            <div tabIndex={0} role={TEXTBOX} style={{
                                fontSize: "14px",
                                lineHeight: "14px",
                                marginTop: "5px",
                                
                                color: "#696969"
                            }}> {QUESTION_PROFILE_MODAL_MESSAGE} </div>
                            {/* <div className="questionProfile-layout">
            {Array.from(questionProfileData.values()).map((value, index) => (
                // <div style = {{overflowWrap: "break-word"}} className={selectedProfile != value.questions ? "QuestionProfile-value": "QuestionProfile-value-selected"} onClick={() => handleKeyClick(value, index)}>{value.ProfileTitle}</div>
                <>
                    <div className="questionProfile-cards" onClick={() => {setIsImporting(true); handleKeyClick(value, index);}}>
                        <div style={{flex: '80%', overflowWrap: "break-word", width: "80%"}}>{value.ProfileTitle.length > 30 ? `${value.ProfileTitle.slice(0, 30)}...` :value.ProfileTitle} </div>  
                        <div style={{flex: '20%', display: "flex", flexDirection: "row-reverse", height: "100%", alignSelf: "flex-start", zIndex: "100"}}> <div style={{display: "flex", flexDirection: "column"}}> 
                        {!collapsed || !collapsed.get(Array.from(questionProfileData.keys())[index]) ? <FcMenu
                                onClick={(e) => {
                                    const updatedMap = new Map(collapsed);
                                    const collapse = collapsed.get(Array.from(questionProfileData.keys())[index])
                                    updatedMap.set(Array.from(questionProfileData.keys())[index], !collapse); 
                                    setCollapsed(updatedMap);
                                    e.stopPropagation();
                                }}
                                size={24}
                            /> :
                        
                        <>
                        <img
                            src={EditIcon}
                            alt="edit icon"
                            style={{margin: "0px", padding: "0px", cursor: "pointer",width: "24px", height: "24px",  zIndex: "100"}}
                            title={"Edit Question"}
                            onClick={(e) => { onEdit(Array.from(questionProfileData.keys())[index]); e.stopPropagation();}}
                        />
                        <img
                            src={DeleteIcon}
                            alt="delete icon"
                            style={{ marginRight: "2px", padding: "0px", cursor: "pointer", width: "24px", height: "24px",  zIndex: "100"}}
                            title={"Delete Question"}
                            onClick={(e) => {onDelete(Array.from(questionProfileData.keys())[index]); e.stopPropagation();}}
                        /> </> } 
                        </div></div> 
                    </div>       
                </>
                ))}
            </div>  */}
                            <QuestionBankSearch handleSubmit={handleSearch} disabled={!dataReady}/>
                            {dataReady ?
                                <QuestionProfileTable columns={columns} data={Array.from(questionProfileData.values())}
                                                      getQuestionProfiles={getQuestionProfiles}
                                                      keys={Array.from(questionProfileData.keys())}
                                                      selectedQuestionProfile={selectedQuestionProfile}
                                                      setSelectedQuestionProfile={setSelectedQuestionProfile}
                                                      setIsImporting={setIsImporting} setModalShow={setModalShow}
                                                      handleKeyClick={handleKeyClick} globalFilterObj={filter}/> :
                                <div className={styles.skeletonTableContainer}>
                                    <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                                        <div className={styles.skeletonHeaderContainer}>
                                            <div className={styles.skeletonHeaderCell}>{QUESTION_BANK_NAME_HEADER}</div>
                                            <div className={styles.skeletonActionHeaderCell}>{QUESTIONS_HEADER}</div>
                                            <div className={styles.skeletonActionHeaderCell}>{ACTION_HEADER}</div>
                                        </div>
                                        <div className={styles.skeletonBodyContainer}>
                                            {Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                                                return (<div className={styles.skeletonBodyRow}>
                                                    <Skeleton height={20}  borderRadius={10} inline={true}
                                                              width={`${6 + Math.random() * 3}vw`}
                                                              className={styles.skeletonBodyCell}
                                                              containerClassName={styles.skeletonBodyCellWrapper}/>
                                                    <Skeleton height={20}  borderRadius={10} inline={true} width={`${2 + Math.random()}vw`}
                                                              className={styles.skeletonActionBodyCell}
                                                              containerClassName={styles.skeletonBodyCellWrapper}/>
                                                    <Skeleton height={20}  borderRadius={10} inline={true} width="2vw"
                                                              className={styles.skeletonActionBodyCell}
                                                              containerClassName={styles.skeletonBodyCellWrapper}/>
                                                </div>)
                                            })}
                                        </div>
                                    </SkeletonTheme>
                                </div>
                            }
                        </div>
                    </>
                    : <div style={{padding: "10px 0px 0px 10px"}}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>
                                <div tabIndex={0} role={TEXTBOX} style={{
                                    fontSize: "16px",
                                    lineHeight: "16px",
                                    fontWeight: "bold",
                                    
                                    color: "#000000"
                                }} title={selectedProfileName?.length > 30 ? selectedProfileName : ""}>
                                    {selectedProfileName?.length > 30 ? `${selectedProfileName.slice(0, 30)}...` : selectedProfileName}
                                </div>
                                <div tabIndex={0} role={TEXTBOX} style={{
                                    fontSize: "14px",
                                    lineHeight: "14px",
                                    marginTop: "5px",
                                    
                                    color: "#696969"
                                }}> {QUESTION_PROFILE_IMPORT_MESSAGE} </div>
                            </div>
                            {/* <YuJaButton style={{fontSize: 14, padding: 5}} onClick={(e) => {
                                onEdit(selectedProfileId);
                                e.stopPropagation();
                            }}>
                                <EditIcon style={{width: 20, height: 20}}/>{QUESTION_PROFILE_IMPORT_EDIT}
                            </YuJaButton> */}
                        </div>
                        {/* <div>
            {Array.from(questionProfileData.values()).map((value, index) => (
                // <div style = {{overflowWrap: "break-word"}} className={selectedProfile != value.questions ? "QuestionProfile-value": "QuestionProfile-value-selected"} onClick={() => handleKeyClick(value, index)}>{value.ProfileTitle}</div>
                <>
                    <div className={selectedProfile != value.questions ? "questionProfile-cards": "questionProfile-cards-activated"} onClick={() => handleKeyClick(value, index)}>
                        <div style={{flex: '100%', overflowWrap: "break-word", width: "80%"}}>{value.ProfileTitle.length > 30 ? `${value.ProfileTitle.slice(0, 30)}...` :value.ProfileTitle} </div>  
                    </div>       
                </>
                ))}
            </div> */}
                        <div style={{
                            flex: '7 1 70%',
                            maxHeight: "70vh",
                            minHeight: "20vh",
                            marginTop: "20px",
                            overflowY: "auto"
                        }}>
                            <div style={{display: "flex", gap: "20px"}}>
                                <YuJaButton onClick={() => {
                                    selectAllProfile();
                                }}> {SELECT_ALL_TEXT} </YuJaButton>
                                <YuJaButton onClick={() => {
                                    clearAllProfile();
                                }} style={{paddingLeft: "10px"}}> {CLEAR_ALL_TEXT} </YuJaButton>
                            </div>
                            <div style={{marginTop: "10px", borderBottom: "0.73px solid #8A8A8A"}}>
                                {selectedProfile &&
                                    <div>
                                        {Array.from(selectedProfile.values()).map((value, index) => (
                                            <div style={{
                                                display: "flex",
                                                gap: "10px",
                                                alignItems: "center",
                                                width: "100%",
                                                border: "0.73px solid #8A8A8A",
                                                borderBottom: "0px",
                                                height: "33px",
                                                paddingLeft: "10px"
                                            }}>
                                                <YuJaCheckbox
                                                    size={18}
                                                    id={selectedProfileId + index}
                                                    ariaLabel={QUES_BANK_CHECKBOX + value.queTitle + " " + (checkedValues.has(selectedProfileId) && checkedValues.get(selectedProfileId).includes(index) ? "checked" : "unchecked")}
                                                    checked={checkedValues.has(selectedProfileId) && checkedValues.get(selectedProfileId).includes(index)}
                                                    onClick={() => {
                                                        handleCheckboxChange(index)
                                                    }}
                                                    // disabled={!isValidQue(pollType, value.questionType)}
                                                />
                                                <div style={{display: "block", width: "100%"}}>
                                                    <div
                                                        title={value.queTitle?.length > questionTitleLimit ? value.queTitle : ""}>
                                                        {(!value.queTitle || value.queTitle === "") ? "untitled question" : (value.queTitle?.length > questionTitleLimit) ? `${value.queTitle.slice(0, questionTitleLimit)}...` : value.queTitle}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div style={{display: "flex"}}>
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                {!isImporting? 
                    <YuJaButton aria-label={QUES_BANK_CLOSE} onClick={handleClose} type={"secondary"}>Cancel</YuJaButton>
                    :
                    <>
                        <YuJaButton type={"secondary"} onClick={() => {setIsImporting(false)}}>
                         {GO_BACK_TEXT}
                        </YuJaButton>
                        <YuJaButton style={{width: 70, height: 40}} onClick={handleSubmit} disabled={loading} >
                            { !loading ? QUESTION_PROFILE_MODAL_TEXT : <Spin size="small" wrapperClassName="globalSpin" spinning={loading}/>}
                        </YuJaButton>
                    </>
                }
            </Modal.Footer>

        </Modal>
    );
}