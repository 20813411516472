import "antd/dist/antd.min.css";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import { Spin } from "../../components/standardization/YuJaLoading";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { useLoading } from "../../utils/LoadingContext";
import {
  ATTENDANCE_NAVBAR,
  AUDIENCE_RESTRICTION,
  DEFAULT_ATTENDANCE_MANUAL_END,
  DEFAULT_ATTENDANCE_SAVED_LOCATIONS,
  DEFAULT_ATTENDANCE_TIME_LIMIT,
  DEFAULT_AUDIENCE_RESTRICTION,
  DEFAULT_ENTRIES_PER_PARTICIPANT,
  DEFAULT_GET_READY_ALL_TIME_LIMIT,
  DEFAULT_GET_READY_TIME_LIMIT,
  DEFAULT_POINTS,
  DEFAULT_POLL_BREAK_TIME_LIMIT,
  DEFAULT_POLL_LMS_SYNC,
  DEFAULT_POLL_MAX_ATTEMPTS,
  DEFAULT_POLL_RECORD_ATTEMPTS,
  DEFAULT_POLL_REQUIRED_NAME,
  DEFAULT_POLL_SHOW_CORRECT_ANS,
  DEFAULT_POLL_TIME_LIMIT,
  DEFAULT_PPT_AUTO_SHARE,
  DEFAULT_TIME_LIMIT,
  DRAFT_STATUS,
  MODAL_EDIT_TEXT,
  POLL_NAVBAR,
  POLL_PATH,
  POLL_SHARE_MODE,
  POLL_TIME_LIMIT_MODAL_CANCEL,
  POLL_TITLE_LENGTH,
  POLL_TYPE,
  QUESTION_TYPES,
  SWITCH_POLL_MESSAGE,
  SWITCH_POLL_MODAL_TEXT,
  SWITCH_POLL_MODAL_TITLE,
  USER_SETTINGS
} from "../../utils/constants";
import { getDefaultUserSettings, notifyError, updateCreateMode, validateText } from "../../utils/helpers";
import { addQuestion, chooseQuestion, getBlankRestriction, questionError, trimQuestion } from "../../utils/questionUtils";
import { ATTENDANCE_TITLE_ERROR, POLL_TITLE_ERROR, QUESTION_MAX_POINTS_ERROR, SURVEY_TITLE_ERROR } from "../../utils/toast-message-constants";
import CreateQuizSidebar from "./CreateQuizSidebar";
import EmptySidebar from "./EmptySidebar";
import QuestionsMain from "./QuestionsMain";
import "./styles.css";
import {useImageLoaderManager} from "../../hooks/useImageLoaderManager";

export default function EditQuizPage({
  setSidebarContent,
  setPageIdentifier
}) {
  const { pollKey, pollCode } = useParams();
  const location = useLocation();
  const [, getSession] = useLocalStorageNew("session", {});
  const session = getSession();
  const [userSettings, setUserSettings] = useState(getDefaultUserSettings());
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { updateAndPublish, getPollDataFromKey } = PollApiCalls();
  // const { getSetting } = InstitutionApiCalls();
  const history = useHistory();
  // const queChangeCount = useRef(0);
  const [currQueDisplay, setCurrQueDisplay] = useState("1");
  const { loading } = useLoading();
  const [pollType, setPollType] = useState("");
  // const [pollTypeModalShow, setPollTypeModalShow] = useState(false);
  // const [desiredPollMode, setDesiredPollMode] = useState("");
  const [setPollTitle, getPollTitle] = useLocalStorageNew("pollTitle", "");
  const [getReadyAllTimeLimit, setGetReadyAllTimeLimit] = useState(DEFAULT_GET_READY_ALL_TIME_LIMIT);
  const [pollTimeLimit, setPollTimeLimit] = useState(DEFAULT_POLL_TIME_LIMIT);
  const [pollBreakDuration, setPollBreakDuration] = useState(DEFAULT_POLL_BREAK_TIME_LIMIT);
  const [recordAttempt, setRecordAttempt] = useState(DEFAULT_POLL_RECORD_ATTEMPTS);
  const [lmsAttempt, setLMSAttempt] = useState(DEFAULT_POLL_LMS_SYNC);
  const [maxAttempts, setMaxAttempts] = useState(DEFAULT_POLL_MAX_ATTEMPTS);
  const [showAnswerOnViewer, setShowAnswerOnViewer] = useState(DEFAULT_POLL_SHOW_CORRECT_ANS);
  // const [anonymousJoin, setAnonymousJoin] = useState(DEFAULT_POLL_REQUIRED_NAME);
  const [audienceRestriction, setAudienceRestriction] = useState(DEFAULT_AUDIENCE_RESTRICTION);
  const [pptAutoShare, setPptAutoShare] = useState(DEFAULT_PPT_AUTO_SHARE);
  const [geofence, setGeofence] = useState({});
  const [syncLms, setSyncLms] = useState(true);
  const [manualEnd, setManualEnd] = useState(DEFAULT_ATTENDANCE_MANUAL_END);
  const [attendanceDuration, setAttendanceDuration] = useState(DEFAULT_ATTENDANCE_TIME_LIMIT);
  const [hasBlankRestriction, setHasBlankRestriction] = useState(false);
  const [setQues, getQues] = useLocalStorageNew("questions", []);
  const [setType, getType] = useLocalStorageNew("pollType", "");

  const [focusMCElem, setFocusMCElem] = useState(-1);
  const [focusSAElem, setFocusSAElem] = useState(-1);
  const [focusTFElem, setFocusTFElem] = useState(-1);
  const [focusFITBElem, setFocusFITBElem] = useState(-1);
  const [focusCIElem, setFocusCIElem] = useState(-1);
  const [focusMHElem, setFocusMHElem] = useState(-1);
  const [focusRKElem, setFocusRKElem] = useState(-1);
  const [disableSave, setDisableSave] = useState(false);

  const [setSaveExit, getsaveExit] = useLocalStorageNew("saveExit", false); 
  const [nextRouterPath, setNextRouterPath] = useState({ pathname: '', state: null });
  const [alertShow, setAlertShow] = useState(false);
  const [isConfirmationPending, setIsConfirmationPending] = useState(false);
  const [alertContent,] = useState(SWITCH_POLL_MESSAGE);
  const [setRecentQuestions, getRecentQuestions] = useLocalStorageNew("recentQuestions", []);
  const [setRecentTitle, getRecentTitle] = useLocalStorageNew("recentTitle", []);

  const [isSaving, setIsSaving] = useState(false); 
  const [dataloading , setDataLoading] = useState(false);

  const {checkAnyQuestionIsLoading} = useImageLoaderManager(questions, setQuestions);

  if (!!questions && questions.length < 1) {
    const { settings = {} } = session;
    if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION)) {
      settings[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
    }
    settings[USER_SETTINGS.QUESTION_DURATION] = parseInt(settings[USER_SETTINGS.QUESTION_DURATION], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS)) {
      settings[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
    }
    settings[USER_SETTINGS.QUESTION_POINTS] = parseInt(settings[USER_SETTINGS.QUESTION_POINTS], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_COUNTDOWN)) {
      settings[USER_SETTINGS.QUESTION_COUNTDOWN] = DEFAULT_GET_READY_TIME_LIMIT;
    }
    settings[USER_SETTINGS.QUESTION_COUNTDOWN] = parseInt(settings[USER_SETTINGS.QUESTION_COUNTDOWN], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_ENTRIES)) {
      settings[USER_SETTINGS.QUESTION_ENTRIES] = DEFAULT_ENTRIES_PER_PARTICIPANT;
    }
    settings[USER_SETTINGS.QUESTION_ENTRIES] = parseInt(settings[USER_SETTINGS.QUESTION_ENTRIES], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_DURATION)) {
      settings[USER_SETTINGS.POLL_DURATION] = DEFAULT_POLL_TIME_LIMIT;
    }
    settings[USER_SETTINGS.POLL_DURATION] = parseInt(settings[USER_SETTINGS.POLL_DURATION], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_COUNTDOWN)) {
      settings[USER_SETTINGS.POLL_COUNTDOWN] = DEFAULT_GET_READY_ALL_TIME_LIMIT;
    }
    settings[USER_SETTINGS.POLL_COUNTDOWN] = parseInt(settings[USER_SETTINGS.POLL_COUNTDOWN], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_REQUIRED_NAME)) {
      settings[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
    }
    // settings[USER_SETTINGS.POLL_REQUIRED_NAME] = (settings[USER_SETTINGS.POLL_REQUIRED_NAME] == "true");
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_SHOW_CORRECT_ANS)) {
      settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = DEFAULT_POLL_SHOW_CORRECT_ANS;
    }
    // settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = (settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] == "true");
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_LMS_SYNC)) {
      settings[USER_SETTINGS.POLL_LMS_SYNC] = DEFAULT_POLL_LMS_SYNC;
    }
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_RECORD_ATTEMPTS)) {
      settings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
    }
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_BREAK_DURATION)) {
      settings[USER_SETTINGS.POLL_BREAK_DURATION] = DEFAULT_POLL_BREAK_TIME_LIMIT;
    }
    if (!settings.hasOwnProperty(USER_SETTINGS.POLL_MAX_ATTEMPTS)) {
      settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
    }
    settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = parseInt(settings[USER_SETTINGS.POLL_MAX_ATTEMPTS], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_MANUAL_END)) {
      settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = DEFAULT_ATTENDANCE_MANUAL_END;
    }
    settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = (settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === "true" || settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === true);
    if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_DURATION)) {
      settings[USER_SETTINGS.ATTENDANCE_DURATION] = DEFAULT_ATTENDANCE_TIME_LIMIT;
    }
    settings[USER_SETTINGS.ATTENDANCE_DURATION] = parseInt(settings[USER_SETTINGS.ATTENDANCE_DURATION], 10);
    if (!settings.hasOwnProperty(USER_SETTINGS.PPT_AUTO_SHARE)) {
      settings[USER_SETTINGS.PPT_AUTO_SHARE] = DEFAULT_PPT_AUTO_SHARE;
    } else {
      settings[USER_SETTINGS.PPT_AUTO_SHARE] = (settings[USER_SETTINGS.PPT_AUTO_SHARE] === "true" || settings[USER_SETTINGS.PPT_AUTO_SHARE] === true);
    }
    if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE)) {
      settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] = DEFAULT_PPT_AUTO_SHARE;
    } else {
      settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] = (settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] === "true" || settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] === true);
    }
    if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS)) {
      settings[USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS] = DEFAULT_ATTENDANCE_SAVED_LOCATIONS;
    }
    setUserSettings(settings);
    const qs = addQuestion(questions, QUESTION_TYPES.MCSS.name, settings);
    setQuestions(qs);
    setCurrQueDisplay(qs[0].serialNo);
  }

  const checkChanged = () => {
    const curr_ques = JSON.stringify(getRecentQuestions()); 
    const curr_saved = JSON.stringify(getQues());
    if(getPollTitle() !== getRecentTitle()){
      return true; 
    }
    return !(curr_ques === curr_saved);
  }

  useEffect(() => {
    let isCheckChangedPending = false;
  
    const unblock = history.block((location, action) => {
      if (isCheckChangedPending) {
        return true; 
      }
      isCheckChangedPending = true;
  
      const result = checkChanged();
      if (!result || getsaveExit() === true) {
        setSaveExit(false);
        resetPoll();
        return true;
      }
  
      if (location.pathname && !isConfirmationPending) {
        setNextRouterPath({ pathname: location.pathname, state: location.state });
        setAlertShow(true);
        return false;
      }
  
      return true;
    });
  
    if (isConfirmationPending) {
      resetPoll();
      history.push(nextRouterPath.pathname, nextRouterPath.state);
      setIsConfirmationPending(false);
      unblock();
    }
  
    return () => {
      unblock();
    };
  }, [history, isConfirmationPending, nextRouterPath]);

  useEffect(async () => {
    if (location && location.state) {
      if (location.state.pollType) {
        console.log(location.state.pollType);
        setPollType(location.state.pollType);
      }
    }
  }, [location.state]);

  const alertConfig = {
    title: SWITCH_POLL_MODAL_TITLE,
    okText: SWITCH_POLL_MODAL_TEXT,
    cancelText: POLL_TIME_LIMIT_MODAL_CANCEL, 
    submit: async () => {
      setAlertShow(false);
      setIsConfirmationPending(true);
    },
    close:  async () => {
      setAlertShow(false);
    }, 
  };

  useEffect (() => {
    setRecentQuestions(questions); 
  }, [questions]);

  useEffect (() => {
    setRecentTitle(title); 
  }, [title]);

  useEffect(() => {
    setTimeout(() => {
      switch(questions[currQueDisplay-1].questionType) {
        case QUESTION_TYPES.MCSS.name:
          if(focusMCElem !== -1) {
            document.getElementsByName("question-text")[focusMCElem].focus();
            setFocusMCElem(-1);
          }
          break;
        case QUESTION_TYPES.SA.name:
          if(focusSAElem !== -1) {
            document.getElementsByName("question-text")[focusSAElem].focus();
            setFocusSAElem(-1);
          }
          break;
        case QUESTION_TYPES.TF.name:
          if(focusTFElem !== -1) {
            document.getElementsByName("question-text")[focusTFElem].focus();
            setFocusTFElem(-1);
          }
          break;
        case QUESTION_TYPES.FITB.name:
          if(focusFITBElem !== -1) {
            if(focusFITBElem === 0) {
              document.getElementsByClassName("notranslate public-DraftEditor-content")[focusFITBElem].focus();
            } else {
              document.getElementsByName("question-text")[focusFITBElem-1].focus();
            }
            setFocusFITBElem(-1);
          }
          break;
        case QUESTION_TYPES.CI.name:
          if(focusCIElem !== -1) {
            document.getElementsByName("question-text")[focusCIElem].focus();
            setFocusCIElem(-1);
          }
          break;
        case QUESTION_TYPES.MH.name:
          if (focusMHElem !== -1) {
            console.log(focusMHElem);
            document.getElementsByName("question-text")[focusMHElem].focus();
            setFocusMHElem(-1);
          }
          break;
        case QUESTION_TYPES.RK.name:
          if (focusRKElem !== -1) {
            console.log(focusRKElem);
            document.getElementsByName("question-text")[focusRKElem].focus();
            setFocusRKElem(-1)
          }
          break;
      }
    }, 500);

  }, [currQueDisplay, focusMCElem, focusSAElem, focusTFElem, focusFITBElem, focusCIElem, focusMHElem, focusRKElem]);


  const changeCurrQueDisplay = (newValue) => {
    setCurrQueDisplay(newValue);
  }

  const validatePoll = (focusError=true) => {
    if (!validateText(title, POLL_TITLE_LENGTH)) {
      if (focusError) {
        notifyError(pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE_TITLE_ERROR : pollType.toUpperCase() === POLL_TYPE.SURVEY ? SURVEY_TITLE_ERROR : POLL_TITLE_ERROR);
        document.getElementsByName("pollTitle")[0].focus();
      }
      return false;
    }

    let i = 0;
    for (let q of questions) {
      i++;
      if (q.weightage > 1000) {
        if (focusError) {
          let errorWithQuestion = "Question " + i + ": " + QUESTION_MAX_POINTS_ERROR;
          notifyError(errorWithQuestion);
          setCurrQueDisplay(q.serialNo);
        }
        return false;
      }
    }

    let index = 0;
    for (let q of questions) {
      index++;
      if (focusError) {
        setCurrQueDisplay(q.serialNo);
      }
      let {quesError, elemIndex} = questionError(q, pollType);
      switch(q.questionType) {
        case QUESTION_TYPES.MCSS.name:
          if (focusError) {
            setFocusMCElem(elemIndex);
          }
          break;
        case QUESTION_TYPES.SA.name:
          if (focusError) {
            setFocusSAElem(elemIndex);
          }
          break;
        case QUESTION_TYPES.TF.name:
          if (focusError) {
            setFocusTFElem(elemIndex);
          }
          break;
        case QUESTION_TYPES.FITB.name:
          if (focusError) {
            setTimeout(() => {
              setFocusFITBElem(elemIndex);
            }, 100);
          }
          break;
        case QUESTION_TYPES.CI.name:
          if (focusError) {
            setFocusCIElem(elemIndex);
          }
        case QUESTION_TYPES.MH.name: 
          if (focusError) {
            setFocusMHElem(elemIndex);
          }
          break;
        case QUESTION_TYPES.RK.name:
          if (focusError) {
              setFocusRKElem(elemIndex);
          }
          break;
      }

      if (quesError) {
        if (focusError) {
          let errorWithQuestion = "Question " + index + ": " + quesError;
          setQuestions(chooseQuestion(q.serialNo, questions));
          notifyError(errorWithQuestion);
        }
        return false;
      }
    }
    return true;
  };

  const editPoll = async (updatedQuestions=[]) => {
    setDisableSave(true);

    let questionsCopy = JSON.parse(JSON.stringify(
        !!updatedQuestions && !!Object.entries(updatedQuestions).length
            ? updatedQuestions
            : questions
    ));

    let savedQuestions = trimQuestion(questionsCopy);

    let pollToAdd = {
      pollKey: pollKey,
      pollType: pollType,
      questions: savedQuestions,
      // ownerId: "794e0074-e325-4fb5-973c-d55750ab5324",
      pollTitle: title,
      pollShareMode: POLL_SHARE_MODE.UNKNOWN,
      pollDescription: description,
      pollTimeLimit: pollTimeLimit,
      getReadyAllTimeLimit: getReadyAllTimeLimit,
      showAnswerOnViewer: showAnswerOnViewer,
      // anonymousJoin: anonymousJoin,
      audienceRestriction: audienceRestriction,
      recordAttempt: recordAttempt,
      lmsAttempt: lmsAttempt,
      maxAttempts: maxAttempts,
      pptAutoShare: pptAutoShare
    };
    if (pollType === POLL_TYPE.ATTENDANCE) {
      pollToAdd.geofence = geofence;
      pollToAdd.syncLms = syncLms;
      pollToAdd.manualEnd = manualEnd;
      pollToAdd.attendanceDuration = attendanceDuration;
    }

    let result = await updateAndPublish(pollToAdd, false);
    if (!result) {
      setDisableSave(false);
      return false;
    }

    window.onbeforeunload = undefined;
    setDisableSave(false);
    setPollTitle(title);
    setQues(questionsCopy);
    setQuestions(questionsCopy);
    return true;
  };

  const editPollClose = async (normalEdit = true) => {
    setDisableSave(true);
    setSaveExit(true);
    setQues(questions);
    if (!validatePoll()) {
      setDisableSave(false);
      return;
    }

    let saveQuestions = JSON.parse(JSON.stringify(questions));
    saveQuestions = trimQuestion(saveQuestions);

    let pollToAdd = {
      pollKey: pollKey,
      pollType: pollType,
      questions: saveQuestions,
      pollTitle: title,
      pollShareMode: POLL_SHARE_MODE.UNKNOWN,
      pollDescription: description,
      pollTimeLimit: pollTimeLimit,
      pollBreakDuration: pollBreakDuration,
      getReadyAllTimeLimit: getReadyAllTimeLimit,
      showAnswerOnViewer: showAnswerOnViewer,
      // anonymousJoin: anonymousJoin,
      audienceRestriction: audienceRestriction,
      recordAttempt: recordAttempt,
      lmsAttempt: lmsAttempt,
      maxAttempts: maxAttempts,
      pptAutoShare: pptAutoShare
    };
    if (pollType === POLL_TYPE.ATTENDANCE) {
      pollToAdd.geofence = geofence;
      pollToAdd.syncLms = syncLms;
      pollToAdd.manualEnd = manualEnd;
      pollToAdd.attendanceDuration = attendanceDuration;
    }

    let result = await updateAndPublish(pollToAdd);
    if (!result) {
      setDisableSave(false);
      return false;
    }

    setDisableSave(false);
    history.push(POLL_PATH);
  };

  useEffect(() => {
    const { settings = {} } = session;
    setUserSettings(() => {
      if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION)) {
        settings[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
      }
      settings[USER_SETTINGS.QUESTION_DURATION] = parseInt(settings[USER_SETTINGS.QUESTION_DURATION], 10);
      if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS)) {
        settings[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
      }
      settings[USER_SETTINGS.QUESTION_POINTS] = parseInt(settings[USER_SETTINGS.QUESTION_POINTS], 10);
      if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_COUNTDOWN)) {
        settings[USER_SETTINGS.QUESTION_COUNTDOWN] = DEFAULT_GET_READY_TIME_LIMIT;
      }
      settings[USER_SETTINGS.QUESTION_COUNTDOWN] = parseInt(settings[USER_SETTINGS.QUESTION_COUNTDOWN], 10);
      if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_ENTRIES)) {
        settings[USER_SETTINGS.QUESTION_ENTRIES] = DEFAULT_ENTRIES_PER_PARTICIPANT;
      }
      settings[USER_SETTINGS.QUESTION_ENTRIES] = parseInt(settings[USER_SETTINGS.QUESTION_ENTRIES], 10);
      if (!settings.hasOwnProperty(USER_SETTINGS.POLL_DURATION)) {
        settings[USER_SETTINGS.POLL_DURATION] = DEFAULT_POLL_TIME_LIMIT;
      }
      settings[USER_SETTINGS.POLL_DURATION] = parseInt(settings[USER_SETTINGS.POLL_DURATION], 10);
      if (!settings.hasOwnProperty(USER_SETTINGS.POLL_COUNTDOWN)) {
        settings[USER_SETTINGS.POLL_COUNTDOWN] = DEFAULT_GET_READY_ALL_TIME_LIMIT;
      }
      settings[USER_SETTINGS.POLL_COUNTDOWN] = parseInt(settings[USER_SETTINGS.POLL_COUNTDOWN], 10);
      if (!settings.hasOwnProperty(USER_SETTINGS.POLL_REQUIRED_NAME)) {
        settings[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
      }
      settings[USER_SETTINGS.POLL_REQUIRED_NAME] = (settings[USER_SETTINGS.POLL_REQUIRED_NAME] === "true" || settings[USER_SETTINGS.POLL_REQUIRED_NAME] === true);
      if (!settings.hasOwnProperty(USER_SETTINGS.POLL_SHOW_CORRECT_ANS)) {
        settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = DEFAULT_POLL_SHOW_CORRECT_ANS;
      }
      settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = (settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === "true" || settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === true);
      if (!settings.hasOwnProperty(USER_SETTINGS.POLL_LMS_SYNC)) {
        settings[USER_SETTINGS.POLL_LMS_SYNC] = DEFAULT_POLL_LMS_SYNC;
      }
      if (!settings.hasOwnProperty(USER_SETTINGS.POLL_RECORD_ATTEMPTS)) {
        settings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
      }
      if (!settings.hasOwnProperty(USER_SETTINGS.POLL_MAX_ATTEMPTS)) {
        settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
      }
      settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = parseInt(settings[USER_SETTINGS.POLL_MAX_ATTEMPTS], 10);
      if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_MANUAL_END)) {
        settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = DEFAULT_ATTENDANCE_MANUAL_END;
      } else {
        settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = (settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === "true" || settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === true);
      }
      if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_DURATION)) {
        settings[USER_SETTINGS.ATTENDANCE_DURATION] = DEFAULT_ATTENDANCE_TIME_LIMIT;
      } else {
        settings[USER_SETTINGS.ATTENDANCE_DURATION] = parseInt(settings[USER_SETTINGS.ATTENDANCE_DURATION], 10);
      }
      return settings;
    })
    setDataLoading(true);
    getPollDataFromKey(pollKey).then(res => {
      for (const question of res.questions) {
        if (question.questionType === QUESTION_TYPES.MH.name && !question.correctAnswers) {
          question.correctAnswers = question.optionsMap;
        }
      }


      setQuestions(res.questions);
      setHasBlankRestriction(getBlankRestriction(res.questions));
      setQues(res.questions);
      setTitle(res.pollTitle);
      setPollTitle(res.pollTitle);
      setDescription(res.pollDescription);
      setPollType(res.pollType);
      setPageIdentifier(MODAL_EDIT_TEXT + (res.pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE_NAVBAR : POLL_NAVBAR));
      updateCreateMode(res.pollType === POLL_TYPE.ATTENDANCE ? POLL_TYPE.ATTENDANCE : POLL_TYPE.MERGED_POLL);
      setType(res.pollType);
      setPollTimeLimit(res.hasOwnProperty("pollTimeLimit") ? res.pollTimeLimit : settings[USER_SETTINGS.POLL_DURATION]);
      setPollBreakDuration(res.hasOwnProperty("pollBreakDuration") ? res.pollBreakDuration : settings[USER_SETTINGS.POLL_BREAK_DURATION]);
      setGetReadyAllTimeLimit(res.hasOwnProperty("getReadyAllTimeLimit") ? res.getReadyAllTimeLimit : settings[USER_SETTINGS.POLL_COUNTDOWN]);
      setRecordAttempt(res.hasOwnProperty("recordAttempt") ? res.recordAttempt : settings[USER_SETTINGS.POLL_RECORD_ATTEMPTS]);
      setLMSAttempt(res.hasOwnProperty("lmsAttempt") ? res.lmsAttempt : settings[USER_SETTINGS.POLL_LMS_SYNC]);
      setMaxAttempts(res.hasOwnProperty("maxAttempts") ? res.maxAttempts : settings[USER_SETTINGS.POLL_MAX_ATTEMPTS]);
      setShowAnswerOnViewer(res.hasOwnProperty("showAnswerOnViewer") ? res.showAnswerOnViewer : settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS]);
      // setAnonymousJoin(res.hasOwnProperty("anonymousJoin") ? res.anonymousJoin : settings[USER_SETTINGS.POLL_REQUIRED_NAME]);
      setAudienceRestriction(
        res.hasOwnProperty("audienceRestriction") ?
          res.audienceRestriction :
          ((res.hasOwnProperty("anonymousJoin") ?
            res.anonymousJoin :
            settings[USER_SETTINGS.POLL_REQUIRED_NAME]
          ) ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED)
      );
      const defaultPptAutoShare = res.pollType === POLL_TYPE.ATTENDANCE ? settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] : settings[USER_SETTINGS.PPT_AUTO_SHARE];
      setPptAutoShare(res.hasOwnProperty("pptAutoShare") ? res.pptAutoShare : defaultPptAutoShare);
      setGeofence(res.hasOwnProperty("geofence") ? res.geofence : {});
      setSyncLms(res.hasOwnProperty("syncLms") ? res.syncLms : true);
      setManualEnd(res.hasOwnProperty("manualEnd") ? res.manualEnd : settings[USER_SETTINGS.ATTENDANCE_MANUAL_END]);
      setAttendanceDuration(res.hasOwnProperty("attendanceDuration") ? res.attendanceDuration : settings[USER_SETTINGS.ATTENDANCE_DURATION]);
      setDataLoading(false);
    });
  }, []);


  // useEffect(() => {
  //   queChangeCount.current += 1;
  //   if (queChangeCount.current > 2 && !window.onbeforeunload) {
  //     window.onbeforeunload = function () {
  //       return '';
  //     };
  //   }
  // }, [questions]);


  useEffect(() => {
    return () => {
      window.onbeforeunload = undefined;
    }
  }, []);


  useEffect(() => {
    if (!!questions && questions.length >= currQueDisplay) {
      const que = questions[currQueDisplay - 1];
      setHasBlankRestriction(que.hasOwnProperty("hasBlankRestriction") ? que.hasBlankRestriction : getBlankRestriction([que]));
    }

    if (pollType === POLL_TYPE.ATTENDANCE) { // no sidebar for attendance
      return;
    }

    if (typeof(questions) != "undefined" && questions.length > 0 && pollType) {
      setSidebarContent(
        <CreateQuizSidebar
          questions={questions}
          setQuestions={setQuestions}
          currQueDisplay={currQueDisplay}
          setCurrQueDisplay={setCurrQueDisplay}
          pollKey={pollKey}
          userSettings={userSettings}
          dataloading={dataloading}
        />
      );
    } else {
      setSidebarContent(
        <EmptySidebar
          title="Poll Questions"
          noContentText="No Questions added yet"
        />
      );
    }
  }, [questions, setQuestions, setSidebarContent, pollType, currQueDisplay, userSettings, dataloading]);

  // useEffect(() => {
  //   setRightSidebarContent(
  //     <CreateQuestionDetailSidebar
  //       title={"Question Details"}
  //       noContentText={"Question Details"}
  //       questions={questions}
  //       setQuestions={setQuestions}
  //       currQueDisplay={currQueDisplay}
  //       pollType={pollType}
  //       showAnswerOnViewer={showAnswerOnViewer}
  //       setShowAnswerOnViewer={setShowAnswerOnViewer}
  //       anonymousJoin={anonymousJoin}
  //       setAnonymousJoin={setAnonymousJoin}
  //       recordAttempt={recordAttempt}
  //       setRecordAttempt={setRecordAttempt}
  //       lmsAttempt={lmsAttempt}
  //       setLMSAttempt={setLMSAttempt}
  //       maxAttempts={maxAttempts}
  //       setMaxAttempts={setMaxAttempts}
  //     />
  //   );
  // }, [questions, setQuestions, currQueDisplay, setRightSidebarContent, pollType, showAnswerOnViewer, setShowAnswerOnViewer, anonymousJoin, setAnonymousJoin, recordAttempt, setRecordAttempt, lmsAttempt, setLMSAttempt, maxAttempts, setMaxAttempts]);

  const resetPoll = (newPollType) => {
    history.push({ pathname: "/create", state : { pollType : newPollType, fromEditQuizPage : true }});
  }

  // const handlePollTypeChange = async (newPollType, isSave) => {
  //   await setShowAnswerOnViewer(USER_SETTINGS.POLL_SHOW_CORRECT_ANS);
  //   await setAnonymousJoin(USER_SETTINGS.POLL_REQUIRED_NAME);
  //   // if (await editPoll(false)) {
  //     setSaveExit(true); 
  //     resetPoll(newPollType);
  //     return true;
  //   // }
  //   // return false;
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!title || pollType === POLL_TYPE.ATTENDANCE) {
        return
      }

      if (checkAnyQuestionIsLoading()) {
        return;
      }
      
      if (title !== getPollTitle() || JSON.stringify(questions) !== JSON.stringify(getQues())) {
        setIsSaving(true);
        editPoll(false);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [title, questions, pollType]);

  useEffect(() => {
    if (pollType === POLL_TYPE.ATTENDANCE) {
      // if (title === "") {
      //   setTitle(ATTENDANCE_DEFAULT_TITLE.replace(DATE_TO_REPLACE, new Date().toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })));
      // }
      if (questions.length === 0 || questions[0].questionType !== QUESTION_TYPES.SA.name) {
        const qs = addQuestion([], QUESTION_TYPES.SA.name, userSettings, false, POLL_TYPE.ATTENDANCE);
        // qs[0].queTitle = ATTENDANCE_DEFAULT_QUESTION_TITLE;
        qs[0].geofence = {};
        qs[0].syncLms = true;
        qs[0].timeLimit = userSettings[USER_SETTINGS.ATTENDANCE_DURATION];
        qs[0].getReadyTimeLimit = 0; // no get ready time for attendance
        setQuestions(qs);
        setCurrQueDisplay(qs[0].serialNo);
      }
    }
  }, [pollType]);

  useEffect(() => {
    if (isSaving) {
      const gifTimer = setTimeout(() => {
        setIsSaving(false);
      }, 5000);

      return () => clearTimeout(gifTimer);
    }
  }, [isSaving]);

  return (
      <>
      <YuJaGeneralAlert
            show={alertShow}
            setModalShow={setAlertShow}
            content={alertContent}
            config={alertConfig}
        />
        <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
          <div style={{backgroundColor: "#FCFBFF", padding: 20, paddingTop : "0px",overflowY: "hidden"}}>
            {questions &&
              <QuestionsMain
                title={title}
                isEditPage={true}
                setTitle={setTitle}
                questions={questions}
                setQuestions={setQuestions}
                savePoll={editPoll}
                savePollClose={editPollClose}
                pollKey={pollKey}
                currQueDisplay={currQueDisplay}
                changeCurrQueDisplay={changeCurrQueDisplay}
                pollType={pollType}
                pollCode={pollCode}
                isSaving={isSaving}
                disableSave={disableSave}
                userSettings={userSettings}
                geofence={geofence}
                setGeofence={setGeofence}
                syncLms={syncLms}
                setSyncLms={setSyncLms}
                hasBlankRestriction={hasBlankRestriction}
                dataloading={dataloading}
              />
            }
          </div>
        </Spin>
      </>
  );
}

