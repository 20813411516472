import React, { useCallback, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import CreateCourseModal from "../../components/modals/CreateCourseModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as DownGrey } from "../../images/SortingIcon.svg";
import { ACTION_HEADER, COURSES, COURSE_CODE, COURSE_NAME, MENU_LIST, DEFAULT_ROWS_PER_PAGE, ENROLLED_MEMBERS, TEXTBOX } from "../../utils/constants";
import { getPriority } from "../../utils/helpers";
import CourseSearchForm from "./CourseSearchForm";
import CourseTable from "./CourseTable";
import styles from "./ManageCourses.module.css";
import { ReactComponent as NoResultIllustration } from "../../images/NoActivityFound.svg";
export default function ManageCoursesPage(props) {
  const [filter, setFilter] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [courseData, setCourseData] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [courseNameSort, setCourseNameSort] = useState(1);
  const [courseCodeSort, setCourseCodeSort] = useState(1);
  const [enrolledMemberSort, setEnrolledMemberSort] = useState(1);
  const { getCourseByUser } = CourseApiCalls();
  const [, getSession] = useLocalStorageNew("session", {});
  const history = useHistory();
  
  const getCourses = async (pageIndex=0) => {
    setDataReady(false);
    const session = getSession();
    let res;
    if(session.idpType && session.intType) {
        res = await getCourseByUser(session.idpType.toLowerCase(), session.intType.toLowerCase());
    } else {
        res = await getCourseByUser();
    }

    res.sort(function (a, b) {
      return new Date(Date.parse(b.updateTime)) - new Date(Date.parse(a.updateTime));
    })
    setCourseData(res);
    setPageIndex(pageIndex);
    setDataReady(true);
  };

  useEffect(() => {
    props.setPageIdentifier(MENU_LIST.COURSES.name);
    getCourses();
  }, []);

  useEffect(() => {
    if (props.show === "nav") {
      history.push("/course/user")
      setModalShow(true)
    }
  }, [props.show]);

  const handleSubmit = useCallback(({ ...filterObj }) => {
    setFilter(filterObj);
    setPageIndex(0);
  }, []);

  const openCourseModal = () => {
    setModalShow(true);  
  };

  const isMobileScreen = useMobileAccess();

  const columns = [
    { Header: 'Course Name', accessor: 'courseName', width: isMobileScreen ? 100 : 120 },
    { Header: 'Course Code', accessor: 'courseCode', width: isMobileScreen ? 100 : 120 },
    // { Header: 'Instructor\'s Name', accessor: 'instructorsName', width: 120 },
    // { Header: 'Start Date', accessor: 'startDate', width: isMobileScreen ? 120 : 140 },
    // { Header: 'Edit', accessor: 'courseId', width: 100, id: 'edit' },
    // { Header: 'View Course', accessor: 'courseId', width: 100 }
    { Header: ENROLLED_MEMBERS, accessor: 'studentCount',  width: isMobileScreen ? 100 : 120 },
    { Header: 'Action', accessor: 'courseId', width: 50 },
  ];

  const sortCourseName = () => {
    if(!!courseData) {
      setCourseData(courseData.sort(function (a, b) {
        const nameA = a.courseName?.toLowerCase();
        const nameB = b.courseName?.toLowerCase();

        const priorityA = getPriority(nameA);
        const priorityB = getPriority(nameB);

        if (priorityA < priorityB) return -1 * courseNameSort;
        if (priorityA > priorityB) return 1 * courseNameSort;

        if (nameA < nameB) return -1 * courseNameSort;
        if (nameA > nameB) return 1 * courseNameSort;
        return 0;
      }));
      setCourseNameSort(-courseNameSort);
    }
  };

  const sortCourseCode = () => {
    if(!!courseData) {
      setCourseData(courseData.sort(function (a, b) {
        const nameA = a.courseCode?.toLowerCase();
        const nameB = b.courseCode?.toLowerCase();

        const priorityA = getPriority(nameA);
        const priorityB = getPriority(nameB);

        if (priorityA < priorityB) return -1 * courseCodeSort;
        if (priorityA > priorityB) return 1 * courseCodeSort;

        if (nameA < nameB) return -1 * courseCodeSort;
        if (nameA > nameB) return 1 * courseCodeSort;
        return 0;
      }));
      setCourseCodeSort(-courseCodeSort);
    }
  };

  const sortEnrolledMembers = () => {
    if(!!courseData) {
      setCourseData(courseData.sort(function (a, b) {
        const countA = a.studentCount;
        const countB = b.studentCount;

        if (countA < countB) return -1 * enrolledMemberSort;
        if (countA > countB) return 1 * enrolledMemberSort;
      }));
      setEnrolledMemberSort(-enrolledMemberSort);
    }
  };

  const clickCreate = () => {
    history.push({ pathname: "/course/user/create" });
  };

  return (
    <>
      {!dataReady && 
        <div className={styles.container}>
          <div className={styles.titleBox}>
            <div className={styles.titleBoxIcon} />
            <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>{COURSES}</div>
          </div>
          <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <CourseSearchForm handleSubmit={handleSubmit} openCourseModal={openCourseModal}/>
            {/* <YuJaButton onClick={openCourseModal} style={{marginLeft: 15}}><PlusIcon style={{width: 20}}/>{CREATE_COURSE}</YuJaButton> */}
          </div>
          <div className={styles.skeletonTableContainer}>
              <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
              <div className={styles.skeletonHeaderContainer}>
                <div className={styles.skeletonHeaderCell}>{COURSE_NAME}<DownGrey role={TEXTBOX} tabIndex={0} aria-label={COURSE_NAME + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" /></div>
                <div className={styles.skeletonHeaderCell}>{COURSE_CODE}<DownGrey role={TEXTBOX} tabIndex={0} aria-label={COURSE_CODE + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" /></div>
                <div className={styles.skeletonHeaderCell}>{ENROLLED_MEMBERS}<DownGrey role={TEXTBOX} tabIndex={0} aria-label={ENROLLED_MEMBERS + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" /></div>
                <div className={styles.skeletonActionHeaderCell}>{ACTION_HEADER}</div>
              </div>
              <div className={styles.skeletonBodyContainer}>
                {Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                  return (<div className={styles.skeletonBodyRow}>
                    <Skeleton borderRadius={10} height={20} inline={true} width={`${4 + (Math.random() * 7)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                    <Skeleton borderRadius={10} height={20} inline={true} width={`${4 + (Math.random() * 4)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                    <Skeleton borderRadius={10} height={20} inline={true} width={`${4 + (Math.random() * 4)}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                    <Skeleton borderRadius={10} height={20} inline={true} width={`${2 + Math.random()}vw`} className={styles.skeletonActionBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  </div>)
                })}
              </div>
              </SkeletonTheme>
          </div>
        </div>
      }
      {courseData && courseData.length !== 0 && dataReady && (
        <>
          <div className={styles.container} id="manage-course-page-container">
            <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div tabIndex={0} className={styles.titleBoxTitle}>{COURSES}</div>
            </div>
            <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <CourseSearchForm handleSubmit={handleSubmit} openCourseModal={openCourseModal}/>
            </div>
            <CourseTable columns={columns} data={courseData} pageIdx={pageIndex} globalFilterObj={filter} getCourses={getCourses} courseNameSort={courseNameSort} sortCourseName={sortCourseName} courseCodeSort={courseCodeSort} sortCourseCode={sortCourseCode} enrolledMemberSort={enrolledMemberSort} sortEnrolledMembers={sortEnrolledMembers}/>
          </div>
        </>
      ) }
      {(!courseData || courseData.length === 0) && (
        <>
          <div className={styles.c2}>
            <div className={styles.titleBox}>
              <div className={styles.titleBoxTitle}>You have not created any courses.</div>
            </div>
            <NoResultIllustration style={{width: "100%", maxHeight: "100%", marginTop: "150px"}}/>
            <div className={styles.text}>Press the button below to create a course.</div>
            <div className={styles.center}>
              <YuJaButton onClick={() => clickCreate()} style={{fontSize: 20, minWidth:145}}>Create Course</YuJaButton>
            </div>
          </div>
        </>
      )}
      <CreateCourseModal
            show={modalShow}
            setModalShow={setModalShow}
            getCourses={getCourses}
          />
    </>
  );
}