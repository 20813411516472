import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { ATTEMPTS_BOX, ATTENDANCE_OPTIONS_MODAL_DESCRIPTION, ATTENDANCE_OPTIONS_MODAL_TITLE, AUDIENCE_RESTRICTION, AUDIENCE_RESTRICTION_COURSE, AUDIENCE_RESTRICTION_EVERYONE, AUDIENCE_RESTRICTION_LOGGED_IN, AUDIENCE_RESTRICTION_NAME, AUDIENCE_RESTRICTION_TITLE, CORRECT_ANSWER_TITLE, COURSE_PLACEHOLDER, CUSTOM_MAX_ATTEMPTS_TEXT, GRADED_OPTIONS_MODAL_DESCRIPTION, LMS_ATTEMPT_TITLE, MAX_ATTEMPTS_TITLE, MODAL, MODAL_CANCEL_TEXT, MODAL_SAVE_TEXT, POLL_OPTIONS_MODAL_DISABLED_SAVE_TOOLTIP_TEXT, POLL_OPTIONS_MODAL_TITLE, POLL_SHARE_MODE, POLL_TYPE, RADIAL_LABEL, RECORD_ATTEMPT, RECORD_ATTEMPT_TITLE, SHOW_CORRECT_ANSWER_CUSTOM_TEXT, SYNC_ATTEMPT, TEXTBOX, UNLIMITED_MAX_ATTEMPTS_TEXT, USER_SETTINGS } from "../../utils/constants";
import { notifyError, removePrefixNew } from "../../utils/helpers";
import YuJaButton from "../standardization/YuJaButton";
import { YuJaCheckbox } from "../standardization/YuJaCheckbox";
import { YuJaDropdown } from "../standardization/YuJaDropdown";
import { YuJaRadioBox } from "../standardization/YuJaRadiobox";
import { YuJaTextBox } from "../standardization/YuJaTextBox";
import { YuJaTooltip } from "../standardization/YuJaTooltip";
import "./modal.css";

export default function ActivityOptionsModal({ show, setShow, pollOptions, courseData, loadingCourses, userSettings, pollShareMode, handleSubmit, pollType }) {
    const [customRecordAttempt, setCustomRecordAttempt] = useState(null);
    const [customLMSAttempt, setCustomLMSAttempt] = useState(null);
    const [customMaxAttempts, setCustomMaxAttempts] = useState(null);
    const [customShowCorrectAnswer, setCustomShowCorrectAnswer] = useState(null);
    // const [customRequireName, setCustomRequireName] = useState(null);
    const [customAudienceRestriction, setCustomAudienceRestriction] = useState(null);
    const [courseOptions, setCourseOptions] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const recordAttemptOptions = useRef([RECORD_ATTEMPT.ALL, RECORD_ATTEMPT.RECENT]);
    const LMSQuizAttemptOptions = useRef([SYNC_ATTEMPT.FIRST, SYNC_ATTEMPT.RECENT, SYNC_ATTEMPT.HIGHEST]);

    useEffect(() => {
        if (!pollOptions || !userSettings || !show) return;
        console.log(pollOptions, userSettings);
        setCustomRecordAttempt(pollOptions.recordAttempt ? pollOptions.recordAttempt : userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS]);
        setCustomLMSAttempt(pollOptions.lmsAttempt ? pollOptions.lmsAttempt : userSettings[USER_SETTINGS.POLL_LMS_SYNC]);
        setCustomMaxAttempts(pollOptions.maxAttempts ? pollOptions.maxAttempts : userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS]);
        setCustomShowCorrectAnswer(pollOptions?.showAnswerOnViewer !== undefined ? pollOptions.showAnswerOnViewer : userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS]);
        // setCustomRequireName(pollOptions?.anonymousJoin !== undefined ? pollOptions.anonymousJoin : userSettings[USER_SETTINGS.POLL_REQUIRED_NAME]);
        setCustomAudienceRestriction(pollOptions?.audienceRestriction ? pollOptions.audienceRestriction : // has audience restriction
            pollOptions?.index2Pk ? AUDIENCE_RESTRICTION.REGISTERED : // has course restriction
                (pollOptions?.anonymousJoin !== undefined ? pollOptions.anonymousJoin : userSettings[USER_SETTINGS.POLL_REQUIRED_NAME]) ?
                    AUDIENCE_RESTRICTION.NAME_REQUIRED : // has name restriction
                    AUDIENCE_RESTRICTION.UNRESTRICTED // no restriction
        );
        const course = pollOptions?.index2Pk ? courseData.find(course => course.value === removePrefixNew(pollOptions.index2Pk)) : null;
        setSelectedCourse(course);
        // console.log(pollOptions?.lmsHomeUrl, [course], courseData.filter(c => !c.isLmsCourse));
        setCourseOptions(pollOptions?.lmsHomeUrl ? [course] : courseData.filter(c => !c.isLmsCourse));
    }, [pollOptions, userSettings, show, courseData]);

    useEffect(() => {
        if (!!customAudienceRestriction && customAudienceRestriction !== AUDIENCE_RESTRICTION.REGISTERED) {
            setSelectedCourse(null);
        }
    }, [customAudienceRestriction]);

    const handleRecordAttemptOptionClick = (value) => {
        setCustomRecordAttempt(value);
        if (value === RECORD_ATTEMPT.RECENT.value) {
            setCustomLMSAttempt(SYNC_ATTEMPT.RECENT.value);
        } else if (value === RECORD_ATTEMPT.ALL.value) {
            setCustomLMSAttempt(pollOptions.lmsAttempt ? pollOptions.lmsAttempt : userSettings[USER_SETTINGS.POLL_LMS_SYNC]);
        }
    };

    const handleLMSAttemptOptionClick = (value) => {
        if (customRecordAttempt === RECORD_ATTEMPT.RECENT.value) {
            return;
        }
        setCustomLMSAttempt(value);
    };

    const onChangeCustomMaxAttempts = (e) => {
        let a = e.target.value.replace(/[^0-9]/g, '');
        setCustomMaxAttempts(a);
    };

    const onBlurCustomMaxAttempts = (e) => {
        if (e.target.value === "" || parseInt(e.target.value) < 1) {
            setCustomMaxAttempts(1);
        } else {
            setCustomMaxAttempts(parseInt(e.target.value));
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="activity-options-modal"
        >
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ModalClose onClick={() => setShow(false)} className="modal-close" />
                <Modal.Title
                    className="modal-title"
                    id="contained-modal-title-vcenter"
                    tabIndex={0}
                    aria-label={pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE_OPTIONS_MODAL_TITLE : POLL_OPTIONS_MODAL_TITLE + MODAL}
                    role={TEXTBOX}
                >
                    {pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE_OPTIONS_MODAL_TITLE : POLL_OPTIONS_MODAL_TITLE}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: pollType === POLL_TYPE.MERGED_POLL ? 510 : 230 }}>
                <div className="activityModalDescription">
                    {pollType === POLL_TYPE.MERGED_POLL ? GRADED_OPTIONS_MODAL_DESCRIPTION : ATTENDANCE_OPTIONS_MODAL_DESCRIPTION}
                </div>
                {pollType === POLL_TYPE.MERGED_POLL && (<>
                <Row className="gradebookOptionsRow">
                        <Col sm={4} tabIndex={0} role={TEXTBOX} className="gradebookOptionTitle" style={{ display: "flex", alignItems: "center" }}>
                        {RECORD_ATTEMPT_TITLE}
                    </Col>
                    <Col sm={8} className="gradebookOptionConfigure" style={{display: "flex"}}>
                        {Object.entries(recordAttemptOptions.current).map((optionData, index) => {
                            const { value, label } = optionData[1];
                            return (
                                <div key={index} className="attemptsOptionWrapper" style={{ padding: index === 0 ? "0" : "0 0 0 1vw" }}>
                                    <YuJaRadioBox
                                        size={20}
                                        aria-label={label + RADIAL_LABEL + (value === customRecordAttempt ? "selected" : "unselected")}
                                        label={label}
                                        checked={value === customRecordAttempt}
                                        onClick={() => handleRecordAttemptOptionClick(value)}
                                        labelStyle={{ flexWrap: "wrap" }}
                                    />
                                </div>
                            );
                        })}
                    </Col>
                </Row>

                <hr />

                <Row className="gradebookOptionsRow">
                    <Col sm={4} tabIndex={0} role={TEXTBOX} className="gradebookOptionTitle" style={{ display: "flex", alignItems: "center" }}>
                        {LMS_ATTEMPT_TITLE}
                    </Col>
                    <Col sm={8} className="gradebookOptionConfigure">
                        {/* {Object.entries(LMSQuizAttemptOptions.current).map((optionData, index) => {
                            const { value, label } = optionData[1];
                            return (
                                <div key={index} style={{ padding: "0.5vh 0px" }}>
                                    <YuJaRadioBox
                                        aria-label={label + RADIAL_LABEL + (value === customLMSAttempt ? "selected" : "unselected")}
                                        label={label}
                                        checked={value === customLMSAttempt}
                                        onClick={() => handleLMSAttemptOptionClick(value)}
                                        disabled={customRecordAttempt === RECORD_ATTEMPT.RECENT.value}
                                    />
                                </div>
                            );
                        })} */}
                        <YuJaDropdown
                            data={LMSQuizAttemptOptions.current}
                            value={customLMSAttempt}
                            onChange={option => handleLMSAttemptOptionClick(option.value)}
                            disabled={customRecordAttempt === RECORD_ATTEMPT.RECENT.value}
                            containerStyle={{ width: 300 }}
                        />
                    </Col>
                </Row>

                <hr />

                <Row className="gradebookOptionsRow">
                    <Col sm={4} tabIndex={0} role={TEXTBOX} className="gradebookOptionTitle">
                        {MAX_ATTEMPTS_TITLE}
                    </Col>
                    <Col sm={8} className="gradebookOptionConfigure">
                        <div
                            // className="attemptsOption margin-vertical-auto"
                            style={{ padding: "0 0 2vh 0" }}
                        >
                            <Row style={{ margin: '0px' }}>
                                <Col className="gradebook-option-radio-col">
                                    <YuJaCheckbox
                                        size={20}
                                        ariaLabel={ATTEMPTS_BOX + (customMaxAttempts === -1 ? "checked" : "unchecked")}
                                        label={UNLIMITED_MAX_ATTEMPTS_TEXT}
                                        checked={customMaxAttempts === -1}
                                        onClick={(e) => {
                                            if (e.target.checked) {
                                                setCustomMaxAttempts(-1);
                                            } else {
                                                setCustomMaxAttempts(1);
                                            }
                                        }}
                                    />

                                </Col>
                            </Row>
                        </div>
                        {/* {customMaxAttempts !== -1 && ( */}
                            <div style={{ display: "flex", alignItems: "center", opacity: customMaxAttempts === -1 ? 0.5 : 1 }}>
                                <YuJaTextBox
                                    placeholder={"1"}
                                    value={customMaxAttempts === -1 ? 1 : customMaxAttempts}
                                    onChange={onChangeCustomMaxAttempts}
                                    onBlur={onBlurCustomMaxAttempts}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    inputAlign={"center"}
                                    containerStyle={{ width: 35, marginRight: 10, fontSize: 13, padding: 6 }}
                                />
                                <div className="attemptsOptionText">{CUSTOM_MAX_ATTEMPTS_TEXT}</div>
                            </div>
                        {/* )} */}
                    </Col>
                </Row>

                <hr />

                <Row className="gradebookOptionsRow">
                    <Col sm={4} tabIndex={0} role={TEXTBOX} className="gradebookOptionTitle" style={{ display: "flex", alignItems: "center" }}>
                        {CORRECT_ANSWER_TITLE}
                    </Col>
                    <Col sm={8} className="gradebookOptionConfigure">
                        <YuJaCheckbox
                            size={20}
                            ariaLabel={ATTEMPTS_BOX + (customShowCorrectAnswer ? "checked" : "unchecked")}
                            label={SHOW_CORRECT_ANSWER_CUSTOM_TEXT}
                            checked={customShowCorrectAnswer}
                            onClick={() => setCustomShowCorrectAnswer(!customShowCorrectAnswer)}
                        />
                    </Col>
                </Row>

                <hr />

                </>)}

                <Row className="gradebookOptionsRow" style={{marginTop: "10px"}}>
                    <Col sm={4} tabIndex={0} role={TEXTBOX} className="gradebookOptionTitle">
                        {AUDIENCE_RESTRICTION_TITLE}
                    </Col>
                    <Col sm={8} className="gradebookOptionConfigure">
                        <YuJaRadioBox
                            size={20}
                            // ariaLabel={ATTEMPTS_BOX + (customAudienceRestriction ? "checked" : "unchecked")}
                            label={AUDIENCE_RESTRICTION_EVERYONE}
                            checked={customAudienceRestriction === AUDIENCE_RESTRICTION.UNRESTRICTED}
                            onClick={() => setCustomAudienceRestriction(AUDIENCE_RESTRICTION.UNRESTRICTED)}
                            disabled={pollOptions?.lmsHomeUrl}
                        />
                        <YuJaRadioBox
                            size={20}
                            // ariaLabel={ATTEMPTS_BOX + (customAudienceRestriction ? "checked" : "unchecked")}
                            label={AUDIENCE_RESTRICTION_NAME}
                            checked={customAudienceRestriction === AUDIENCE_RESTRICTION.NAME_REQUIRED}
                            onClick={() => setCustomAudienceRestriction(AUDIENCE_RESTRICTION.NAME_REQUIRED)}
                            disabled={pollOptions?.lmsHomeUrl}
                            style={{ marginTop: "1vh" }}
                        />
                        {/* <YuJaRadioBox
                            size={20}
                            // ariaLabel={ATTEMPTS_BOX + (customAudienceRestriction ? "checked" : "unchecked")}
                            label={AUDIENCE_RESTRICTION_EVERYONE}
                            checked={customAudienceRestriction === AUDIENCE_RESTRICTION.NAME_REQUIRED || customAudienceRestriction === AUDIENCE_RESTRICTION.UNRESTRICTED}
                            onClick={() => setCustomAudienceRestriction(userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED)}
                        />
                        {customAudienceRestriction === AUDIENCE_RESTRICTION.NAME_REQUIRED || customAudienceRestriction === AUDIENCE_RESTRICTION.UNRESTRICTED ? (
                            <YuJaCheckbox
                                size={20}
                                ariaLabel={ATTEMPTS_BOX + (customRequireName ? "checked" : "unchecked")}
                                label={REQUIRED_NAME_CUSTOM_TEXT}
                                checked={customRequireName}
                                onClick={() => setCustomRequireName(!customRequireName)}
                                style={{ marginTop: "0.5vh", marginLeft: "20px" }}
                            />
                        ) : null} */}
                        <YuJaRadioBox
                            size={20}
                            // ariaLabel={ATTEMPTS_BOX + (customAudienceRestriction ? "checked" : "unchecked")}
                            label={AUDIENCE_RESTRICTION_LOGGED_IN}
                            checked={customAudienceRestriction === AUDIENCE_RESTRICTION.LOGGED_IN}
                            onClick={() => setCustomAudienceRestriction(AUDIENCE_RESTRICTION.LOGGED_IN)}
                            disabled={pollOptions?.lmsHomeUrl}
                            style={{ marginTop: "1vh" }}
                        />
                        <YuJaRadioBox
                            size={20}
                            // ariaLabel={ATTEMPTS_BOX + (customAudienceRestriction ? "checked" : "unchecked")}
                            label={AUDIENCE_RESTRICTION_COURSE}
                            checked={customAudienceRestriction === AUDIENCE_RESTRICTION.REGISTERED}
                            onClick={() => setCustomAudienceRestriction(AUDIENCE_RESTRICTION.REGISTERED)}
                            disabled={pollOptions?.lmsHomeUrl}
                            style={{ marginTop: "1vh" }}
                        />
                        {customAudienceRestriction === AUDIENCE_RESTRICTION.REGISTERED ? (
                            <Select
                                options={courseOptions}
                                value={selectedCourse}
                                onChange={setSelectedCourse}
                                isLoading={loadingCourses}
                                isMulti={false}
                                isInvalid={false}
                                placeholder={COURSE_PLACEHOLDER}
                                isDisabled={pollOptions?.lmsHomeUrl}
                                className="courseSelect"
                            />
                        ) : null}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton type="secondary" onClick={() => setShow(false)}>{MODAL_CANCEL_TEXT}</YuJaButton>
                <YuJaTooltip
                    text={
                        // pollShareMode && pollShareMode === POLL_SHARE_MODE.UNKNOWN ? POLL_OPTIONS_MODAL_SAVE_TOOLTIP_TEXT :
                        POLL_OPTIONS_MODAL_DISABLED_SAVE_TOOLTIP_TEXT
                    }
                    placement="top"
                    tooltipStyle={{maxWidth: 400}}
                    prohibit={!pollShareMode || pollShareMode !== POLL_SHARE_MODE.UNKNOWN}
                >
                    <span>
                    <YuJaButton
                        onClick={() => {
                            if (customAudienceRestriction === AUDIENCE_RESTRICTION.REGISTERED) {
                                if (!selectedCourse) {
                                    notifyError("Please select a course");
                                    // document.querySelector('.courseSelect').classList.add('required');
                                    return;
                                } else {
                                    // console.log(selectedCourse.value);
                                    // document.querySelector('.courseSelect').classList.remove('required');
                                }
                            }
                            const customOptions = {
                                audienceRestriction: customAudienceRestriction,
                                courseId: selectedCourse?.value ? selectedCourse.value : "",
                                courseCode: selectedCourse?.code ? selectedCourse.code : ""
                            };
                            // console.log(selectedCourse, selectedCourse?.value, selectedCourse?.code);
                            // if (selectedCourse && selectedCourse.value && selectedCourse.code) {
                            //     customOptions.courseId = selectedCourse.value;
                            //     customOptions.courseCode = selectedCourse.code;
                            // }
                            if (pollType === POLL_TYPE.MERGED_POLL) {
                                customOptions.recordAttempt = customRecordAttempt;
                                customOptions.lmsAttempt = customLMSAttempt;
                                customOptions.maxAttempts = customMaxAttempts;
                                customOptions.showAnswerOnViewer = customShowCorrectAnswer;
                                // customOptions.anonymousJoin = customRequireName;
                            }
                            handleSubmit(customOptions);
                        }}
                        disabled={!pollShareMode || pollShareMode !== POLL_SHARE_MODE.UNKNOWN}
                    >
                        {MODAL_SAVE_TEXT}
                    </YuJaButton>
                    </span>
                </YuJaTooltip>
            </Modal.Footer>
        </Modal>
    );
}