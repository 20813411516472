import "antd/dist/antd.min.css";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { UserApiCalls } from "../../apis/UserApiCalls";
import { Spin } from "../../components/standardization/YuJaLoading";
import useHostname from "../../hooks/useHostname";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { useLoading } from "../../utils/LoadingContext";
import { EDIT_QUESTION_BANK, NO_QUESTION_ADDED, POLL_QUESTION_TITLE, POLL_TITLE_LENGTH, QUESTION_TYPES, SWITCH_POLL_MESSAGE, SWITCH_POLL_MODAL_TEXT, SWITCH_POLL_MODAL_TITLE } from "../../utils/constants";
import { notifyError, validateText } from "../../utils/helpers";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import { addQuestion, getBlankRestriction, trimQuestion } from "../../utils/questionUtils";
import { QUESTION_BANK_TITLE_ERROR } from "../../utils/toast-message-constants";
import EmptySidebar from "../CreateQuizPage/EmptySidebar";
import "../CreateQuizPage/styles.css";
// import CreateQuestionDetailSidebar from "./CreateQuestionDetailSidebar";
// import CreateQuestionSidebar from "./CreateQuestionSidebar";
import CreateQuizSidebar from "../CreateQuizPage/CreateQuizSidebar";
import QuestionBankMain from "./QuestionBankMain";
import {useImageLoaderManager} from "../../hooks/useImageLoaderManager";

export default function EditQuestionProfilePage({
  setSidebarContent,
  setPageIdentifier
}) {
  const { questionProfileId} = useParams();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [currQueDisplay, setCurrQueDisplay] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("");
  const [hasBlankRestriction, setHasBlankRestriction] = useState(false);
  const { updateQuestionProfile, getQuestionProfileId } = UserApiCalls(); 
  const [desiredPollMode, setDesiredPollMode] = useState("");
  const { loading } = useLoading();
  const location = useLocation();
  const [setPollTitle, getPollTitle] = useLocalStorageNew("pollTitle", "");
  const [setQues, getQues] = useLocalStorageNew("questions", []);
  const [setIsSaving, getIsSaving] = useLocalStorageNew("isSaving", false);

  const [isAutoSaving, setIsAutoSaving] = useState(false); 
  const history = useHistory();

  const [focusMCElem, setFocusMCElem] = useState(-1);
  const [focusSAElem, setFocusSAElem] = useState(-1);
  const [focusTFElem, setFocusTFElem] = useState(-1);
  const [focusFITBElem, setFocusFITBElem] = useState(-1);
  const [focusCIElem, setFocusCIElem] = useState(-1);
  const hostResource = useHostname(window.location.hostname);
  const {institutionId = ""} = hostResource;

  const [nextRouterPath, setNextRouterPath] = useState({ pathname: '', state: null });
  const [isConfirmationPending, setIsConfirmationPending] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [setSaveExit, getsaveExit] = useLocalStorageNew("saveExit", false); 
  const [setRecentQuestions, getRecentQuestions] = useLocalStorageNew("recentQuestions", []);
  const [setRecentTitle, getRecentTitle] = useLocalStorageNew("recentTitle", []);
  const [dataloading , setDataLoading] = useState(false);

  const {checkAnyQuestionIsLoading} = useImageLoaderManager(questions, setQuestions);

  const alertConfig = {
    title: SWITCH_POLL_MODAL_TITLE,
    okText: SWITCH_POLL_MODAL_TEXT,
    submit: async () => {
      setAlertShow(false);
      setIsConfirmationPending(true);
    },
    close:  async () => {
      setAlertShow(false);
    }, 
  };

  const checkChanged = () => {
    const curr_ques = JSON.stringify(getRecentQuestions()); 
    const curr_saved = JSON.stringify(getQues());
    if(getPollTitle() !== getRecentTitle()){
      return true; 
    }
    return !(curr_ques === curr_saved);
  }


  useEffect (() => {
    setRecentQuestions(questions); 
  }, [questions]);

  useEffect (() => {
    setRecentTitle(title); 
  }, [title]);

  if (questions.length < 1) {
    const qs = addQuestion(questions, "MCSS");
    setQuestions(qs);
    setCurrQueDisplay(qs[0].serialNo);
  }
  
  useEffect(() => {
    switch(questions[currQueDisplay-1].questionType) {
      case QUESTION_TYPES.MCSS.name:
        if(focusMCElem !== -1) {
          document.getElementsByClassName("poll-sub-text focus-outline-none")[focusMCElem].focus();
          setFocusMCElem(-1);
        }
        break;
      case QUESTION_TYPES.SA.name:
        if(focusSAElem !== -1) {
          document.getElementsByClassName("poll-sub-text focus-outline-none")[focusSAElem].focus();
          setFocusSAElem(-1);
        }
        break;
      case QUESTION_TYPES.TF.name:
        if(focusTFElem !== -1) {
          document.getElementsByClassName("poll-sub-text focus-outline-none")[focusTFElem].focus();
          setFocusTFElem(-1);
        }
        break;
      case QUESTION_TYPES.FITB.name:
        if(focusFITBElem !== -1) {
          if(focusFITBElem === 0 && document.getElementsByClassName("fitb-bold focus-outline-none").length === 0) {
            document.getElementsByClassName("notranslate public-DraftEditor-content")[focusFITBElem].focus();
          } else {
            document.getElementsByClassName("fitb-bold focus-outline-none")[focusFITBElem].focus();
          }
          setFocusFITBElem(-1);
        }
        break;
      case QUESTION_TYPES.CI.name:
        if(focusCIElem !== -1) {
          document.getElementsByClassName("poll-sub-text focus-outline-none")[focusCIElem].focus();
          setFocusCIElem(-1);
        }
      case QUESTION_TYPES.MH.name: 
        if (focusCIElem !== -1) {
          document.getElementsByClassName("poll-sub-text focus-outline-none")[focusCIElem].focus();
          setFocusCIElem(-1);
        }
    }
  }, [currQueDisplay, focusMCElem, focusSAElem, focusTFElem, focusFITBElem, focusCIElem]);

  
  useEffect(() => {
    let isCheckChangedPending = false;
  
    const unblock = history.block((location, action) => {
      if (isCheckChangedPending) {
        return true; 
      }
      isCheckChangedPending = true;
  
      const result = checkChanged();
      if (!result || getsaveExit() === true) {
        setSaveExit(false);
        resetPoll();
        return true;
      }
  
      if (location.pathname && !isConfirmationPending) {
        setNextRouterPath({ pathname: location.pathname, state: location.state });
        setAlertShow(true);
        return false;
      }
  
      return true;
    });
  
    if (isConfirmationPending) {
      resetPoll();
      history.push(nextRouterPath.pathname, nextRouterPath.state);
      setIsConfirmationPending(false);
      unblock();
    }
  
    return () => {
      unblock();
    };
  }, [history, isConfirmationPending, nextRouterPath]);

  const savePoll = async (showwarning=true) => {
    if (!getIsSaving()) {
      setIsSaving(true); 
      if (!validateText(title, POLL_TITLE_LENGTH)) {
        if(showwarning){
          notifyError(QUESTION_BANK_TITLE_ERROR);
        }
        return false;
      }

      setSaveButtonDisabled(true);

    const trimmedQs = trimQuestion(questions); 
    let pollToAdd = {
          questions: trimmedQs,
          ProfileTitle: title,
          questionProfileId: questionProfileId, 
    };
    console.log(pollToAdd);
    if (questionProfileId != null) {
        await updateQuestionProfile(pollToAdd, showwarning); 
    }


    setPollTitle(title);
    setQues(questions);
    setSaveButtonDisabled(false);
    setIsSaving(false);
    return true;
    }
  };

  const savePollClose = async (showwarning=true) => {
    await savePoll(showwarning);
    history.push("/questionBank");
  };


  useEffect(() => {
    if (questions.length > 0) {
      setSidebarContent(
        <CreateQuizSidebar
          setQuestions={setQuestions}
          questions={questions}
          currQueDisplay={currQueDisplay}
          setCurrQueDisplay={setCurrQueDisplay}
          isBank={true}
          questionBankId={questionProfileId}
          dataloading={dataloading}
        />
      );
    } else {
      setSidebarContent(
        <EmptySidebar
          title={POLL_QUESTION_TITLE}
          noContentText={NO_QUESTION_ADDED}
        />
      );
    }
  }, [title, questions, setQuestions, setSidebarContent, desiredPollMode, setDesiredPollMode, currQueDisplay, setCurrQueDisplay,dataloading]);

  // useEffect(() => {
  //   setRightSidebarContent(
  //     <CreateQuestionDetailSidebar
  //       title={CREATE_QUESTION_DETAIL_SIDEBAR_TITLE}
  //       noContentText={CREATE_QUESTION_DETAIL_SIDEBAR_TITLE}
  //       questions={questions}
  //       setQuestions={setQuestions}
  //       currQueDisplay={currQueDisplay}
  //     />
  //   );
  // }, [questions, setQuestions, currQueDisplay, setRightSidebarContent, showAnswerOnViewer, setShowAnswerOnViewer]);

  useEffect(async () => {
    if (location && location.state) {
        resetPoll();
    }
  }, [location.state]);

  const resetPoll = () => {
    setSaveButtonDisabled(false);
    setQuestions([]);
    setPollTitle("");
    setIsSaving(false);
    setQues({});
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!title) {
        return
      }

      if (checkAnyQuestionIsLoading()) {
        return;
      }

      if ((title !== getPollTitle()) || (JSON.stringify(questions) !== JSON.stringify(getQues()))) {
        setIsAutoSaving(true);
        savePoll(false);
        setPollTitle(title);
        setQues(questions);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [title, questions]);

  useEffect(() => {
    setPageIdentifier(EDIT_QUESTION_BANK);
    setIsSaving(false);
    setDataLoading(true)
    getQuestionProfileId(questionProfileId).then(res => {
      for (const question of res.questions) {
        if (question.questionType === QUESTION_TYPES.MH.name && !question.correctAnswers) {
          question.correctAnswers = question.optionsMap;
        }
      }
      setQuestions(res.questions);
      setQues(res.questions);
      setHasBlankRestriction(getBlankRestriction(res.questions));
      setTitle(res.ProfileTitle);
      setPollTitle(res.ProfileTitle);
      setDataLoading(false);
    });
  }, []);

  useEffect(() => {
    if (isAutoSaving) {
      const gifTimer = setTimeout(() => {
        setIsAutoSaving(false);
      }, 5000);

      return () => clearTimeout(gifTimer);
    }
  }, [isAutoSaving]);

  return (
    <>
    <YuJaGeneralAlert
            show={alertShow}
            setModalShow={setAlertShow}
            content={SWITCH_POLL_MESSAGE}
            config={alertConfig}
        />
      <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
        <div style={{ backgroundColor: "#FCFBFF", padding: "0 2.063rem", overflowY: "hidden", height: "100%"}}>
          {(questions.length > 0) && 
            <QuestionBankMain
              title={title}
              setTitle={setTitle}
              questions={questions}
              setQuestions={setQuestions}
              savePoll={savePoll}
              savePollClose={savePollClose}
              currQueDisplay={currQueDisplay}
              changeCurrQueDisplay={setCurrQueDisplay}
              saveButtonDisabled={saveButtonDisabled}
              institutionId={institutionId}
              isEditPage={true}
              questionBankId={questionProfileId}
              isSaving={isAutoSaving}
              isBank={true}
              hasBlankRestriction={hasBlankRestriction}
              dataloading={dataloading}
            />}
        </div>
      </Spin>
    </>
  );
}