import {
    ACTION_HEADER,
    DEFAULT_PAGINATION_SIZE,
    NO_RESULTS_FOUND, QUESTION_TYPES, REPORT_ACTIVITY_QUESTION_TABLE_HEADERS,
    REPORT_ACTIVITY_TABLE_SEARCH_BOX_PLACEHOLDER,
    SEARCH_POLL,
    SEARCH_TEXT,
    TEXTBOX,
} from "../../utils/constants";

import classes from "./Report.module.css"
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import React, {useEffect, useState} from "react";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import { ReactComponent as SortDown } from "../../images/SortingIconDesc.svg";
import { ReactComponent as SortUp } from "../../images/SortingIconAsec.svg";
import { ReactComponent as SortGrey } from "../../images/SortingIcon.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { ReactComponent as ICIcon } from "../../images/clickable_purple.svg";
import { ReactComponent as DropDown } from "../../images/YuJa_arrow_down_icon.svg";
import { ReactComponent as FITBIcon } from "../../images/fitb_purple.svg";
import { ReactComponent as MHIcon } from "../../images/matching_purple.svg";
import { ReactComponent as MCSSIcon } from "../../images/mcss_purple.svg";
import { ReactComponent as SAIcon } from "../../images/sa_purple.svg";
import { ReactComponent as OEIcon } from "../../images/OpenEnded.svg";
import { ReactComponent as TFIcon } from "../../images/tf_purple.svg";
import { ReactComponent as WCIcon } from "../../images/wcloud_purple.svg";
import { ReactComponent as RKIcon } from "../../images/RankingIcon.svg";

import YuJaButton from "../../components/standardization/YuJaButton";
import {useGlobalFilter, usePagination, useTable} from "react-table";
import styles from "../ManagePollsPage/ManagePolls.module.css";
import {Table} from "react-bootstrap";
import NormalPagination from "../../components/pagination/NormalPagination";

const columns = [
    { Header: REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.SERIAL_NO.description, accessor: REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.SERIAL_NO.name, width: 120},
    { Header: REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TITLE.description, accessor: REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TITLE.name, width: 400 },
    { Header: REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TYPES.description, accessor: REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TYPES.name, width: 150 },
    { Header: REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.WEIGHTAGE.description, accessor: REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.WEIGHTAGE.name, width: 150 },
    { Header: REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.FINISHED_COUNT.description, accessor: REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.FINISHED_COUNT.name, width: 120 },
    { Header: REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.INCOMPLETE_COUNT.description, accessor: REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.INCOMPLETE_COUNT.name, width: 120 },
];

export default function QuestionTab({data, setData}) {
    const [keyword, setKeyword] = useState();
    const [pageIdx, setPageIdx] = useState(0);
    const [filter, setFilter] = useState({});
    const [sortSelected, setSortSelected] = useState(0);
    const [sortSerialNo, setSortSerialNo] = useState(1);
    const [sortTitle, setSortTitle] = useState(-1);
    const [sortType, setSortType] = useState(-1);
    const [sortWeightage, setSortWeightage] = useState(-1);
    const [sortFinished, setSortFinished] = useState(-1);
    const [sortIncomplete, setSortIncomplete] = useState(-1);


    const clickSearch = () => {
        setFilter({keyword});
        setPageIdx(0);
    }

    useEffect(() => {
        setGlobalFilter(filter);
    }, [data, filter]);

    const filterCustomize = (rows, _, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase().trim() : "";
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.queTitle && values.queTitle.toLowerCase().indexOf(filteredKeyword.toLowerCase()) > -1) {
                return true;
            }

            return false;
        });
    };


    const checkDisplayDownArrow = (Header) => {
        if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.SERIAL_NO.description && sortSerialNo === -1 && sortSelected === 0) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TITLE.description && sortTitle === -1 && sortSelected === 1) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TYPES.description && sortType === -1 && sortSelected === 2) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.WEIGHTAGE.description && sortWeightage === -1 && sortSelected === 3) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.FINISHED_COUNT.description && sortFinished === -1 && sortSelected === 4) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.INCOMPLETE_COUNT.description && sortIncomplete === -1 && sortSelected === 5) {
            return true;
        }
        return false;
    }

    const checkDisplayUpArrow= (Header) => {
        if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.SERIAL_NO.description && sortSerialNo === 1 && sortSelected === 0) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TITLE.description && sortTitle === 1 && sortSelected === 1) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TYPES.description && sortType === 1 && sortSelected === 2) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.WEIGHTAGE.description && sortWeightage === 1 && sortSelected === 3) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.FINISHED_COUNT.description && sortFinished === 1 && sortSelected === 4) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.INCOMPLETE_COUNT.description && sortIncomplete === 1 && sortSelected === 5) {
            return true;
        }
        return false;
    }

    const checkDisplayGreyArrow = (Header) => {
        if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.SERIAL_NO.description && sortSelected !== 0) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TITLE.description && sortSelected !== 1) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TYPES.description && sortSelected !== 2) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.WEIGHTAGE.description && sortSelected !== 3) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.FINISHED_COUNT.description && sortSelected !== 4) {
            return true;
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.INCOMPLETE_COUNT.description && sortSelected !== 5) {
            return true;
        }
        return false;
    }

    const handleSort = (Header) => {
        if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.SERIAL_NO.description) {
            sortSerialNoHelper();
            setSortSelected(0);
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TITLE.description) {
            sortTitleHelper();
            setSortSelected(1);
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TYPES.description){
            sortTypeHelper();
            setSortSelected(2);
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.WEIGHTAGE.description){
            sortWeightageHelper();
            setSortSelected(3);
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.FINISHED_COUNT.description){
            sortFinishedHelper();
            setSortSelected(4);
        } else if (Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.INCOMPLETE_COUNT.description){
            sortIncompleteHelper();
            setSortSelected(5);
        }
    };



    const sortSerialNoHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortSerialNo === 1){
                copy = copy.sort(function (a, b) {
                    return b.serialNo - a.serialNo;
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return a.serialNo - b.serialNo;
                });
            }
            setData(copy);
        }
        setSortSerialNo(-1 * sortSerialNo);
    }

    const sortTitleHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortTitle === 1){
                copy = copy.sort(function (a, b) {
                    return b.queTitle.localeCompare(a.queTitle);
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return a.queTitle.localeCompare(b.queTitle);
                });
            }
            setData(copy);
        }
        setSortTitle(-1 * sortTitle);
    }


    const sortTypeHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortType === 1){
                copy = copy.sort(function (a, b) {
                    return b.questionType.localeCompare(a.questionType);
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return a.questionType.localeCompare(b.questionType);
                });
            }
            setData(copy);
        }
        setSortType(-1 * sortType);
    }


    const sortWeightageHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortWeightage === 1){
                copy = copy.sort(function (a, b) {
                    return b.weightage - a.weightage;
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return a.weightage - b.weightage;
                });
            }
            setData(copy);
        }
        setSortWeightage(-1 * sortWeightage);
    }


    const sortFinishedHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortFinished === 1){
                copy = copy.sort(function (a, b) {
                    return b.finishedCount - a.finishedCount;
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return a.finishedCount - b.finishedCount;
                });
            }
            setData(copy);
        }
        setSortFinished(-1 * sortFinished);
    }

    const sortIncompleteHelper = () => {
        if(!!data) {
            let copy = JSON.parse(JSON.stringify(data));
            if(sortIncomplete === 1){
                copy = copy.sort(function (a, b) {
                    return b.incompleteCount - a.incompleteCount;
                });

            } else {
                copy = copy.sort(function (a, b) {
                    return a.incompleteCount - b.incompleteCount;
                });
            }
            setData(copy);
        }
        setSortIncomplete(-1 * sortIncomplete);
    }

    const renderQuestionType = (type) => {
        if (type === QUESTION_TYPES.MCSS.name) {
            return <><MCSSIcon/> {QUESTION_TYPES.MCSS.description}</>
        } else if (type === QUESTION_TYPES.SA.name) {
            return <><SAIcon/> {QUESTION_TYPES.SA.description}</>
        } else if (type === QUESTION_TYPES.TF.name) {
            return <><TFIcon/> {QUESTION_TYPES.TF.description}</>
        } else if (type === QUESTION_TYPES.FITB.name) {
            return <><FITBIcon/> {QUESTION_TYPES.FITB.description}</>
        } else if (type === QUESTION_TYPES.CI.name) {
            return <><ICIcon/> {QUESTION_TYPES.CI.description}</>
        } else if (type === QUESTION_TYPES.WC.name) {
            return <><WCIcon/> {QUESTION_TYPES.WC.description}</>
        } else if (type === QUESTION_TYPES.MH.name) {
            return <><MHIcon/> {QUESTION_TYPES.MH.description}</>
        } else if (type === QUESTION_TYPES.RK.name) {
            return <><RKIcon/> {QUESTION_TYPES.RK.description}</>
        } else if (type === QUESTION_TYPES.OE.name) {
            return <><OEIcon/> {QUESTION_TYPES.OE.description}</>
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize},
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: DEFAULT_PAGINATION_SIZE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);


    return (
        <>
            <div className={classes.tableSearchBox}>
                <YuJaTextBox
                    label={SEARCH_POLL}
                    containerStyle={{fontSize: 15, marginLeft: 10, width: "70%"}}
                    placeholder={REPORT_ACTIVITY_TABLE_SEARCH_BOX_PLACEHOLDER}
                    onChange={e => setKeyword(e.target.value)}
                    value={keyword}
                    before={<SearchIcon style={{marginRight: 5, width: 16}}/>}
                />
                <YuJaButton onClick={clickSearch}>
                    <div style={{fontSize: 14}}>{SEARCH_TEXT}</div>
                </YuJaButton>
            </div>
            <div className={classes.tableContainer}>
                <Table hover {...getTableProps()} responsive={true}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            textAlign: column.render('Header') === ACTION_HEADER ? 'center' : 'left'
                                        }
                                    })}
                                >
                                    <span tabIndex={0} role={TEXTBOX}
                                          className={column.render('Header') === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.SERIAL_NO.description ? classes.firstCell : classes.tableCell}>
                                        {column.render('Header')}
                                        {checkDisplayDownArrow(column.render('Header')) &&
                                            <SortDown
                                                tabIndex={0}
                                                role={TEXTBOX}
                                                aria-label={column.render('Header') + "descending order"}
                                                style={{width: "15px", cursor: "pointer", marginLeft: "10px"}}
                                                onClick={() => handleSort(column.render('Header'))}
                                            />
                                        }

                                        {checkDisplayUpArrow(column.render('Header')) &&
                                            <SortUp
                                                tabIndex={0}
                                                role={TEXTBOX}
                                                aria-label={column.render('Header') + "ascending order"}
                                                style={{width: "15px", cursor: "pointer", marginLeft: "10px"}}
                                                onClick={() => handleSort(column.render('Header'))}
                                            />
                                        }

                                        {checkDisplayGreyArrow(column.render('Header')) &&
                                            <SortGrey
                                                role={TEXTBOX}
                                                tabIndex={0}
                                                aria-label={column.render('Header') + "ascending order"}
                                                style={{width: "15px", cursor: "pointer", marginLeft: "10px"}}
                                                onClick={() => handleSort(column.render('Header'))}
                                            />

                                        }
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                    {page && page.length !== 0 &&
                        page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        if (cell.column.Header === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.TYPES.description) {
                                            return (
                                                <td role={TEXTBOX}>
                                                    <div style={{display: "flex", alignItems: "center", gap: 10}} tabIndex={0}>
                                                        {renderQuestionType(cell.value)}
                                                    </div>
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td role={TEXTBOX}>
                                                    <div
                                                        tabIndex={0}
                                                        className={cell.column.render('Header') === REPORT_ACTIVITY_QUESTION_TABLE_HEADERS.SERIAL_NO.description ? classes.firstCell : classes.tableCell}>
                                                        {cell.value}
                                                    </div>
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            );
                        })
                    }


                    {(!page || page.length === 0) &&
                        <tr>
                            <td colSpan="5" className={styles.noData} style={{boxShadow: "none"}}>
                                <div
                                    style={{
                                        justifyContent: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: 5,
                                        padding: 20
                                    }}
                                >
                                    <NoResultIllustration/>
                                    <div style={{
                                        fontSize: "18px",
                                        marginTop: "10px",
                                        fontWeight: 600
                                    }}> {NO_RESULTS_FOUND} </div>
                                </div>
                            </td>
                        </tr>

                    }
                    </tbody>
                </Table>
            </div>

            {page && pageCount > 1 &&
                <div style={{flex: 1, display: "flex", alignItems: "flex-end"}}>
                    <NormalPagination
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        gotoPage={gotoPage}
                        paddingTop="0px"
                    />
                </div>
            }
        </>
    );
}