import { useFetchWrapper } from "../hooks/useFetchWrapper";
import { useLoading } from "../utils/LoadingContext";
import {
    formatAPIErrorLog,
    formatAPIResponseLog,
    getFormattedCurrentTime,
    notifyError,
    notifySuccess
} from "../utils/helpers";
import { INSTITUTION_API_URL, } from "../utils/properties";
import { LOAD_PLATFORM_SETTINGS_ERROR, LOAD_SSO_ERROR, SERVER_ERROR, SSO_UPDATE_SUCCESS, UPDATE_LMS_ERROR, UPDATE_LMS_SUCCESS, UPDATE_PLATFORM_SETTINGS_ERROR } from "../utils/toast-message-constants";

export const InstitutionApiCalls = () => {
    const fetchWrapper = useFetchWrapper();
    const {setLoading} = useLoading();
    return {
        uploadImg,
        saveSSODetails,
        getSSODetails,
        saveLMSConfig,
        getSetting,
        updateSetting,
    }

    function uploadImg(file, refreshFunc) {
        setLoading(true);
        const fileName = `${getFormattedCurrentTime()}${getSuffix(file.name)}`;

        const cleanedFileName = removeSpecialCharacters(fileName);
        return fetchWrapper.put({
            url: `${INSTITUTION_API_URL}/institution/bgImg`,
            body: {originalName: cleanedFileName}
        }).then(result => {
            console.log(result);
            if (result.success) {
                return result.result;
            } else {
                notifyError(result.errorMessage);
                return "";
            }
        }).then(({putURL: url, getURL}) => {
            if (!url) {
                return "";
            }
            const requestOptions = {
                headers: {"Content-Type": file.type},
                method: 'PUT',
                body: file
            };
            return fetch(url, requestOptions).then(res=> {
                console.log(res);
                if (refreshFunc) {
                    refreshFunc(getURL);
                }
            });
        }).catch(err => {
            console.log(err);
            notifyError(SERVER_ERROR);
        }).finally(() =>{
            setLoading(false);
        })
    }

    function saveSSODetails(configJson, integrationType) {
        setLoading(true);
        return fetchWrapper.put(
            {
                url: `${INSTITUTION_API_URL}/institution/ssoconfig`,
                body: {
                    ssoConfigJsonString: JSON.stringify(configJson),
                    integrationType: integrationType
                }
            }
        ).then((result) => {
            console.log(result);
            if (result.success) {
                notifySuccess(SSO_UPDATE_SUCCESS);
            } else {
                notifyError(result.errorMessage);
            }
            return result;
        },
            (error) => {
                notifyError(SSO_UPDATE_SUCCESS);
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
    }

    function getSSODetails() {
        return fetchWrapper.get(
            {
                url: `${INSTITUTION_API_URL}/institution/ssoconfig`,
                authorizeRequired: true
            }
        )
        .then(res => {
            if (res.success) {
                return res.result;
            }
        })
        .catch(err => {
            console.log(err);
            notifyError(LOAD_SSO_ERROR);
        });

    }

    function saveLMSConfig(configJson, integrationType) {
        setLoading(true);
        return fetchWrapper.put(
            {
                url: `${INSTITUTION_API_URL}/institution/lmsconfig`,
                body: {
                    lmsConfigJsonString: JSON.stringify(configJson),
                    integrationType: integrationType
                }
            }
        ).then((result) => {
            console.log(result);
            if (result.success) {
                notifySuccess(UPDATE_LMS_SUCCESS);
            } else {
                notifyError(result.errorMessage);
            }
            return result;
        },
            (error) => {
                notifyError(UPDATE_LMS_ERROR);
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
    }

    function getSetting() {
        return fetchWrapper.get({
            url: `${INSTITUTION_API_URL}/institutions/platformsetting`,
            authorizeRequired: true
        }).then(res => {
            // console.log(res);
            if (res.success) {
                formatAPIResponseLog("getSetting", res.result);
                return res.result;
            }
        }).catch(err => {
            console.log(err);
            formatAPIErrorLog("getSetting", err);
            notifyError(LOAD_PLATFORM_SETTINGS_ERROR);
        });
    }

    function updateSetting(setting) {
        setLoading(true);
        return fetchWrapper.put({
            url: `${INSTITUTION_API_URL}/institutions/platformsetting`,
            body: {setting: setting}
        }).then((result) => {
            // console.log(result);
            if (result.success) {
                setLoading(false);
            } else {
                notifyError(result.errorMessage);
            }
            return result;
        }).catch(error => {
            notifyError(UPDATE_PLATFORM_SETTINGS_ERROR);
            console.log(error);
        });
    }

    function removeSpecialCharacters(fileName) {
        const regex = /[^a-zA-Z0-9.]+/g;
        return fileName.replace(regex, '');
    }

    function getSuffix(imageName) {
        const regexp = /(.+)(\.\w+)/g;
        return regexp.exec(imageName)[2];
    }
}