import {useLocalStorageNew} from "./useLocalStorageNew";
import {SESSION_WILL_EXPIRED_DURATION} from "../utils/constants";


export const useSessionValidation = () => {
    const[, getSession] = useLocalStorageNew("session", {});

    function parseToken(token) {
        if (!token || !token.includes(".")) {
            return null;
        }

        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    function checkExpired(token='') {
        if (!token) {
            const session = getSession();
            const {authorization=''} = session;
            token = authorization
        }
        const now = Date.now();
        const payLoad = parseToken(token);
        return !payLoad || !payLoad.exp || (now > payLoad.exp * 1000);
    }

    function checkWillExpired() {
        const session = getSession();
        const now = Date.now();
        const {authorization=''} = session;
        const payLoad = parseToken(authorization);
        return !payLoad || !payLoad.exp || (now + SESSION_WILL_EXPIRED_DURATION > payLoad.exp * 1000);
    }

    return {checkExpired, checkWillExpired, parseToken}

}