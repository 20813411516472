import React, {useEffect, useState} from "react";
import { Draggable } from "react-beautiful-dnd";
import CancelIcon from "../../images/cancel.svg";
import DragDropIcon from "../../images/drag_drop.svg";
import { updateCorrectAnswers} from "../../utils/questionUpdate";
import { notifyError } from "../../utils/helpers";
import {ASCII_CODE_A, BUTTON, DELETE_OPT_BTN, MC_ANSWER_LENGTH, TEXT_BOX} from "../../utils/constants";
import "../styles.css";
import { MC_CI_ANSWER_LENGTH_EXCEEDED } from "../../utils/toast-message-constants";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import useMobileAccess from "../../hooks/useMobileAccess";

export default function CIQuestionOption({
    optionData,
    selectedQuestion,
    questions,
    setQuestions,
    index,
    pollType,
    questionBuilder = false,
    disabled = false
}) {
    const isNarrow = useMobileAccess(1100);

    const [showDragIcon, setShowDragIcon] = useState(false);
    const { serialNo, correctAnswers, weightage } = selectedQuestion;
    const gradeEnable = !!weightage;
    const selectedQuestionIndex = serialNo - 1;

    const [text, setText] = useState("");
 

    useEffect(() => {
        setText(optionData.text ? optionData.text : "");
    }, [optionData]);

    const handleAnswerChange = (input, itemKey) => {
        const copyOfQuestions = [...questions];
        const newOptionsMap = [...copyOfQuestions[selectedQuestionIndex].optionsMap];
        newOptionsMap[itemKey].text = input;
        copyOfQuestions[selectedQuestionIndex].optionsMap = newOptionsMap;
        setQuestions(copyOfQuestions);
        setText(input);
    };

    const handleCorrectAnswerChange = (input) => {
        setQuestions(updateCorrectAnswers(input, selectedQuestionIndex, questions));
    };

    const handleDeleteOption = () => {
        let newQuestions = [];

        questions.forEach((q) => {
            if (q.serialNo === serialNo) {
                const newOptionsMap = [...q.optionsMap];
                newOptionsMap.splice(index, 1);
                let updatedQuestion = {
                    ...q,
                    optionsMap: newOptionsMap,
                    correctAnswers:
                        q.correctAnswers.includes(index) ?  q.correctAnswers.filter(item => item !== index)  : q.correctAnswers,
                };
                newQuestions.push(updatedQuestion);
            } else {
                newQuestions.push({ ...q });
            }
        });
        setQuestions(newQuestions);
    };

    return (
        <Draggable
            draggableId={`${index}`}
            index={index}
            key={index}
            isDragDisabled={questionBuilder && disabled}
        >
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1}>
                    <div
                        style={{display: "flex", flex: 1, alignItems: "center", paddingTop: "5px"}}
                        onMouseEnter={() => setShowDragIcon(true)}
                        onMouseLeave={() => setShowDragIcon(false)}
                    >
                        <div
                            {...provided.dragHandleProps}
                            style={{width: (questionBuilder && disabled) ? 0 : 40,  display: "flex", justifyContent: "center"}}
                            title={"Drag to Reorder"}
                            tabIndex={-1}
                        >
                            {(!questionBuilder || !disabled) && showDragIcon && <img src={DragDropIcon} alt="drag drop icon" width={15} height={25} />}
                        </div>

                        {/* <Radio
                            ariaLabel={CREATE_OPT_RADIAL + String.fromCharCode(index + ASCII_CODE_A)}
                            text={String.fromCharCode(index + ASCII_CODE_A)}
                            value={index}
                            //currently only accept radio buttons
                            selected={correctAnswers[0]}
                            onChange={(value) => {
                                handleCorrectAnswerChange(value);
                            }}
                            index={index}
                            pollType={pollType}
                            transform={`translateX(${questionBuilder ? 45 : 70}px)`}
                            disabled={questionBuilder && hasResponses}
                        /> */}
                        { gradeEnable &&
                            <input
                                type="checkbox"
                                checked={correctAnswers.indexOf(index) !== -1}
                                onChange={(value) => {
                                    handleCorrectAnswerChange(index);
                                }}
                                disabled={questionBuilder && disabled}
                                style={{width: "25px", height: "25px", transform: questionBuilder? "translateX(50px)": "translateX(70px)", zIndex: "10"}}
                            />
                        }
                        <span className="helper-text" role={TEXT_BOX} aria-label={"Option " + String.fromCharCode(index + ASCII_CODE_A)} style={{textAlign: "center", marginLeft: !gradeEnable ? 0 : "-28px"}}>{String.fromCharCode(index + ASCII_CODE_A)}</span>

                        {gradeEnable &&
                            <YuJaTextBox
                                disabled={questionBuilder && disabled}
                                label={TEXT_BOX + String.fromCharCode(index + ASCII_CODE_A)}
                                name={"question-text"}
                                placeholder={`Option ` + String.fromCharCode(index + ASCII_CODE_A)}
                                value={text}
                                containerStyle={{ width: "100%", fontSize: isNarrow ? 18 : 20, marginLeft: questionBuilder ? 10 : 25, padding: isNarrow || questionBuilder ? "8px 8px 8px 55px" : "12.5px 12.5px 12.5px 55px", border: "1.13px solid #858687"}}
                                maxLength={MC_ANSWER_LENGTH}
                                onChange={(e) => {
                                    if(e.target.value.length <= MC_ANSWER_LENGTH) {
                                        handleAnswerChange(e.target.value, index);
                                    }
                                    else {
                                        notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                                        handleAnswerChange(e.target.value.substring(0, MC_ANSWER_LENGTH), index);
                                    }
                                }}
                                showRemaining={true}
                            />
                        }

                        {!gradeEnable &&
                            <YuJaTextBox
                                disabled={questionBuilder && disabled}
                                label={TEXT_BOX}
                                name={"question-text"}
                                placeholder={`Option ` + String.fromCharCode(index + ASCII_CODE_A)}
                                value={text}
                                containerStyle={{ width: "100%", fontSize: isNarrow ? 18 : 20, marginLeft: questionBuilder ? 10 : 25, padding: 12.5, border: "1.13px solid #858687"}}
                                maxLength={MC_ANSWER_LENGTH}
                                onChange={(e) => {
                                    if(e.target.value.length <= MC_ANSWER_LENGTH) {
                                        handleAnswerChange(e.target.value, index);
                                    }
                                    else {
                                        notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                                        handleAnswerChange(e.target.value.substring(0, MC_ANSWER_LENGTH), index);
                                    }
                                }}
                                showRemaining={true}
                            />
                        }


                        {(!questionBuilder || !disabled) &&
                            <div style={{width: questionBuilder ? 20 : 30, marginRight: questionBuilder ? 0 : 10, marginLeft: questionBuilder ? 5 : 0}}>
                                <img
                                    aria-label={DELETE_OPT_BTN + String.fromCharCode(index + ASCII_CODE_A)}
                                    role={BUTTON}
                                    tabIndex={0}
                                    onClick={() => {
                                        handleDeleteOption();
                                    }}
                                    src={CancelIcon}
                                    alt="delete icon"
                                    title={"Delete Option"}
                                    className={questionBuilder ? "cursor-pointer" : "cursor-pointer questions-page-icons"}
                                    width={questionBuilder ? 20 : 30}
                                    height={questionBuilder ? 20 : 30}
                                />
                            </div>
                        }
                    </div>
                </div>
            )}
        </Draggable>
    );
}
