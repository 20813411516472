import {
  ASCII_CODE_A,
  ASCII_CODE_SMALL_A,
  ATTENDANCE_DEFAULT_QUESTION_TITLE,
  BLANK_REGEX,
  BLANK_REGEX_NEW,
  DEFAULT_ATTENDANCE_TIME_LIMIT,
  DEFAULT_CLASS_RESULT_TIME_LIMIT,
  DEFAULT_ENTRIES_PER_PARTICIPANT,
  DEFAULT_GET_READY_TIME_LIMIT,
  DEFAULT_POINTS,
  DEFAULT_TIME_LIMIT,
  ENTRIES_TEXT,
  ENTRY_TEXT,
  FITB_ANSWER_LENGTH,
  FITB_BLANK_LENGTH,
  FIXED_ANSWER,
  MC_ANSWER_LENGTH,
  POLL_TYPE,
  QUESTION_LENGTH,
  QUESTION_TYPES,
  SA_ANSWER_LENGTH,
  USER_SETTINGS
} from "./constants";
import {
  validateArray,
  validateMap, validateMCSSOptText,
  validateOptions,
  validateText,
  validateTextEmpty,
  validateTextMaxLength
} from "./helpers";
import {
  CI_MIN_OPTIONS_ERROR,
  CORRECT_ANSWER_NOT_SELECTED,
  DUPLICATE_OPTION_ERROR,
  FITB_MIN_OPTIONS_ERROR,
  FITB_MISSING_RESPONSE_ERROR,
  FITB_QUESTION_DUPLICATE_CORRECT,
  INCORRECT_QUESTION_TYPE,
  MC_MIN_OPTIONS_ERROR,
  MC_UNIQUE_OPTIONS_ERROR,
  MH_MIN_OPTIONS_ERROR,
  MH_MIN_PREMISE_ERROR,
  MH_MISSING_PREMISE_ERROR,
  MH_MISSING_RESPONSE_ERROR,
  MISSING_CORRECT_ANSWER,
  MISSING_CORRECT_RESPONSE_ERROR,
  MISSING_RESPONSE_ERROR,
  QUESTION_CHARACTER_LIMIT_EXCEEDED,
  QUESTION_TITLE_ERROR,
  RK_MIN_OPTIONS_ERROR,
  WC_ENTRIES_ERROR
} from "./toast-message-constants";
export const deleteQuestion = (questionToDelete, questions) => {
  let newQuestions = [];

  questions
    .filter((x) => {
      return x.serialNo !== questionToDelete.serialNo;
    })
    .forEach((item, index) => {
      item = { ...item, serialNo: index + 1 };
      newQuestions.push(item);
    });

  if (
    newQuestions.filter((x) => {
      return x.checked === true;
    }).length === 0
  ) {
    if (questionToDelete.serialNo !== 1) {
      newQuestions = chooseQuestion(
        questionToDelete.serialNo - 1,
        newQuestions
      );
    } else {
      newQuestions = chooseQuestion(questionToDelete.serialNo, newQuestions);
    }
  }
  return newQuestions;
};

export const duplicateQuestion = (questionToDuplicate, questions, imageName="", imageUrl="") => {
  let newQuestions = [];


  questions.forEach((q) => {
    if (q.serialNo === questionToDuplicate.serialNo) {
      newQuestions.push({ ...q, checked: false });
      const duplicatedQuestion = JSON.parse(JSON.stringify(q));
      duplicatedQuestion.serialNo = q.serialNo + 1;
      duplicatedQuestion.checked = true;
      duplicatedQuestion.suffix = undefined;
      duplicatedQuestion.image = imageName ? imageName : undefined;
      duplicatedQuestion.imageURL = imageUrl ? imageUrl : undefined;
      newQuestions.push(duplicatedQuestion);
    } else if (q.serialNo > questionToDuplicate.serialNo) {
      newQuestions.push({ ...q, serialNo: q.serialNo + 1, checked: false });
    } else {
      newQuestions.push({ ...q, checked: false });
    }
  });
  return newQuestions;
};

export const handleQuestionOrderChange = (result, questions) => {
  if (!result.destination) return;
  const items = Array.from(questions);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);
  let newQuestions = [];
  items.forEach((q, index) => {
    newQuestions.push({ ...q, serialNo: index + 1 });
  });
  return newQuestions;
};

export const questionNameToCode = (questionName) => {
  switch (questionName) {
    case QUESTION_TYPES.MCSS.description:
      return QUESTION_TYPES.MCSS.name;
    case QUESTION_TYPES.TF.description:
      return QUESTION_TYPES.TF.name;
    case QUESTION_TYPES.FITB.description:
      return QUESTION_TYPES.FITB.name;
    case QUESTION_TYPES.SA.description:
      return QUESTION_TYPES.SA.name;
    case QUESTION_TYPES.CI.description:
      return QUESTION_TYPES.CI.name;
    case QUESTION_TYPES.WC.description:
      return QUESTION_TYPES.WC.name;
    case QUESTION_TYPES.MH.description: 
    return QUESTION_TYPES.MH.name;
    case QUESTION_TYPES.RK.description: 
    return QUESTION_TYPES.RK.name; 
    case QUESTION_TYPES.OE.description:
    return QUESTION_TYPES.OE.name;
    default:
      throw new Error("Unhandled question type: " + questionName);
  }
};

export const questionCodeToType = (questionName) => {
  switch (questionName) {
    case QUESTION_TYPES.MCSS.name:
      return QUESTION_TYPES.MCSS;
    case QUESTION_TYPES.TF.name:
      return QUESTION_TYPES.TF;
    case QUESTION_TYPES.FITB.name:
      return QUESTION_TYPES.FITB;
    case QUESTION_TYPES.SA.name:
      return QUESTION_TYPES.SA;
    case QUESTION_TYPES.CI.name:
      return QUESTION_TYPES.CI;
    case QUESTION_TYPES.WC.name:
      return QUESTION_TYPES.WC;
    case QUESTION_TYPES.MH.name:
      return QUESTION_TYPES.MH;
    case QUESTION_TYPES.RK.name:
      return QUESTION_TYPES.RK;
    case QUESTION_TYPES.OE.name:
      return QUESTION_TYPES.OE;
    default:
      throw new Error("Unhandled question type: " + questionName);
  }
};

export const FormatDate = (dateTime) => {
  const monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
  
  const month = monthNames[dateTime.getMonth()];
  const day = dateTime.getDate();
  const year = dateTime.getFullYear();
  
  let hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;

  const timeZone = dateTime.toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];

  return`${month} ${day}, ${year} - ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
}

export const questionCodeToName = (questionName) => {
  switch (questionName) {
    case QUESTION_TYPES.MCSS.name:
      return QUESTION_TYPES.MCSS.description;
    case QUESTION_TYPES.TF.name:
      return QUESTION_TYPES.TF.description;
    case QUESTION_TYPES.FITB.name:
      return QUESTION_TYPES.FITB.description;
    case QUESTION_TYPES.SA.name:
      return QUESTION_TYPES.SA.description;
    case QUESTION_TYPES.CI.name:
      return QUESTION_TYPES.CI.description;
    case QUESTION_TYPES.WC.name:
      return QUESTION_TYPES.WC.description;
    case QUESTION_TYPES.MH.name: 
      return QUESTION_TYPES.MH.description;
    case QUESTION_TYPES.RK.name:
      return QUESTION_TYPES.RK.description;
    case QUESTION_TYPES.OE.name:
      return QUESTION_TYPES.OE.description;
    default:
      throw new Error("Unhandled question type: " + questionName);
  }
};

// 10 Sec => 10
export const timeStringToSeconds = (name) => {
  name = name.toLowerCase();
  let multiplier;
  if (name.includes("sec")) {
    multiplier = 1;
  } else if (name.includes("min")) {
    multiplier = 60;
  } else if (name.includes("hour")) {
    multiplier = 3600;
  }
  return parseInt(name.substring(0, name.indexOf(" "))) * multiplier;
};

// 10 => 10 Sec
export const timeSecondsToString = (time) => {
  let output = "";
  if (time >= 3600) {
    let h = Math.floor(time / 3600);
    output += h + " Hour" + (h > 1 ? "s" : "");
    time = time % 3600;
    if (time > 0) output += " ";
  }
  if (time >= 60) {
    let m = Math.floor(time / 60);
    output += m + " Min" + (m > 1 ? "s" : "");
    time = time % 60;
    if (time > 0) output += " ";
  }
  if (time > 0) {
    let s = time;
    output += s + " Sec" + (s > 1 ? "s" : "");
  }
  return output;
};

export const validCustomTime = (time) => {
  if (time.length === 0) return false; // empty string
  let timeArr = time.split(":");
  if (timeArr.length !== 2) return false; // does not contain 1 colon
  if (timeArr[0].length > 2 || timeArr[1].length > 2) return false; // minutes or seconds are more than 2 digits
  if (timeArr[0].length === 0 || timeArr[1].length === 0) return false; // minutes or seconds are empty
  let min = parseInt(timeArr[0]);
  let sec = parseInt(timeArr[1]);
  if (min >= 60 || sec > 59) return false; // minutes or seconds are more than 59
  if (min < 0 || sec < 0) return false; // minutes or seconds are less than 0
  if (min === 0 && sec === 0) return false; // minutes and seconds are 0
  return true;
};

export const validCustomPollTime = (time) => {
  if (time.length === 0) return false; // empty string
  let timeArr = time.split(":");
  if (timeArr.length !== 3) return false; // does not contain 2 colons
  if (timeArr[0].length > 2 || timeArr[1].length > 2 || timeArr[2].length > 2) return false; // hours or minutes or seconds are more than 2 digits
  let hours = parseInt(timeArr[0]);
  let min = parseInt(timeArr[1]);
  let sec = parseInt(timeArr[2]);
  if (isNaN(hours) || isNaN(min) || isNaN(sec)) return false;
  if (hours > 99 || min > 99 || sec > 99) return false; // hours or minutes or seconds are more than 99
  if (hours < 0 || min < 0 || sec < 0) return false; // minutes or seconds are less than 0
  if (hours === 0 && min === 0 && sec === 0) return false; // minutes and seconds are 0
  if (hours === 0 && min === 0 && sec < 10) return false; // min time limit = 10sec
  return true;
};

// 0:10 00:10 => true
export const isCustomEqual = (time1, time2) => {
  return timeCustomToSeconds(time1) === timeCustomToSeconds(time2);
};

// 10 => 00:10
export const timeSecondsToCustom = (time) => {
  let hours = Math.floor(time / 3600); 
  let minutes = Math.floor(time / 60);
  let seconds = time % 60;
  return (hours > 0 ? hours.toString().padStart(2, "0") + ":" : "") + minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
};

// 10 => 00:10 updated

export const timeSecondsToCustomNew = (time) => {
  let hours = Math.floor(time / 3600); 
  let remainingSeconds = time % 3600;
  let minutes = Math.floor(remainingSeconds / 60);
  let seconds = remainingSeconds % 60;
  return (hours > 0 ? hours.toString().padStart(2, "0") + ":" : "00:") + minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
};

// 10 => 00:10 updated in the format mm:ss
export const timeSecondsToCustomNewMinutes = (time) => {
  let remainingSeconds = time % 3600;
  let minutes = Math.floor(remainingSeconds / 60);
  let seconds = remainingSeconds % 60;
  return minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
};

// 00:10 => 10
export const timeCustomToSeconds = (time) => {
  let output = 0;
  // let hours = parseInt(time.substring(0, time.indexOf(":")));
  // output += (hours * 3600);
  let minutes = parseInt(time.substring(0, time.indexOf(":")));
  // let minutes = parseInt(time.substring(time.indexOf(":") + 1, time.lastIndexOf(":")));
  output += (minutes * 60);
  let seconds = parseInt(time.substring(time.indexOf(":") + 1));
  // let seconds = parseInt(time.substring(time.lastIndexOf(":") + 1));
  output += seconds;
  return output;
};

// 00:10 => 10
export const timePollCustomToSeconds = (time) => {
  let output = 0;
  let hours = parseInt(time.substring(0, time.indexOf(":")));
  output += (hours * 3600);
  let minutes = parseInt(time.substring(time.indexOf(":") + 1, time.lastIndexOf(":")));
  output += (minutes * 60);
  let seconds = parseInt(time.substring(time.lastIndexOf(":") + 1));
  output += seconds;
  return output;
};

export const TimeCustomToStringLabel = (timeString) => {
  const [minutes, seconds] = timeString.split(':').map(Number);

  if (minutes === 0 && seconds !== 0) {
    return `${seconds} secs`;
  } else if (minutes !== 0 && seconds === 0) {
    return `${minutes} mins`;
  } else {
    return `${minutes}m, ${seconds}s`;
  }
}


// 00:10 => 10 Sec
export const timeCustomToString = (time) => {
  return timeSecondsToString(timeCustomToSeconds(time));
};

export const weightagePointsToString = (points) => {
  return points + " Point" + (points > 1 ? "s" : "");
};

export const weightageStringToPoints = (name) => {
  return parseInt(name.substring(0, name.indexOf(" ")));
};

export const comparePoints = (p1, p2) => {
  return parseInt(p1) === parseInt(p2);
};

export const entriesToString = (entries) => {
  return entries + ' ' + (entries > 1 ? ENTRIES_TEXT : ENTRY_TEXT);
};

export const entriesParseInt = (name) => {
  return parseInt(name.substring(0, name.indexOf(" ")));
};

export const compareEntries = (e1, e2) => {
  return parseInt(e1) === parseInt(e2);
};

export const initialQuestionData = (questionType, userSettings={}, questionBuilder=false) => {
  switch (questionType) {
    case QUESTION_TYPES.MCSS.name:
      return {
        optionsMap: questionBuilder ? {
          a: ""
        } : {
          a: "",
          b: "",
          c: "",
          d: "",
        },
        correctAnswers: [],
      };
    case QUESTION_TYPES.TF.name:
      return {
        optionsMap: ["True", "False"],
        correctAnswers: "",
      };
    case QUESTION_TYPES.FITB.name:
      return {
        correctAnswers: {},
      };
    case QUESTION_TYPES.SA.name:
      return {
        correctAnswers: [""],
      };
    case QUESTION_TYPES.CI.name:
      return {
        optionsMap: [],
        correctAnswers: [],
      };
    case QUESTION_TYPES.WC.name:
      return {
        // correctAnswers: [],
        entries: userSettings[USER_SETTINGS.QUESTION_ENTRIES] ? userSettings[USER_SETTINGS.QUESTION_ENTRIES] : DEFAULT_ENTRIES_PER_PARTICIPANT,
      };
      case QUESTION_TYPES.MH.name:
      return {
        correctAnswers: questionBuilder ? [
          ["", ""]
        ] : [
          ["", ""],
          ["", ""],
          ["", ""],
          ["", ""],
        ],
      };
      case QUESTION_TYPES.RK.name: 
      return {
        optionsMap: ["", "", "", ""],
      };
    case QUESTION_TYPES.OE.name: 
    return {
      correctAnswers: [""],
    };
    default:
      throw new Error(
        "Unhandled question type detected, question type code: " + questionType
      );
  }
};

export const addQuestionPPT = (questions, questionType, pollType="") => {
  const initial = initialQuestionData(questionType);
  const defaultQuestion = {
    queTitle: pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE_DEFAULT_QUESTION_TITLE : "",
    serialNo: 1,
    checked: true,
    questionType: questionType,
    optionsMap: initial.optionsMap,
    correctAnswers: initial.correctAnswers,
  };
  let toAdd = { ...defaultQuestion, serialNo: questions.length + 1 };
  let newQuestions = [];
  questions.forEach((item) => {
    item = { ...item, checked: false };
    newQuestions.push(item);
  });
  return [...newQuestions, toAdd];
};


export const addQuestion = (questions, questionType, userSettings={}, questionBuilder=false, pollType=POLL_TYPE.MERGED_POLL) => {
  const initial = initialQuestionData(questionType, userSettings, questionBuilder);
  const defaultQuestion = {
    queTitle: pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE_DEFAULT_QUESTION_TITLE : "",
    serialNo: 1,
    checked: true,
    questionType: questionType,
    optionsMap: initial.optionsMap,
    correctAnswers: initial.correctAnswers,
    weightage: userSettings && userSettings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS) ? parseInt(userSettings[USER_SETTINGS.QUESTION_POINTS]) : DEFAULT_POINTS,
    timeLimit: pollType === POLL_TYPE.ATTENDANCE
        ? (userSettings && userSettings[USER_SETTINGS.ATTENDANCE_DURATION] ? userSettings[USER_SETTINGS.ATTENDANCE_DURATION] : DEFAULT_ATTENDANCE_TIME_LIMIT)
        : (userSettings && userSettings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION) ? userSettings[USER_SETTINGS.QUESTION_DURATION] : DEFAULT_TIME_LIMIT),
    getReadyTimeLimit: pollType === POLL_TYPE.ATTENDANCE ? 0 : userSettings && userSettings[USER_SETTINGS.QUESTION_COUNTDOWN] ? userSettings[USER_SETTINGS.QUESTION_COUNTDOWN] : DEFAULT_GET_READY_TIME_LIMIT,
    classResultDuration: userSettings && userSettings[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION] ? userSettings[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION] : DEFAULT_CLASS_RESULT_TIME_LIMIT,
  };
  if (questionType === QUESTION_TYPES.WC.name) {
    defaultQuestion.entries = userSettings && userSettings[USER_SETTINGS.QUESTION_ENTRIES] ? userSettings[USER_SETTINGS.QUESTION_ENTRIES] : DEFAULT_ENTRIES_PER_PARTICIPANT;
  }

  if ([QUESTION_TYPES.WC.name, QUESTION_TYPES.RK.name].includes(questionType)){
    defaultQuestion.weightage = 0;
  }

  let toAdd = { ...defaultQuestion, serialNo: questions.length + 1 };
  let newQuestions = [];
  questions.forEach((item) => {
    item = { ...item, checked: false };
    newQuestions.push(item);
  });
  return [...newQuestions, toAdd];
};

export const questionError = (question, pollType) => {
  let error = null;
  let retIndex = -1;

  const {weightage, optionsImageMap={}} = question;
  const gradeEnable = !!weightage;

  if (!validateTextEmpty(question.queTitle)) {
    return {quesError: QUESTION_TITLE_ERROR, elemIndex: 0};
  }

  if (!validateTextMaxLength(
    question.questionType === QUESTION_TYPES.FITB.name ?
      question.queTitle.replace(question.hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX, '') :
      question.queTitle,
    QUESTION_LENGTH
)) {
    return {quesError: QUESTION_CHARACTER_LIMIT_EXCEEDED, elemIndex: 0};
  }

  if (question.questionType === QUESTION_TYPES.MCSS.name) {
    let i;
    for (i = Object.keys(question.optionsMap).length - 1; i >= 0; i--) {

      const optIdx = String.fromCharCode(ASCII_CODE_SMALL_A + i);
      if (validateMCSSOptText(question.optionsMap[optIdx], optionsImageMap, optIdx)) {
        break;
      }
      if (i === 1 && validateText(question.optionsMap["a"], MC_ANSWER_LENGTH)) {
        error = MISSING_RESPONSE_ERROR + "B";
        retIndex = 2;
        return {quesError: error, elemIndex: retIndex};
      } else if (i === 0) {
        error = MISSING_RESPONSE_ERROR + "A";
        retIndex = 1;
        return {quesError: error, elemIndex: retIndex};
      }
    }
    for (let j = 0; j < i; j++) {
      const optIdx = String.fromCharCode(ASCII_CODE_SMALL_A + j);
      if (!validateMCSSOptText(question.optionsMap[optIdx], optionsImageMap, optIdx)) {
        error = MISSING_RESPONSE_ERROR + String.fromCharCode(ASCII_CODE_A + j);
        retIndex = j + 1;
        return {quesError: error, elemIndex: retIndex};
      }
    }

    if (Object.keys(question.optionsMap).length < 2) {
      error = MC_MIN_OPTIONS_ERROR;
      return {quesError: error, elemIndex: retIndex};
    }

    const valuesArray = Array.from(Object.values(question.optionsMap)).map(value => value.trim()).filter(value => value !== '');
    const uniqueValues = new Set(valuesArray);

    if (valuesArray.length !== uniqueValues.size) {
      error = MC_UNIQUE_OPTIONS_ERROR;
      retIndex = 0;
      return {quesError: error, elemIndex: retIndex};
    }

    if (!!gradeEnable && !validateArray(question.correctAnswers)) {
      error = CORRECT_ANSWER_NOT_SELECTED;
      return {quesError: error, elemIndex: retIndex};
    }

    const correctOptIdx = Object.keys(question.optionsMap).indexOf(question.correctAnswers[0]);
    if (!!gradeEnable && correctOptIdx > i) {
      error = MISSING_CORRECT_RESPONSE_ERROR;
      retIndex = correctOptIdx + 1;
    }

    return {quesError: error, elemIndex: retIndex};
  }

  if (question.questionType === QUESTION_TYPES.SA.name) {
    if (!validateArray(question.correctAnswers) && pollType === POLL_TYPE.ATTENDANCE) {
      error = CORRECT_ANSWER_NOT_SELECTED;
    } else if (!validateOptions(question.correctAnswers, SA_ANSWER_LENGTH) && pollType === POLL_TYPE.ATTENDANCE) {
      for(let i = 0; i < question.correctAnswers.length; i++) {
        if(!validateText(question.correctAnswers[i], SA_ANSWER_LENGTH)) {
          error = MISSING_CORRECT_ANSWER;
          retIndex = i+1;
        }
      }
    }
    return {quesError: error, elemIndex: retIndex};
  }

  if (question.questionType === QUESTION_TYPES.TF.name) {
    if (!question.correctAnswers && !!gradeEnable) {
      error = CORRECT_ANSWER_NOT_SELECTED;
    }
    return {quesError: error, elemIndex: retIndex};
  }


  if (question.questionType === QUESTION_TYPES.FITB.name) {
    if (Object.keys(question.correctAnswers).length === 0) {
      error = FITB_MIN_OPTIONS_ERROR;
      retIndex = 0;
      return {quesError: error, elemIndex: retIndex};
    }

    if (!gradeEnable) {
      return {quesError: error, elemIndex: retIndex}; // no need to check for correct answers
    }

    if (!validateMap(question.correctAnswers)) {
      error = CORRECT_ANSWER_NOT_SELECTED;
      return {quesError: error, elemIndex: retIndex};
    }
    
    let total = 0;
    Object.entries(question.correctAnswers).forEach(obj => {
      for(let i = 0; i < obj[1].length; i++) {
        total++;
        if(!validateText((obj[1])[i], FITB_ANSWER_LENGTH)) {
          let index = obj[0].substring(obj[0].indexOf(" "), obj[0].length);
          error = FITB_MISSING_RESPONSE_ERROR + index;
          retIndex = total;
          return {quesError: error, elemIndex: retIndex};
        }
      }
    });

    for (let i = 0; i < Object.keys(question.correctAnswers).length; i++) {
      const correctAnswerArray = question.correctAnswers[Object.keys(question.correctAnswers)[i]].filter(str => str.trim() !== ''); 
      const lowerCaseArr = correctAnswerArray.filter(str => str.trim() !== '').map(str => str.trim().toLowerCase());
      if (new Set(lowerCaseArr).size !== correctAnswerArray.length) {
        return {quesError: FITB_QUESTION_DUPLICATE_CORRECT, elemIndex: 0};
      }
    }
    return {quesError: error, elemIndex: retIndex};
  }

  if (question.questionType === QUESTION_TYPES.CI.name) {
    for (let i = 0; i < question.optionsMap.length; i++) {
      if (!validateText(question.optionsMap[i].text, MC_ANSWER_LENGTH)){
        error = MISSING_RESPONSE_ERROR + (i+1);
        retIndex = i+1;
        return {quesError: error, elemIndex: retIndex };
      }
    }

    if (question.optionsMap.length < 1){
      return { quesError: CI_MIN_OPTIONS_ERROR, elemIndex: retIndex };
    }

    if (!!gradeEnable && !validateArray(question.correctAnswers)) {
      return { quesError: CORRECT_ANSWER_NOT_SELECTED, elemIndex: retIndex };
    }

    return {quesError: error, elemIndex: retIndex};
  }

  if (question.questionType === QUESTION_TYPES.WC.name) {
    if (!question.entries || question.entries === "" || question.entries < 1 || question.entries > 10) {
      return {quesError: WC_ENTRIES_ERROR, elemIndex: retIndex };
    }
    return {quesError: error, elemIndex: retIndex};
  }

  if (question.questionType === QUESTION_TYPES.MH.name) {
    let filteredMap =  question.correctAnswers.filter(sublist => sublist.length === 2);
    for (let i = 0; i < question.correctAnswers.length; i++) {
      if (!question.correctAnswers[i]
          || (question.correctAnswers[i].length > 1
              && (!question.correctAnswers[i][0] || (question.correctAnswers[i][0].length === 0 || question.correctAnswers[i][0].length > 1000)))
      ) {
        error = MH_MISSING_PREMISE_ERROR + (i + 1);
        retIndex = i+1;
        return {quesError: error, elemIndex: retIndex};
      } else if (!question.correctAnswers[i]
          || (question.correctAnswers[i].length === 1
              && (!question.correctAnswers[i][0] || (question.correctAnswers[i][0].length === 0 || question.correctAnswers[i][0].length > 1000)) || (question.correctAnswers[i].length > 1 && (!question.correctAnswers[i][1] || (question.correctAnswers[i][1].length === 0 || question.correctAnswers[i][1].length > 1000))))) {
        error = MH_MISSING_RESPONSE_ERROR + (i + 1);
        retIndex = i+1+filteredMap.length;
        return {quesError: error, elemIndex: retIndex};
      }
    }

    if(question.correctAnswers.length <= 1){
      error = MH_MIN_OPTIONS_ERROR;
    }

    if (question.correctAnswers[0].length === 1) { // first option is distractor
      error = MH_MIN_PREMISE_ERROR;
    }

    return {quesError: error, elemIndex: retIndex};
  }

  if (question.questionType === QUESTION_TYPES.RK.name) {
    let i = question.optionsMap.length - 1;
    const uniqueOptions = new Set();
    for (; i >= 0; i--) {
      if (validateText(question.optionsMap[i], MC_ANSWER_LENGTH)) {
        break;
      }
    }

    console.log(`test ${i}`)
    for (let j = 0; j <= i; j++) {
      console.log(`element index: ${retIndex}`)
      if (!validateText(question.optionsMap[j], MC_ANSWER_LENGTH)) {
        error = MISSING_RESPONSE_ERROR + (j + 1);
        retIndex = j + 1;

        return {quesError: error, elemIndex: retIndex};
      }

      const trimmedOption = question.optionsMap[j].trim();
      if (uniqueOptions.has(trimmedOption)) {
        error = DUPLICATE_OPTION_ERROR;
        return { quesError: error, elemIndex: j + 1 };
      }
      uniqueOptions.add(trimmedOption);
    }

    if(i < 1){
      error = RK_MIN_OPTIONS_ERROR;
      if (!validateText(question.optionsMap[0], MC_ANSWER_LENGTH)) {
        retIndex = 1;
      } else {
        retIndex = 2;
      }
      return {quesError: error, elemIndex: retIndex};
    }

    return {quesError: error, elemIndex: retIndex};
  }

  if (question.questionType === QUESTION_TYPES.OE.name) {
    return {quesError: error, elemIndex: retIndex};
  }

  return {quesError: INCORRECT_QUESTION_TYPE, elemIndex: retIndex };
};

export const chooseQuestion = (qSerialNoToChoose, questions) => {
  let newQuestions = [];
  questions.forEach((q) => {
    q =
      qSerialNoToChoose === q.serialNo
        ? { ...q, checked: true }
        : { ...q, checked: false };
    newQuestions.push(q);
  });

  return newQuestions;
};

export const trimQuestion = (questions) => {
  let newQuestions = [];

  questions.forEach((q) => {
    if (q.questionType === QUESTION_TYPES.MCSS.name) {
      let last_option_index = Object.keys(q.optionsMap).length - 1;
      while (last_option_index >= 0) {
        const last_option_letter = Object.keys(q.optionsMap)[last_option_index--];
        if (validateMCSSOptText(q.optionsMap[last_option_letter], q.optionsImageMap, last_option_letter)) {
          break;
        }
        let newOptionMap = { ...q.optionsMap };
        delete newOptionMap[last_option_letter];
        q = { ...q, optionsMap: newOptionMap };
      }
    }
    else if (q.questionType === QUESTION_TYPES.RK.name) {
      let last_option_index = q.optionsMap.length - 1;
      while (last_option_index >= 0) {
          if (validateText(q.optionsMap[last_option_index], MC_ANSWER_LENGTH)) {
              break;
          }
          let newOptionMap = [ ...q.optionsMap ];
          newOptionMap.splice(last_option_index, 1); 
          q = { ...q, optionsMap: newOptionMap };
          last_option_index--; 
      }
  }
    newQuestions.push({ ...q });
  });

  return newQuestions;
}

export const addOptionToQuestion = (questionToUpdate, questions) => {
  const { serialNo, optionsMap } = questionToUpdate;
  const letterToAdd = getNextLetter(optionsMap);
  if (letterToAdd > "z") {
    return null;
  }
  let newQuestions = [];
  questions.forEach((q) => {
    if (serialNo === q.serialNo) {
      let newOptionMap = { ...q.optionsMap };
      newOptionMap[letterToAdd] = "";
      q = { ...q, optionsMap: newOptionMap };
    }
    newQuestions.push({ ...q });
  });
  return newQuestions;
};

export const addRankToQuestion = (questionToUpdate, questions) => {
  const { serialNo, optionsMap } = questionToUpdate;
  if (optionsMap.length >= 26) {
    return null; 
  }
  let newQuestions = [];
  questions.forEach((q) => {
    if (serialNo === q.serialNo) {
      q = { ...q, optionsMap:[...q.optionsMap, ""] };
    }
    newQuestions.push({ ...q });
  });
  return newQuestions;
};

export const addPremiseToQuestion = (questionToUpdate, questions) => {
  const { serialNo, correctAnswers } = questionToUpdate;
  if (correctAnswers.length > 24) {
    return null;
  }
  let newQuestions = [];
  questions.forEach((q) => {
    if (serialNo === q.serialNo) {
      let newCorrectAnswers = [];
      let updated = false; 

      for (let i = 0; i < q.correctAnswers.length; i++) {
        if (q.correctAnswers[i].length === 1 && !updated) {
          newCorrectAnswers.push(["", ""]);
          updated = true; 
        }
        newCorrectAnswers.push(q.correctAnswers[i]);
        if(i === q.correctAnswers.length -1 && !updated) {
          newCorrectAnswers.push(["", ""]);
          updated = true; 
        }
      }

      q = { ...q, correctAnswers: newCorrectAnswers };
    }

    newQuestions.push({ ...q });
  });
  return newQuestions;
};

export const addDistractorToQuestion = (questionToUpdate, questions) => {
  const { serialNo, correctAnswers } = questionToUpdate;
  if (correctAnswers.length > 24) {
    return null;
  }
  let newQuestions = [];
  questions.forEach((q) => {
    if (serialNo === q.serialNo) {
      let newCorrectAnswers = [...q.correctAnswers, [""]];
      q = { ...q, correctAnswers: newCorrectAnswers };
    }
    newQuestions.push({ ...q });
  });
  return newQuestions;
};

const getNextLetter = (optionsMap) => {
  const keyList = Object.keys(optionsMap);
  if (!keyList.length) {
    return String.fromCharCode(ASCII_CODE_SMALL_A);
  }


  const lastItem = keyList[keyList.length - 1];
  return String.fromCharCode(lastItem.charCodeAt(0) + 1);
};


export const encodeCIParam = (num, divisor) => {
  return Number.parseFloat((num / divisor).toFixed(6));
}

export const decodeCIParam = (code, actualNum) => {
  return code * actualNum;
}


export const getOption4CI = (xCoordinate, yCoordinate, optionsMap) => {
  if (!xCoordinate || !yCoordinate) {
    return FIXED_ANSWER.UNANSWERED;
  }

  for (let i = 0; i < optionsMap.length; i++) {
    const {width, height, x, y} = optionsMap[i],
        endX = x + width,
        endY = y + height;
    if (xCoordinate >= x && xCoordinate <= endX && yCoordinate >= y && yCoordinate <= endY) {
      return i.toString();
    }
  }

  return FIXED_ANSWER.OTHER;
}

export const getBlanks = (title, hasBlankRestriction) => {
  const blankRegex = hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX;
  return title && title.match(blankRegex) ? title.match(blankRegex) : [];
};

export const checkBlankCountChange = (oldTitle, newTitle, hasBlankRestriction) => {
  const oldBlanks = getBlanks(oldTitle, hasBlankRestriction);
  const newBlanks = getBlanks(newTitle, hasBlankRestriction);

  return oldBlanks.length !== newBlanks.length;
};

export const checkBlankNameChange = (oldTitle, newTitle, hasBlankRestriction) => {
  const oldBlanks = getBlanks(oldTitle, hasBlankRestriction);
  const newBlanks = getBlanks(newTitle, hasBlankRestriction);
  
  for (let i = 0; i < oldBlanks.length; i++) {
    if (oldBlanks[i] !== newBlanks[i]) {
      return true;
    }
  }

  return false;
};

export const checkBlankNameExceed = (blanks) => {
  let exceed = false;
  blanks.map(blank => {
    console.log(blank, blank.length > FITB_BLANK_LENGTH + 2);
    if (blank.length > FITB_BLANK_LENGTH + 2) {
      exceed = true;
    }
  });

  console.log(exceed);
  return exceed;
};

export const getBlankRestriction = (questions) => {
  let violates = false;
  if (!questions) return true;

  questions.map(question => {
    if (question.questionType === QUESTION_TYPES.FITB.name) {
      const blanksOldFormat = getBlanks(question.queTitle, false);
      const blanksNewFormat = getBlanks(question.queTitle, true);
      if (blanksOldFormat.length !== blanksNewFormat.length) { // check if the question violates blank restriction
        violates = true;
        return false;
      }
    }
  });

  return !violates;
};


export const checkSameQuestions = (qs1, qs2, serialNo=-1) => {
  const trimmedQs1 = trimQuestion(qs1);
  const trimmedQs2 = trimQuestion(qs2);

  // console.log(`qs: ${JSON.stringify(qs1)}`)
  // console.log(`originalData questions: ${JSON.stringify(qs2)}`)

  if (serialNo === -1) {
    if (qs1?.length !== qs2?.length) {
      return false;
    }
  } else {
    if (serialNo > qs1.length || serialNo > qs2.length) {
      return false;
    }
  }

  for (let i = 0; i < qs1.length; i++) {
    const q1 = trimmedQs1[i];
    const q2 = trimmedQs2[i];

    if (serialNo !== -1 && q1.serialNo !== serialNo) {
      continue;
    }

    if (q1.questionType !== q2.questionType) {
      return false;
    }

    if (q1.queTitle !== q2.queTitle) {
      return false;
    }

    if (JSON.stringify(q1.optionsMap) !== JSON.stringify(q2.optionsMap)) {
      return false;
    }

    if (JSON.stringify(q1.optionsImageMap) !== JSON.stringify(q2.optionsImageMap)) {
      return false;
    }

    if (JSON.stringify(q1.correctAnswers) !== JSON.stringify(q2.correctAnswers)) {
      return false;
    }

    // if (q1.weightage !== q2.weightage) {
    //   return false;
    // }

    // if (q1.timeLimit !== q2.timeLimit) {
    //   return false;
    // }

    // if (q1.entries !== q2.entries) {
    //   return false;
    // }

    if (q1.image !== q2.image) {
      return false;
    }

    if (q1.directLinkEvp !== q2.directLinkEvp) {
      return false;
    }

    if (q1.imageAlt !== q2.imageAlt) {
      return false;
    }
  }

  return true;
}

export const convertMHOptionMap = (correctAnswer=[]) => {
  let responseArr = correctAnswer.map(i => i.length > 1 ? i[1] : i[0]);

  //shuffle response array
  for (let i = responseArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [responseArr[i], responseArr[j]] = [responseArr[j], responseArr[i]];
  }

  let optionsMap = [];
  for (let i = 0; i < correctAnswer.length; i++) {
    const option = correctAnswer[i];
    if (option.length > 1) {
      optionsMap.push([option[0], responseArr[i]]);
    } else {
      optionsMap.push([responseArr[i]]);
    }
  }

  return optionsMap;
}


export const setMHOptionsMap = (questions=[]) => {
  for (let question of questions) {
    const {questionType, correctAnswers} = question;
    if (questionType === QUESTION_TYPES.MH.name) {
      question.optionsMap = convertMHOptionMap(correctAnswers);
    }
  }
}

