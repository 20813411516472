import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PublicApiCall } from "../../apis/PublicApiCall";
import { ReactComponent as YUJA_LOGO } from "../../images/YuJa_Logo.svg";
import {
    ACCOUNT_CARD_LOGOUT_TEXT,
    ACCOUNT_CARD_SUPPORT_TEXT,
    BUTTON, CREATE_ATTENDANCE,
    CREATE_POLL_PATH,
    POLL_TEXT,
    POLL_TYPE, PPT_MENU_LIST
} from "../../utils/constants";
import { useLoading } from "../../utils/LoadingContext";
import { PPT_MGMT_PORTAL_URL } from "../../utils/properties";
import useQuery from "../../utils/queryparam";
import styles from "./PPTLayout.module.css";

import { NavDropdown } from "react-bootstrap";
import YuJaHelpModal from "../../components/modals/YujaHelpModal";
import { usePPTWebView } from "../../hooks/usePPTWebView";
import letterA from "../../images/letterA.svg";
import letterB from "../../images/letterB.svg";
import letterC from "../../images/letterC.svg";
import letterD from "../../images/letterD.svg";
import letterE from "../../images/letterE.svg";
import letterF from "../../images/letterF.svg";
import letterG from "../../images/letterG.svg";
import letterH from "../../images/letterH.svg";
import letterI from "../../images/letterI.svg";
import letterJ from "../../images/letterJ.svg";
import letterK from "../../images/letterK.svg";
import letterL from "../../images/letterL.svg";
import letterM from "../../images/letterM.svg";
import letterN from "../../images/letterN.svg";
import letterO from "../../images/letterO.svg";
import letterP from "../../images/letterP.svg";
import letterQ from "../../images/letterQ.svg";
import letterR from "../../images/letterR.svg";
import letterS from "../../images/letterS.svg";
import letterT from "../../images/letterT.svg";
import letterU from "../../images/letterU.svg";
import letterV from "../../images/letterV.svg";
import letterW from "../../images/letterW.svg";
import letterX from "../../images/letterX.svg";
import letterY from "../../images/letterY.svg";
import letterZ from "../../images/letterZ.svg";
import ActiveCreateAttendanceIcon from "../../images/links/active_attendance_icon.svg";
import ActiveCreatePollIcon from "../../images/links/active_create_polls_icon.svg";
import { ReactComponent as ActiveDown } from "../../images/links/active_down.svg";
import { ReactComponent as ActiveUp } from "../../images/links/active_up.svg";
import CreateAttendanceIcon from "../../images/links/create_attendance.svg";
import CreatePollIcon from "../../images/links/create_poll_icon.svg";
import { ReactComponent as Down } from "../../images/links/down.svg";
import { ReactComponent as Up } from "../../images/links/up.svg";
import { ReactComponent as LogOut } from "../../images/LogoutIcon.svg";
import { ReactComponent as Support } from "../../images/SupportIcon.svg";
import { SHOW_CREATE_OPTIONS, roleValuetoDisplay, updateShowCreateOptions } from "../../utils/helpers";

export default function PPTLayout({children,}) {
    const { setLoading } = useLoading();
    const { appId, pollType } = useParams();
    const query = useQuery();
    const {checkExpired, parseToken, goCreatePollPage, goCreateAttendancePage, goAnyPage, goLoginPage} = usePPTWebView();
    const {postPPTMessage} = PublicApiCall();

    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("")
    const [role, setRole] = useState("");
    const [token, ] = useState(() => query.get("token"));
    const [collapsed, setCollapsed] = useState(true);
    const [showCreateOptions, setShowCreateOptions] = useState(SHOW_CREATE_OPTIONS);
    var StringToImage = {
        "A": letterA,
        "B": letterB,
        "C": letterC,
        "D": letterD,
        "E": letterE,
        "F": letterF,
        "G": letterG,
        "H": letterH,
        "I": letterI,
        "J": letterJ,
        "K": letterK,
        "L": letterL,
        "M": letterM,
        "N": letterN,
        "O": letterO,
        "P": letterP,
        "Q": letterQ,
        "R": letterR,
        "S": letterS,
        "T": letterT,
        "U": letterU,
        "V": letterV,
        "W": letterW,
        "X": letterX,
        "Y": letterY,
        "Z": letterZ
    };;
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        if (checkExpired()) {
            //1. post websocket message
            postPPTMessage(appId, JSON.stringify({action: "clean"})).then(() => {
                //2. do redirect
                goLoginPage();
            });
            return;
        }

        const {firstName="", lastName="", role: roleVal="", email=""} = parseToken();
        let name = "";
        if (!!firstName) {
            name = firstName;
        }

        if (!!lastName) {
            name = !!name ? `${firstName} ${lastName}` : lastName;
        }
        setUserName(name);
        setEmail(email); 
        setRole(roleVal);
    }, []);

    const logout = () => {
        //1. post websocket message
        setLoading(true);
        postPPTMessage(appId, JSON.stringify({action: "clean"})).then(() => {
            //2. do redirect
            window.open(`${PPT_MGMT_PORTAL_URL}/PPT/${appId}/dispatch`, "_self");
        }).finally(() => {
            setLoading(false);
        });
    }

    const isActiveCreatePollPage = () => {
        return window.location.pathname.includes(CREATE_POLL_PATH);

    };


    const isActiveCreateMergedPollPage = () => {
        return window.location.pathname.includes(CREATE_POLL_PATH) && pollType === POLL_TYPE.MERGED_POLL;

    };


    const isActiveCreateSurveyPage = () => {
        return window.location.pathname.includes(CREATE_POLL_PATH) && pollType === POLL_TYPE.SURVEY;
    };

    const isActiveCreateAttendancePage = () => {
        return window.location.pathname.includes(CREATE_POLL_PATH) && pollType === POLL_TYPE.ATTENDANCE;
    };

    return (
        <div className={styles.container}>
              <YuJaHelpModal showModal={showModal} setShowModal={setShowModal} userName={userName} userEmail={email} />
            <div className={styles.topBarContainer}>
                <YUJA_LOGO style={{width: 30, height: 30, marginRight: 5}}/>
                <span className={styles.title} style={{color: "#FFFFFF"}}>Engage</span>
                <div style={{flex: 1}}/>

                <NavDropdown className = {"navDropdown"} title={(
                <div style={{display: "flex", alignItems: "center"}}>
                {userName && <img src={StringToImage[userName[0].substring(0, 1).toUpperCase()] ? StringToImage[userName[0].substring(0, 1).toUpperCase()] : StringToImage["G"]} style={{width:35, height:35  , marginRight: 5, color: "#FFFFFF"}}/> }
                                {/* <span className={styles.description} style={{color: "#FFFFFF", fontSize: 16}} >{userName}</span> */}
                {!userName && <img src={StringToImage["G"]} style={{width:35, height:35  , marginRight: 5, color: "#FFFFFF"}}/>}
                </div>)} style={{padding: 0}}>
                    {/* <Dropdown.Toggle
                        as={React.forwardRef(({ children, onClick }, ref) => (
                            <span
                                ref={ref}
                                style={{cursor: "pointer", display: "flex", alignItems: "center"}}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onClick(e);
                                }}
                            >
                                <BiUserCircle size={25} style={{marginRight: 5, color: "#FFFFFF"}}/>
                                <span className={styles.description} style={{color: "#FFFFFF", fontSize: 16}} >{userName}</span>
                                <MdKeyboardArrowDown size={20} style={{color: "#FFFFFF"}}/>
                            </span>
                        ))}
                    /> */}
                    {/* <Dropdown.Menu style={{width: 150}}>
                        <Dropdown.Item eventKey="3" onClick={logout}>Log out</Dropdown.Item>
                    </Dropdown.Menu> */}
                    <ul role="menu" tabIndex={0} style={{boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 5px 0px", padding: "8px 0px", marginTop: "7px",  position: "absolute", right: 5,  backgroundColor: "#FFFFFF", borderRadius: "5px", display: "flex",  alignItems: "center", flexDirection: "column", width: 180, height: "fit-content"}} > 
                      {userName && <img src={StringToImage[userName[0].substring(0, 1).toUpperCase()] ? StringToImage[userName[0].substring(0, 1).toUpperCase()] : StringToImage["G"]} style={{width: 45, height:45, margin: "20px 10px"}} />}
                      {!userName && <img src={StringToImage["G"]} style={{width:35, height:35  , marginRight: 5, color: "#FFFFFF"}}/>}
                      <div style={{textAlign: "center", fontWeight: 700, fontSize: 20, overflow: "hidden", maxWidth: "175px", whiteSpace: "nowrap", textOverflow: "ellipsis", paddingLeft: "10px", paddingRight: "10px"}}>{userName ? userName: "Guest"} </div>
                      <div style={{color: "rgb(111, 111, 111)", fontSize: "14px"}}> { roleValuetoDisplay(role)} </div>
                      <div title={email} style={{overflow: "hidden", maxWidth: "175px", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "14px", paddingLeft: "10px", paddingRight: "10px"}}> {email} </div>
                      <hr style={{width: "100%"}}/>
                        <NavDropdown.Item tabIndex={0} onClick={() => {setShowModal(true)}} style={{ fontSize: "15px", display: "flex"}}>
                            <span style={{display: "flex", alignItems: "center", gap: 10, width: 150}}><Support size={24} style={{width: 24 , height: 24}}/>{ACCOUNT_CARD_SUPPORT_TEXT} </span>
                        </NavDropdown.Item>
                        <hr style={{width: "100%"}}/>
                        <NavDropdown.Item tabIndex={0} onClick={logout} style={{ fontSize: "15px", display: "flex"}}>
                            <span style={{display: "flex", alignItems: "center", gap: 10, width: 150}}><LogOut size={24} style={{width: 24 , height: 24}}/>{ACCOUNT_CARD_LOGOUT_TEXT} </span>
                        </NavDropdown.Item>
                      </ul>
                </NavDropdown>
            </div>

            <div style={{flex: 1, width: "100%", display: "flex", position: "relative", overflow: "auto"}}>
                <div className={styles.sideBarContainer} style={{width: (collapsed ? 60 : 200)}}>
                    <div className={styles.sidebarMenu} onMouseLeave={() => setCollapsed(true)} onMouseEnter={() => setCollapsed(false)}>
                        <div style={{padding: "20px 5px 0 5px"}}>
                                {Object.values(PPT_MENU_LIST).filter(menu => menu.roles.includes(role)).map((menu, idx) => (
                                    <div key={idx}>
                                        {menu.name === PPT_MENU_LIST.CREATE.name &&
                                            <>
                                                <div
                                                    tabIndex={0}
                                                    aria-label={menu.name}
                                                    role={BUTTON}
                                                    id={`menu-link-${idx}`}
                                                    onClick={() => {
                                                        setShowCreateOptions(collapsed ? true : !showCreateOptions);
                                                        updateShowCreateOptions(collapsed ? true : !showCreateOptions);
                                                        setCollapsed(false);
                                                    }}
                                                    className={`${(isActiveCreatePollPage() && collapsed) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                                    hidden={!menu.roles.includes(role)}
                                                    style={{display: "flex", justifyContent: "space-between"}}
                                                >
                                                    <div style={{width: "100%"}}>
                                                        {!collapsed && <div>
                                                            <img
                                                                id={`menu-icon-${menu.name}`}
                                                                src={menu.icon}
                                                                style={{width: "50px", height: "18px", marginRight: "5px"}}
                                                                alt={`${menu.name} icon`}
                                                            />
                                                            <span className={styles.sidebarText}>{menu.name}</span>
                                                        </div>}
                                                        {collapsed &&
                                                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                                                <img
                                                                    id={`menu-icon-${menu.name}`}
                                                                    src={isActiveCreatePollPage() ? menu.activeIcon : menu.icon}
                                                                    style={{height: "18px"}}
                                                                    alt={`${menu.name} icon`}
                                                                    className={`${isActiveCreatePollPage() ? styles.activeSubIcon: ""}`}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                    {!collapsed && showCreateOptions && window.location.pathname === menu.path &&
                                                        <ActiveUp style={{width: "18px", cursor: "pointer", marginRight: "5px"}}/>
                                                    }
                                                    {!collapsed &&  showCreateOptions && window.location.pathname !== menu.path &&
                                                        <Up style={{width: "18px", cursor: "pointer", marginRight: "5px"}}/>
                                                    }

                                                    {!collapsed &&  !showCreateOptions && window.location.pathname === menu.path &&
                                                        <ActiveDown style={{width: "18px", cursor: "pointer", marginRight: "5px"}}/>
                                                    }
                                                    {!collapsed && !showCreateOptions && window.location.pathname !== menu.path &&
                                                        <Down style={{width: "18px", cursor: "pointer", marginRight: "5px"}}/>
                                                    }
                                                </div>
                                                {!collapsed && showCreateOptions &&
                                                    <div className={styles.createOptionWrap}>
                                                        <span
                                                            tabIndex={0}
                                                            onClick={goCreatePollPage}
                                                            className={`${isActiveCreateMergedPollPage() ? styles.activeSideBarLink : styles.createOption}`}
                                                        >
                                                            <div>
                                                                <img
                                                                    id={`menu-icon-${menu.name}`}
                                                                    src={isActiveCreateMergedPollPage() ? ActiveCreatePollIcon : CreatePollIcon}
                                                                    style={{
                                                                        width: "50px",
                                                                        height: "18px",
                                                                        marginRight: "5px",
                                                                        marginLeft: "15px"
                                                                    }}
                                                                    alt={`${menu.name} poll icon`}
                                                                    className={`${isActiveCreateMergedPollPage() ? styles.activeSubIcon : ""}`}
                                                                />
                                                                <span className={styles.sidebarText}>{POLL_TEXT}</span>
                                                            </div>
                                                        </span>
                                                        <span
                                                            tabIndex={0}
                                                            onClick={goCreateAttendancePage}
                                                            className={`${isActiveCreateAttendancePage() ? styles.activeSideBarLink : styles.createOption}`}
                                                        >
                                                           <div>
                                                                <img
                                                                    id={`menu-icon-${menu.name}`}
                                                                    src={isActiveCreateAttendancePage() ? ActiveCreateAttendanceIcon : CreateAttendanceIcon}
                                                                    style={{
                                                                        width: "50px",
                                                                        height: "18px",
                                                                        marginRight: "5px",
                                                                        marginLeft: "15px"
                                                                    }}
                                                                    alt={`${menu.name} survey icon`}
                                                                    className={`${isActiveCreateSurveyPage() ? styles.activeSubIcon : ""}`}
                                                                />
                                                                <span
                                                                    className={styles.sidebarText}>{CREATE_ATTENDANCE}</span>
                                                           </div>
                                                        </span>
                                                    </div>
                                                }
                                            </>
                                        }
                                        {menu.name !== PPT_MENU_LIST.CREATE.name &&
                                            <span
                                                tabIndex={0}
                                                aria-label={menu.name}
                                                role={BUTTON}
                                                id={`menu-link-${idx}`}
                                                onClick={() => {
                                                    setShowCreateOptions(false);
                                                    goAnyPage(menu.path);
                                                }}
                                                className={`${window.location.pathname.includes(menu.path) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                                hidden={!menu.roles.includes(role)}
                                            >
                                                {!collapsed &&
                                                    <div>
                                                        <img
                                                            id={`menu-icon-${menu.name}`}
                                                            src={window.location.pathname.includes(menu.path) ? menu.activeIcon : menu.icon}
                                                            style={{width: "50px", height: "18px", marginRight: "5px"}}
                                                            alt={`${menu.name} icon`}
                                                            className={`${window.location.pathname.includes(menu.path) ? styles.activeSubIcon: ""}`}
                                                        />
                                                        <span className={styles.sidebarText}>{menu.name}</span>
                                                    </div>
                                                }
                                                {collapsed &&
                                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                                        <img
                                                            id={`menu-icon-${menu.name}`}
                                                            src={window.location.pathname.includes(menu.path) ? menu.activeIcon : menu.icon}
                                                            style={{height: "18px"}}
                                                            alt={`${menu.name} icon`}
                                                            className={`${window.location.pathname.includes(menu.path) ? styles.activeSubIcon: ""}`}
                                                        />
                                                    </div>
                                                }
                                            </span>
                                        }
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                {!!token &&
                    <div className={styles.majorContainer}>{children}</div>
                }
            </div>
        </div>
    );
}