import React, { useCallback, useEffect, useState } from "react";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import {
    ADD_TIME_ALL_STUDENT_MESSAGE,
    ADD_TIME_EACH_STUDENT_MESSAGE,
    ATTENDANCE_PAGE_STATE, DEFAULT_CLASS_RESULT_TIME_LIMIT, DEFAULT_POLL_BREAK_TIME_LIMIT,
    DEFAULT_POLL_TIME_LIMIT,
    DEFAULT_TIME_LIMIT,
    ERROR_CODES,
    LOG_TYPES, POLL_SHARE_MODE,
    POLL_STATE,
    POLL_TYPE,
    QUIZ_STATE,
    RECORD_ATTEMPT,
    SYNC_ATTEMPT,
    UNLIMITED_ATTEMPTS, VIEWER_WEBSOCKET_MESSAGE,
    WAIT_LENGTH,
    WAIT_QUESTION_TEXT
} from "../../utils/constants";
import { fillFormatText, newPageLog, notifyInfo, updateLogger } from "../../utils/helpers";
import AttendancePage from "./AttendancePage";
import ClassResult from "./ClassResult";
import EndedPollNew from "./EndedPollNew";
import Expired from "./Expired";
import GetReady from "./GetReady";
import GetReadyAll from "./GetReadyAll";
import PollReady from "./PollReady";
import PollReadyAll from "./PollReadyAll";
import UserCompleteMergedPoll from "./UserCompleteMergedPoll";
import WaitingRoom from "./WaitingRoom";
import ClassResultAllScheduled from "./classResultSchedule";

export default function JoinQuizPageQRNew({ param, websocketState, websocketMsg, handleReattempt, handleReset, setPollClosed, visibility, loadingDelay, setCountdownContent}) {

    const {
        pollKey = "",
        userName = "",
        pollTitle = "",
        pollDescription = "",
        uniqueCode = "",
        showAnswerOnViewer=false,
        getReadyAllTimeLimit = 0,
        pollBreakDuration=DEFAULT_POLL_BREAK_TIME_LIMIT,
        questions = [],
        liveQuestionNo = -1,
        liveQuestionTime = -1,
        liveQuestionResponse,
        pollState = "NONE",
        pollShareMode,
        hasGeofence,
        geofence,
        syncLms,
        pollType = POLL_TYPE.MERGED_POLL,
        pollTimeLimit = DEFAULT_POLL_TIME_LIMIT,
        updateTimeEpoch = 0,
        errorCode = "",
        pollQuestionIdx = 0,
        institutionId = "",
        pollJoinTime = "",
        startTime = null,
        endTime = null,
        recordAttempt=RECORD_ATTEMPT.RECENT.value,
        lmsAttempt=SYNC_ATTEMPT.RECENT.value,
        maxAttempts=UNLIMITED_ATTEMPTS,
        attemptsCount = 1
    } = param;

    const pollInfo = {
        pollKey,
        endTime,
        uniqueCode,
        userName,
        pollTitle,
        pollDescription,
        pollType,
        pollShareMode,
        showAnswerOnViewer,
        recordAttempt,
        lmsAttempt,
        maxAttempts,
        attemptsCount,
        hasGeofence,
        geofence,
        syncLms,
        pollBreakDuration
    };

    // const currentQuestion = useRef({});
    // const currentPoll = useRef({});
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [currentPoll, setCurrentPoll] = useState({});
    const [pollLiveQuestionIdx, setPollLiveQuestionIdx] = useState(pollQuestionIdx);
    const [state, setState] = useState(QUIZ_STATE.LOADING);
    const [error, setError] = useState("");
    const [stopped, setStopped] = useState(false);
    const [stoppedAll, setStoppedAll] = useState(false);
    const [quesShareCount, setQuesShareCount] = useState("");
    const [allSharecount, setAllShareCount] = useState("");
    const [sharedQuestionList, setSharedQuestionList] = useState("");
    const [, getTimeOffset] = useLocalStorageNew("timeOffset", 0);
    const [, getSession] = useLocalStorageNew("session", {});
    const {getSharedQuestionList} = ResponseApiCalls();
    const [allowReattempt, setAllowReattempt] = useState(false);
    const [attendancePageState, setAttendancePageState] = useState(ATTENDANCE_PAGE_STATE.WAITING);

    // useEffect(() => {
    //   if(state !== QUIZ_STATE.LOADING) {
    //     setJoinLoading(false);
    //   }
    // }, [state]);
    
    useEffect(() => {
        if (!!setCountdownContent && state !== QUIZ_STATE.POLL_TIME && state !== QUIZ_STATE.POLL_TIME_ALL) {
            setCountdownContent(null);
        }
    }, [state]);

    useEffect(() => {
        if (loadingDelay) {
            return;
        }

        if (errorCode === ERROR_CODES.INVALID_POLL_CODE || errorCode === ERROR_CODES.EXPIRED_POLL_CODE) {
            setError(errorCode);
            setState(QUIZ_STATE.EXPIRED);
            return;
        }

        if (pollState === POLL_STATE.CLOSED) {
            setState(QUIZ_STATE.CLOSED)
            return;
        }

        setAllowReattempt(!!getSession().userName); // (maxAttempts === UNLIMITED_ATTEMPTS || attemptsCount < maxAttempts);
        if (pollState.includes(POLL_STATE.STOPPED_ALL)) {
            const pollTime = liveQuestionTime + parseInt(getTimeOffset());
            const timeLimit = pollTimeLimit;
            const pollEndPoint = pollTime + timeLimit * 1000;
            const classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
            let duration = (pollTime - Date.now()) / 1000;

            const currentPollObj = {};
            currentPollObj.rawQueResponses = liveQuestionResponse ? liveQuestionResponse : [];
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.pollStartTime = pollTime;
            currentPollObj.pollEndTime = pollEndPoint;
            currentPollObj.classResultEndTime = classResultEndTime;
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.questions = questions;
            // currentPoll.current = currentPollObj;
            setCurrentPoll(currentPollObj);

            // if(pollType === POLL_TYPE.SURVEY){
            //     setState(QUIZ_STATE.SURVEY_ALL_END);
            // } else {
            setState(QUIZ_STATE.CLASS_RESULT_ALL);
            // }
            return;
        }
        if (pollState.includes(POLL_STATE.SHARED_ALL)) {
            setState(QUIZ_STATE.POLL_TIME_ALL);
            const pollTime = liveQuestionTime + parseInt(getTimeOffset());
            const timeLimit = pollTimeLimit;
            const pollEndPoint = pollTime + timeLimit * 1000;
            const currentTime = Date.now();
            // configure class results later (TBD)
            const classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
            let duration = (pollTime - Date.now()) / 1000;

            const currentPollObj = {};
            currentPollObj.rawQueResponses = liveQuestionResponse ? liveQuestionResponse : [];
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.pollStartTime = pollTime;
            currentPollObj.pollEndTime = pollEndPoint;
            currentPollObj.classResultEndTime = classResultEndTime;
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.questions = questions;
            currentPollObj.timeLimit = timeLimit;
            // currentPoll.current = currentPollObj;
            setCurrentPoll(currentPollObj);
            // if (classResultEndTime < currentTime) {
            //   setState(QUIZ_STATE.PENDING);
            //   currentQuestion.current = currentPollObj
            //   return;
            // }
            console.log(currentPollObj);
            console.log(currentTime);
            console.log(pollQuestionIdx);
            if (currentPollObj.pollStartTime > currentTime) {
                setState(QUIZ_STATE.GET_READY_ALL);
                const duration = (currentPollObj.pollStartTime - currentTime) / 1000;
                currentPollObj.timeReadyDuration = duration;
                // currentPoll.current = currentPollObj;
                setCurrentPoll(currentPollObj);
                return;
            }

            if (currentPollObj.pollEndTime > currentTime && pollQuestionIdx < questions.length) {
                setState(QUIZ_STATE.POLL_TIME_ALL);
                // currentPoll.current = currentPollObj;
                setCurrentPoll(currentPollObj);
                return;
            }

            // if (pollQuestionIdx < questions.length && pollType.toUpperCase() === POLL_TYPE.SURVEY && !endTime) {
            //     setState(QUIZ_STATE.POLL_TIME_ALL);
            //     setCurrentPoll(currentPollObj);
            //     return;
            // }

            setCurrentPoll(currentPollObj);
            // if (pollType.toUpperCase() === POLL_TYPE.SURVEY) {
            //     setState(QUIZ_STATE.USER_COMPLETE_SURVEY);
            // } else {
            setState(QUIZ_STATE.USER_COMPLETE_POLL);
            // }
            // currentPoll.current = currentPollObj;
            return;
        }

        if (pollState.includes(POLL_STATE.STOPPED_SCHEDULED)) {
            const currentPollObj = {};
            currentPollObj.rawQueResponses = liveQuestionResponse ? liveQuestionResponse : [];
            currentPollObj.questions = questions;
            setCurrentPoll(currentPollObj);
            setState(QUIZ_STATE.CLASS_RESULT_ALL);
            return;
        }

        if (pollState.includes(POLL_STATE.SCHEDULED)) {
            let pollTime = new Date(startTime).getTime();
            const timeLimit = pollTimeLimit;
            const currentTime = Date.now();
            if (pollJoinTime && new Date(pollJoinTime).getTime() > pollTime) {
                pollTime = new Date(pollJoinTime).getTime();
            }
            const pollEndPoint = Math.min(new Date(pollTime).getTime() + timeLimit * 1000,  new Date(endTime).getTime());



            const classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
            let duration = (pollTime - Date.now()) / 1000;

            const currentPollObj = {};
            currentPollObj.rawQueResponses = liveQuestionResponse ? liveQuestionResponse : [];
            currentPollObj.pollStartTime = pollTime;
            currentPollObj.pollFinishTime = new Date(endTime).getTime();
            currentPollObj.pollEndTime = pollEndPoint;
            currentPollObj.classResultEndTime = classResultEndTime;
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.questions = questions;
            currentPollObj.timeLimit = timeLimit;
            currentPollObj.getReadyAllTimeLimit = getReadyAllTimeLimit;
            // currentPoll.current = currentPollObj;


            if (currentPollObj.pollStartTime > currentTime) {
                setState(QUIZ_STATE.GET_READY_ALL);
                currentPollObj.timeReadyDuration = (currentPollObj.pollStartTime - currentTime) / 1000;
                // currentPoll.current = currentPollObj;
                setCurrentPoll(currentPollObj);
                return;
            }
            if ( (currentPollObj.pollEndTime > currentTime && pollQuestionIdx < questions.length)) {
                setState(QUIZ_STATE.POLL_TIME_ALL);
                // currentPoll.current = currentPollObj;
                setCurrentPoll(currentPollObj);
                return;
            }

            setState(QUIZ_STATE.USER_COMPLETE_POLL);
            // currentPoll.current = currentPollObj;
            setCurrentPoll(currentPollObj);
            return;
        }

        if (liveQuestionNo <= 0) {
            setState(pollType === POLL_TYPE.ATTENDANCE ? QUIZ_STATE.ATTENDANCE_PAGE : QUIZ_STATE.WAITING);
            return;
        }

        //share-each
        const curQue = questions[Number(liveQuestionNo) - 1];
        const { timeLimit = 0, classResultDuration = DEFAULT_CLASS_RESULT_TIME_LIMIT } = curQue;
        const timed = !!timeLimit;
        const queEndPoint = liveQuestionTime + parseInt(getTimeOffset()) + timeLimit * 1000;
        const classResultEndTime = queEndPoint + (classResultDuration + 2) * 1000;
        const currentTime = Date.now();
        const currentObj = {}

        currentObj.question = curQue;
        currentObj.questionStartTime = liveQuestionTime + parseInt(getTimeOffset());
        currentObj.questionEndTime = pollType === POLL_TYPE.ATTENDANCE ? endTime : queEndPoint;
        currentObj.classResultEndTime = classResultEndTime;
        currentObj.questionResponse = liveQuestionResponse;

        if (pollType === POLL_TYPE.ATTENDANCE) {
            if (currentObj.questionStartTime > currentTime) {
                const duration = (currentObj.questionStartTime - currentTime) / 1000;
                currentObj.timeReadyDuration = duration;
            }
            setCurrentQuestion(currentObj);
            if (!!liveQuestionResponse && !!Object.entries(liveQuestionResponse).length) {
                setAttendancePageState(ATTENDANCE_PAGE_STATE.THANK_YOU);
                setState(QUIZ_STATE.ATTENDANCE_PAGE);
                return;
            }
            if (pollState === POLL_STATE.STOPPED || (!!endTime && endTime < currentTime)) {
                setAttendancePageState(ATTENDANCE_PAGE_STATE.CLOSED);
                setState(QUIZ_STATE.ATTENDANCE_PAGE);
                return;
            }
            setAttendancePageState(hasGeofence ? ATTENDANCE_PAGE_STATE.CHECK_IN : ATTENDANCE_PAGE_STATE.QUESTION);
            setState(QUIZ_STATE.ATTENDANCE_PAGE);
            return;
        }

        // if (pollType.toUpperCase() === POLL_TYPE.SURVEY) {
        //     currentObj.classResultEndTime = null;
        //     //stopped
        //     if (pollState === POLL_STATE.STOPPED) {
        //         setState(QUIZ_STATE.PENDING);
        //         // currentQuestion.current = currentObj
        //         setCurrentQuestion(currentObj);
        //         return;
        //     }

        //     //get ready
        //     if (currentObj.questionStartTime > currentTime) {
        //         setState(QUIZ_STATE.GET_READY);
        //         const duration = (currentObj.questionStartTime - currentTime) / 1000;
        //         currentObj.timeReadyDuration = duration;
        //         // currentQuestion.current = currentObj;
        //         setCurrentQuestion(currentObj);
        //         return;
        //     }

        //     const { selectedOption = null} = liveQuestionResponse;

        //     //unanswered
        //     if (!selectedOption) {
        //         setState(QUIZ_STATE.POLL_TIME);
        //         // currentQuestion.current = currentObj
        //         setCurrentQuestion(currentObj);
        //     } else {
        //         setState(QUIZ_STATE.CLASS_RESULT);
        //         // currentQuestion.current = currentObj
        //         setCurrentQuestion(currentObj);
        //     }
        //     return;

        // }

        if (pollState === POLL_STATE.STOPPED || (timed && classResultEndTime < currentTime)) {
            setState(QUIZ_STATE.PENDING);
            // currentQuestion.current = currentObj
            setCurrentQuestion(currentObj);
            return;
        }

        if (currentObj.questionStartTime > currentTime) {
            setState(QUIZ_STATE.GET_READY);
            const duration = (currentObj.questionStartTime - currentTime) / 1000;
            currentObj.timeReadyDuration = duration;
            // currentQuestion.current = currentObj
            setCurrentQuestion(currentObj);
            return;
        }

        if (!timed) {
            const { selectedOption = null} = liveQuestionResponse;

            if (!selectedOption) { // unanswered
                setState(QUIZ_STATE.POLL_TIME);
            } else {
                setState(QUIZ_STATE.CLASS_RESULT);
            }
            setCurrentQuestion(currentObj);
            return;
        }

        if (currentObj.questionEndTime > currentTime) {
            setState(QUIZ_STATE.POLL_TIME);
            // currentQuestion.current = currentObj
            setCurrentQuestion(currentObj);
            return;
        }

        if (currentObj.classResultEndTime > currentTime) {
            setState(QUIZ_STATE.CLASS_RESULT);
            // currentQuestion.current = currentObj
            setCurrentQuestion(currentObj);
        }
    }, [param, loadingDelay]);


    useEffect(async () => {
        if (state === QUIZ_STATE.LOADING || pollShareMode !== POLL_SHARE_MODE.SHARE_EACH || document.visibilityState !== "visible") {
            return;
        }
        let shareData = await getSharedQuestionList(pollKey, uniqueCode);
        setQuesShareCount(shareData.sharedQuestionCount + "/" + questions.length);
        setSharedQuestionList(shareData.sharedQuestionList);
        setAllShareCount(shareData.sharedCountList);
    }, [state, visibility]);

    useEffect(() => {
        const {liveQuestionSerialNo, liveQuestionMessage, liveQuestionTime} = websocketMsg;
        if (!websocketMsg || !Object.entries(websocketMsg).length || !liveQuestionMessage || !liveQuestionSerialNo) {
            return;
        }

        const now = new Date();
        updateLogger(LOG_TYPES.INFO + "Websocket received message on JoinQuizPageQR.js");
        updateLogger(LOG_TYPES.INFO + "msg receive time: " + now.toString());
        updateLogger(LOG_TYPES.INFO + "liveQuestionTime: " + liveQuestionTime);
        updateLogger(LOG_TYPES.INFO + "liveQuestionSerialNo: " + liveQuestionSerialNo);
        updateLogger(LOG_TYPES.INFO + "liveQuestionMessage: " + liveQuestionMessage);
        //when poll is closed then join poll
        if (pollState === POLL_STATE.CLOSED) {
            setPollClosed(false);
            return;
        }


        const currentQue = questions[Number(liveQuestionSerialNo) - 1];
        const currentObj = {};
        const currentPollObj = JSON.parse(JSON.stringify(currentPoll));
        currentObj.question = currentQue;

        if (liveQuestionMessage === VIEWER_WEBSOCKET_MESSAGE.STOP) {
            setStopped(true);
            if (pollType === POLL_TYPE.ATTENDANCE) {
                // setState(QUIZ_STATE.ATTENDANCE_PAGE);
                // setAttendancePageState(ATTENDANCE_PAGE_STATE.THANK_YOU);
                // setStopped(false);
                return;
            }

            const queTime = liveQuestionTime + parseInt(getTimeOffset());
            const {timeLimit = DEFAULT_TIME_LIMIT, classResultDuration=DEFAULT_CLASS_RESULT_TIME_LIMIT} = currentQue;
            const queEndPoint = queTime + timeLimit * 1000;
            const classResultEndTime = queEndPoint + (classResultDuration + 2) * 1000;
            currentObj.questionStartTime = queTime;
            currentObj.questionEndTime = queEndPoint;
            currentObj.classResultEndTime = classResultEndTime;
            setCurrentQuestion(currentObj);
            if (state !== QUIZ_STATE.POLL_TIME) {
                if (state === QUIZ_STATE.GET_READY) {
                    handleClassResultComplete()
                } else {
                    handleQuestionComplete();
                }
            }
        } else if ([VIEWER_WEBSOCKET_MESSAGE.STOP_ALL, VIEWER_WEBSOCKET_MESSAGE.STOP_SCHEDULED].includes(liveQuestionMessage)) {
            setStoppedAll(true);
            const pollEndPoint = Date.now() + pollBreakDuration * 1000;
            currentPollObj.pollEndTime = pollEndPoint;
            currentPollObj.classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
            setCurrentPoll(currentPollObj);

            if (state !== QUIZ_STATE.POLL_TIME_ALL) {
                handlePollComplete();
            }
        } else if (liveQuestionMessage === VIEWER_WEBSOCKET_MESSAGE.SHARE) {
            setStopped(false);
            const queTime = liveQuestionTime + parseInt(getTimeOffset());
            const {timeLimit = DEFAULT_TIME_LIMIT, classResultDuration=DEFAULT_CLASS_RESULT_TIME_LIMIT} = currentQue;
            const queEndPoint = queTime + timeLimit * 1000;
            const classResultEndTime = queEndPoint + (classResultDuration + 2) * 1000;
            let duration = (queTime - Date.now()) / 1000;
            currentObj.questionStartTime = queTime;
            currentObj.questionEndTime = pollType === POLL_TYPE.ATTENDANCE ? endTime : queEndPoint;
            currentObj.classResultEndTime = classResultEndTime;
            currentObj.timeReadyDuration = duration;
            // currentQuestion.current = currentObj;
            setCurrentQuestion(currentObj);
            if (pollType === POLL_TYPE.ATTENDANCE) {
                setState(QUIZ_STATE.ATTENDANCE_PAGE);
                // if (!!liveQuestionResponse && !!Object.entries(liveQuestionResponse).length) {
                //     setAttendancePageState(ATTENDANCE_PAGE_STATE.THANK_YOU);
                //     return;
                // }
                setAttendancePageState(hasGeofence ? ATTENDANCE_PAGE_STATE.CHECK_IN : ATTENDANCE_PAGE_STATE.QUESTION);
                return;
            }
            setState(QUIZ_STATE.GET_READY);
        } else if (liveQuestionMessage === VIEWER_WEBSOCKET_MESSAGE.SHARE_ALL){
            setStoppedAll(false);
            const pollTime = liveQuestionTime + parseInt(getTimeOffset());
            // const timeLimit = liveQuestionMessage.split(':')[1];
            const pollEndPoint = pollTime + pollTimeLimit * 1000;
            const classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
            let duration = (pollTime - Date.now()) / 1000;
            currentPollObj.timeLimit = pollTimeLimit;
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.pollStartTime = pollTime;
            currentPollObj.pollEndTime = pollEndPoint;
            currentPollObj.classResultEndTime = classResultEndTime;
            currentPollObj.questions = questions;
            // currentPoll.current = currentPollObj;
            setCurrentPoll(currentPollObj);
            setState(QUIZ_STATE.GET_READY_ALL);
        } else if (liveQuestionMessage === VIEWER_WEBSOCKET_MESSAGE.UPDATE_SCHEDULE_TIME) {
            let startTime = websocketMsg.startTime;
            let endTime = websocketMsg.endTime;
            let pollTime = new Date(startTime).getTime();
            const timeLimit =  parseInt(websocketMsg.pollTimeLimit, 10);
            if (pollJoinTime && new Date(pollJoinTime).getTime() > pollTime) {
                pollTime = pollJoinTime;
            }
            const pollEndPoint = Math.min(new Date(pollTime).getTime() + timeLimit * 1000,  new Date(endTime).getTime());
            const classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
            let duration = (pollTime - Date.now()) / 1000;
            currentPollObj.rawQueResponses = liveQuestionResponse ? liveQuestionResponse : [];
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.pollStartTime = pollTime;
            currentPollObj.pollFinishTime = new Date(endTime).getTime();
            currentPollObj.pollEndTime = pollEndPoint;
            currentPollObj.classResultEndTime = classResultEndTime;
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.questions = questions;
            currentPollObj.timeLimit = timeLimit;
            setCurrentPoll(currentPollObj);
        } else if (liveQuestionMessage === VIEWER_WEBSOCKET_MESSAGE.UPDATE_POLL_TIME) {
            if (state === QUIZ_STATE.POLL_TIME_ALL ){
            const pollTime = currentPollObj.pollStartTime;
            const timeLimit =  parseInt(websocketMsg.pollTimeLimit, 10);
            let timeAdded =  timeLimit - currentPollObj.timeLimit; 
            const TimeString = formatTime(timeAdded);
            const pollEndPoint = pollTime + (timeLimit * 1000);
            const classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
            currentPollObj.rawQueResponses = liveQuestionResponse ? liveQuestionResponse : [];
            currentPollObj.pollStartTime = pollTime;
            currentPollObj.pollEndTime = pollEndPoint;
            currentPollObj.classResultEndTime = classResultEndTime;
            currentPollObj.questions = questions;
            currentPollObj.timeLimit = timeLimit;
            notifyInfo( fillFormatText(ADD_TIME_ALL_STUDENT_MESSAGE, {Time: TimeString}))
            setCurrentPoll(currentPollObj);
            handleReset();
            } else if (state === QUIZ_STATE.POLL_TIME) {
                const queTime = currentQuestion.questionStartTime;
                const timeLimit =  parseInt(websocketMsg.pollTimeLimit, 10);
                const queEndPoint = queTime + timeLimit * 1000;
                const classResultEndTime = queEndPoint + (WAIT_LENGTH + 2) * 1000;
                let timeAdded =  parseInt((queEndPoint - currentQuestion.questionEndTime )/1000); 
                const TimeString = formatTime(timeAdded);
                currentObj.questionEndTime = queEndPoint;
                currentObj.classResultEndTime = classResultEndTime;
                notifyInfo(fillFormatText(ADD_TIME_EACH_STUDENT_MESSAGE, {Time: TimeString}))
                setCurrentQuestion(currentObj);
                handleReset();
            }
        } else if (liveQuestionMessage === VIEWER_WEBSOCKET_MESSAGE.RESET) { // reset poll/question details
            console.log("reset ws message received");
            handleReset();
        }
    }, [websocketMsg]);

    function formatTime(timeInSeconds) {
        if (timeInSeconds < 60) {
            return timeInSeconds + " secs have";
        } else if (timeInSeconds < 3600) {
            const mins = Math.floor(timeInSeconds / 60);
            return mins === 1 ? "1 min has" : mins + " mins have";
        } else {
            const hours = Math.floor(timeInSeconds / 3600);
            return hours === 1 ? "1 hour has" : hours + " hours have";
        }
    }

    useEffect(() => {
        setPollLiveQuestionIdx(pollQuestionIdx);
    }, [param, pollQuestionIdx]);

    useEffect(() => {
        newPageLog("JoinQuizPageQR.js");
    }, []);

    const handleGetReadyComplete = useCallback(() => {
        console.log("go question page!!!!!!")
        setState(QUIZ_STATE.POLL_TIME);
    }, []);

    const handleGetReadyAllComplete = useCallback(() => {
        setState(QUIZ_STATE.POLL_TIME_ALL);
    }, []);

    const handleQuestionComplete = useCallback(() => {
        setState(QUIZ_STATE.CLASS_RESULT);
        setStopped(false);
    }, []);

    const handleSAQuestionComplete = useCallback(() => {
        setState(QUIZ_STATE.SA_RESULT);
        setStopped(false);
    }, []);

    const handleUserCompletePoll = useCallback(() => {
        // if(pollType === POLL_TYPE.SURVEY){
        //     setState(QUIZ_STATE.USER_COMPLETE_SURVEY);
        // } else {
        setState(QUIZ_STATE.USER_COMPLETE_POLL);
        // }
    }, []);

    const handlePollComplete = () => {
        // if(pollType === POLL_TYPE.SURVEY){
        //     setState(QUIZ_STATE.SURVEY_ALL_END);
        // } else {
        setState(QUIZ_STATE.CLASS_RESULT_ALL);
        // }
        setStoppedAll(false);
    };

    const handleClassResultComplete = useCallback(() => {
        setState(QUIZ_STATE.PENDING);
    }, []);

    // const handleSAResultComplete = useCallback(() => {
    //     setState(QUIZ_STATE.PENDING);
    // }, []);

    const changeCurQue = (que) => {
        // currentQuestion.current = que;
        setCurrentQuestion(que);
    };

    const changeCurPoll = (poll) => {
        // currentPoll.current = poll;
        setCurrentPoll(poll);
    };

    return (
        <>
            {state === QUIZ_STATE.EXPIRED &&
                <Expired errObj={error} />
            }
            {(state === QUIZ_STATE.LOADING || state === QUIZ_STATE.WAITING) &&
                <WaitingRoom
                    bodyText={pollDescription}
                    titleText={pollTitle}
                    afterQ={false}
                    websocketState={websocketState}
                    loadingDelay={loadingDelay}
                />
            }
            {state === QUIZ_STATE.PENDING &&
                <WaitingRoom
                    allSet={true}
                    bodyText={WAIT_QUESTION_TEXT}
                    hasGeofence={hasGeofence}
                    bodyShareCount={quesShareCount}
                    titleText={pollTitle}
                    afterQ={true}
                    websocketState={websocketState}
                />
            }
            {state === QUIZ_STATE.GET_READY &&
                <GetReady
                    question={currentQuestion}
                    handleGetReadyComplete={handleGetReadyComplete}
                    pollTitle={pollTitle}
                    websocketState={websocketState}
                />
            }
            {state === QUIZ_STATE.POLL_TIME &&
                <PollReady
                    pollInfo={pollInfo}
                    currentQuestion={currentQuestion}
                    handleQuestionComplete={handleQuestionComplete}
                    handleSAQuestionComplete={handleSAQuestionComplete}
                    state={state}
                    setState={setState}
                    stopped={stopped}
                    institutionId={institutionId}
                    // quesShareCount={quesShareCount}
                    allSharecount={allSharecount}
                    changeCurQue={changeCurQue}
                    websocketState={websocketState}
                    totalQuestion={questions.length}
                    setCountdownContent={setCountdownContent}
                />
            }
            {state === QUIZ_STATE.POLL_TIME_ALL &&
                <PollReadyAll
                    pollInfo={pollInfo}
                    currentPoll={currentPoll}
                    pollLiveQuestionIdx={pollLiveQuestionIdx}
                    setPollLiveQuestionIdx={setPollLiveQuestionIdx}
                    handleUserCompletePoll={handleUserCompletePoll}
                    handlePollComplete={handlePollComplete}
                    allowReattempt={allowReattempt}
                    handleReattempt={handleReattempt}
                    attemptsCount={attemptsCount}
                    maxAttempts={maxAttempts}
                    stoppedAll={stoppedAll}
                    state={state}
                    institutionId={institutionId}
                    changeCurPoll={changeCurPoll}
                    websocketState={websocketState}
                    setCountdownContent={setCountdownContent}
                />
            }
            {/* {state === QUIZ_STATE.SA_RESULT &&
                <SAResult
                    pollInfo={pollInfo}
                    currentQuestion={currentQuestion}
                    handleSAResultComplete={handleSAResultComplete}
                    institutionId={institutionId}
                    quesShareCount={quesShareCount}
                    websocketState={websocketState}
                />
            } */}
            { (state === QUIZ_STATE.CLASS_RESULT || state === QUIZ_STATE.SA_RESULT) &&
                <ClassResult
                    pollInfo={pollInfo}
                    currentQuestion={currentQuestion}
                    handleSAResult={handleSAQuestionComplete}
                    handleClassResultComplete={handleClassResultComplete}
                    institutionId={institutionId}
                    quesShareCount={quesShareCount}
                    sharedQuestionList={sharedQuestionList}
                    websocketState={websocketState}
                    questions={questions}
                />
            }
            {state === QUIZ_STATE.GET_READY_ALL &&
                <GetReadyAll
                    poll={currentPoll}
                    handleGetReadyAllComplete={handleGetReadyAllComplete}
                    pollState={pollState}
                    pollTitle={pollTitle}
                    websocketState={websocketState}
                />
            }
            {/* {state === QUIZ_STATE.USER_COMPLETE_POLL && !pollState.includes(POLL_STATE.SCHEDULED) &&
                <UserCompletePoll
                    handlePollComplete={handlePollComplete}
                    pollEndTime={currentPoll.pollEndTime}
                    allowReattempt={allowReattempt}
                    handleReattempt={handleReattempt}
                    attemptsCount={attemptsCount}
                    maxAttempts={maxAttempts}
                    pollTitle={pollTitle}
                    websocketState={websocketState}
                    userName={userName}
                    currentPoll={currentPoll}
                    institutionId={institutionId}
                />
            } */}
            {/* {state === QUIZ_STATE.CLASS_RESULT_ALL &&
                <ClassResultAll
                    userName={userName}
                    pollInfo={pollInfo}
                    currentPoll={currentPoll}
                    handleClassResultComplete={handleClassResultComplete}
                    websocketState={websocketState}
                    institutionId={institutionId}
                />
            } */}
            {state === QUIZ_STATE.USER_COMPLETE_POLL && !pollState.includes(POLL_STATE.SCHEDULED) &&
                <UserCompleteMergedPoll
                    stopped={false} 
                    pollInfo={pollInfo}
                    currentPoll={currentPoll}
                    institutionId={institutionId}
                    allowReattempt={allowReattempt}
                    handleReattempt={handleReattempt}
                    attemptsCount={attemptsCount}
                    maxAttempts={maxAttempts}
                    websocketState={websocketState}
                    pollTitle={pollTitle}
                    userName={userName}
                    handlePollComplete={handlePollComplete}
                    pollEndTime={currentPoll.pollEndTime}
                />
            }
            {state === QUIZ_STATE.CLASS_RESULT_ALL &&
                <UserCompleteMergedPoll
                    stopped={true} 
                    pollInfo={pollInfo}
                    currentPoll={currentPoll}
                    institutionId={institutionId}
                    allowReattempt={allowReattempt}
                    handleReattempt={handleReattempt}
                    attemptsCount={attemptsCount}
                    maxAttempts={maxAttempts}
                    websocketState={websocketState}
                    pollTitle={pollTitle}
                    userName={userName}
                    handlePollComplete={handlePollComplete}
                    pollEndTime={currentPoll.pollEndTime}
                />
            }
            {state === QUIZ_STATE.USER_COMPLETE_POLL && pollState.includes(POLL_STATE.SCHEDULED) &&
                <ClassResultAllScheduled
                    handlePollComplete={handlePollComplete}
                    pollEndTime={currentPoll.pollFinishTime}
                    allowReattempt={allowReattempt}
                    handleReattempt={handleReattempt}
                    attemptsCount={attemptsCount}
                    maxAttempts={maxAttempts}
                    isComplete={state === QUIZ_STATE.CLASS_RESULT_ALL}
                    websocketState={websocketState}
                    pollTitle={pollTitle}
                />
            }
            {/* {state === QUIZ_STATE.USER_COMPLETE_SURVEY &&
                <UserCompleteSurvey
                    stoppedSurvey={false} 
                    currentPoll={currentPoll}
                    institutionId={institutionId}
                    allowReattempt={allowReattempt}
                    handleReattempt={handleReattempt}
                    attemptsCount={attemptsCount}
                    maxAttempts={maxAttempts}
                    websocketState={websocketState}
                    pollTitle={pollTitle}
                    userName={userName}
                />
            } */}
            {/* {state === QUIZ_STATE.SURVEY_ALL_END &&
                < UserCompleteSurvey
                    stoppedSurvey={true} 
                    currentPoll={currentPoll}
                    institutionId={institutionId}
                    websocketState={websocketState}
                    allowReattempt={allowReattempt}
                    handleReattempt={handleReattempt}
                    attemptsCount={attemptsCount}
                    maxAttempts={maxAttempts}
                    pollTitle={pollTitle}
                    userName={userName}
                />
            } */}
            {state === QUIZ_STATE.CLOSED &&
                <EndedPollNew
                    pollTitle={pollTitle}
                    websocketState={websocketState}
                />
            }
            {state === QUIZ_STATE.ATTENDANCE_PAGE &&
                <AttendancePage
                    pollInfo={pollInfo}
                    currentQuestion={currentQuestion}
                    handleQuestionComplete={handleQuestionComplete}
                    state={state}
                    // setState={setState}
                    stopped={stopped}
                    setStopped={setStopped}
                    changeCurQue={changeCurQue}
                    websocketState={websocketState}
                    attendancePageState={attendancePageState}
                    setAttendancePageState={setAttendancePageState}
                    handleReset={handleReset}
                />
            }
        </>
    );
}
