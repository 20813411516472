import classes from "./Report.module.css";
import {Table} from "react-bootstrap";
import { ReactComponent as POLL_COUNT } from "./images/CountIconPoll.svg";
import { ReactComponent as ATTENDANCE_COUNT } from "./images/CountIconAttendance.svg";
import { ReactComponent as QUESTION_COUNT } from "./images/CountIconQuestion.svg";
import { ReactComponent as RESPONSE_COUNT } from "./images/CountIconResponse.svg";
import { ReactComponent as PARTICIPANT_COUNT } from "./images/CountIconParticipant.svg";
import { ReactComponent as NAN_COURSE } from "./images/NanCourseIcon.svg";
import YuJaButton from "../../components/standardization/YuJaButton";
import {
    REPORT_INSTITUTION_NAN_COURSE_LABEL_ATTENDANCE_COUNT,
    REPORT_INSTITUTION_NAN_COURSE_LABEL_PARTICIPANT_COUNT,
    REPORT_INSTITUTION_NAN_COURSE_LABEL_POLL_COUNT,
    REPORT_INSTITUTION_NAN_COURSE_LABEL_QUESTION_COUNT,
    REPORT_INSTITUTION_NAN_COURSE_LABEL_RESPONSE_COUNT,
    REPORT_INSTITUTION_NAN_COURSE_TABLE_HEADERS, REPORT_INSTITUTION_VIEW_NAN_COURSE_BUTTON,
    REPORT_INSTITUTION_VIEW_NAN_COURSE_DESC,
    REPORT_INSTITUTION_VIEW_NAN_COURSE_METRIC_SUFFIX,
    REPORT_INSTITUTION_VIEW_NAN_COURSE_TITLE
} from "../../utils/constants";
import {useHistory} from "react-router-dom";

export default function StandaloneTab({data={}, reportStartDate, reportEndDate }) {
    const {
        pollCount=0,
        attendanceCount=0,
        questionCount=0,
        responseCount=0,
        participantCount=0
    } = data;

    let history = useHistory();


    const onClickFullReport = () => {
        document.getElementsByTagName("main")[0].scrollTo(0, 0);
        history.push({
            pathname: "/report/standalone",
            state: { reportStartDate, reportEndDate}
        });
    }


    return (
        <>
            {(pollCount + attendanceCount) > 0 &&
                <div className={classes.viewNanCourseReportContainer}>
                    <div className={classes.viewNanCourseReportIconContainer}>
                        <NAN_COURSE/>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", gap: 15}}>
                        <div style={{display: "flex", gap: 10, alignItems: "center"}}>
                            <span className={classes.regularLabel} style={{fontWeight: "800"}} tabIndex={0}>{REPORT_INSTITUTION_VIEW_NAN_COURSE_TITLE}</span>
                            <span className={classes.viewNanCourseReportCountContainer} tabIndex={0}>
                                {(pollCount + attendanceCount).toLocaleString()} {REPORT_INSTITUTION_VIEW_NAN_COURSE_METRIC_SUFFIX}
                            </span>
                        </div>
                        <span className={classes.regularLabel} tabIndex={0}>{REPORT_INSTITUTION_VIEW_NAN_COURSE_DESC}</span>
                    </div>
                    <div style={{flex: 1}}/>
                    <YuJaButton onClick={onClickFullReport} tabIndex={0} wrapperStyle={{whiteSpace: "nowrap"}}>{REPORT_INSTITUTION_VIEW_NAN_COURSE_BUTTON}</YuJaButton>
                </div>
            }

            <div className={classes.tableContainer}>
                <Table hover responsive={true}>
                    <thead>
                        <tr>
                            <th width={200} tabIndex={0}>
                                <span className={classes.firstCell}>{REPORT_INSTITUTION_NAN_COURSE_TABLE_HEADERS.METRIC_NAME.description}</span>
                            </th>
                            <th width={50} tabIndex={0}>{REPORT_INSTITUTION_NAN_COURSE_TABLE_HEADERS.COUNT.description}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td tabIndex={0}>
                                <span className={classes.firstCell}>
                                    <POLL_COUNT/> {REPORT_INSTITUTION_NAN_COURSE_LABEL_POLL_COUNT}
                                </span>
                            </td>
                            <td tabIndex={0}>{pollCount}</td>
                        </tr>
                        <tr>
                            <td tabIndex={0}>
                                <span className={classes.firstCell}>
                                   <ATTENDANCE_COUNT/> {REPORT_INSTITUTION_NAN_COURSE_LABEL_ATTENDANCE_COUNT}
                                </span>
                            </td>
                            <td tabIndex={0}>{attendanceCount}</td>
                        </tr>
                        <tr>
                            <td tabIndex={0}>
                                <span className={classes.firstCell}>
                                    <QUESTION_COUNT/> {REPORT_INSTITUTION_NAN_COURSE_LABEL_QUESTION_COUNT}
                                </span>
                            </td>
                            <td tabIndex={0}>{questionCount}</td>
                        </tr>
                        <tr>
                            <td tabIndex={0}>
                                <span className={classes.firstCell}>
                                    <RESPONSE_COUNT/> {REPORT_INSTITUTION_NAN_COURSE_LABEL_RESPONSE_COUNT}
                                </span>
                            </td>
                            <td tabIndex={0}>{responseCount}</td>
                        </tr>
                        <tr>
                            <td tabIndex={0}>
                                <span className={classes.firstCell}>
                                    <PARTICIPANT_COUNT/> {REPORT_INSTITUTION_NAN_COURSE_LABEL_PARTICIPANT_COUNT}
                                </span>
                            </td>
                            <td tabIndex={0}>{participantCount}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </>
    );
}