import React, {useEffect, useState} from "react";
import { Draggable } from "react-beautiful-dnd";
import { Radio } from "../../components/radio/Radio";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import useMobileAccess from "../../hooks/useMobileAccess";
import CancelIcon from "../../images/cancel.svg";
import DragDropIcon from "../../images/drag_drop.svg";
import {
  CREATE_OPT_RADIAL,
  DELETE_OPT_BTN,
  MC_ANSWER_LENGTH,
  OPTION_DELETE_ALT_TEXT,
  OPTION_DELETE_TITLE,
  TEXTBOX,
  TEXT_BOX,
} from "../../utils/constants";
import {notifyError, notifyInfo} from "../../utils/helpers";
import { updateAnswers, updateCorrectAnswers } from "../../utils/questionUpdate";
import { addOptionToQuestion } from "../../utils/questionUtils";
import {
  ADD_OPTIONS_INFO,
  MC_CI_ANSWER_LENGTH_EXCEEDED
} from "../../utils/toast-message-constants";
import "../styles.css";
import {useImageLoaderManager} from "../../hooks/useImageLoaderManager";
import UploadOptionImage from "../UploadOptionImage";

export default function MCQuestionOption({
    pollKey,
    changePollKey,
    optionData,
    selectedQuestion,
    questions,
    setQuestions,
    questionBankId,
    index,
    pollType,
    maxSize,
    warningShowed,
    setWarningShowed,
    questionBuilder=false,
    disabled=false,
}) {

  const isNarrow = useMobileAccess(1100);
  const {weightage, optionsImageMap={}} = selectedQuestion;
  const gradeEnable = !!weightage;
  const [showDragIcon, setShowDragIcon] = useState(false);
  const [isDraggable, setIsDraggable] = useState(!questionBuilder || !disabled);
  const { choice, optionValue } = optionData;
  const { serialNo, optionsMap, correctAnswers } = selectedQuestion;
  const selectedQuestionIndex = serialNo - 1;



  const handleAnswerChange = (input, itemKey) => {
    setQuestions(
      updateAnswers(input, itemKey, selectedQuestionIndex, questions)
    );
  };
  const handleCorrectAnswerChange = (input) => {
    setQuestions(updateCorrectAnswers(input, selectedQuestionIndex, questions));
  };

  const handleDeleteOption = () => {
    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        let updatedQuestion = {...q};
        let newOptionsMap = {};
        Object.entries(q.optionsMap).forEach(([key, value]) => {
          if (key !== choice) {
            if (key > choice) {
              const newKey = String.fromCharCode(key.charCodeAt(0) - 1);
              newOptionsMap[newKey] = value;
            } else {
              newOptionsMap[key] = value;
            }
          }
        });

        updatedQuestion.optionsMap = newOptionsMap;
        updatedQuestion.orrectAnswers = choice === q.correctAnswers[0] ? [] : q.correctAnswers;


        if (!!q.optionsImageMap) {
          let newOptionsImageMap = {};
          Object.entries(q.optionsImageMap).forEach(([key, value]) => {
            if (key !== choice) {
              if (key > choice) {
                const newKey = String.fromCharCode(key.charCodeAt(0) - 1);
                newOptionsImageMap[newKey] = value;
              } else {
                newOptionsImageMap[key] = value;
              }
            }
          });
          updatedQuestion.optionsImageMap = newOptionsImageMap;
        }

        // updatedQuestion["image" + choice] = null;
        newQuestions.push(updatedQuestion);
      } else {
        newQuestions.push({ ...q });
      }
    });
    setQuestions(newQuestions);
  };

  const handlePaste = (e) => {
    if(e.target.value.length <= MC_ANSWER_LENGTH) {
      if (index + 1 === maxSize) {
        const newQuestions = addOptionToQuestion(
          selectedQuestion,
          questions
        );
        if (newQuestions) {
          setQuestions(newQuestions);
        } else if(!warningShowed) {
          setWarningShowed(true);
          notifyInfo(ADD_OPTIONS_INFO);
        }
      }
    }
    else {
      notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
    }
  }

  return (
    <Draggable
      draggableId={choice}
      index={index}
      key={choice}
      isDragDisabled={!isDraggable}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1}>
          <div
              style={{display: "flex", flex: 1, alignItems: "center", paddingTop: "5px"}}
              onMouseEnter={() => setShowDragIcon(true)}
              onMouseLeave={() => setShowDragIcon(false)}
          >
            <div
              {...provided.dragHandleProps}
              style={{width: (questionBuilder && disabled) ? 0 : 40,  display: "flex", justifyContent: "center", padding: 0}}
              title={(questionBuilder && disabled) ? "" : "Drag to Reorder"}
              tabIndex={-1}
            >
              {(!questionBuilder || !disabled) && showDragIcon && <img src={DragDropIcon} alt="drag drop icon" width={15} height={25}/>}
            </div>
            <Radio
              ariaLabel={CREATE_OPT_RADIAL + choice.toUpperCase()}
              text={choice.toUpperCase()}
              value={choice}
              //currently only accept radio buttons
              selected={correctAnswers[0]}
              onChange={(value) => {
                handleCorrectAnswerChange(value);
              }}
              index={index}
              pollType={pollType}
              transform={`translateX(${!gradeEnable ? 20 : questionBuilder ? 45 : 70}px)`}
              disabled={questionBuilder && disabled}
              gradeEnable={gradeEnable}
            />
            {gradeEnable &&
                <YuJaTextBox
                  disabled={questionBuilder && disabled}
                  label={TEXT_BOX + choice.toUpperCase() +  " " + (choice === correctAnswers[0] ? "selected" : "unselected")}
                  containerStyle={{ flex: 1, fontSize: isNarrow ? 18 : 20, marginLeft: questionBuilder ? 10 : 25, paddingLeft: questionBuilder ? 45: 55, padding: isNarrow || questionBuilder ? "8px 8px 8px 55px" : "12.5px 12.5px 12.5px 55px", border: "1.13px solid #858687"}}
                  placeholder={`Option ${choice.toUpperCase()}`}
                  value={optionValue}
                  name={"question-text"}
                  maxLength={MC_ANSWER_LENGTH}
                  onPaste={(e) => {handlePaste(e)}}
                  onChange={(e) => {
                    e.preventDefault();
                    if(e.target.value.length <= MC_ANSWER_LENGTH) {
                      handleAnswerChange(e.target.value, choice);
                      if (index + 1 === maxSize) {
                        const newQuestions = addOptionToQuestion(
                          selectedQuestion,
                          questions
                        );
                        if (newQuestions) {
                          setQuestions(newQuestions);
                          setTimeout(() => {
                            if (questionBuilder) {
                              const questionBuilderMain = document.querySelector(".questionBuilderMain");
                              questionBuilderMain?.scrollTo({ top: questionBuilderMain?.scrollHeight, behavior: "smooth" });
                            } else {
                              const mcqOptionsContainer = document.getElementById("mcq-options-container");
                              mcqOptionsContainer?.scroll({ top: mcqOptionsContainer?.scrollHeight, behavior: "smooth" });
                            }
                          }, 10);
                        } else if (!warningShowed) {
                          setWarningShowed(true);
                          notifyInfo(ADD_OPTIONS_INFO);
                        }
                      }
                    }
                    else {
                      notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                    }
                  }}
                  showRemaining={true}
                />
            }
            {!gradeEnable &&
                <YuJaTextBox
                  disabled={questionBuilder && disabled}
                  label={TEXT_BOX + choice.toUpperCase()}
                  containerStyle={{ width: "100%", fontSize: 20, marginLeft: 25, padding: "12.5px", border: "1.13px solid #858687"}}
                  placeholder={`Option ${choice.toUpperCase()}`}
                  value={optionValue}
                  name={"question-text"}
                  maxLength={MC_ANSWER_LENGTH}
                  onPaste={(e) => {handlePaste(e)}}
                  onChange={(e) => {
                    e.preventDefault();
                    if(e.target.value.length <= MC_ANSWER_LENGTH) {
                      handleAnswerChange(e.target.value, choice);
                      if (index + 1 === maxSize) {
                        const newQuestions = addOptionToQuestion(
                          selectedQuestion,
                          questions
                        );
                        if (newQuestions) {
                          setQuestions(newQuestions);
                          setTimeout(() => {
                            const mcqOptionsContainer = document.getElementById("mcq-options-container");
                            mcqOptionsContainer?.scroll({ top: mcqOptionsContainer.scrollHeight, behavior: "smooth" });
                          }, 10);
                        } else if (!warningShowed) {
                          setWarningShowed(true);
                          notifyInfo(ADD_OPTIONS_INFO);
                        }
                      }
                    }
                    else {
                      notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                    }
                  }}
                  showRemaining={true}
              />
            }
            <div style={{display: "flex", gap: questionBuilder ? 5 : 10, alignItems: "center", paddingLeft: questionBuilder ? 5 : 10}}>
              <UploadOptionImage
                  optionsImageMap={optionsImageMap}
                  pollKey={pollKey}
                  changePollKey={changePollKey}
                  questionBuilder={questionBuilder}
                  disabled={disabled}
                  questions={questions}
                  setQuestions={setQuestions}
                  selectedQuestion={selectedQuestion}
                  optionData={optionData}
                  questionBankId={questionBankId}
                  setIsDraggable={setIsDraggable}
              />

              {(!questionBuilder || !disabled) && Object.keys(optionsMap).length > 1 &&
                  <div style={{width : questionBuilder ? 20 : 30}}>
                    <img
                        aria-label={DELETE_OPT_BTN + choice.toUpperCase() + " button " + (choice === correctAnswers[0] ? "selected" : "unselected")}
                        tabIndex={0}
                        role={TEXTBOX}
                        onClick={handleDeleteOption}
                        src={CancelIcon}
                        alt={OPTION_DELETE_ALT_TEXT}
                        title={OPTION_DELETE_TITLE}
                        className="cursor-pointer"
                        width={questionBuilder ? 20 : 30}
                        height={questionBuilder ? 20 : 30}
                    />
                  </div>
              }
            </div>


          </div>

          {/* {selectedImage && (
            <Row>
              <Col xs={9} className="margin-vertical-auto">
                <div>
                  <div style={{ position: "relative" }} className="float-left">
                    <img alt="uploaded" src={selectedImage} />

                    <label htmlFor={uploadImageId + "2"}>
                      <img
                        src={ReuploadImageIcon}
                        alt="re-upload icon"
                        className="questions-page-icons cursor-pointer"
                        style={{
                          width: "32.98px",
                          position: "absolute",
                          right: "60px",
                          bottom: "10px",
                        }}
                      />
                    </label>
                    <input
                      id={uploadImageId + "2"}
                      type="file"
                      value=""
                      onChange={async (event) =>
                        resizeAndHandleImageChange(event)
                      }
                    />

                    <img
                      onClick={() => {handleAnswerImageChange(null, choice);}}
                      src={DeleteImageIcon}
                      alt="delete updated icon"
                      className="questions-page-icons cursor-pointer"
                      style={{
                        width: "34.98px",
                        position: "absolute",
                        right: "10px",
                        bottom: "8.5px",
                      }}
                    />
                  </div>

                  <div className="float-left">
                    <img
                      onClick={() => {
                        handleAnswerImageChange(null, choice);
                      }}
                      src={CancelIcon}
                      alt="delete icon"
                      className="questions-page-icons cursor-pointer no-margin"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          )} */}
        </div>
      )}
    </Draggable>
  );
}
