import React, { useEffect, useRef, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Lottie from "react-lottie";
import { useHistory, useParams } from "react-router-dom";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import ActivityOptionsModal from "../../components/modals/ActivityOptionsModal";
import AddPollTimeModal from "../../components/modals/AddPollTimeModal";
import EditDateModal from "../../components/modals/EditDateModal";
import PollTimeLimitModal from "../../components/modals/PollTimeLimitModal";
import ReleasePollModal from "../../components/modals/ReleasePollModal";
import ReleaseQuestionModal from "../../components/modals/ReleaseQuestionModal";
import ScheduleModal from "../../components/modals/ScheduleModal";
import { useClassResultsAnalysisManager } from "../../hooks/useClassResultsAnalysisManager";
import { useJoinQuizWebsocketManager } from "../../hooks/useJoinQuizWebsocketManager";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as NoResult } from "../../images/NoActivityFound.svg";
import { ReactComponent as ShareIcon } from "../../images/SHARE_ICON.svg";
import { ReactComponent as StopIcon } from "../../images/STOP_POLL_ICON.svg";
import { ReactComponent as Timer } from "../../images/green_timer.svg";
import { ReactComponent as Megaphone } from "../../images/megaphone.svg";
import { ReactComponent as RedTimer } from "../../images/red_timer.svg";
import {
    ADD_TIME_ALL_SUCCESS_MESSAGE,
    ADD_TIME_EACH_SUCCESS_MESSAGE,
    ANALYTICS,
    ATTENDANCE,
    ATTENDANCE_END_TITLE,
    ATTENTION_TEXT,
    AUDIENCE_RESTRICTION,
    CLOSE,
    DEFAULT_ATTENDANCE_MANUAL_END,
    DEFAULT_ATTENDANCE_SAVED_LOCATIONS,
    DEFAULT_ATTENDANCE_TIME_LIMIT,
    DEFAULT_ENTRIES_PER_PARTICIPANT,
    DEFAULT_POINTS,
    DEFAULT_POLL_LMS_SYNC,
    DEFAULT_POLL_MAX_ATTEMPTS,
    DEFAULT_POLL_RECORD_ATTEMPTS,
    DEFAULT_POLL_REQUIRED_NAME,
    DEFAULT_POLL_SHOW_CORRECT_ANS,
    DEFAULT_POLL_TIME_LIMIT,
    DEFAULT_TIME_LIMIT,
    DEPRECATED_POLL_MESSAGE,
    DEPRECATED_POLL_TITLE,
    EDIT_QUESTION,
    GRADED_ADD_TIME_OPTION_TEXT,
    GRADED_ADD_TIME_TEXT,
    MERGED_MODE,
    NO_ACCESS_TO_POLL_ERROR,
    OPEN,
    POLL_CODE_PREFIX,
    POLL_SHARE_MODE,
    POLL_STATE,
    POLL_TEXT,
    POLL_TYPE,
    QUESTION,
    QUESTION_BUILDER,
    QUESTION_TYPES,
    RELEASE,
    RELEASE_POLL_SURVEY_ERROR,
    SCHEDULE_POLL_EXPIRED_TITLE_END,
    SCHEDULE_POLL_EXPIRED_TITLE_MID,
    SCHEDULE_POLL_EXPIRED_TITLE_START,
    SCHEDULE_POLL_FUTURE_TITLE_PREFIX,
    SCHEDULE_POLL_LIVE_TITLE,
    STOP,
    TEXTBOX,
    UPDATE_RELEASE_SCHEDULE,
    UPDATE_RELEASE_SCHEDULE_PREFIX,
    USER_SETTINGS,
    WEBSOCKET_CHECK_HEARTBEAT_INTERVAL,
    WEBSOCKET_INSTRUCTOR,
    WEBSOCKET_MAX_TOLERANCE_HEARTBEAT_GAP,
    WEBSOCKET_MESSAGE_REMOVE_CONNECTIONS_ACTION,
    serialNo_TEXT
} from "../../utils/constants";
import {
    convertTime,
    fillFormatText,
    getDefaultUserSettings,
    getInstitutionId,
    getPollShareModeFromPollState,
    getShortName,
    notifyError,
    notifySuccess, removePrefix,
} from "../../utils/helpers";
import { WSS_BASE_URL } from "../../utils/properties";
import { checkSameQuestions, questionError, trimQuestion } from "../../utils/questionUtils";
import {
    EDIT_ATTENDANCE_SUCCESS,
    EDIT_QUESTION_SUCCESS,
    INVALID_SCHEDULE_END_DATE_ERROR,
    INVALID_SCHEDULE_END_TIME_ERROR,
    INVALID_SCHEDULE_START_TIME_ERROR,
    INVALID_SURVEY_END_TIME_ERROR,
    INVALID_TIME_LIMIT_ERROR,
    MISSING_SCHEDULE_START_END_TIME_ERROR,
    PAST_NOTIFICATION_ERROR,
    POLL_TIME_LIMIT_MIN_ERROR,
    SAVE_AND_SHARE_ERROR,
    SCHEDULE_POLL_START_END_GAP_ERROR
} from "../../utils/toast-message-constants";
import QuestionBuilder from "./QuestionBuilder";
import ViewActivityFeed from "./ViewActivityFeed";
import ViewBrowserInfo from "./ViewBrowserInfo";
import ViewParticipants from "./ViewParticipants";
import ViewPollCode from "./ViewPollCode";
import ViewQuestions from "./ViewQuestions";
import ViewResponses from "./ViewResponses";
import Clock from "./animations/Green_Timer.json";
import RedClock from "./animations/Red_Timer.json";
import "./styles.css";

export default function SharePollPage({ setPageIdentifier }) {
    const { pollKey, pollCode } = useParams();
    const isNarrow = useMobileAccess(1200);
    const [collapsed, setCollapsed] = useState(isNarrow ? true : false);
    const [currentView, setCurrentView] = useState(0);
    const [defaultView, setDefaultView] = useState(true);
    const [geofence, setGeofence] = useState(null);
    const [setLocalGeofence, getLocalGeofence] = useLocalStorageNew("geofence", {});
    const [setLocalSyncLms, getLocalSyncLms] = useLocalStorageNew("syncLms", {});
    const [syncLms, setSyncLms] = useState(null);
    const [mapInitialized, setMapInitialized] = useState(false);
    const [firstAdd, setFirstAdd] = useState(true);
    const { getPublishedPollDataFromKey, publishPoll, shareQuestion, shareAllQuestions, stopQuestion, stopAllQuestions, stopScheduledPoll, updatePollDetails, updateQuestionDetails, editPublishedPollDataFromKey } = PollApiCalls();
    const [pollData, setPollData] = useState({});
    const [pollDataBuilder, setPollDataBuilder] = useState({});
    const [isSharing, setIsSharing] = useState(false);
    const [viewQuestionDefaultView, setViewQuestionDefaultView] = useState(true);
    const countDownRef = useRef();
    const [queTimeLimit, setQueTimeLimit] = useState(0);
    const [queEnd, setQueEnd] = useState(0);
    const [pollEnd, setPollEnd] = useState(0);
    const [pollTimeLimit, setPollTimeLimit] = useState(0);
    const timeRemainingRef = useRef(0);
    const [localState, setLocalState] = useState("START POLL");
    const [, getTimeOffset] = useLocalStorageNew("timeOffset", 0);
    const [, getSession] = useLocalStorageNew("session", {});
    const [, getHostResource] = useLocalStorageNew(window.location.hostname, {});
    const session = getSession();
    const [userSettings, setUserSettings] = useState(getDefaultUserSettings());
    const [sharingAll, setSharingAll] = useState(false);
    const { getCourseByUser } = CourseApiCalls();
    const [courseList, setCourseList] = useState([]);
    const [loadingCourses, setLoadingCourses] = useState(true);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);
    const [loadingParticipants, setLoadingParticipants] = useState(true);
    const [initializedParticipants, setInitializedParticipants] = useState(false);
    const [loadingResponses, setLoadingResponses] = useState(true);
    const [initializedResponses, setInitalizedResponses] = useState(false);

    const [selectedQuestion, setSelectedQuestion] = useState(false);

    const [liveResponsesData, setLiveResponsesData] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const {getActiveStudentsInPoll, getResponsesDataByPoll} = ResponseApiCalls();
    const [hasResponses, setHasResponses] = useState(false);
    const [points, setPoints] = useState([]);
    const [activeStudents, setActiveStudents] = useState({});
    const [sharedQuestionNo, setSharedQuestionNo] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const [shareAll, setShareAll] = useState("");
    const [resetData, setResetData] = useState(0);
    const [_, setInLMS] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [showAnswer, setShowAnswer] = useState(false);
    const [invalidPoll, setInvalidPoll] = useState(false);


    const [pollType, setPollType] = useState("");
    const [, setLivePollTime] = useState(0);
    const [, setInstitutionId] = useState("");
    const history = useHistory();
    const ref = useRef(null);

    const [dateModalShow, setDateModalShow] = useState(false);
    const [disabledReleasePoll, setDisabledReleasePoll] = useState(false);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [showPollTimeLimitModal, setShowPollTimeLimitModal] = useState(false);
    const [showPollOptionsModal, setShowPollOptionsModal] = useState(false);
    const [showReleaseQuestionModal, setShowReleaseQuestionModal] = useState(false);
    const [showReleasePollModal, setShowReleasePollModal] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [attendanceEndTime, setAttendanceEndtime] = useState("");
    const [showGradedMessage, setShowGradedMessage] = useState(false);
    const [showAddTimeModal, setShowAddTimeModal] = useState(false);
    const shareAllAddTimeOptions = ["30 Secs", "1 Min", "5 Mins", "10 Mins", "20 Mins", "30 Mins"];
    const shareAllAddTimeToastDisplay = shareAllAddTimeOptions.map((time) => fillFormatText(ADD_TIME_ALL_SUCCESS_MESSAGE, {Time: time.replace("Min", "minute").replace("Sec", "second")}));
    const shareAllAddTimeValues = [30, 60, 300, 600, 1200, 1800]; 
    const shareEachAddTimeOptions = ["15 Secs", "30 Secs", "1 Min", "5 Mins", "10 Mins", "20 Mins"];
    const shareEachAddTimeToastDisplay = shareEachAddTimeOptions.map((time) => fillFormatText(ADD_TIME_EACH_SUCCESS_MESSAGE, {Time: time.replace("Min", "minute").replace("Sec", "second")}));
    const shareEachAddTimeValues = [15, 30, 60, 300, 600, 1200];

    const {
        responsesAnalysisData,
        participantsAnalysisData,
        participantsAllAnalysisData,
        browserAnalysisData,
        responseCountData,
        reactionCountData,
    } = useClassResultsAnalysisManager(pollData, liveResponsesData);

    // const websocketRef = useRef(null);

    const handleShare = async (checkEdited=true) => {
        if (checkEdited &&
            (!checkSameQuestions(pollData.questions, pollDataBuilder.questions, currentQuestion) ||
                (pollType === POLL_TYPE.ATTENDANCE &&
                    (getLocalSyncLms() !== syncLms || JSON.stringify(getLocalGeofence()) !== JSON.stringify(geofence))))) {
            const {quesError, elemIndex} = questionError(pollDataBuilder.questions[currentQuestion - 1], pollType);
            if (quesError) {
                notifyError(quesError);
                return;
            }
            setShowReleaseQuestionModal(true);
            return;
        }
        if (pollType === POLL_TYPE.ATTENDANCE) {
            setShowPollTimeLimitModal(true);
            return;
        }
        if (shareAll === POLL_SHARE_MODE.UNKNOWN) {
            await updatePollDetails(pollKey, pollCode, {pollShareMode: POLL_SHARE_MODE.SHARE_EACH}, true);
        }
        let res = await shareQuestion(pollKey, pollCode, currentQuestion, pollType);
        setMapInitialized(false);
        if (shareAll === POLL_SHARE_MODE.UNKNOWN) {
            setResetData(resetData+1);
        }
        setSharingAll(false);
        let quesTimeLimit = pollData.questions[currentQuestion-1].timeLimit;
        setQueTimeLimit(quesTimeLimit);
        setQueEnd(res.liveQuestionTime + parseInt(getTimeOffset()) + quesTimeLimit*1000);
        setSharedQuestionNo(currentQuestion);
        setLocalState('GET_READY');
        countDownRef.current.start();
    };

    const onClickShareAll = async (checkEdited=true) => {
        if (checkEdited && !checkSameQuestions(pollData.questions, pollDataBuilder.questions)) {
            for (let i = 0; i < pollDataBuilder.questions.length; i++) {
                const {quesError, elemIndex} = questionError(pollDataBuilder.questions[i], pollType);
                if (quesError) {
                    if (currentQuestion !== i + 1) {
                        setCurrentQuestion(i + 1);
                    }
                    if (!selectedQuestion) {
                        setSelectedQuestion(true);
                    }
                    notifyError(quesError);
                    return;
                }
            }
            setShowReleasePollModal(true);
            return;
        }
        if (pollType === POLL_TYPE.MERGED_POLL && shareAll === POLL_SHARE_MODE.UNKNOWN) {
            setShowScheduleModal(true);
        } else {
            setShowPollTimeLimitModal(true);
        }
    };

    const handleUpdateQuestion = async (updatedQuestion) => {
        const pollDataCopy = JSON.parse(JSON.stringify(pollData));
        const pollDataBuilderCopy = JSON.parse(JSON.stringify(pollDataBuilder));
        if (pollType === POLL_TYPE.ATTENDANCE) {
            let pollDetailsToUpdate = {};
            if (getLocalSyncLms() !== syncLms) {
                pollDetailsToUpdate["syncLms"] = syncLms;
                setLocalSyncLms(syncLms);
            }
            if (JSON.stringify(getLocalGeofence()) !== JSON.stringify(geofence)) {
                pollDetailsToUpdate["geofence"] = geofence;
                setLocalGeofence(geofence);
            }
            if (JSON.stringify(pollDetailsToUpdate) !== "{}") {
                await updatePollDetails(pollKey, pollCode, pollDetailsToUpdate, true);
                pollDataCopy.geofence = geofence;
                pollDataCopy.syncLms = syncLms;
                pollDataBuilderCopy.geofence = geofence;
                pollDataBuilderCopy.syncLms = syncLms;
            }
        }
        if (pollType !== POLL_TYPE.ATTENDANCE || !checkSameQuestions(pollDataCopy.questions, updatedQuestion ? updatedQuestion :  pollDataBuilderCopy.questions, currentQuestion)) {
            const trimmedQs = trimQuestion(updatedQuestion ? updatedQuestion : pollDataBuilder.questions);
            const res = await updateQuestionDetails(pollKey, pollCode, currentQuestion, trimmedQs[currentQuestion - 1]);
            if (!res) {
                return false;
            }
            pollDataCopy.questions[currentQuestion - 1] = JSON.parse(JSON.stringify(trimmedQs[currentQuestion - 1]));
            pollDataBuilderCopy.questions[currentQuestion - 1] = JSON.parse(JSON.stringify(trimmedQs[currentQuestion - 1]));
        }
        setPollDataBuilder(pollDataBuilderCopy);
        setPollData(pollDataCopy);
        notifySuccess(pollType === POLL_TYPE.ATTENDANCE ? EDIT_ATTENDANCE_SUCCESS : EDIT_QUESTION_SUCCESS);
        return true;
        //notifySuccess(EDIT_QUESTION_SUCCESS);
    };
    
    const handleSaveandShare = async () => {
        const res = await handleUpdateQuestion();
        if (!res) {
            notifyError(SAVE_AND_SHARE_ERROR.replace(serialNo_TEXT, `${currentQuestion}`));
            setShowReleaseQuestionModal(false);
            return;
        }

        handleShare(false);
        setShowReleaseQuestionModal(false);
    };
    
    const handleSaveandShareAll = async () => {
        const pollDataCopy = JSON.parse(JSON.stringify(pollData));
        const pollDataBuilderCopy = JSON.parse(JSON.stringify(pollDataBuilder));

        const trimmedQs = trimQuestion(pollDataBuilder.questions);
        for (let i = 0; i < pollDataBuilder.questions.length; i++) {
            if (!checkSameQuestions(pollData.questions[i], pollDataBuilder.questions[i])) {
                const res = await updateQuestionDetails(pollKey, pollCode, i + 1, trimmedQs[i]);
                if (!res) {
                    notifyError(SAVE_AND_SHARE_ERROR.replace(serialNo_TEXT, `${i + 1}`));
                    setShowReleasePollModal(false);
                    return;
                }
            }
        }
        pollDataCopy.questions = trimmedQs;
        pollDataBuilderCopy.questions = trimmedQs;
        setPollData(pollDataCopy);
        setPollDataBuilder(pollDataBuilderCopy);
        setShowReleasePollModal(false);
        onClickShareAll(false);
    };

    const handleSubmitPollTimeLimit = async (pTimeLimit, selected=true) => {
        if(pollType === POLL_TYPE.MERGED_POLL) {
            if (pTimeLimit < 60) {
                notifyError(POLL_TIME_LIMIT_MIN_ERROR);
                return;
            }
            // if (pTimeLimit !== pollData.poll.pollTimeLimit) {
            //     await updatePollDetails(pollKey, pollCode, {pollTimeLimit: pTimeLimit});
            //     setResetData(resetData+1);
            // }
            setDisabledReleasePoll(true);
            await handleShareAll(pTimeLimit);
            setDisabledReleasePoll(false);
            setShowPollTimeLimitModal(false);
        } else if (!selected) {
            if (!pTimeLimit || (pTimeLimit.getTime() < new Date().getTime() + 300 * 1000)) {
                notifyError(INVALID_SURVEY_END_TIME_ERROR); 
                return ; 
            }
            setShowPollTimeLimitModal(false);
            if (pollType === POLL_TYPE.ATTENDANCE) {
                await handleOpen(pTimeLimit);
            }
        } else {
            setShowPollTimeLimitModal(false);
            if (pollType === POLL_TYPE.ATTENDANCE) {
                await handleOpen();
            }
        }
    };

    const handleSubmitSchedule = async (props) => {
        // console.log(props);
        const {shareMode, timeLimit, startDate, endDate, notificationTime} = {...props};
        if (shareMode === POLL_SHARE_MODE.SCHEDULE) {
            let pollToAdd = { ...pollData };
            if (!pollToAdd.getReadyAllTimeLimit) {
                pollToAdd.getReadyAllTimeLimit = userSettings[USER_SETTINGS.POLL_COUNTDOWN];
            }
            if (!pollToAdd.lmsAttempt) {
                pollToAdd.lmsAttempt = userSettings[USER_SETTINGS.POLL_LMS_SYNC];
            }
            if (!pollToAdd.recordAttempt) {
                pollToAdd.recordAttempt = userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS];
            }
            if (!pollToAdd.maxAttempts) {
                pollToAdd.maxAttempts = userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS];
            }
            // if (!pollToAdd.anonymousJoin) {
            //     pollToAdd.anonymousJoin = userSettings[USER_SETTINGS.POLL_REQUIRED_NAME];
            // }
            if (!pollToAdd.audienceRestriction) {
                pollToAdd.audienceRestriction = userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED;
            }
            if (!pollToAdd.showAnswerOnViewer) {
                pollToAdd.showAnswerOnViewer = userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS];
            }
            pollToAdd.pollKey = removePrefix(pollKey);
            pollToAdd.pollType = pollType;
            pollToAdd.pollShareMode = POLL_SHARE_MODE.SCHEDULE;
            if (!startDate && !endDate) {
                notifyError(MISSING_SCHEDULE_START_END_TIME_ERROR);
                return;
            }
            if (endDate < startDate) {
                notifyError(INVALID_SCHEDULE_END_DATE_ERROR);
                return;
            }
            if (endDate < new Date()) {
                notifyError(INVALID_SCHEDULE_END_TIME_ERROR);
                return;
            }
            if (startDate < new Date()) {
                notifyError(INVALID_SCHEDULE_START_TIME_ERROR);
                return;
            }
            if (endDate > startDate.getTime() + 30 * 24 * 60 * 60 * 1000) {
                notifyError(SCHEDULE_POLL_START_END_GAP_ERROR);
                return;
            }
            pollToAdd.startTime = startDate;
            pollToAdd.endTime = endDate;
            if ((endDate - startDate) / 1000 < timeLimit) {
                notifyError(INVALID_TIME_LIMIT_ERROR);
                return;
            }
            pollToAdd.pollTimeLimit = timeLimit;
            const timeToStart = startDate - new Date();
            if (notificationTime && timeToStart < notificationTime * 1000) {
                notifyError(PAST_NOTIFICATION_ERROR);
                return;
            }
            pollToAdd.reminderTime = notificationTime ? notificationTime : -1;
            pollToAdd.shortName = getShortName();
            // console.log(pollToAdd);
            await publishPoll(pollToAdd);
            setResetData(resetData+1);
        } else { // shareAll
            if (timeLimit < 60) {
                notifyError(POLL_TIME_LIMIT_MIN_ERROR);
                return;
            }
            // if (pTimeLimit !== pollData.poll.pollTimeLimit) {
            //     await updatePollDetails(pollKey, pollCode, {pollTimeLimit: pTimeLimit});
            //     setResetData(resetData+1);
            // }
            setDisabledReleasePoll(true);
            await handleShareAll(timeLimit);
            setDisabledReleasePoll(false);
            setShowPollTimeLimitModal(false);
        }
        setShowScheduleModal(false);
    };

    const handleShareAll = async (pTimeLimit=undefined) => {
        if (shareAll === POLL_SHARE_MODE.UNKNOWN || (pTimeLimit && pTimeLimit !== pollData.poll.pollTimeLimit)) {
            const pollDetails = {};
            pollDetails.pollShareMode = POLL_SHARE_MODE.SHARE_ALL;
            if (pTimeLimit && pTimeLimit !== pollData.poll.pollTimeLimit) {
                pollDetails.pollTimeLimit = pTimeLimit;
            }
            await updatePollDetails(pollKey, pollCode, pollDetails, true);
        }
        let res = await shareAllQuestions(pollKey, pollCode);
        if (shareAll === POLL_SHARE_MODE.UNKNOWN || (pTimeLimit && pTimeLimit !== pollData.poll.pollTimeLimit)) {
            setSharingAll(true);
            setLocalState('GET_READY_ALL');
            setResetData(resetData+1);
        }
        else {
            setPollEnd(res.liveQuestionTime + (parseInt(getTimeOffset()) + pTimeLimit ? pTimeLimit : pollTimeLimit) * 1000);
            setSharingAll(true);
            setLocalState('GET_READY_ALL');
            countDownRef.current.start();
        }
    };

    const handleOpen = async (endDate=null) => {
        let timeLimit = endDate ? (new Date(endDate).getTime() - new Date().getTime())/1000 : null; // no get ready time limit for attendance

        // const pollDetails = {};
        // pollDetails.pollShareMode = POLL_SHARE_MODE.SHARE_EACH;
        // pollDetails.endTime = endDate ? endDate : "";
        // await updatePollDetails(pollKey, pollCode, pollDetails, true);

        if (endDate && (shareAll === POLL_SHARE_MODE.UNKNOWN || (timeLimit && timeLimit !== pollData.questions[currentQuestion-1].timeLimit))) {
            const pollDetails = {};
            pollDetails.pollShareMode = POLL_SHARE_MODE.SHARE_EACH;
            pollDetails.endTime = endDate; 
            await updatePollDetails(pollKey, pollCode, pollDetails, true);
            if (timeLimit && timeLimit !== pollData.questions[currentQuestion-1].timeLimit) {
                await updateQuestionDetails(pollKey, pollCode, currentQuestion, {timeLimit: timeLimit});
            }
        } else if (!endDate) {
            const pollDetails = {};
            pollDetails.pollShareMode = POLL_SHARE_MODE.SHARE_EACH;
            pollDetails.endTime = "";
            await updatePollDetails(pollKey, pollCode, pollDetails, true);
        }

        setAttendanceEndtime(endDate);
        let res = await shareQuestion(pollKey, pollCode, currentQuestion, pollType);
        setMapInitialized(false);
        setSharedQuestionNo(currentQuestion);
        if (shareAll === POLL_SHARE_MODE.UNKNOWN || (timeLimit && timeLimit !== pollData.questions[currentQuestion-1].timeLimit)) {
            setSharingAll(false);
            setLocalState('SHARED');
            setResetData(resetData+1);
        } else {
            let quesTimeLimit = timeLimit ? timeLimit : pollData.questions[currentQuestion-1].timeLimit;
            setQueTimeLimit(quesTimeLimit);
            setQueEnd(res.liveQuestionTime + parseInt(getTimeOffset()) + quesTimeLimit*1000);
            setSharingAll(false);
            setLocalState('SHARED');
            countDownRef.current.start();
        }
    };

    const handleStop = async () => {
        console.log("stop");
        await stopQuestion(pollKey, pollCode, sharedQuestionNo, pollType);
        setLocalState('TIMEUP');
        setMapInitialized(false);
        setTimeout(() => {
            setLocalState("START POLL");
        }, 2000);
    };

    const handleStopAll = async () => {
        await stopAllQuestions(pollKey, pollCode);
        setLocalState('TIMEUP_ALL');
        setTimeout(() => {
            setLocalState("START POLL");
        }, 2000);
    };

    const handleStopScheduled = async () => {
        await stopScheduledPoll(pollKey, pollCode);
        const copy = JSON.parse(JSON.stringify(pollData));
        copy.poll.pollState = POLL_STATE.STOPPED_SCHEDULED;
        setPollData(copy);
    };


    const updateAllInfo = () => {
        getResponsesDataByPoll(pollKey, pollCode).then(res => {
           setLiveResponsesData(res);
        }).finally(() => {
            setLoadingResponses(false);
            setInitalizedResponses(true);
            setLoadingParticipants(false);
            setInitializedParticipants(true);
        });
        // getResultByGradeBookPerspective(pollKey, pollCode).then(res => {
        //     handleUserInfoUpdate(res.gradeBookBreakdownResult.responses);
        // });
        // handleEngagementData();
        // handleFetchAllRespones(shareAll, pollData.questions);
        // if (shareAll === POLL_SHARE_MODE.SHARE_ALL || shareAll === POLL_SHARE_MODE.SCHEDULE) {
        //     getAudienceResponseByPoll(pollKey, pollCode, pollData.questions.length).then(resp => {
        //         if (JSON.stringify(resp) === JSON.stringify(getRespData())) return;
        //         setRespData(resp);
        //         setLoadingParticipants(true);
        //         setInitalizedParticipants(false);
        //         setResponseData(resp);
        //     }).finally( () => {
        //         setLoadingParticipants(false);
        //         setInitalizedParticipants(true);
        //     });
        // }
    };

    useEffect(() => {
        setFirstLoad(true);
    }, []);

    const getWebSocketUrl = () => {
        let sessionUserName = getSession().userName ? getSession().userName : "";
        let sessionUserId = getSession().userId ? getSession().userId : "";
        let institutionId = getHostResource().institutionId;
        let url = `${WSS_BASE_URL}?pollKey=${pollKey}&pollCode=${pollCode}&userName=${sessionUserName}&userId=${sessionUserId}&role=${WEBSOCKET_INSTRUCTOR}&institutionId=${institutionId}`;
        return url;
    }

    const handleAfterWebsocketOpen = () => {
        getActiveStudentsInPoll(pollKey, pollCode).then(resp => {
            const {students=[]} = resp;
            const lastHeartbeat = Date.now();
            resp.students = students.map(i => {
                i.lastHeartbeat = lastHeartbeat;
                return i;
            });
            setActiveStudents(resp);
        });
    }


    const {websocketState, websocketMsg, buildConnection, closeConnection, sendMsg} = useJoinQuizWebsocketManager(getWebSocketUrl, handleAfterWebsocketOpen);

    useEffect(() => {
        buildConnection();

        return () => {
            closeConnection();
        }
    }, []);

    //check inactive websocket connections
    useEffect(() => {
        const interval = setInterval( () => {
            const activeStudentsCopy = JSON.parse(JSON.stringify(activeStudents));
            const {students: studentsCopy=[]} = activeStudentsCopy;
            const now = Date.now();
            const removedConnIds = [];
            const newStudents = [];
            for (let stu of studentsCopy) {
                const {lastHeartbeat=0, sk=""} = stu;
                if (!!lastHeartbeat && now - lastHeartbeat > WEBSOCKET_MAX_TOLERANCE_HEARTBEAT_GAP) {
                    removedConnIds.push(removePrefix(sk));
                    continue;
                }

                if (!lastHeartbeat) {
                    stu.lastHeartbeat = now;
                }
                newStudents.push(stu);
            }

            if (removedConnIds.length > 0) {
                activeStudentsCopy.students = newStudents;
                setActiveStudents(activeStudentsCopy);
                sendMsg({action: WEBSOCKET_MESSAGE_REMOVE_CONNECTIONS_ACTION, connectionIds: removedConnIds});
            }

        }, WEBSOCKET_CHECK_HEARTBEAT_INTERVAL);

        return () => clearInterval(interval);
    }, [activeStudents]);


    useEffect(() => {
        if (websocketState === WebSocket.OPEN) {
            updateAllInfo();
        }

        let intervalId = 0;
        if (websocketState !== null && websocketState !== WebSocket.OPEN) {
            intervalId = setInterval(() => {
                updateAllInfo();
            }, 15000);
        }

        return () => {
            if (!!intervalId) {
                clearInterval(intervalId);
            }
        }

    }, [websocketState]);

    useEffect(() => {
        if(!!websocketMsg.eventMessage && websocketMsg.eventMessage === "connect") {
            const now = Date.now();
            let {student={}, userResponse={}} = websocketMsg;
            const copy = JSON.parse(JSON.stringify(activeStudents));
            const {students=[]} = copy;
            const existedStudent = students.find(i => i.sk === student.sk);
            if (!!existedStudent) {
                existedStudent.lastHeartbeat = now;
            } else {
                student.lastHeartbeat = now;
                students.push(student);
            }

            setActiveStudents(copy);

            let copyData = JSON.parse(JSON.stringify(liveResponsesData));
            if (Object.entries(userResponse).length > 0) {
                copyData = copyData.filter(r => r.pollKey === userResponse.pollKey && r.pollSortKey !== userResponse.pollSortKey);
                copyData.push(userResponse);
                setLiveResponsesData(copyData);
            }
        }
        else if(!!websocketMsg.eventMessage && websocketMsg.eventMessage === "disconnect") {
            let {student={}} = websocketMsg;
            const copy = JSON.parse(JSON.stringify(activeStudents));
            let {students=[]} = copy;
            students = students.filter(i => i.sk !== student.sk);
            copy.students = students;
            setActiveStudents(copy);
        }
        else if(!!websocketMsg.eventMessage && websocketMsg.eventMessage === "updateResponse") {
            let {questionResponses=[]} = websocketMsg;
            let copyData = JSON.parse(JSON.stringify(liveResponsesData));
            if (questionResponses.length === 0) {
                return;
            }

            const keySet = new Set(
                questionResponses.map(response => {
                    const {pollKey="", pollSortKey=""} = response;
                    return `${pollKey}-${pollSortKey}`;
                })
            );

            copyData = copyData.filter(
                ({ pollKey="", pollSortKey="" }) => !keySet.has(`${pollKey}-${pollSortKey}`)
            );

            for (const response of questionResponses) {
                copyData.push(response);
            }
            setLiveResponsesData(copyData);
        }
        else if (!!websocketMsg.eventMessage && websocketMsg.eventMessage === "updateReaction") {
            let {userResponse={}} = websocketMsg;
            let copyData = JSON.parse(JSON.stringify(liveResponsesData));
            copyData = copyData.filter(r => r.pollKey === userResponse.pollKey && r.pollSortKey !== userResponse.pollSortKey);
            copyData.push(userResponse);
            setLiveResponsesData(copyData);
        }
        else if (!!websocketMsg.eventMessage && websocketMsg.eventMessage === "heartbeat") {
            const now = Date.now();
            let {student={}} = websocketMsg;
            if (!student) {
                return;
            }
            const copy = JSON.parse(JSON.stringify(activeStudents));
            const {students=[]} = copy;
            const existedStudent = students.find(i => i.sk === student.sk);
            if (!!existedStudent) {
                existedStudent.lastHeartbeat = now;
            } else {
                student.lastHeartbeat = now;
                students.push(student);
            }

            setActiveStudents(copy);
        }
        else if (!!websocketMsg.eventMessage && websocketMsg.eventMessage === "reattemptPoll") {
            let {userResponse={}} = websocketMsg;
            if (Object.entries(userResponse).length === 0) {
                return;
            }
            let copyData = JSON.parse(JSON.stringify(liveResponsesData));
            copyData = copyData.filter(r => r.pollKey === userResponse.pollKey && r.pollSortKey !== userResponse.pollSortKey);
            copyData.push(userResponse);
            setLiveResponsesData(copyData);
        }
    }, [websocketMsg]);


    /* END OF WEBSOCKET LOGIC */

    useEffect(() => {
        if (currentQuestion > pollDataBuilder?.questions?.length) {
            return;
        }

        if(selectedQuestion && (localState === "START POLL" || localState === "TIMEUP" || localState === "TIMEUP_ALL")) {
            if(shareAll === POLL_SHARE_MODE.SHARE_ALL) {
                setPollEnd(Date.now() + pollTimeLimit*1000);
                setSharingAll(true);
            } else {
                let quesTimeLimit = pollDataBuilder.questions[currentQuestion-1]?.timeLimit ? pollDataBuilder.questions[currentQuestion-1].timeLimit : userSettings[USER_SETTINGS.QUESTION_DURATION];
                setQueTimeLimit(quesTimeLimit);
                setQueEnd(Date.now() + quesTimeLimit*1000);
                setSharingAll(false);
            }
        }
        if(!selectedQuestion && (localState === "START POLL" || localState === "TIMEUP" || localState === "TIMEUP_ALL")) {
            setPollEnd(Date.now() + pollTimeLimit*1000);
            setSharingAll(true);
        }

        // if(localState === "TIMEUP_ALL" && (shareAll === POLL_SHARE_MODE.SHARE_ALL || shareAll === POLL_SHARE_MODE.SCHEDULE)) {
        //     updateAllInfo();
        // }
    }, [pollDataBuilder, currentQuestion, selectedQuestion, localState]);

    useEffect(() => {
        if (!userSettings) return;
        let settings = userSettings;
        if (resetData === 0) {
            settings = session?.settings ? session.settings : {};
            setLoading(true);
            setInitialized(false);

            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION)) {
                settings[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
            } else {
                settings[USER_SETTINGS.QUESTION_DURATION] = parseInt(settings[USER_SETTINGS.QUESTION_DURATION], 10);
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS)) {
                settings[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
            } else {
                settings[USER_SETTINGS.QUESTION_POINTS] = parseInt(settings[USER_SETTINGS.QUESTION_POINTS], 10);
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_DURATION)) {
                settings[USER_SETTINGS.POLL_DURATION] = DEFAULT_POLL_TIME_LIMIT;
            } else {
                settings[USER_SETTINGS.POLL_DURATION] = parseInt(settings[USER_SETTINGS.POLL_DURATION], 10);
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_ENTRIES)) {
                settings[USER_SETTINGS.QUESTION_ENTRIES] = DEFAULT_ENTRIES_PER_PARTICIPANT;
            } else {
                settings[USER_SETTINGS.QUESTION_ENTRIES] = parseInt(settings[USER_SETTINGS.QUESTION_ENTRIES], 10);
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_REQUIRED_NAME)) {
                settings[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
            } else {
                settings[USER_SETTINGS.POLL_REQUIRED_NAME] = (settings[USER_SETTINGS.POLL_REQUIRED_NAME] === "true" || settings[USER_SETTINGS.POLL_REQUIRED_NAME] === true);
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_SHOW_CORRECT_ANS)) {
                settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = DEFAULT_POLL_SHOW_CORRECT_ANS;
            } else {
                settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = (settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === "true" || settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === true);
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_LMS_SYNC)) {
                settings[USER_SETTINGS.POLL_LMS_SYNC] = DEFAULT_POLL_LMS_SYNC;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_RECORD_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_MAX_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
            } else {
                settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = parseInt(settings[USER_SETTINGS.POLL_MAX_ATTEMPTS], 10);
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_MANUAL_END)) {
                settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = DEFAULT_ATTENDANCE_MANUAL_END;
            } else {
                settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = (settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === "true" || settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === true);
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_DURATION)) {
                settings[USER_SETTINGS.ATTENDANCE_DURATION] = DEFAULT_ATTENDANCE_TIME_LIMIT;
            } else {
                settings[USER_SETTINGS.ATTENDANCE_DURATION] = parseInt(settings[USER_SETTINGS.ATTENDANCE_DURATION], 10);
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS)) {
                settings[USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS] = DEFAULT_ATTENDANCE_SAVED_LOCATIONS;
            }
            setUserSettings(settings);
        }

        try {
        getPublishedPollDataFromKey(pollKey, pollCode).then(res => {
            const {poll, questions} = res;
            if (!poll || ! questions || poll.active == "2") {
                setInvalidPoll(true);
                return; 
            }
            if (poll?.pollType !== POLL_TYPE.MERGED_POLL && poll?.pollType !== POLL_TYPE.ATTENDANCE) {
                if (window.location === window.parent.location) { // not in LMS
                    notifyError(RELEASE_POLL_SURVEY_ERROR);
                    history.push("/poll/user");
                    return;
                }
                // LMS iframe
                history.push("/disabled/poll-survey", { fromReleasePage: true });
                return;
            }
            setInLMS(!!poll.lmsHomeUrl && (!poll.hasOwnProperty("syncLms") || poll.syncLms));
            setPollData(JSON.parse(JSON.stringify(res)));
            setPollDataBuilder(JSON.parse(JSON.stringify(res)));
            // Checks that user accessing poll has access to it (created it or was shared to them)
            if (poll.index1Pk.substring(2) !== getSession().userId) {
                notifyError(NO_ACCESS_TO_POLL_ERROR);
                history.push(`/poll/user/`);
            }
            const altSharedAll = getPollShareModeFromPollState(poll.pollState);
            let shareType = poll.pollShareMode ? poll.pollShareMode : (altSharedAll ?  altSharedAll : POLL_SHARE_MODE.SHARE_EACH);
            setShareAll(shareType);
            if (resetData === 0) {
                setLoading(false);
                setInitialized(true);
                setPollType(poll.pollType);
                if (poll.pollType === POLL_TYPE.ATTENDANCE) {
                    setLocalGeofence(poll.geofence);
                    setLocalSyncLms(poll.syncLms);
                    setGeofence(poll.geofence);
                    setSyncLms(poll.syncLms);
                }
            }
            setPageIdentifier(RELEASE + (poll.pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE : MERGED_MODE));
            const {liveQuestionNo = 0, pollState = '', liveQuestionTime = 0, pollTimeLimit = settings[USER_SETTINGS.POLL_COUNTDOWN], index5Pk} = poll;
            if (resetData === 0) {
                setSelectedQuestion(poll.pollType === POLL_TYPE.ATTENDANCE || poll.pollShareMode === POLL_SHARE_MODE.SHARE_EACH);
                setDefaultView(poll.pollType !== POLL_TYPE.ATTENDANCE && (poll.pollShareMode === POLL_SHARE_MODE.SHARE_ALL || (poll.pollShareMode === POLL_SHARE_MODE.SCHEDULE && new Date() > new Date(poll.startTime))));
            }
            setInstitutionId(getInstitutionId(index5Pk));

            setPollTimeLimit(pollTimeLimit);
            setPollEnd(Date.now() + pollTimeLimit*1000);

            let totalWeightage = 0;
            for (let question of questions) {
                if (!question?.weightage) continue; // skip if ungraded
                totalWeightage += question.weightage;
            }
            setTotalPoints(totalWeightage);

            //handleFetchAllRespones(shareType, questions);

            if (pollState === POLL_STATE.STOPPED || pollState === POLL_STATE.STOPPED_ALL) {
                setLocalState("START POLL");
            } else if (pollState === POLL_STATE.SHARED_ALL) {
                // if ((liveQuestionTime + (pollTimeLimit * 1000)) < Date.now() && poll.pollType.toUpperCase() !== POLL_TYPE.SURVEY) {
                //     return;
                // }
                let pollEndPoint= liveQuestionTime + pollTimeLimit * 1000;
                const currentTime = Date.now();

                if (liveQuestionTime > currentTime) {
                    setSharingAll(true);
                    setLivePollTime(liveQuestionTime);
                    setPollEnd(pollEndPoint);
                    setLocalState("GET_READY_ALL");
                    countDownRef.current?.start();
                    return;
                }

                if (pollEndPoint > currentTime) {
                    setSharingAll(true);
                    setLivePollTime(liveQuestionTime);
                    setPollEnd(pollEndPoint);
                    setLocalState("SHARED_ALL");
                    countDownRef.current?.start();
                    return;
                }
            } else if (pollState === POLL_STATE.SCHEDULED) {
                const currentTime = new Date();

                if (poll.startTime && poll.endTime && (new Date(poll.endTime) > currentTime && currentTime > new Date(poll.startTime) )) {
                    setSharingAll(true);
                    setLivePollTime(liveQuestionTime);
                    setPollEnd(new Date(poll.endTime).valueOf());
                    setLocalState("SHARED_ALL");
                    countDownRef.current?.start();
                    return;
                }

                if (poll.startTime && poll.endTime && (new Date(poll.endTime) < currentTime)) {
                    setLocalState("START POLL");
                    return;
                }
            } else {
                if (liveQuestionNo < 1) {
                    // handleFetchAllRespones(shareType, questions);
                    return;
                }

                // if ((liveQuestionTime + (questions[liveQuestionNo-1].timeLimit * 1000)) < Date.now() && poll.pollType.toUpperCase() !== POLL_TYPE.SURVEY) {
                //     return;
                // }
                const liveQue = questions[liveQuestionNo - 1];

                const { timeLimit = 0 } = liveQue;
                let queEndPoint = pollType === POLL_TYPE.ATTENDANCE ? attendanceEndTime : liveQuestionTime + timeLimit * 1000;
                const currentTime = Date.now();


                if (liveQuestionTime > currentTime) {
                    setSharedQuestionNo(liveQuestionNo);
                    if (resetData === 0) {
                        setCurrentQuestion(liveQuestionNo);
                    }
                    setQueTimeLimit(timeLimit);
                    setQueEnd(queEndPoint);
                    setLocalState("GET_READY");
                    countDownRef.current?.start();
                    return;
                }


                if (queEndPoint > currentTime || !timeLimit) {
                    setSharedQuestionNo(liveQuestionNo);
                    if (resetData === 0) {
                        setCurrentQuestion(liveQuestionNo);
                    }
                    setQueTimeLimit(timeLimit);
                    setQueEnd(queEndPoint);
                    setLocalState("SHARED");
                    countDownRef.current?.start();
                    return;
                }

                // untimed attendance
                if (poll.pollType === POLL_TYPE.ATTENDANCE
                        && !attendanceEndTime // untimed attendance
                        // && poll.hasOwnProperty("manualEnd") // ensure old attendances without manualEnd are not affected
                    ) {
                    setSharedQuestionNo(liveQuestionNo);
                    if (resetData === 0) {
                        setCurrentQuestion(liveQuestionNo);
                    }
                    setQueTimeLimit(timeLimit);
                    setQueEnd(queEndPoint);
                    setLocalState("SHARED");
                    countDownRef.current?.start();
                }
            }
        })
    } catch (e) {
        setInvalidPoll(true);
    }
    }, [resetData]);

    const checkShowAttendanceTimer = () => {
        let endTimeDate = new Date(attendanceEndTime).getTime();
        let currentDate = new Date().getTime();
        if (attendanceEndTime && endTimeDate > currentDate) {   
            return true;
        } else if(endTimeDate <= currentDate) {
            // setLocalState('START POLL');
            return false;
        } else {
            return false;
        }
    };

    const timeUp = () => {
        if ((pollType === POLL_TYPE.MERGED_POLL && !sharingAll && !pollData.questions[sharedQuestionNo - 1].timeLimit) || // untimed share each
            (pollType === POLL_TYPE.ATTENDANCE && !pollData.poll.endTime) // if attendance then no need to time up
        ) {
            setLocalState(sharingAll ? "SHARED_ALL" : "SHARED");
            return;
        }
        sharingAll ? setLocalState("TIMEUP_ALL") : setLocalState("TIMEUP");
        setTimeout(() => {
            setLocalState("START POLL");
        }, 2000);
    };

    const options = {
        loop: false,
        autoplay: true,
        animationData: Clock,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        }
      };

      const Redoptions = {
        loop: true,
        autoplay: true,
        animationData: RedClock,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        }
      };

    const renderer = ({ days, hours, minutes, seconds }) => {
        if ((localState !== 'GET_READY' && pollType === POLL_TYPE.MERGED_POLL && !sharingAll
                && !pollDataBuilder?.questions[(localState === 'SHARED' ? sharedQuestionNo : currentQuestion) - 1]?.timeLimit)
            || (pollType === POLL_TYPE.ATTENDANCE && !checkShowAttendanceTimer())
        ) {
            return null;
        }

        if (shareAll === POLL_SHARE_MODE.SCHEDULE) {
            return null;
        }



        if (localState === 'TIMEUP_ALL') {
            return (
                <span className="questionPanelQuestionTypeContainerTimer urgent">
                    <div className="pollPanelTimerText urgent" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><RedTimer style={{ marginRight: "8px", width: 30, height: 30 }} />00:00:00</div>
                </span>
            );
        }

        if (localState === 'TIMEUP') {
            return (
                <span className="questionPanelQuestionTypeContainerTimer urgent">
                    <div className="pollPanelTimerText urgent" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><RedTimer style={{ marginRight: "8px", width: 30, height: 30 }} />00:00:00</div>
                </span>
            );
        }

        const hoursView = ('00' + ((days * 24) + hours)).slice(-2);
        const minutesView = ('00' + minutes).slice(-2);
        const secondsView = ('00' + seconds).slice(-2);
        const urgent = days === 0 && hours === 0 && minutes === 0 && seconds < 10 && seconds !== 0;

        const time = (seconds + minutes * 60 + hours * 3600 + days * 86400);
        timeRemainingRef.current = time;

        if (localState === "SHARED_ALL" && pollType === POLL_TYPE.MERGED_POLL && time <= 5 * 60) {
            setShowGradedMessage(true);
        } else {
            setShowGradedMessage(false);
        }

        if (localState === "START POLL" && pollType === POLL_TYPE.ATTENDANCE) {
            if (document.getElementById("shareCountDown") !== null) {
                document.getElementById("shareCountDown").style.display = "none";
            }
            return (
                <></>
            );
        }

        if (localState === "START POLL") {
            return (
                <span className="questionPanelQuestionTypeContainerTimer">
                    <div className="pollPanelTimerText" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Timer style={{ marginRight: "8px", width: 30, height: 30 }} />
                        {hoursView}:{minutesView}:{secondsView}
                    </div>
                </span>
            );
        }

        if (localState === "GET_READY_ALL" && time > pollTimeLimit) {
            return (
                <span className="questionPanelQuestionTypeContainerTimer">
                    <div className="pollPanelTimerText" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Timer style={{ width: 30, height: 30 }} />
                        Get Ready: {time - pollTimeLimit - 1}
                    </div>
                </span>
            );
        }

        if (attendanceEndTime && (localState !== "GET_READY_ALL" && localState !== "GET_READY") && time > 86400) {
            if (document.getElementById("shareCountDown") !== null) {
                document.getElementById("shareCountDown").style.display = "none";
            }
        } else {
            if (document.getElementById("shareCountDown") !== null) {
                document.getElementById("shareCountDown").style.display = "inline";
            }
        }

        if (localState === "GET_READY" && time > queTimeLimit) {
            return (
                <span className="questionPanelQuestionTypeContainerTimer">
                    <div className="pollPanelTimerText" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><Timer style={{ width: 30, height: 30 }} />Get Ready: {time - queTimeLimit - 1}</div>
                </span>
            );
        }

        // pad with 2 leading zeros
        if (time && sharingAll && time <= pollTimeLimit) {
            setLocalState('SHARED_ALL');

            return (
                <span className={`questionPanelQuestionTypeContainerTimer ${urgent ? 'urgent' : ''}`} id="shareCountDown">
                    <div
                        className={`pollPanelTimerText ${time === 0 ? 'urgent' : ''}`}
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        {urgent ?
                            <div style={{ width: 30, marginRight: "8px" }}>
                                <Lottie
                                    options={Redoptions}
                                    height={30}
                                    width={30}
                                    style={{ paddingBottom: 5 }}
                                    isClickToPauseDisabled={true}
                                />
                            </div> : <div style={{ width: 30, marginRight: "8px" }}>
                                <Lottie
                                    options={options}
                                    height={30}
                                    width={30}
                                    style={{ paddingBottom: 5 }}
                                    isClickToPauseDisabled={true}
                                />
                            </div>
                        }
                        {hoursView}:{minutesView}:{secondsView}
                    </div>
                </span>
            );
        }

        if (time && time <= queTimeLimit) {
            setLocalState('SHARED');

            // if (pollType === POLL_TYPE.ATTENDANCE) {
            //     // console.log(time, ATTENDANCE_COUNTDOWN_START);
            //     // if (showTimer !== (time <= ATTENDANCE_COUNTDOWN_START)) {
            //     //     setShowTimer(time <= ATTENDANCE_COUNTDOWN_START);
            //     // }
            //     if (time > ATTENDANCE_COUNTDOWN_START) {
            //         return <></>; // uncomment this line to hide the timer until last 10 seconds
            //     }
            // }

            return (
                <span className={`questionPanelQuestionTypeContainerTimer ${urgent ? 'urgent' : ''}`} id="shareCountDown">
                    <div
                        className={`pollPanelTimerText ${time === 0 ? 'urgent' : ''}`}
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        {urgent ?
                            <div style={{ width: 30, marginRight: "8px" }}>
                                <Lottie
                                    options={Redoptions}
                                    height={30}
                                    width={30}
                                    style={{ paddingBottom: 5 }}
                                    isClickToPauseDisabled={true}
                                />
                            </div> : <div style={{ width: 30, marginRight: "8px" }}>
                                <Lottie
                                    options={options}
                                    height={30}
                                    width={30}
                                    style={{ paddingBottom: 5 }}
                                    isClickToPauseDisabled={true}
                                />
                            </div>
                        }
                        {hoursView}:{minutesView}:{secondsView}
                    </div>
                </span>
            );
        }

        return <></>;
    };

    const setPointsCallback = (questionType, res) => {
        if (questionType !== QUESTION_TYPES.CI.name) {
            return;
        }
        const {points} = res;
        setPoints(points ? points: []);
    };

    useEffect(() => {
        if (initialized) {
            const session = getSession();
            const {idpType="", intType=""} = session;
            if (idpType && intType) {
                getCourseByUser(idpType, intType).then(courses => {
                    const options = courses.map(course => ({
                        value: course.courseId,
                        label: `${course.courseCode} : ${course.courseName}`,
                        code: course.courseCode,
                        isLmsCourse: !!course.index3Pk
                    }));
                    setCourseList(options);
                }).finally(() => {
                    setLoadingCourses(false);
                });
            } else {
                getCourseByUser().then(courses => {
                    const options = courses.map(course => ({
                        value: course.courseId,
                        label: `${course.courseCode} : ${course.courseName}`,
                        code: course.courseCode,
                        isLmsCourse: !!course.index3Pk
                    }));
                    setCourseList(options);
                }).finally(() => {
                    setLoadingCourses(false);
                });
            }
        }
    }, [initialized]);


    useEffect(() => {
        if(isNarrow) {
            setCollapsed(true);
        }
        else {
            setCollapsed(false);
        }
    }, [isNarrow]);

    useEffect(() => {
        // const interval = setInterval(() => {
        //     updateAllInfo();
        // }, 30000);

        // return () => {
        //     if (!!interval) {
        //         clearInterval(interval);
        //     }
        // };

    }, []);

    const handleAddTime = async (AddTimeValues, selectedTime) => {
        if(selectedTime === "") {
            notifyError("Please select a time to add")
        } else if (shareAll === POLL_SHARE_MODE.SHARE_ALL) {
            let Time = AddTimeValues[selectedTime]; 
            const pollDetails = {};
            pollDetails.pollTimeLimit = pollTimeLimit + Time;
            setPollEnd(pollEnd + (Time * 1000)); 
            setPollTimeLimit(pollTimeLimit + Time);
            await editPublishedPollDataFromKey(pollDetails, pollKey, pollCode);
            notifySuccess(shareAllAddTimeToastDisplay[selectedTime]);
            setResetData(resetData+1);
        } else {
            let Time = AddTimeValues[selectedTime]; 
            setQueEnd(queEnd + (Time * 1000)); 
            setQueTimeLimit(queTimeLimit + Time);
            await updateQuestionDetails(pollKey, pollCode, sharedQuestionNo, {timeLimit: queTimeLimit + Time, AddTime: true});
            await updatePollDetails(pollKey, pollCode, { qTimeLimit: queTimeLimit + Time }, true);
            notifySuccess(shareEachAddTimeToastDisplay[selectedTime]);
            setResetData(resetData+1);
        }
        setShowAddTimeModal(false); 
    }

    useEffect(() => {
        if (!pollData || !pollData.poll || pollData.poll.pollShareMode !== POLL_SHARE_MODE.SCHEDULE || !startDate || !endDate) {
            return;
        }

        let intervalId = setInterval(() => {
            const currentTime = new Date().getTime();
            const startTime = new Date(startDate).getTime();
            const endTime = new Date(endDate).getTime();

            if (endTime < currentTime || pollData.poll.pollState === POLL_STATE.STOPPED_SCHEDULED) {
                setLocalState("TIMEUP_ALL");
                clearInterval(intervalId);
            } else if(currentTime >= startTime && currentTime <= endTime) {
                setLocalState("SHARED_ALL");
            }
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [pollData, startDate, endDate]);

    useEffect(() => {
        if (pollData?.poll?.endTime) {
            if (pollData.poll.pollType === POLL_TYPE.ATTENDANCE) {
                setAttendanceEndtime(pollData.poll.endTime);
            }
        }

        if (!startDate) {
            setStartDate(pollData?.poll?.startTime ? pollData.poll.startTime : "");
        }

        if (!endDate) {
            setEndDate(pollData?.poll?.endTime ? pollData.poll.endTime : "");
        }
    }, [pollData]);

    const handleSubmitPollOptions = async (pollOptions) => {
        // console.log(userSettings, pollOptions);
        await updatePollDetails(pollKey, pollCode, pollOptions);
        setResetData(resetData+1);
        setShowPollOptionsModal(false);
    };

    const handleSidebarHeaderClick = (index) => {
        if (currentView === index) {
            return;
        }
        // if (currentView === 1) {
        //     // setDefaultView(true);
        //     // setPollDataBuilder(pollData);
        // }
        setMapInitialized(false);
        setCurrentView(1 - currentView);
    };

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (ref.current && !ref.current.contains(event.target)) {
    //             setCollapsed(true);
    //         }
    //     };

    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [ref]);

    useEffect(() => {
        // console.log(pollResults);
        if (!participantsAnalysisData || participantsAnalysisData.length < currentQuestion) {
            setHasResponses(false);
            return;
        }
        const curParticipantsData = participantsAnalysisData[currentQuestion - 1];
        setHasResponses(curParticipantsData.attempted > 0)
    }, [participantsAnalysisData, currentQuestion]);

    useEffect(() => {
        setIsSharing(shareAll === POLL_SHARE_MODE.SHARE_ALL ||
            (shareAll === POLL_SHARE_MODE.SCHEDULE && new Date() > new Date(startDate)) ||
            (shareAll === POLL_SHARE_MODE.SHARE_EACH && localState !== "START POLL" && currentQuestion === sharedQuestionNo));
    }, [shareAll, localState, currentQuestion, sharedQuestionNo, startDate]);

    useEffect(() => {
        // console.log(defaultView || (currentView === 0 && (pollData?.questions.length === pollDataBuilder?.questions.length || currentQuestion !== pollDataBuilder?.questions?.length)));
        setViewQuestionDefaultView((defaultView || (currentView === 0 && (pollData?.questions.length === pollDataBuilder?.questions.length || currentQuestion !== pollDataBuilder?.questions?.length))));
    }, [currentView, pollData, pollDataBuilder, currentQuestion, defaultView]);


    // useEffect(() => {
    //     // console.log(getLocalGeofence(), geofenceInitialized, geofence);
    //     if (geofence === null) {
    //         return;
    //     }

    //     if (!geofenceInitialized) {
    //         setLocalGeofence(geofence);
    //         setGeofenceInitialized(true);
    //         return;
    //     }

    //     // if (JSON.stringify(getLocalGeofence()) === JSON.stringify(geofence)) {
    //     //     return;
    //     // }

    //     // updatePollDetails(pollKey, pollCode, { geofence });
    //     // setResetData(resetData+1);
    // }, [geofence]);

    // useEffect(() => {
    //     // console.log(getLocalSyncLms(), syncLmsInitialized, syncLms);
    //     if (syncLms === null) {
    //         return;
    //     }

    //     if (!syncLmsInitialized) {
    //         setLocalSyncLms(syncLms);
    //         setSyncLmsInitialized(true);
    //         return;
    //     }

    //     // if (getLocalSyncLms() === syncLms) {
    //     //     return;
    //     // }

    //     // updatePollDetails(pollKey, pollCode, { syncLms });
    //     // setResetData(resetData+1);
    // }, [syncLms]);

    return (
        <>
        {!invalidPoll &&
        <div className="sharePollContainer" style={{ gap: "15px" }}>
            {dateModalShow &&
                <EditDateModal
                    show={dateModalShow}
                    setModalShow={setDateModalShow}
                    pollKey={pollKey}
                    pollUniqueCode={POLL_CODE_PREFIX + pollCode}
                    setDate={setStartDate}
                    setPollEnd={setEndDate}
                    inCourse={!!pollData?.poll?.index2Pk}
                />
            }
            <ScheduleModal
                show={showScheduleModal}
                setShow={setShowScheduleModal}
                disabled={disabledReleasePoll}
                pollTimeLimit={pollData && pollData.poll && pollData.poll.pollTimeLimit ? pollData.poll.pollTimeLimit : 0}
                inCourse={!!pollData?.poll?.index2Pk}
                userSettings={userSettings}
                handleSubmit={handleSubmitSchedule}
            />
            <AddPollTimeModal show={showAddTimeModal} setShow={setShowAddTimeModal} handleSubmit={handleAddTime} AddTimeOptions={shareAll === POLL_SHARE_MODE.SHARE_ALL ? shareAllAddTimeOptions : shareEachAddTimeOptions} AddTimeValues={shareAll === POLL_SHARE_MODE.SHARE_ALL ? shareAllAddTimeValues : shareEachAddTimeValues}/>
            <PollTimeLimitModal
                show={showPollTimeLimitModal}
                setShow={setShowPollTimeLimitModal}
                disabled={disabledReleasePoll}
                pollTimeLimit={pollData && pollData.poll && pollData.poll.pollTimeLimit ? pollData.poll.pollTimeLimit : 0}
                userSettings={userSettings}
                handleSubmit={handleSubmitPollTimeLimit}
                pollType={pollType}
                manualEnd={pollData && pollData.poll && pollData.poll.hasOwnProperty("manualEnd") ? pollData.poll.manualEnd : userSettings[USER_SETTINGS.ATTENDANCE_MANUAL_END]}
                attendanceDuration={pollData && pollData.poll && pollData.poll.attendanceDuration ? pollData.poll.attendanceDuration : userSettings[USER_SETTINGS.ATTENDANCE_DURATION]}
            />
            <ActivityOptionsModal
                show={showPollOptionsModal}
                setShow={setShowPollOptionsModal}
                pollOptions={pollData ? pollData.poll : {}}
                courseData={courseList}
                loadingCourses={loadingCourses}
                userSettings={userSettings}
                pollShareMode={shareAll}
                handleSubmit={handleSubmitPollOptions}
                pollType={pollType}
            />
            <ReleaseQuestionModal
                show={showReleaseQuestionModal}
                setShow={setShowReleaseQuestionModal}
                handleSubmit={handleSaveandShare}
            />
            <ReleasePollModal
                show={showReleasePollModal}
                setShow={setShowReleasePollModal}
                handleSubmit={handleSaveandShareAll}
            />
            {shareAll === POLL_SHARE_MODE.SCHEDULE && !isNarrow &&
                <div style={{ backgroundColor: "#C3CDFF", padding: "15px 30px 15px 30px", marginBottom: "10px",  fontSize: "14.5px", display: "flex", flexWrap: "wrap", alignItems: "center", borderRadius: "6.25px" }}>
                    <Megaphone style={{ marginRight: "10px" }} />
                    <strong style={{ marginRight: "5px" }}>{ATTENTION_TEXT}</strong>
                    {(Date.now() > new Date(endDate) || pollData?.poll?.pollState === POLL_STATE.STOPPED_SCHEDULED) ?
                        <>
                            {SCHEDULE_POLL_EXPIRED_TITLE_START}
                            <strong style={{ marginLeft: "5px", marginRight: "5px" }}>{convertTime(startDate)}</strong>
                            {SCHEDULE_POLL_EXPIRED_TITLE_MID}
                            <strong style={{ marginLeft: "5px", marginRight: "5px" }}>{convertTime(endDate)}</strong>
                            {SCHEDULE_POLL_EXPIRED_TITLE_END}
                        </> :
                        <>
                            {Date.now() > new Date(startDate)
                                ? <>{SCHEDULE_POLL_LIVE_TITLE}<strong style={{ marginLeft: 5, marginRight: 5 }}>{convertTime(endDate) + "."}</strong></>
                                : <>{SCHEDULE_POLL_FUTURE_TITLE_PREFIX}<strong style={{ marginLeft: 5, marginRight: 5 }}>{convertTime(startDate) + "."}</strong></>
                            }
                            {UPDATE_RELEASE_SCHEDULE_PREFIX}
                            <span
                                style={{ color: "#0277AD", textDecoration: "underline", fontWeight: "700", cursor: "pointer", marginLeft: 5 }}
                                onClick={() => { setDateModalShow(true) }}
                            >
                                {UPDATE_RELEASE_SCHEDULE}
                            </span>
                        </>
                    }
                </div>
            }
            {/* {!surveyDataLoading && localState === "SHARED_ALL" && surveyEndTime && pollData.poll.pollType.toUpperCase() === POLL_TYPE.SURVEY &&
                <div style={{ backgroundColor: "#C3CDFF", padding: "15px 30px 15px 30px", marginBottom: "10px",  fontSize: "14.5px", display: "flex", flexWrap: "wrap", alignItems: "center", borderRadius: "6.25px" }}>
                    <Megaphone style={{ marginRight: "10px" }} />
                    <strong style={{ marginRight: "5px" }}>{ATTENTION_TEXT}</strong>
                    {Date.now() < new Date(surveyEndTime) ? <>{SURVEY_END_TITLE}<strong style={{ marginLeft: "5px", marginRight: "5px" }}>{convertTime(surveyEndTime)}</strong></> : (<>
                    </>)}
                </div>
            } */}
            {localState === "SHARED" && attendanceEndTime && pollData.poll.pollType === POLL_TYPE.ATTENDANCE &&
                <div style={{ backgroundColor: "#C3CDFF", padding: "15px 30px 15px 30px", marginBottom: "10px",  fontSize: "14.5px", display: "flex", flexWrap: "wrap", alignItems: "center", borderRadius: "6.25px" }}>
                    <Megaphone style={{ marginRight: "10px" }} />
                    <strong style={{ marginRight: "5px" }}>{ATTENTION_TEXT}</strong>
                    {Date.now() < new Date(attendanceEndTime) ? <>{ATTENDANCE_END_TITLE}<strong style={{ marginLeft: "5px", marginRight: "5px" }}>{convertTime(attendanceEndTime)}</strong></> : (<>
                    </>)}
                </div>
            }
            {shareAll === POLL_SHARE_MODE.SCHEDULE && isNarrow &&
                <div style={{ backgroundColor: "#C3CDFF", padding: "15px 30px 15px 30px", marginBottom: "10px",  fontSize: "14.5px", borderRadius: "6.25px", display: "flex", alignItems: "center" }}>
                    <div style={{ minWidth: "120px" }}>
                        <Megaphone style={{ marginRight: "10px" }} />
                        <strong style={{ marginRight: "5px" }}>{ATTENTION_TEXT}</strong>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        {(Date.now() > new Date(endDate) || pollData?.poll?.pollState === POLL_STATE.STOPPED_SCHEDULED) ?
                            <>
                                <div>{SCHEDULE_POLL_EXPIRED_TITLE_START}<strong style={{ marginLeft: "5px", marginRight: "5px" }}>{convertTime(startDate)}</strong></div>
                                <div>{SCHEDULE_POLL_EXPIRED_TITLE_MID}<strong style={{ marginLeft: "5px", marginRight: "5px" }}>{convertTime(endDate)}</strong>{SCHEDULE_POLL_EXPIRED_TITLE_END}</div>
                            </> :
                            <>
                                {Date.now() > new Date(startDate)
                                    ? <div>{SCHEDULE_POLL_LIVE_TITLE}<strong style={{ marginLeft: "5px", marginRight: "5px" }}>{convertTime(endDate) + "."}</strong></div>
                                    : <div>{SCHEDULE_POLL_FUTURE_TITLE_PREFIX}<strong style={{ marginTop: "10px" }}>{convertTime(startDate) + "."}</strong></div>}
                                <div>
                                    {UPDATE_RELEASE_SCHEDULE_PREFIX}
                                    <span
                                        style={{ color: "#0277AD", textDecoration: "underline", fontWeight: "700", cursor: "pointer" }}
                                        onClick={() => { setDateModalShow(true) }}
                                    >
                                        {UPDATE_RELEASE_SCHEDULE}
                                    </span>
                                </div>
                            </>
                        }

                    </div>
                </div>
            }
            {shareAll === POLL_SHARE_MODE.SHARE_ALL && pollType === POLL_TYPE.MERGED_POLL && showGradedMessage &&
                <div style={{ backgroundColor: "#C3CDFF", padding: "15px 30px 15px 30px", marginBottom: "10px", fontSize: "14px", display: "flex", flexWrap: "wrap", alignItems: "center", borderRadius: "6.25px" }}>
                    <Megaphone style={{ marginRight: "10px" }} />
                    <strong style={{ marginRight: "5px" }}>{ATTENTION_TEXT}</strong>
                     <>{GRADED_ADD_TIME_TEXT}<strong onClick={()=> {setShowAddTimeModal(true);}} style={{ marginLeft: "5px", marginRight: "5px", color: "#075A6C", textDecoration: "underline", cursor: "pointer"}}>{GRADED_ADD_TIME_OPTION_TEXT}</strong></>
                </div>
            }

            {shareAll === POLL_SHARE_MODE.SHARE_EACH && localState === "SHARED" && pollType === POLL_TYPE.MERGED_POLL && !!pollData.questions[sharedQuestionNo - 1].timeLimit &&
                <div style={{ backgroundColor: "#C3CDFF", padding: "15px 30px 15px 30px", marginBottom: "10px",  fontSize: "14px", display: "flex", flexWrap: "wrap", alignItems: "center", borderRadius: "6.25px" }}>
                    <Megaphone style={{ marginRight: "10px" }} />
                    <strong style={{ marginRight: "5px" }}>{ATTENTION_TEXT}</strong>
                     <>{GRADED_ADD_TIME_TEXT}<strong onClick={()=> {setShowAddTimeModal(true);}} style={{ marginLeft: "5px", marginRight: "5px", color: "#075A6C", textDecoration: "underline", cursor: "pointer"}}>{GRADED_ADD_TIME_OPTION_TEXT}</strong></>
                </div>
            }

            <div style={{ display: "flex", gap: "25px", height: shareAll === POLL_SHARE_MODE.SCHEDULE ? "80%" : "100%", flex: shareAll === POLL_SHARE_MODE.SCHEDULE ? '1' : "" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "15px", flex: "1", overflowX: "auto" }}>
                    <div className="pollPanel">
                        {!loading && initialized ?
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", gap: 20 }}>
                                <div style={{ display: "flex", flexDirection: "column", flex: 1, width: "50%" }}>
                                    <div className="pollPanelSubtitle">{pollData.poll.pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE : MERGED_MODE} Title</div>
                                    <div className={"pollPanelTitle"}>
                                        {pollData.poll.pollTitle}
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    {!selectedQuestion && pollType !== POLL_TYPE.ATTENDANCE && shareAll === POLL_SHARE_MODE.SHARE_EACH &&
                                        <div className="grayedGradientWrap" style={{ marginRight: "10px" }}>
                                            <span className="grayedGradientBtnPublish" tabIndex={0}>
                                                <ShareIcon style={{ marginRight: "10px" }} />{RELEASE}{POLL_TEXT}
                                            </span>
                                        </div>
                                    }
                                    {!selectedQuestion && pollType !== POLL_TYPE.ATTENDANCE && localState === "START POLL" && (shareAll === POLL_SHARE_MODE.SHARE_ALL || shareAll === POLL_SHARE_MODE.UNKNOWN) &&
                                        <div className="gradientWrapPoll" onClick={onClickShareAll} style={{ marginRight: "10px" }}>
                                            <span className="gradientBtnPublish" tabIndex={0}>
                                                <ShareIcon style={{ marginRight: "10px" }} />{RELEASE}{POLL_TEXT}
                                            </span>
                                        </div>
                                    }
                                    {!selectedQuestion && pollType !== POLL_TYPE.ATTENDANCE && (localState === "GET_READY_ALL" || localState === "SHARED_ALL") && shareAll === POLL_SHARE_MODE.SHARE_ALL &&
                                        <div className="gradientWrapPoll" onClick={handleStopAll} style={{ marginRight: "10px" }}>
                                            <span className="gradientBtnStop" tabIndex={0}>
                                                <StopIcon style={{ marginRight: "10px" }} />{STOP}{POLL_TEXT}
                                            </span>
                                        </div>
                                    }
                                    {!selectedQuestion && (localState === "GET_READY_ALL" || localState === "SHARED_ALL") && shareAll === POLL_SHARE_MODE.SCHEDULE &&
                                        <div className="gradientWrapPoll" onClick={handleStopScheduled} style={{ marginRight: "10px" }}>
                                            <span className="gradientBtnStop" tabIndex={0}>
                                                <StopIcon style={{ marginRight: "10px" }} />{STOP}{POLL_TEXT}
                                            </span>
                                        </div>
                                    }
                                    {!selectedQuestion && pollType !== POLL_TYPE.ATTENDANCE && localState === "TIMEUP_ALL" && shareAll === POLL_SHARE_MODE.SHARE_ALL &&
                                        <div className="grayedGradientWrap" style={{ opacity: 0.5, marginRight: "10px" }}>
                                            <span className="grayedGradientBtnStop">
                                                <StopIcon style={{ marginRight: "10px" }} />{STOP}{POLL_TEXT}
                                            </span>
                                        </div>
                                    }
                                    {selectedQuestion && (shareAll === POLL_SHARE_MODE.SHARE_ALL ) && (localState !== "GET_READY" && localState !== "SHARED") && shareAll !== POLL_SHARE_MODE.SCHEDULE &&
                                        <div className="grayedGradientWrap" style={{ marginRight: "10px", width: 195 }}>
                                            <span className="grayedGradientBtnPublish" tabIndex={0}>
                                                <ShareIcon style={{ marginRight: "10px" }} />{pollType === POLL_TYPE.ATTENDANCE ? OPEN + ATTENDANCE : RELEASE + QUESTION + currentQuestion}
                                            </span>
                                        </div>
                                    }
                                    {selectedQuestion && localState === "START POLL" && (shareAll === POLL_SHARE_MODE.SHARE_EACH || shareAll === POLL_SHARE_MODE.UNKNOWN) &&
                                        <div className="gradientWrapPoll" onClick={handleShare} style={{ marginRight: "10px", width: 195 }}>
                                            <span className="gradientBtnPublish" tabIndex={0}>
                                                <ShareIcon style={{ marginRight: "10px" }} />{pollType === POLL_TYPE.ATTENDANCE ? OPEN + ATTENDANCE : RELEASE + QUESTION + currentQuestion}
                                            </span>
                                        </div>
                                    }
                                    {selectedQuestion && (localState === "GET_READY" || localState === "SHARED") && shareAll === POLL_SHARE_MODE.SHARE_EACH &&
                                        <div className="gradientWrapPoll" onClick={handleStop} style={{ marginRight: "10px", width: 195 }}>
                                            <span className="gradientBtnStop" tabIndex={0}>
                                                <StopIcon style={{ marginRight: "10px" }} />{pollType === POLL_TYPE.ATTENDANCE ? CLOSE + ATTENDANCE : STOP + QUESTION + sharedQuestionNo}
                                            </span>
                                        </div>
                                    }
                                    {selectedQuestion && localState === "TIMEUP" && shareAll === POLL_SHARE_MODE.SHARE_EACH &&
                                        <div className="grayedGradientWrap" style={{ opacity: 0.5, marginRight: "10px", width: 195 }}>
                                            <span className="grayedGradientBtnStop">
                                                <StopIcon style={{ marginRight: "10px" }} />{pollType === POLL_TYPE.ATTENDANCE ? CLOSE + ATTENDANCE : STOP + QUESTION + sharedQuestionNo}
                                            </span>
                                        </div>
                                    }
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div tabIndex={0} aria-label={collapsed ? "ExpandIcon" : "CollapseIcon"} className={collapsed ? "ExpandIcon" : "CollapseIcon"} onClick={() => setCollapsed(!collapsed)} onMouseDown={(e) => e.preventDefault()} />
                                    </div>
                                </div>
                            </div> :
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div className="pollPanelSubtitle">Title</div>
                                    <div className={isNarrow ? (collapsed ? "pollPanelTitleNarrowCollapsed" : "pollPanelTitleNarrow") : pollData && pollData.poll && pollData.poll.pollTitle.length > 60 ? "pollPanelTitleSmall" : "pollPanelTitle"}>
                                        {pollData && pollData.poll && pollData.poll.pollTitle ?
                                            pollData.poll.pollTitle :
                                            <Skeleton borderRadius={10} height={28} inline={true} width={`${5 + Math.random()*10}vw`} className="skeletonTitle" containerClassName="skeletonTitleWrapper" />
                                        }
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div className="grayedGradientWrap" style={{ marginRight: "10px" }}>
                                        <span className="grayedGradientBtnPublish" tabIndex={0}>
                                            <ShareIcon style={{ marginRight: "10px" }} />{RELEASE}{(!pollData?.poll?.pollType || pollData.poll.pollType === POLL_TYPE.ATTENDANCE) ? "" : POLL_TEXT}
                                        </span>
                                    </div>
                                    {/* {pollType !== POLL_TYPE.ATTENDANCE && <div className="grayedGradientWrap" style={{ marginRight: "10px" }}>
                                        <span className="grayedGradientBtnPublish" tabIndex={0}>
                                            <ShareIcon style={{ marginRight: "10px" }} />{RELEASE}{QUESTION}{currentQuestion}
                                        </span>
                                    </div>} */}
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div className={collapsed ? "ExpandIcon" : "CollapseIcon"} onClick={() => setCollapsed(!collapsed)} onMouseDown={(e) => e.preventDefault()} />
                                    </div>
                                </div>
                                </SkeletonTheme>
                            </div>
                        }
                    </div>

                    <div className="sharePollBodyContainer">
                        <div className={isNarrow ? "viewQuestionsContainerNarrow" : "viewQuestionsContainer2"} style={{ marginRight: "0px" }}>
                            <ViewQuestions
                                countData={responseCountData}
                                reactionCountData={reactionCountData}
                                originalData={pollData}
                                data={pollDataBuilder}
                                setData={setPollDataBuilder}
                                disabled={hasResponses || isSharing}
                                initialized={initialized}
                                loading={loading}
                                localState={localState}
                                currentQuestion={currentQuestion}
                                setCurrentQuestion={setCurrentQuestion}
                                geofence={geofence}
                                setGeofence={setGeofence}
                                syncLms={syncLms}
                                setSyncLms={setSyncLms}
                                mapInitialized={mapInitialized}
                                setMapInitialized={setMapInitialized}
                                firstLoad={firstLoad}
                                setFirstLoad={setFirstLoad} showAnswer={showAnswer} setShowAnswer={setShowAnswer} currentView={currentView}
                                selectedQuestion={selectedQuestion} setSelectedQuestion={setSelectedQuestion} defaultView={viewQuestionDefaultView} setDefaultView={setDefaultView}
                                points={points} totalPoints={totalPoints} pollShareMode={shareAll} userSettings={userSettings} // userId={getSession() ? getSession().userId : ""}
                                resetData={resetData} setResetData={setResetData} setShowPollOptionsModal={setShowPollOptionsModal}
                                countDownRef={countDownRef} renderer={renderer}
                                sharingAll={sharingAll} pollEnd={pollEnd} queEnd={shareAll === POLL_SHARE_MODE.SHARE_ALL ? pollEnd : queEnd} timeUp={timeUp}
                            />
                        </div>
                        <>
                            {/* <div className={collapsed ? "viewResultsCollapsedControl" : "viewResultsControl"}>
                            {collapsed ?
                                <ExpandIcon onClick={() => {
                                    setCollapsed(false);
                                    setTimeout(() => {
                                        ref.current?.scrollIntoView({ behavior: 'smooth' });
                                    }, 50);
                                }}/> :
                                <CollapseIcon onClick={() => setCollapsed(true)}/>}
                        </div> */}
                        </>
                        {/* <div className="viewResultsContainer2">
                        <ViewResponses data={pollResults[currentQuestion-1]} pollData={pollData} currQues={currentQuestion} setCurrQues={setCurrentQuestion} initialized={initializedResponses} loading={loadingResponses}/>
                        <ViewParticipants pollData={pollData} data={pollResults[currentQuestion-1]} responseData={responseData} allData={engagementData} currQues={currentQuestion} initialized={initializedParticipants} loading={loadingParticipants} localState={localState} shareMode={shareAll}/>
                        <ViewBrowserInfo data={userInfo} />
                        <ViewPollCode data={pollData.poll} initialized={initialized} loading={loading}/>
                        <ViewActivityFeed data={activeStudents} localState={localState}/>
                    </div> */}
                    </div>
                </div>

                <div ref={ref} className={collapsed ? "viewResultsContainerCollapsed" : "viewResultsContainerNarrow"} style={{ height: "100%" }}>
                    <div className="sidebarHeader">
                        <div
                            className={currentView === 0 ? "sidebarHeaderItemActive" : "sidebarHeaderItem"}
                            tabIndex={collapsed ? -1 : 0}
                            role={TEXTBOX}
                            onClick={() => handleSidebarHeaderClick(0)}
                        >
                            {ANALYTICS}
                        </div>
                        <div
                            className={currentView === 1 ? "sidebarHeaderItemActive" : "sidebarHeaderItem"}
                            tabIndex={collapsed ? -1 : 0}
                            role={TEXTBOX}
                            onClick={() => handleSidebarHeaderClick(1)}
                        >
                            {pollType === POLL_TYPE.ATTENDANCE ? EDIT_QUESTION : QUESTION_BUILDER}
                        </div>
                    </div>
                    {currentView === 0 ? <div style={{width: 350, height: "100%", overflow: "auto"}}>
                        <ViewResponses
                            data={responsesAnalysisData}
                            pollData={pollDataBuilder}
                            currQues={currentQuestion}
                            setCurrQues={setCurrentQuestion}
                            initialized={initializedResponses}
                            loading={loadingResponses}
                            localState={localState}
                            collapsed={collapsed}
                        />
                        {pollType !== POLL_TYPE.ATTENDANCE &&
                            <ViewParticipants
                                data={participantsAnalysisData}
                                dataAll={participantsAllAnalysisData}
                                pollData={defaultView ? pollData : pollDataBuilder}
                                currQues={currentQuestion}
                                initialized={initializedParticipants}
                                loading={loadingParticipants}
                                localState={localState}
                                shareMode={shareAll}
                                collapsed={collapsed}
                                selectedQuestion={selectedQuestion}
                            />
                        }
                        <ViewBrowserInfo data={browserAnalysisData} collapsed={collapsed}/>
                        {/* {!inLMS && */}
                            <ViewPollCode data={pollData?.poll} initialized={initialized} loading={loading} collapsed={collapsed}/>
                        {/* } */}
                        <ViewActivityFeed data={activeStudents} localState={localState} collapsed={collapsed} pollType={pollType}/>
                    </div> :
                        <QuestionBuilder
                            pollKey={pollKey}
                            pollCode={pollCode}
                            hasResponses={hasResponses}
                            isSharing={isSharing}
                            data={pollDataBuilder} setData={setPollDataBuilder}
                            originalData={pollData} setOriginalData={setPollData}
                            defaultView={defaultView} setDefaultView={setDefaultView}
                            firstAdd={firstAdd} setFirstAdd={setFirstAdd}
                            currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion}
                            selectedQuestion={selectedQuestion} setSelectedQuestion={setSelectedQuestion}
                            resetData={resetData} setResetData={setResetData}
                            geofence={geofence} setGeofence={setGeofence}
                            mapInitialized={mapInitialized} setMapInitialized={setMapInitialized}
                            syncLms={syncLms} setSyncLms={setSyncLms}
                            shareMode={shareAll}
                            startDate={startDate}
                            userSettings={userSettings}
                            // initialized={initialized}
                            loading={loading}
                            collapsed={collapsed}
                            handleUpdateQuestion={handleUpdateQuestion}
                        />
                    }
                </div>
            </div>
        </div>
}
{invalidPoll &&
        <div className="col-md-12" style={{height: "100%", display: "flex", alignItems: "center", flexDirection: "column"}}>
            <div style={{marginTop: "90px", height: "631px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            <NoResult />
        <div style={{fontSize: "20px", marginTop: "50px"}}> 
            {DEPRECATED_POLL_TITLE}
        </div>
        <h4 className="page-sub-title" style={{color: "#000000", fontSize: "15px", marginTop: "15px"}}>
            {DEPRECATED_POLL_MESSAGE}
        </h4>
        </div>
    </div>
}
    </>
    );
}
