import React, {useEffect, useRef, useState} from "react";
import {
    HOW_TO_USE_TEXT,
    QUESTION_TYPES, WATCH_VIDEO_TEXT
} from "../utils/constants";
import {ReactComponent as BULB} from "../images/LightBulb.svg";
import {ReactComponent as VIDEO} from "../images/video.svg";
import {ReactComponent as EXTERNAL} from "../images/ExtenrnalIcon.svg";
import {useYuJaEventBus} from "../components/standardization/YuJaEventBusProvider";
import {useWindowDimensions} from "react-native-web";

export default function CreateQuestionGuidance({questionType="MCSS"}) {
    const URL_MAPPING = {
        MCSS: "https://demo.video.yuja.com/V/Video?v=11072575&node=49127356&a=166285668",
        SA: "https://demo.video.yuja.com/V/Video?v=11072578&node=49127359&a=157824720",
        TF: "https://demo.video.yuja.com/V/Video?v=11072580&node=49127368&a=124698386",
        FITB: "https://demo.video.yuja.com/V/Video?v=11072584&node=49127390&a=175292728",
        CI: "https://demo.video.yuja.com/V/Video?v=11072569&node=49127314&a=32818730",
        WC: "https://demo.video.yuja.com/V/Video?v=11072570&node=49127330&a=175750107",
        MH: "https://demo.video.yuja.com/V/Video?v=11072571&node=49127331&a=181153166",
        RK: "https://demo.video.yuja.com/V/Video?v=11072573&node=49127333&a=60802832",
        OE: "https://demo.video.yuja.com/V/Video?v=11072574&node=49127341&a=154067260",
    }

    const urlRef = useRef(URL_MAPPING[questionType]);
    const [isOpen, setIsOpen] = useState(false);
    const [desc, setDesc] = useState("");
    const [URL, setURL] = useState(URL_MAPPING[questionType]);
    const linkRef = useRef();
    const containerRef = useRef();
    const { addClickEventHandler, removeClickEventHandler } = useYuJaEventBus();
    let {width: windowWidth} = useWindowDimensions();
    const [isOnLeft, setIsOnLeft] = useState(true);
    const [guidanceWindowWidth, setGuidanceWindowWidth] = useState(500);


    useEffect(() => {
        const handleGlobalClick = (event) => {
            // Check if the click occurred outside of the dropdown
            if (isOpen && containerRef.current && !containerRef.current.contains(event.target) && !linkRef.current.contains(event.target)) {
                setIsOpen(false);
                let iFrame = document.getElementById('tutorial-video-iframe');
                if (!!iFrame) {
                    iFrame.src = urlRef.current;
                }
            }
        };

        if (isOpen) {
            let iFrame = document.getElementById('tutorial-video-iframe');
            if (urlRef.current !== iFrame.src) {
                iFrame.src = urlRef.current;
            }

        }

        addClickEventHandler(handleGlobalClick);
        return () => {
            removeClickEventHandler(handleGlobalClick);
        };
    }, [isOpen]);


    useEffect(() => {
        setDesc(QUESTION_TYPES[questionType].detail);
        urlRef.current = URL_MAPPING[questionType];
        document.getElementById('tutorial-video-iframe').src = URL_MAPPING[questionType];
    }, [questionType]);

    useEffect(() => {
        resetContainerPosition();

    }, [windowWidth, linkRef]);

    const resetContainerPosition = () => {
        const guidanceWindowWidth = Math.min(windowWidth * 0.4, 500) ;
        setGuidanceWindowWidth(guidanceWindowWidth);




        const {width, left} = linkRef.current.getBoundingClientRect();

        setIsOnLeft(left + guidanceWindowWidth + 50 <= windowWidth);

        console.log(`left: ${left}, width: ${width}, window width: ${windowWidth}`);
    }

    return (
        <div className="create-question-guidance-container">
            <BULB style={{marginRight: 3}}/>
            <span>{`${HOW_TO_USE_TEXT}: ${desc}`}</span>
            <span
                onLoad={resetContainerPosition}
                ref={linkRef}
                className="fitb-desc"
                style={{ marginLeft: 3,  alignItems: "center", position: "relative", whiteSpace: "nowrap"}}
                onClick={() => setIsOpen(true)}
            >
                <VIDEO /> <span style={{textDecoration: "underline", color: "#0B6F85", cursor: "pointer", fontStyle: "normal"}}>{WATCH_VIDEO_TEXT}</span>
                <div
                    className={`create-question-guidance ${isOnLeft ? "question-guidance-left" : "question-guidance-right"}`}
                    ref={containerRef}
                    style={{display: isOpen ? "block" : "none", width: guidanceWindowWidth}}
                >
                    <div className="create-question-guidance-video-container">
                        <div className="create-question-guidance-body">
                            <div className="create-question-guidance-row">
                                <span
                                    className="create-question-guidance-title">{QUESTION_TYPES[questionType].description}</span>
                            </div>
                            <div className="create-question-guidance-row">
                                <span style={{fontSize: 14, color: "#42296E", fontWeight: "400", whiteSpace: "normal"}}>
                                    {"Watch this video to learn more about how to build this question."}
                                </span>
                                <EXTERNAL style={{cursor: "pointer", fill: "#38225E"}} onClick={() => window.open(URL_MAPPING[questionType], "blank")}/>
                            </div>
                        </div>
                        <iframe id={"tutorial-video-iframe"} allowFullScreen style={{width: "100%", height: guidanceWindowWidth * 9 / 16}} src={URL_MAPPING[questionType]}/>
                    </div>
                </div>

            </span>

        </div>

    );
}