import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGlobalFilter, usePagination, useRowSelect, useTable } from "react-table";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { UserApiCalls } from "../../apis/UserApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import NormalPagination from "../../components/pagination/NormalPagination";
import { YuJaKebabMenu } from "../../components/standardization/YuJaKebabMenu";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { ReactComponent as ReleaseIcon } from "../../images/share.svg";
import { ReactComponent as DeleteIcon } from "../../images/trash.svg";
import {
    ACTION_HEADER,
    ACT_ITEMS,
    ATTENDANCE,
    CHECKBOX_COLUMN_ID,
    CREATION_ROLES,
    DATE,
    DATE_HEADER,
    DEFAULT_ATTENDANCE_MANUAL_END,
    DEFAULT_ATTENDANCE_TIME_LIMIT,
    DEFAULT_GET_READY_ALL_TIME_LIMIT,
    DEFAULT_GET_READY_TIME_LIMIT,
    DEFAULT_POINTS,
    DEFAULT_POLL_LMS_SYNC,
    DEFAULT_POLL_MAX_ATTEMPTS,
    DEFAULT_POLL_RECORD_ATTEMPTS,
    DEFAULT_POLL_REQUIRED_NAME,
    DEFAULT_POLL_SHOW_CORRECT_ANS,
    DEFAULT_POLL_TIME_LIMIT,
    DEFAULT_ROWS_PER_PAGE,
    DEFAULT_TIME_LIMIT,
    DELETE_ACTIVITY,
    DELETION_TIME_HEADER,
    DRAFT_STATUS,
    FEEDBACK_HEADER,
    GRADED_POLL,
    MENU_LIST,
    MERGED_MODE,
    MODAL_CANCEL_TEXT,
    MODAL_CONFIRM_TEXT,
    MODAL_DELETE_TEXT,
    MODE,
    NO_RESULTS_FOUND,
    PERMANENT_DELETE_POLL_MODAL_BODY,
    POLL_SHARE_MODE,
    POLL_SORT_KEY,
    POLL_STATE,
    POLL_STATUS,
    POLL_STATUS_HEADER,
    POLL_TITLE_CHARACTER_LIMIT,
    POLL_TYPE,
    POLL_TYPE_ACCESSOR,
    POLL_TYPE_HEADER,
    QUES_LABEL,
    READY_TO_SHARE,
    RECYCLE_BIN_RESTORE_TEXT,
    RESTORE_ACTIVITY,
    RESTORE_POLL_MODAL_BODY,
    RESTORE_TEXT,
    SCHEDULED,
    SHARED_BY_QUESTION,
    SHARED_ENTIRELY,
    SURVEY,
    TEXTBOX,
    TITLE,
    TITLE_HEADER,
    UNFINISHED,
    USER_SETTINGS
} from "../../utils/constants";
import { getDefaultUserSettings, getPollStatus, isBefore, notifyError, removePrefix } from "../../utils/helpers";
import { DELETE_ACTIVE_POLL_ERROR } from "../../utils/toast-message-constants";
import styles from "./ManagePolls.module.css";


const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])
        return (
            <div className="recycleCheckbox"> 
            <input className={"form-check-input"} type="checkbox" aria-label="checkbox" ref={resolvedRef} {...rest} />
            </div>
        )
    }
)

export default React.memo(RecycleBinTable);
function RecycleBinTable({ data = [], columns = [], pageIdx=0, globalFilterObj = {}, setShowButtons, handleSelectPoll,  getPollInformation}) {
    const [, getSession] = useLocalStorageNew("session", {});
    const { hardDeletePolls, restorePolls} = PollApiCalls();
    const { loadUsers } = UserApiCalls();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRestoreModal, setShowRestoreModal] = useState(false);
    const [pollKey, setPollKey] = useState("");
    const [pollUniqueCode, setPollUniqueCode] = useState("");
    const [pollType, setPollType] = useState("");

    const filterCustomize = (rows, _, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase().trim() : "";
        const filteredStartDate = globalFilterValue.startDate;
        const filteredEndDate = globalFilterValue.endDate;
        const filteredMode = globalFilterValue.mode;
        const filteredStatus = globalFilterValue.status;
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.pollTitle && values.pollTitle.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            if (r.original.courseCode && r.original.courseCode.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            return false;
        }).filter(r => {
            if (!filteredStartDate && !filteredEndDate) {
                return true;
            }
            if (!filteredStartDate) {
                return isBefore(r.values.deletionTime, filteredEndDate);
            }
            if (!filteredEndDate) {
                return isBefore(filteredStartDate, r.values.deletionTime);
            }
            const values = r.values;
            return values.deletionTime && isBefore(filteredStartDate, r.values.deletionTime) && isBefore(r.values.deletionTime, filteredEndDate);
        }).filter(r => {
            if(!filteredMode || filteredMode.length === 0) {
                return true;
            }
            const values = r.values;
            if(filteredMode.includes(MERGED_MODE) && [POLL_TYPE.MERGED_POLL, GRADED_POLL, SURVEY].includes(values.pollType)) {
                return true;
            }

            if(filteredMode.includes(ATTENDANCE) && [POLL_TYPE.ATTENDANCE].includes(values.pollType)) {
                return true;
            }

        }).filter(r => {
            if(!filteredStatus || filteredStatus.length === 0) {
                return true;
            }
            const values = r.values;
            if (filteredStatus.includes(UNFINISHED) && (!values.pollShareMode || values.pollSortKey === DRAFT_STATUS)) {
                return true;
            }
            if (filteredStatus.includes(READY_TO_SHARE) && values.pollShareMode === POLL_SHARE_MODE.UNKNOWN && r.original?.isValid) {
                return true;
            }
            if (filteredStatus.includes(SHARED_BY_QUESTION) && values.pollShareMode === POLL_SHARE_MODE.SHARE_EACH && values.pollType !== POLL_TYPE.ATTENDANCE) {
                return true;
            }
            if (filteredStatus.includes(SHARED_ENTIRELY) && (values.pollShareMode === POLL_SHARE_MODE.SHARE_ALL || (values.pollType === POLL_TYPE.ATTENDANCE && values.pollShareMode === POLL_SHARE_MODE.SHARE_EACH))) {
                return true;
            }
            if (filteredStatus.includes(SCHEDULED) && values.pollShareMode === POLL_SHARE_MODE.SCHEDULE) {
                return true;
            }
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize},
        setGlobalFilter,
        selectedFlatRows,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: pageIdx, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, 
        usePagination, 
        useRowSelect,        
        hooks => {
        hooks.visibleColumns.push(columns => [
            // Let's make a column for selection
            {
                id: CHECKBOX_COLUMN_ID,
                // The header can use the table's getToggleAllRowsSelectedProps method to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
                Cell: ({ row }) => (
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                ),
                width: 50,
            },
            ...columns,
        ])
    });

    useEffect(() => {
        const PollKeys = selectedFlatRows.map(r =>  r.original.pollKey + "_" + r.original.pollSortKey);
        if(selectedFlatRows.length > 0) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
        handleSelectPoll(PollKeys, pageIndex);
    }, [selectedFlatRows.length]);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [data, globalFilterObj]);


    useEffect(() => {
        if (!pageOptions.length) {
            gotoPage(0);
            return;
        }

        if (pageIdx < pageOptions.length) {
            gotoPage(pageIdx);
        } else {
            gotoPage(pageIdx - 1);
        }

        for (let i = 0; i < pageSize; i++) {
            if (document.getElementById(`menu-${i}`)) {
                document.getElementById(`menu-${i}`).style.display = "inline-block";
            }
            if (document.getElementById(`options-${i}`)) {
                document.getElementById(`options-${i}`).style.display = "none";
            }

        }
    }, [data, pageIdx, pageOptions]);

    
    

    const onDeleteDraft = (pollKey, pollType) => {
        setPollKey(pollKey);
        setPollType(pollType);
        setShowDeleteModal(true);
    }

    const onRestoreDraft = (pollKey, pollType) => {
        setPollKey(pollKey);
        setPollType(pollType);
        setShowRestoreModal(true);
    }

    const onRestorePublished = (pollKey, uniqueCode, pollType, poll) => {
        setPollKey(pollKey);
        setPollUniqueCode(uniqueCode);
        setPollType(pollType);
        setShowRestoreModal(true);
    }

    const onDeletePublished = (pollKey, uniqueCode, pollType, poll) => {
        setPollKey(pollKey);
        setPollUniqueCode(uniqueCode);
        setPollType(pollType);
        const pollState = poll?.pollState;
        const pollShareMode = poll?.pollShareMode;
        const liveQuestionTime = poll?.liveQuestionTime;
        const timeLimit = poll?.pollTimeLimit;
        const start = new Date(poll?.startTime);
        const end = new Date(poll?.endTime);


        const now = new Date();
        if ((pollShareMode === POLL_SHARE_MODE.SCHEDULE && start && start < now && end && now < end) ||
            (pollShareMode === POLL_SHARE_MODE.SHARE_ALL && !!pollType && !!liveQuestionTime &&
                ((pollType === SURVEY && pollState === POLL_STATE.SHARED_ALL) || ((+now) < (liveQuestionTime + (timeLimit * 1000))))
            )) {
            notifyError(DELETE_ACTIVE_POLL_ERROR);
            return;
        }
        setShowDeleteModal(true);
    }


    const handleDelete = async () => {
        console.log(pollKey, pollUniqueCode, pollType);
        let key = removePrefix(pollKey);
        if (pollUniqueCode) { // delete published
            console.log("DELETE PUBLISHED POLL");
            let pollCode = pollUniqueCode.substring(7);
            const res = await hardDeletePolls([key], [pollCode], true, pollType);
            if (res.success) {
                getPollInformation(pageIndex);
            }
        } else { // delete draft
            console.log("DELETE DRAFT POLL");
            const res = await hardDeletePolls([key], [""], true, pollType);
            if (res.success) {
                getPollInformation(pageIndex);
            }
        }
        setShowDeleteModal(false);
    };

    const handleRestore = async () => {
        console.log(pollKey, pollUniqueCode, pollType);
        let key = removePrefix(pollKey);
        if (pollUniqueCode) { // delete published
            console.log("Restore PUBLISHED POLL");
            let pollCode = pollUniqueCode.substring(7);
            const res = await restorePolls([key], [pollCode], true, pollType);
            if (res.success) {
                getPollInformation(pageIndex);
            }
        } else { // delete draft
            console.log("Restore DRAFT POLL");
            const res = await restorePolls([key], [""], true, pollType);
            if (res.success) {
                getPollInformation(pageIndex);
            }
        }
        setShowRestoreModal(false);
    };

    const deletePollConfig = {
        title: DELETE_ACTIVITY,
        okText: MODAL_DELETE_TEXT,
        cancelText: MODAL_CANCEL_TEXT, 
        submit: () => {
            handleDelete();
        },
        close:  () => {
            setShowDeleteModal(false);
        }, 
    };

    const displayPollType = (pollType) => {
        if (pollType === POLL_TYPE.ATTENDANCE) {
            return ATTENDANCE;
        } else if (pollType === GRADED_POLL) {
            return GRADED_POLL;
        } else if (pollType === SURVEY) {
            return SURVEY;
        } else {
            return MERGED_MODE;
        }
    }

    const restorePollConfig = {
        title: RESTORE_ACTIVITY,
        okText: RESTORE_TEXT,
        cancelText: MODAL_CANCEL_TEXT, 
        submit: () => {
            handleRestore();
        },
        close:  () => {
            setShowRestoreModal(false);
        }, 
    };

    const formatDate = (input) => {
        let date = new Date(input)
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JavaScript
        const year = date.getFullYear();
      
        return `${day}-${month}-${year}`;
      };

    return (
        <>
            <YuJaGeneralAlert 
                show={showDeleteModal}
                setModalShow={setShowDeleteModal}
                content={PERMANENT_DELETE_POLL_MODAL_BODY}
                config={deletePollConfig}
                AlertType={MODAL_DELETE_TEXT}/>
            <YuJaGeneralAlert 
                show={showRestoreModal}
                setModalShow={setShowRestoreModal}
                content={RESTORE_POLL_MODAL_BODY}
                config={restorePollConfig}
                AlertType={MODAL_CONFIRM_TEXT}/>
            <div className={styles.tableContainer}>
                <div style={{}}>
                <Table hover {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth,
                                            textAlign: column.render('Header') === ACTION_HEADER || column.render('Header') === FEEDBACK_HEADER  ? 'center' : 'left',
                                        }
                                    })}>
                                        <span tabIndex={0} role={TEXTBOX} className={column.render('Header') === DATE_HEADER ? styles.dateCell : styles.tableCell}>
                                            <div style={{padding: "10px"}}> {column.render('Header')}  </div>
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page && page.length !== 0 ? page.map((row, index) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        if (cell.column.Header === ACTION_HEADER) {
                                            const isMergedOrAttendance = [POLL_TYPE.MERGED_POLL, POLL_TYPE.ATTENDANCE].includes(cell.row.original.pollType);
                                            return (
                                                <td {...cell.getCellProps()} className={styles.tableBtnUnit}>
                                                    <div tabIndex={0}  role={TEXTBOX} aria-label={ACT_ITEMS + row.cells.at(index-4).value} style={{padding: "10px", display: "flex", justifyContent: "center"}}>
                                                        <YuJaKebabMenu >
                                                            <YuJaKebabMenu.Menu>
                                                                <YuJaKebabMenu.Item
                                                                    onClick={() => {
                                                                        cell.row.original[POLL_SORT_KEY] === DRAFT_STATUS ?
                                                                        onDeleteDraft(cell.value, cell.row.values[POLL_TYPE_ACCESSOR]) :
                                                                        onDeletePublished(cell.value, cell.row.original[POLL_SORT_KEY], cell.row.values[POLL_TYPE_ACCESSOR], cell.row.original)
                                                                    }}
                                                                >
                                                                    <DeleteIcon style={{width: 18, marginRight: 5}}/>{MODAL_DELETE_TEXT}
                                                                </YuJaKebabMenu.Item>

                                                                {isMergedOrAttendance &&
                                                                <YuJaKebabMenu.Item
                                                                  onClick={() => {
                                                                    cell.row.original[POLL_SORT_KEY] === DRAFT_STATUS ?
                                                                        onRestoreDraft(cell.value, cell.row.values[POLL_TYPE_ACCESSOR]) :
                                                                        onRestorePublished(cell.value, cell.row.original[POLL_SORT_KEY], cell.row.values[POLL_TYPE_ACCESSOR], cell.row.original)
                                                                    }}
                                                                >
                                                                    <ReleaseIcon style={{width: 18, marginRight: 5}} />{RECYCLE_BIN_RESTORE_TEXT}
                                                                </YuJaKebabMenu.Item>
                                                                }
                                                                
                                                            </YuJaKebabMenu.Menu>
                                                        </YuJaKebabMenu>
                                                    </div>
                                                </td>
                                            );
                                        } else if (cell.column.Header === POLL_STATUS_HEADER) {
                                            return (
                                                <td role={TEXTBOX} aria-label={POLL_STATUS + row.cells.at(index-3).value + getPollStatus(cell.value, cell.row.original?.isValid)} tabIndex={0} {...cell.getCellProps()}>
                                                    <div style={{padding: "10px"}}> {getPollStatus(cell.value, cell.row.original?.isValid, cell.row.original?.pollType)}</div>
                                                </td>
                                            );
                                        } else if (cell.column.Header === FEEDBACK_HEADER) {
                                            return (
                                                <td role={TEXTBOX} aria-label={"Feedback Count for "  + row.cells.at(index-4).value +(!cell.value ? 0 : cell.value)} tabIndex={0} {...cell.getCellProps()}>
                                                   <div style={{padding: "10px" , fontSize: "13px", textAlign: "center"}}> {!cell.value ? 0 : <div style={{fontWeight: 700}}> {cell.value} </div>} </div>
                                                </td>

                                            );
                                        } else if (cell.column.Header === TITLE_HEADER) {
                                            return (
                                                <td tabIndex={0} role={TEXTBOX} aria-label={TITLE + cell.value}
                                                    title={cell.value && cell.value.length > POLL_TITLE_CHARACTER_LIMIT ? cell.value : ""}
                                                    {...cell.getCellProps()}
                                                >
                                                    <div style={{padding: "10px" , width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}> {cell.value && cell.value.length > POLL_TITLE_CHARACTER_LIMIT ?
                                                        cell.value.substring(0, POLL_TITLE_CHARACTER_LIMIT) + "..." :
                                                        cell.value ? cell.value : ""
                                                    }</div>
                                                </td>

                                            );
                                        } else if (cell.column.Header === POLL_TYPE_HEADER) {
                                            return (
                                                <td tabIndex={0} role={TEXTBOX} aria-label={MODE + cell.value + " for " + row.cells.at(1).value} {...cell.getCellProps()}>
                                                     <div style={{padding: "10px"}}>{displayPollType(cell.value)} </div>
                                                </td>
                                            );
                                        } else if (cell.column.Header === DELETION_TIME_HEADER) {
                                            return (
                                                <td tabIndex={0} role={TEXTBOX} aria-label={cell.column.Header === "Date" ? (DATE + cell.value): (QUES_LABEL + row.cells.at(index-2).value + " " + cell.value)} {...cell.getCellProps()}>
                                                    <div className={cell.column.render('Header') === DELETION_TIME_HEADER ? styles.dateCell : styles.tableCell} style={{padding: "10px"}}>
                                                        {cell.column.render('Header') === DELETION_TIME_HEADER ? formatDate(cell.value): cell.value}
                                                    </div>
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td tabIndex={cell.column.id === CHECKBOX_COLUMN_ID ? -1 : 0} aria-label={cell.column.id + " " + cell.value}
                                                    className={cell.column.id === CHECKBOX_COLUMN_ID ? styles.checkboxCell : ""}
                                                    style={index !== 0 ? {overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"} : {}}
                                                    {...cell.getCellProps()}
                                                >
                                                    <div style={{padding: "10px"}}> {cell.render('Cell')} </div>
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )
                        }) : <tr><td colSpan="6" className={styles.noData} style={{boxShadow: "none"}}> <div style={{height: 450, justifyContent: "center", display: "flex", marginTop: "20px", flexDirection: "column", alignItems: "center", gap: "5px"}}> <NoResultIllustration /> <div style={{fontSize: "18px", marginTop: "10px"}}> {NO_RESULTS_FOUND} </div> </div></td></tr>}
                    </tbody>
                </Table>
                </div>

                {page && page.length !== 0 ? <>
                    <div style={{ position: "absolute", bottom: "25px" }}>
                        <NormalPagination
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            pageOptions={pageOptions}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            gotoPage={gotoPage}
                            paddingTop="0px"
                        />
                    </div>
                </> : null}
            </div>
        </>
    );
}