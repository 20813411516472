import React, { useCallback, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { PollApiCalls } from "../../apis/PollApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as RecycleBin } from "../../images/recycle_active.svg";
import { ACTION_HEADER, DEFAULT_ROWS_PER_PAGE, DELETE_ACTIVITIES, DELETION_TIME, DELETION_TIME_HEADER, MENU_LIST, MODAL_CANCEL_TEXT, MODAL_CONFIRM_TEXT, MODAL_DELETE_TEXT, PERMANENT_DELETE_POLL_SELECTED_MODAL_BODY, POLL_KEY, POLL_TITLE, POLL_TYPE_ACCESSOR, POLL_TYPE_HEADER, QUESTIONS_HEADER, RECYCLE_BIN_TITLE, RESTORE_ACTIVITIES, RESTORE_POLL_SELECTED_MODAL_BODY, RESTORE_TEXT, TITLE_HEADER, TOTAL_QUESTIONS } from "../../utils/constants";
import { notifyError, removePrefix } from "../../utils/helpers";
import { MISSING_POLL_ERROR } from "../../utils/toast-message-constants";
import styles from "./ManagePolls.module.css";
import RecycleBinSearchForm from "./RecycleBinSearchForm";
import RecycleBinTable from "./RecycleBinTable";
export default function RecycleBinPage(props) {
    const [pageIndex, setPageIndex] = useState(0);
    const [dataReady, setDataReady] = useState(false);
    const {getDeletedPollsFromUser, hardDeletePolls, restorePolls} = PollApiCalls();
    const [pollData, setPollData] = useState([]);
    const isMobileScreen = useMobileAccess();
    const [filter, setFilter] = useState({});
    const [showButtons, setShowButtons]  = useState(false);
    const [selectedPollCodes, setSelectedPollCodes] = useState([]);
    const [selectedPollKeys, setSelectedPollKeys] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRestoreModal, setShowRestoreModal] = useState(false);
    const columns = [
      { Header: TITLE_HEADER, accessor: POLL_TITLE, width: 250 },
      { Header: DELETION_TIME_HEADER, accessor: DELETION_TIME, width: isMobileScreen ? 90 : 110 },
      { Header: POLL_TYPE_HEADER, accessor: POLL_TYPE_ACCESSOR, width: isMobileScreen ? 90 : 110 },
      { Header: QUESTIONS_HEADER, accessor: TOTAL_QUESTIONS, width: isMobileScreen ? 80 : 100 },
      { Header: ACTION_HEADER, accessor: POLL_KEY, width: isMobileScreen ? 70 : 90, id: 'action' }
    ];

    const getPollInformation = async (pageIndex=0) => {
      setDataReady(false);
      const res = await getDeletedPollsFromUser(true);
      res.sort(function (a, b) {
        return new Date(Date.parse(b.deletionTime)) - new Date(Date.parse(a.deletionTime));
      });
      setPollData(res);
      setPageIndex(pageIndex);
      setDataReady(true);
    };
  
    useEffect(() => {
      getPollInformation();
    }, []);

    useEffect(() => {
      props.setPageIdentifier(MENU_LIST.RECYCLEBIN.name);
    }, []);


    const handleSubmit = useCallback(({ ...filterObj }) => {
      setFilter(filterObj);
        setPageIndex(0);
      }, []);


      const handleSelectPoll = (map, pageIndex) => {
        const pollKeys = [];
        const pollCodes = [];

        map.forEach(item => {
          const [pollKey, pollCode] = item.split('_');
          pollKeys.push(removePrefix(pollKey));
          pollCodes.push(pollCode.substring(7));
        });
        setSelectedPollKeys(pollKeys);
        setSelectedPollCodes(pollCodes);
        setPageIndex(pageIndex);
    };


    const handleDeleteSelected = async () => {
      if(selectedPollKeys.length <= 0) {
          notifyError(MISSING_POLL_ERROR);
          return false;
      }

      const res = await hardDeletePolls(selectedPollKeys, selectedPollCodes);
      if (res) {
          await getPollInformation(pageIndex);
      }
      setShowDeleteModal(false); 
  };

  const handleRestoreSelected = async () => {
    if(selectedPollKeys.length <= 0) {
        notifyError(MISSING_POLL_ERROR);
        return false;
    }

    const res = await restorePolls(selectedPollKeys, selectedPollCodes);
    if (res) {
        await getPollInformation(pageIndex);
    }
    setShowRestoreModal(false); 
};

  const deletePollConfig = {
    title: DELETE_ACTIVITIES,
    okText: MODAL_DELETE_TEXT,
    cancelText: MODAL_CANCEL_TEXT,
    submit: () => {
      handleDeleteSelected();
    },
    close: () => {
      setShowDeleteModal(false);
    },
  };

  const  restorePollConfig = {
    title: RESTORE_ACTIVITIES,
    okText: RESTORE_TEXT,
    cancelText: MODAL_CANCEL_TEXT,
    submit: () => {
      handleRestoreSelected();
    },
    close: () => {
      setShowRestoreModal(false);
    },
  };

  // const restorePollConfig = {
  //   title: Restore_ACTIVITY,
  //   okText: YES_TEXT,
  //   cancelText: MODAL_CANCEL_TEXT,
  //   submit: () => {
  //     handleRestore();
  //   },
  //   close: () => {
  //     setShowRestoreModal(false);
  //   },
  // };
    
    return (
        <div style={{padding: "30px 5%"}}>
          <YuJaGeneralAlert 
                show={showDeleteModal}
                setModalShow={setShowDeleteModal}
                content={PERMANENT_DELETE_POLL_SELECTED_MODAL_BODY}
                config={deletePollConfig}
                AlertType={MODAL_DELETE_TEXT}/>
                <YuJaGeneralAlert 
                show={showRestoreModal}
                setModalShow={setShowRestoreModal}
                content={RESTORE_POLL_SELECTED_MODAL_BODY}
                config={restorePollConfig}
                AlertType={MODAL_CONFIRM_TEXT}/>
          <div className={styles.titleBox}>

            
              <RecycleBin style={{marginRight: "5px"}} /> 
              <div tabIndex={0} className={styles.titleBoxTitle}>{RECYCLE_BIN_TITLE}</div>
            </div>
            <RecycleBinSearchForm handleSubmit={handleSubmit} showButton={showButtons} collapsed={props?.collapsed} handleDelete={() => {setShowDeleteModal(true)}} handleRestore={() => {setShowRestoreModal(true)}}/>
          {dataReady &&
        <RecycleBinTable columns={columns} setShowButtons={setShowButtons} handleSelectPoll={handleSelectPoll} data={pollData} pageIdx={pageIndex} globalFilterObj={filter} getPollInformation={getPollInformation}/>
          }
        {!dataReady && 
          <div className={styles.skeletonTableContainer}>
          <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <div className={styles.skeletonRecycleHeaderContainer}>
              <div className={styles.skeletonHeaderCell}>{TITLE_HEADER}</div>
              <div className={styles.skeletonHeaderCell}>{DELETION_TIME_HEADER}</div>
              <div className={styles.skeletonHeaderCell}>{POLL_TYPE_HEADER}</div>
              <div className={styles.skeletonHeaderCell}>{QUESTIONS_HEADER}</div>
              <div className={styles.skeletonActionHeaderCell}>{ACTION_HEADER}</div>
            </div>
            <div className={styles.skeletonBodyContainer}>
              {Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                return (<div className={styles.skeletonRecycleBodyRow}>
                  <Skeleton borderRadius={10} inline={true} width={`${2}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  <Skeleton borderRadius={10} height={20} inline={true} width={`${2}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  <Skeleton borderRadius={10} height={20} inline={true} width={`${2}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  <Skeleton borderRadius={10} height={20} inline={true} width={`${1 + Math.random()}vw`} className={styles.skeletonBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                  <Skeleton borderRadius={10} height={20} inline={true} width="2vw" className={styles.skeletonActionBodyCell} containerClassName={styles.skeletonBodyCellWrapper} />
                </div>)
              })}
            </div>
          </SkeletonTheme>
          </div>
        }
        </div>

    );



}